angular.module('core.providers', [])
    .provider('template', [function() {

        this.$get = function() {
            return {};
        };

        this.getTemplate = function(templateName) {
            var absurl = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
            var indexOfAppRel = absurl.toLowerCase().indexOf("app_release");
            var templateUrl = absurl.substring(0,indexOfAppRel) + "templates/" + templateName;
            return templateUrl;
        };

 }])
    .constant('templates', function(templateProvider) { return templateProvider; });