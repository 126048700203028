/**
 * Created by jvitoria on 1/6/2015.
 */
angular.module('pnReportSettings', ['core.ajax'])
    .directive('reportSettings', ['ReportSettingsService', 'templates', 'localizationSvc', function (ReportSettingsService, templates, localizationSvc)
    {
        return {
            restrict: 'E',
            scope: {
                showSettingsBar: '@',
                allowLoad: '@',
                allowSave: '@',
                allowExport: '@',
                allowCsv: '@',
                allowXml: '@',
                allowPdf: '@',
                allowExcel: '@',
                exportReportData: '&',
                saveOptions: '='
            },
            controller: function ($rootScope, $scope)
            {
                $scope.initReportSettings = function ()
                {
                    $scope.exportOptions = [];
                    if ($scope.allowCsv !== 'false')
                    { $scope.exportOptions.push({id: '2', name: 'CSV'}); }
                    if ($scope.allowXml !== 'false')
                    { $scope.exportOptions.push({id: '7', name: 'XML'}); }
                    if ($scope.allowPdf !== 'false')
                    { $scope.exportOptions.push({id: '1', name: 'PDF'}); }
                    if ($scope.allowExcel !== 'false')
                    { $scope.exportOptions.push({id: '3', name: 'Excel'}); }
                    if ($scope.exportOptions.length > 0)
                    { $scope.selectedExportOptions = $scope.exportOptions[0].id; }

                    if (!$rootScope.reportSettings)
                    {
                        $rootScope.reportSettings = {loadOptions: {}, saveOptions: {}};
                    }
                };
            },

            link: function (scope, element, attrs) {
                var localStrings = localizationSvc.getCachedLocalizationsContainer("ReportSettingsLocalization");
                if (!attrs.showSettingsBar)
                { attrs.showSettingsBar = true; }
                if (!attrs.allowLoad)
                { attrs.allowLoad = true; }
                if (!attrs.allowSave)
                { attrs.allowSave = true; }
                if (!attrs.allowExport)
                { attrs.allowExport = true; }

                scope.modalInfo = {saveSettingsName: ''};
                scope.showModalLoad = false;
                scope.showModalSave = false;
                scope.showModalConfirmSave = false;
                scope.selectedKey = 0;
                scope.selectedReportContactKey = 0;
                scope.selectedDescription = "";

                scope.canDelete = function (key)
                {
                    return scope.$parent.$parent.appInfo.webUser.CurrentAuthorization.CustContKey === key;
                };

                scope.exportData = function ()
                {
                    if (angular.isUndefinedOrNullOrEmpty(scope.exportReportData()))
                        return;
                    scope.exportReportData();
                };

                scope.applyReportSettings = function ()
                {
                    if (scope.selectedKey === 0)
                    {
                        scope.$parent.notifier.show(localStrings.getLocalizedString("PleaseSelectReport"), 'error');
                        return;
                    }
                    scope.$root.reportSettings.unwrapReportSettings();
                    scope.showModalLoad = false;
                };

                scope.deleteReportSettings = function (key)
                {
                    var apply = function ()
                    {
                        ReportSettingsService.saveReportSettings
                        (
                            scope.$parent.$parent.appInfo.webUser.CurrentAuthorization.CustContKey,
                            scope.$parent.$parent.appInfo.webUser.CurrentAuthorization.CustKey,
                            "",
                            "",
                            8,
                            scope.$root.reportSettings.reportKey,
                            key
                        ).then
                        (function ()
                        {
                            scope.getReportSettings(true);
                        });
                    };

                    scope.$parent.$parent.modalifier.confirm(localStrings.getLocalizedString("DeleteSelectedReportSettings"), apply);
                };

                scope.getReportSettings = function (notify)
                {
                    ReportSettingsService.getReportSettings(
                        scope.$parent.$parent.appInfo.webUser.CurrentAuthorization.CustKey,
                        scope.$root.reportSettings.reportKey
                    ).then
                    (
                        function (data)
                        {
                            scope.returnedReportSettings = data.ReportSettings;
                            if (scope.returnedReportSettings.ARWebSettings.length === 0 && notify)
                                scope.$parent.notifier.show(localStrings.getLocalizedString("SelectionCriteriaNoResults"), 'warning');
                        }
                    );
                };

                scope.saveSettings = function ()
                {
                    if (!scope.returnedReportSettings)
                        scope.getReportSettings(false);
                    scope.showModalSave = !scope.showModalSave;
                };

                scope.loadSettings = function ()
                {
                    scope.getReportSettings(true);
                    scope.showModalLoad = !scope.showModalLoad;
                };

                scope.saveConfirmReportSettings = function ()
                {
                    scope.showModalSave = false;
                    if (scope.selectedKey > 0 && scope.selectedDescription === scope.modalInfo.saveSettingsName)
                    {
                        scope.showModalConfirmSave = true;
                        return;
                    }
                    scope.selectedKey = 0;
                    scope.saveReportSettings();
                };

                scope.saveReportSettings = function ()
                {
                    if (scope.selectedKey > 0)
                        scope.deleteReportSettings(true);
                    if (angular.isDefined(scope.$root.reportSettings.wrapReportSettings))
                    {
                        scope.$root.reportSettings.wrapReportSettings();
                        ReportSettingsService.saveReportSettings
                        (
                            scope.$parent.$parent.appInfo.webUser.CurrentAuthorization.CustContKey,
                            scope.$parent.$parent.appInfo.webUser.CurrentAuthorization.CustKey,
                            scope.modalInfo.saveSettingsName,
                            scope.$root.reportSettings.saveOptions,
                            4,
                            scope.$root.reportSettings.reportKey,
                            -1
                        );

                        scope.showModalConfirmSave = false;
                        scope.showModalSave = false;
                    }
                    else
                        scope.$parent.notifier.show(localStrings.getLocalizedString("ProcessFailedError"), 'error');
                };

                scope.selectKey = function (item)
                {
                    scope.selectedKey = item.Key;
                    scope.selectedDescription = item.Description;
                    scope.modalInfo.saveSettingsName = item.Description;
                    scope.modalInfo.saveSettingsKey = item.Key;

                    scope.$root.reportSettings.loadOptions = angular.fromJson(item.Options);
                };
            },
            templateUrl: templates.getTemplate("ReportSettings") //'reportSettings/reportSettings.tpl.html'
        };
    }])

    .factory('ReportSettingsService', function ($q, apiService)
    {
        return {
            getReportSettings: function (custKey, reportKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetReportSettings/',
                        data: {
                            CustKey: custKey,
                            RptKey: reportKey
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.reject(data.ErrorMessages);
                    }
                );
                return deferred.promise;
            },
            saveReportSettings: function (custContKey, custKey, description, options, recStatus, reportKey, settingsKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/saveReportSettings/',
                        data: {
                            CustContKey: custContKey,
                            CustKey: custKey,
                            Description: description,
                            Options: angular.toJson(options, false),
                            RecStatus: recStatus,
                            ReportKey: reportKey,
                            SettingsKey: settingsKey
                        }
                    }
                );
                apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.reject(data.ErrorMessages);
                    }
                );
                return deferred.promise;
            }
        };
    });
