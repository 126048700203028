/* ==============================================================================
services.globalizations.js MODULE
============================================================================= */
angular.module('services.globalizations', ['core.ajax']);

/* ==============================================================================
globalizationSvc
============================================================================= */
angular.module('services.globalizations',[]).factory('globalizationSvc', function() {

    'use strict';
    
    // Set the locale to the browser language, if it is null then set it to en-US
    var locale = (window.navigator.language === 'en-US' || window.navigator.language === 'fr-CA') ? window.navigator.language : 'en-US';

    return {
        getLocale: function () {
            return locale;
        },

        formatCurrency : function(num) {
            // Set the currency id based on the locale, default to USD
            var curID = (locale === 'fr-CA') ? 'CAD' : 'USD';

            return num.toLocaleString(locale, {style: 'currency', currency: curID});
        },

        formatNumeric: function (num, format) {
            return kendo.toString(num, format, locale);
        },

        // See https://docs.telerik.com/kendo-ui/globalization/intl/dateformatting for formats
        formatDateTime: function (dateString, format) {
            if (!dateString || dateString === "")
            {
                return "";
            }
                

            var date = new Date(dateString);
            return kendo.toString(date, format, locale);
        },

        convertStringToNumber: function (num) {
            if (typeof (num) === 'number')
                return num;

            if (locale === 'en-US') {
                num = num.replace(/[,$]/g, "");
            }
            else if (locale === 'fr-CA') {
                num = num.replace(/[$\s]/g, "").replace(",", ".");
            }

            return num;
        }
    };
});