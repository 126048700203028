/**
 * Created by rhampton on 2/26/14.
 */
angular.module("pnAccountPayments", ['ngSanitize', 'core.objects', 'services.payments', 'core.directives'])
    .config(function ($provide, $routeProvider, templateProvider)
    {
        //http://localhost/arcustweb/Templates/MakeAPayment"

        $routeProvider.when("/Account/Payments/MakeAPayment",
            {
                templateUrl: templateProvider.getTemplate("MakeAPayment"),
                controller: "MakeAPaymentCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/MakeAPayment/:paymentKey",
            {
                templateUrl: templateProvider.getTemplate("MakeAPayment"),
                controller: "MakeAPaymentCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/AccountVerifyPayment",
            {
                templateUrl: templateProvider.getTemplate("AccountVerifyPayment"),
                controller: "VerifyPaymentCtrl"
            }
        )
            .when("/Account/Payments/AccountConfirmPayment",
            {
                templateUrl: templateProvider.getTemplate("AccountConfirmPayment"),
                controller: "ConfirmPaymentCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/ViewScheduledPayments",
            {
                templateUrl: templateProvider.getTemplate("ViewScheduledPayments"),
                controller: "ScheduledPaymentHistoryCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/ViewPaymentAccounts",
            {
                templateUrl: templateProvider.getTemplate("ViewPaymentAccounts"),
                controller: "PaymentAccountsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/EditPaymentAccount",
            {
                templateUrl: templateProvider.getTemplate("EditPaymentAccount"),
                controller: "EditAccountCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/EditPaymentAccount/:paymentAccountKey",
            {
                templateUrl: templateProvider.getTemplate("EditPaymentAccount"),
                controller: "EditAccountCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/VerifyPaymentAccount",
            {
                templateUrl: templateProvider.getTemplate("VerifyPaymentAccount"),
                controller: "VerifyAccountCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/ConfirmPaymentAccount/:paymentAccountKey",
            {
                templateUrl: templateProvider.getTemplate("ConfirmPaymentAccount"),
                controller: "ConfirmAccountCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/PaymentAccountVerification/:paymentAccountKey",
            {
                templateUrl: templateProvider.getTemplate("PaymentAccountVerification"),
                controller: "AccountVerificationCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/PaymentAccountVerificationVerify/:paymentAccountKey",
            {
                templateUrl: templateProvider.getTemplate("PaymentAccountVerificationVerify"),
                controller: "AccountVerificationVerificationCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/PaymentAccountVerificationConfirmation/:paymentAccountKey",
            {
                templateUrl: templateProvider.getTemplate("PaymentAccountVerificationConfirmation"),
                controller: "AccountVerificationConfirmationCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/TermsAndConditions/",
            {
                templateUrl: templateProvider.getTemplate("TermsAndConditions"),
                controller: "TermsAndConditionsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
            .when("/Account/Payments/PaymentAccountVerificationFailure/",
            {
                templateUrl: templateProvider.getTemplate("PaymentAccountVerificationFailure"),
                controller: "AccountVerificationFailCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization");
                    }
                }
            }
        )
        ;
    })
    .controller("TermsAndConditionsCtrl", function ($scope, $http, $q, $routeParams, $filter, PaymentService, validatorSvc, WebFunctions, templates, localStrings)
    {
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPayments))
            {
                $scope.goTo("/Home");
            }
            validatorSvc.clearValidationScopes();
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenuData = {};
            if ($scope.checkCurrentUserPermission($scope.webFunctions.AddandEditWebPayments))
                $scope.locationInfo.sideMenuData.subMenu = '4';
            else
                $scope.locationInfo.sideMenuData.subMenu = '5';

            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            PaymentService.getTermsAndConditions($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms).then(function (data)
            {
                $scope.theTermsAndConditions = data;
            });
        };

        $scope.declineTerms = function ()
        {
            $scope.goTo("/Home");
        };

        $scope.acceptTerms = function ()
        {
            if (!$scope.agreeToTerms)
            {
                $scope.notifier.show(localStrings.getLocalizedString("PleaseAcceptTermsConditions"), 'error');
                return;
            }

            PaymentService.acceptTermsAndConditions(
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            ).then(function (data)
                {
                    $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings = data.CustomerWebPaymentSettings;
                    if ($scope.checkCurrentUserPermission($scope.webFunctions.AddandEditWebPayments) &&
                        $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.ActiveAccountCount > 0)
                        $scope.goTo('/Account/Payments/ViewScheduledPayments');
                    else if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.ActiveAccountCount > 0)
                        $scope.goTo('/Account/Payments/ViewPaymentAccounts');
                    else
                        $scope.goTo('/Account/Payments/EditPaymentAccount');
                });
        };

    })
    .controller("MakeAPaymentCtrl", function ($scope, $http, $q, $routeParams, $filter, PaymentService, validatorSvc, WebFunctions, templates, localStrings, localizationSvc, globalizationSvc, $rootScope)
    {
        'use strict';
        $scope.paymentKey = 0;
        $scope.customText = "";
        $scope.kendo = {};

        $scope.kendo.invoicesGrid = {};
        $scope.kendo.invoicesGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "Selected",
                    width: 35,
                    attributes: {class: "alignCenter"},
                    filterable: false,
                    sortable: false,
                    headerTemplate: "<input type='checkbox' id='invCheckAll' ng-click='checkAll(kendo.invoicesGrid, \"invCheckAll\")' class='alignCenter'/>",
                    template: "<input type='checkbox' ng-model='dataItem.Selected'/>"
                },
                {
                    field: "DueDate1",
                    title: localStrings.getLocalizedString("DueDate"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Balance",
                    title: localStrings.getLocalizedString("AmtDue"),
                    format: "{0:c}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "RefNo",
                    title: localStrings.getLocalizedString("InvoiceNum"),
                    attributes: {class: "alignLeftt"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "InvoiceDate",
                    title: localStrings.getLocalizedString("InvoiceDate"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Amount",
                    title: localStrings.getLocalizedString("OrigAmt"),
                    format: "{0:c}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                }
            ],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };
        $scope.kendo.invoicesGridInit = function (ds)
        {
            ds.each(function (charge)
            {
                charge.DueDate1 = Date.create(charge.DueDate1).format('{MM}/{dd}/{yyyy}');
                charge.InvoiceDate = Date.create(charge.InvoiceDate).format('{MM}/{dd}/{yyyy}');
                charge.Balance = globalizationSvc.formatCurrency(charge.Balance);
                charge.Amount = globalizationSvc.formatCurrency(charge.Amount);
            });

            $scope.kendo.invoicesGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.invoicesGridDS.read();
            $scope.kendo.invoicesGrid.dataSource.data($scope.kendo.invoicesGridDS.data());
        };
        $scope.kendo.creditsGrid = {};
        $scope.kendo.creditsGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "Selected",
                    width: 35,
                    attributes: {class: "alignCenter"},
                    filterable: false,
                    sortable: false,
                    headerTemplate: "<input type='checkbox' id='creditCheckAll' ng-click='checkAll(kendo.creditsGrid, \"creditCheckAll\")' class='alignCenter'/>",
                    template: "<input type='checkbox' ng-model='dataItem.Selected'/>"
                },
                {
                    field: "RefDate",
                    title: localStrings.getLocalizedString("Date"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "TranCodeDescription",
                    title: localStrings.getLocalizedString("PaymentType"),
                    attributes: {class: "alignLeftt"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "UnAppAmount",
                    title: localStrings.getLocalizedString("Amt"),
                    format: "{0:c}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "RefNo",
                    title: localStrings.getLocalizedString("ReferenceNum"),
                    attributes: {class: "alignLeftt"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                }
            ],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };
        $scope.kendo.creditsGridInit = function (ds)
        {
            ds.each(function (charge)
            {
                charge.RefDate = Date.create(charge.RefDate).format('{MM}/{dd}/{yyyy}');
                charge.UnAppAmount = globalizationSvc.formatCurrency(charge.UnAppAmount);
            });

            $scope.kendo.creditsGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.creditsGridDS.read();
            $scope.kendo.creditsGrid.dataSource.data($scope.kendo.creditsGridDS.data());
        };

        $scope.initialize = function ()
        {
            $scope.appInfo.webPaymentLocked = false;
            $scope.appInfo.paymentControlOptions = {
                format: 'c',
                culture: globalizationSvc.getLocale(),
                spinners: false
            };
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPayments) || !$scope.paymentsEnabled())
            {
                $scope.goTo("/Home");
            }

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "1";
            PaymentService.lockPayments($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    $scope.initializePart2();
                },
                function (err)
                {
                    $scope.appInfo.webPaymentLocked = true;
                }
            );
        };

        $scope.initializePart2 = function ()
        {
            validatorSvc.clearValidationScopes();

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.invoiceWarning = false;

            if ($routeParams.paymentKey)
            {
                $scope.paymentKey = $routeParams.paymentKey.toNumber();
            }

            if ($scope.paymentKey < 0 && !angular.isUndefinedOrNullOrEmpty($scope.appInfo.webPayment))
            {
                //You should only get here by clicking the change button on verify payment
                //We reset invoice selection if payment type is select invoices
                if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType === "2") {
                    var invoicesValueArr = $scope.InvoiceValues();
                    $scope.selctedInvoicesValue = invoicesValueArr[0];
                    $scope.selctedInvoicesValueDisplay = invoicesValueArr[1];
                }
                $scope.setUpView();
                if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Status !== 0)
                {
                    $scope.goTo("/Account/Payments/AccountConfirmPayment");
                }
            } else
            {
                $scope.appInfo.webPayment = {};
                $scope.appInfo.webPayment.WebPaymentWithContext = {};
                PaymentService.getPayment($scope.appInfo.webUser.CurrentAuthorization.CustKey, $scope.paymentKey).then(function (data)
                {
                    $scope.appInfo.webPayment.WebPaymentWithContext = data;

                    if (parseInt($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType, 10) === 2) {
                        $scope.lockInvoices();
                        $scope.appInfo.webPayment.WebPaymentWithContext = data;
                    }

                    PaymentService.getScheduledPayments(
                        $scope.appInfo.webUser.CurrentAuthorization.CustKey, "", "01/01/1900", "01/01/2079", true, false, false
                    ).then(function (data)
                        {
                            $scope.appInfo.webPayment.WebPaymentWithContext.HasPendingPayments = data.CustomerScheduledPayments.any({ Status: 0 });
                            $scope.appInfo.webPayment.WebPaymentWithContext.HasPendingBudgetPayments = data.CustomerScheduledPayments.any({ Status: 0, PaymentType: 4 }) && !data.CustomerScheduledPayments.any({ Key: $scope.paymentKey, Status: 0, PaymentType: 4 });
                        });

                    $scope.setUpView();

                    if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Status !== 0)
                        $scope.goTo("/Account/Payments/AccountConfirmPayment");
                });
            }

            $scope.selctedInvoicesValue = 0.00;
            $scope.selctedCreditsValue = 0.00;
            $scope.totalInvoiceAmount = 0.00;

            $scope.getWebProfileMessage(18);
        };

        $scope.setPaymentType = function (value)
        {
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = value;
        };

        $scope.setUpView = function ()
        {
            var minDateTime = new Date("0001-01-01T00:00:00".replace(/-/g, "/").replace(/T/g, " "));
            var lastStatementDate = new Date($scope.appInfo.webPayment.WebPaymentWithContext.LastStatementDate.replace(/-/g, "/").replace(/T/g, " "));
            $scope.isStatementCustomer = $scope.appInfo.webPayment.WebPaymentWithContext.IsStatementCustomer;
            $scope.isStatementCustomer = lastStatementDate !== minDateTime;
            $scope.isStatementCustomer = $scope.isStatementCustomer && $scope.appInfo.webPayment.WebPaymentWithContext.LastStatementAmount > 0;
            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Key === 0)
            {
                // we keep Budget Balance payment type when changing budget web payment
                if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType !== "4") {
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = ($scope.isStatementCustomer && $scope.appInfo.webPayment.WebPaymentWithContext.HasUnpaidStatement) ? '1' : '0';
                }
            }

            $scope.isBudgetCustomer = $scope.appInfo.webPayment.WebPaymentWithContext.IsBudgetCustomer;
            $scope.isBBEnabled = $scope.appInfo.webPayment.WebPaymentWithContext.IsBBEnabled;
            // we do not want to display negative baudget balance
            if ($scope.appInfo.webPayment.WebPaymentWithContext.BudgetBalance.toNumber() < 0) {
                $scope.appInfo.webPayment.WebPaymentWithContext.BudgetBalance = '0.00';
            }

            $scope.isInvoiceCustomer = $scope.appInfo.webPayment.WebPaymentWithContext.IsOpenItemCustomer && $scope.appInfo.webPayment.WebPaymentWithContext.Invoices.length > 0;
            if ($scope.appInfo.webPayment.WebPaymentWithContext.IsPrepaidCustomer)
            {
                $scope.isStatementCustomer = false;
                $scope.isInvoiceCustomer = false;
            }
            $scope.appInfo.webPayment.WebPaymentWithContext.WebMinEFTDate = $scope.formatDate($scope.appInfo.webPayment.WebPaymentWithContext.WebMinEFTDate);
            $scope.appInfo.webPayment.WebPaymentWithContext.WebMaxEFTDate = $scope.formatDate($scope.appInfo.webPayment.WebPaymentWithContext.WebMaxEFTDate);

            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Key === 0)
            {
                $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentDate = $scope.appInfo.webPayment.WebPaymentWithContext.WebMinEFTDate;
            }
            else
            {
                if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Status < 2)
                {
                    if (new Date($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentDate) < new Date($scope.appInfo.webPayment.WebPaymentWithContext.WebMinEFTDate))
                    {
                        $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentDate = $scope.appInfo.webPayment.WebPaymentWithContext.WebMinEFTDate;
                        $scope.notifier.show(localStrings.getLocalizedString("PaymentDateNoLongerValid"), 'warning');
                    }
                }
                else
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentDate = $scope.formatDate($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentDate);
            }

            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount.toNumber() || 0;
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);
            $scope.formatCurrencies();
            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType === 4) {
                $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = '4';
            }
            $scope.viewSetupComplete = true;
        };

        $scope.InvoiceValues = function ()
        {
            var theValue = 0.00;
            var theGrossValue = 0.00;
            var theDiscount = 0.00;

            if (!angular.isUndefinedOrNullOrEmpty($scope.kendo.invoicesGrid.dataSource))
                angular.forEach($scope.kendo.invoicesGrid.dataSource.data(), function (charge, index)
                {
                    if (charge.Selected)
                    {
                        if (new Date($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentDate) <= new Date(charge.TermsDiscountDate))
                        {
                            theGrossValue = theGrossValue + parseFloat(globalizationSvc.convertStringToNumber(charge.Balance));
                            theDiscount = theDiscount + charge.TermsDiscount;
                            theValue = theValue + (Math.round((parseFloat(globalizationSvc.convertStringToNumber(charge.Balance)) - charge.TermsDiscount) * 10000) / 10000);
                        } else
                        {
                            theGrossValue = theGrossValue + parseFloat(globalizationSvc.convertStringToNumber(charge.Balance));   
                            theDiscount = theDiscount + 0;
            theValue = theValue + parseFloat(globalizationSvc.convertStringToNumber(charge.Balance));
                        }
                    }
                });

            $scope.selctedInvoicesValue = theValue;
            $scope.selctedInvoicesGrossValue = theGrossValue;
            $scope.selctedInvoicesGrossValueDisplay = globalizationSvc.formatCurrency(theGrossValue);
            $scope.selctedInvoicesDiscountValue = theDiscount;
            $scope.selctedInvoicesDiscountValueDisplay = globalizationSvc.formatCurrency(theDiscount);

            theValue = 0;
            if (!angular.isUndefinedOrNullOrEmpty($scope.kendo.creditsGrid.dataSource))
                angular.forEach($scope.kendo.creditsGrid.dataSource.data(), function (credit, index)
                {
                    if (credit.Selected)
                    {
                        theValue = theValue + parseFloat(globalizationSvc.convertStringToNumber(credit.UnAppAmount));
                    }
                });

            $scope.selctedCreditsValue = parseFloat(theValue);
            $scope.selctedCreditsValueDisplay = globalizationSvc.formatCurrency(theValue);

            $scope.totalInvoiceAmount = Math.round(($scope.selctedInvoicesValue - $scope.selctedCreditsValue) * 10000) / 10000;
            if ($scope.totalInvoiceAmount < 0)
            {
                $scope.totalInvoiceAmount = 0.0;
            }
            $scope.totalInvoiceAmountDisplay = globalizationSvc.formatCurrency(Math.round(($scope.selctedInvoicesValue - $scope.selctedCreditsValue) * 10000) / 10000);

            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = $scope.totalInvoiceAmount;
            return [$scope.selctedInvoicesValue, globalizationSvc.formatCurrency($scope.selctedInvoicesValue)];
        };

        $scope.getCssClasses = function (ngModelController)
        {
            return {
                error: ngModelController && ngModelController.$invalid && ngModelController.$dirty,
                success: ngModelController && ngModelController.$valid && ngModelController.$dirty
            };
        };

        $scope.showError = function (ngModelController, error)
        {
            return ngModelController.$error[error];
        };

        $scope.setScheduledPaymentAmount = function () {
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = globalizationSvc.convertStringToNumber($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay);
        };

        $scope.verifyPayment = function ()
        {
            // pay by invoice
            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType === "2") {
                //set selected invoices
                var grid = $scope.kendo.invoicesGrid;

                //set invoices that are selected in grid
                grid.dataItems().forEach(function (item) {
                    var invoice = $scope.appInfo.webPayment.WebPaymentWithContext.Invoices.find({Key: item.Key});

                    if (angular.isDefined(invoice)) {
                        invoice.Selected = item.Selected;
                    }

                });

                //set selected credits
                var gridCr = $scope.kendo.creditsGrid;

                //set invoices that are selected in grid
                gridCr.dataItems().forEach(function (item) {
                    var credit = $scope.appInfo.webPayment.WebPaymentWithContext.Credits.find({Key: item.Key});

                    if (angular.isDefined(credit)) {
                        credit.Selected = item.Selected;
                    }

                });
            }

            //update who made this payment
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CustKey = $scope.appInfo.webUser.CurrentAuthorization.CustKey;
            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Key === 0)
                $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CreatedByCustContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.LastModifiedByCustContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Status = 0;
            //CustPmtAcctKey has been set to a string from the kendo accounts dropdown, so cast it back to a number
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CustPmtAcctKey = $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CustPmtAcctKey.toNumber();

            //handle the selected payment type
            switch ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType)
            {
                case '1':
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = $scope.appInfo.webPayment.WebPaymentWithContext.LastStatementAmount;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);
                    break;

                case '2':
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CreditAmount = $scope.selctedCreditsValue;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.GrossAmount = $scope.selctedInvoicesGrossValue;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = $scope.totalInvoiceAmount;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.DiscountAmount = $scope.selctedInvoicesDiscountValue;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CreditAmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CreditAmount);
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.GrossAmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.GrossAmount);
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.DiscountAmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.DiscountAmount);
                    break;

                case '3':
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = $scope.appInfo.webPayment.WebPaymentWithContext.AccountBalance;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);
                    break;

                case '4':
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = $scope.appInfo.webPayment.WebPaymentWithContext.BudgetBalance;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);
                    break;

                default:
                    var amount = $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount.toNumber() || 0;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount = amount;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);
                    break;
            }

            //ensure that payment amount is greater than 0
            if (!validatorSvc.executeValidations())
                return;

            //passed all tests; continue with process
            $scope.$cancelPromptToSave();
            $scope.goTo("/Account/Payments/AccountVerifyPayment");
        };

        $scope.paymentIsValid = function ()
        {//Return true if there are errors.

            var elem = $('#paymentSelection');
            elem.css("border", "");

            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount &&
                $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount !== '')
            {
                var theValue = $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount.toString().replace(/$/g, "");
                theValue = parseFloat(theValue);
                if (theValue > 0)
                    return false;
            }

            elem.css('border', '1px solid red');

            return true;
        };

        var paymentDateValidation =
        {
            _localStrings: localizationSvc.getCachedLocalizationsContainer("AccountPaymentsLocalization"),
            name: 'validateLiftDate',
            validationMessage: '',
            validationMessageWrn: '',
            validatorError: function (liftDateTime, parms)
            {
                var isValid = true;
                var minDate = new Date($scope.appInfo.webPayment.WebPaymentWithContext.WebMinEFTDate);
                var maxDate = new Date($scope.appInfo.webPayment.WebPaymentWithContext.WebMaxEFTDate);
                var liftDate = new Date(liftDateTime);

                if (Date.create(liftDateTime).format('{MM}/{dd}/{yyyy}') === "Invalid Date" || (liftDate < minDate) || (liftDate > maxDate)) {
                    /*this.validationMessage = "Please select or enter a payment date between " +
                        minDate.format('{MM}/{dd}/{yyyy}') + " and " +
                        maxDate.format('{MM}/{dd}/{yyyy}') + ".";*/

                    var replacementParms = [ minDate.format('{MM}/{dd}/{yyyy}'), maxDate.format('{MM}/{dd}/{yyyy}')];

                    this.validationMessage = paymentDateValidation._localStrings.getLocalizedString("PaymentDateValidationMsg", replacementParms);

                    return false;
                }

                return isValid;
            },
            validatorWarning: function (liftDateTime, parms)
            {
                var hasWarning = false;
                return !hasWarning;
            }
        };

        $scope.validatePaymentDate = new pdi.ng.validate.PdiValidation(paymentDateValidation);

        $scope.lockInvoices = function ()
        {
            PaymentService.lockInvoices($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = '2';
                    $scope.invoicesLocked = true;
                    $scope.kendo.invoicesGridInit.delay(10, $scope.appInfo.webPayment.WebPaymentWithContext.Invoices);
                    $scope.kendo.creditsGridInit.delay(10, $scope.appInfo.webPayment.WebPaymentWithContext.Credits);
                },
                function (err)
                {
                    $scope.invoicesLocked = false;
                    $scope.invoiceWarning = true;
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = '0';
                }
            );
        };

        $scope.unlockInvoices = function (paymentType)
        {
            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType !== '2')
            {
                $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = paymentType;
            }
            PaymentService.unockInvoices($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey);
            $scope.invoicesLocked = false;
            $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType = paymentType;
        };

        $scope.checkAll = function (grid, id)
        {
            var checked = document.getElementById(id).checked || false;
            grid.dataItems().forEach(function (item)
            {
                item.Selected = checked;
            });
        };

        $scope.formatCurrencies = function () {          
            var paymentsInfo = $scope.appInfo.webPayment.WebPaymentWithContext;

            var lastStmtAmt = paymentsInfo.LastStatementAmount;
            var acctBalance = paymentsInfo.AccountBalance;
            var budgetBalance = paymentsInfo.BudgetBalance;

            paymentsInfo.LastStatementAmountDisplay = globalizationSvc.formatCurrency(lastStmtAmt);
            paymentsInfo.AccountBalanceDisplay = globalizationSvc.formatCurrency(acctBalance);
            paymentsInfo.BudgetAmountDisplay = globalizationSvc.formatCurrency(budgetBalance);
        };
    })
    .controller("VerifyPaymentCtrl", function ($scope, $http, $q, PaymentService, WebFunctions, templates, globalizationSvc)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPayments) || !$scope.paymentsEnabled())
                $scope.goTo("/Home");  

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "2";
            $scope.getWebProfileMessage(18);
            $scope.isBudgetPayment = $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType === "4";
            $scope.isBudgetCustomer = $scope.appInfo.webPayment.WebPaymentWithContext.IsBudgetCustomer;
            $scope.isBBEnabled = $scope.appInfo.webPayment.WebPaymentWithContext.IsBBEnabled;
            $scope.budgetBalanceChanged = false;
        };
        $scope.confirmPayment = function () {
            //validate budget billing state if budget payment
            if ($scope.isBudgetPayment) {
                PaymentService.getBudgetPaymentContext($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                    function (data) {
                        $scope.isBudgetCustomer = data.IsBudgetCustomer;
                        $scope.isBBEnabled = data.IsBBEnabled;
                        $scope.budgetBalanceChanged = $scope.appInfo.webPayment.WebPaymentWithContext.BudgetBalance !== data.BudgetBalance;
                        if ($scope.isBBEnabled && $scope.isBudgetCustomer && !$scope.budgetBalanceChanged) {
                            $scope.savePayment();
                        }
                    }
                );
            } else {
                $scope.savePayment();
            }
        };
        $scope.savePayment = function () {
            //create variable of webpayment data
            var webPayment = $scope.appInfo.webPayment;

            //for each invoice, convert the string balance to a number so it properly gets sent to controller
            webPayment.WebPaymentWithContext.Invoices.forEach(function (item) {
                item.Balance = (globalizationSvc.convertStringToNumber(item.Balance)); 
            });

            //for each credit, convert unappAmmount and amount to numbers
            webPayment.WebPaymentWithContext.Credits.forEach(function (item) {
                item.Amount = (globalizationSvc.convertStringToNumber(item.Amount));
                item.UnAppAmount = (globalizationSvc.convertStringToNumber(item.UnAppAmount));
            });

            PaymentService.savePayment(webPayment.WebPaymentWithContext).then(
                function (data) {
                    $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment = data.CustomerScheduledPayment;
                    $scope.appInfo.webPayment.WebPaymentWithContext.Invoices = data.Invoices;
                    $scope.appInfo.webPayment.WebPaymentWithContext.Credits = data.Credits;
                    $scope.goTo("/Account/Payments/AccountConfirmPayment");
                    PaymentService.unLockPayments($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                        $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                        $scope.appInfo.webUser.CurrentAuthorization.CustKey);
                    PaymentService.unockInvoices($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                        $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                        $scope.appInfo.webUser.CurrentAuthorization.CustKey);
                }
            );
        };
        $scope.changePayment = function ()
        {
            $scope.appInfo.webPayment.WebPaymentWithContext.Invoices.forEach(function (item) {
                item.Selected = false;
            });

            $scope.appInfo.webPayment.WebPaymentWithContext.Credits.forEach(function (item) {
                item.Selected = false;
            });

            PaymentService.unLockPayments($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey);
            PaymentService.unockInvoices($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey);

            PaymentService.getBudgetPaymentContext($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data) {
                    $scope.appInfo.webPayment.WebPaymentWithContext.IsBudgetCustomer = data.IsBudgetCustomer;
                    $scope.appInfo.webPayment.WebPaymentWithContext.IsBBEnabled = data.IsBBEnabled;
                    $scope.appInfo.webPayment.WebPaymentWithContext.BudgetBalance = data.BudgetBalance;
                }
            );
            $scope.goTo("/Account/Payments/MakeAPayment/-1");
        };
    })
    .controller("ConfirmPaymentCtrl", function ($scope, $http, $q, PaymentService, WebFunctions, templates, globalizationSvc)
    {
        'use strict';
        //Once you come bace from saving the payment type will be an integer not a string so drop the quotes.

        $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.Amount);

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPayments) || !$scope.paymentsEnabled())
            {
                $scope.goTo("/Home");
            } 

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "3";
            if ($scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.PaymentType === '2')
                $scope.appInfo.webPayment.WebPaymentWithContext.CustomerScheduledPayment.CreditAmount = $scope.reCalculateCreditValues();
            $scope.getWebProfileMessage(21);
        };
        $scope.reCalculateCreditValues = function ()
        {
            var theValue = 0;
            angular.forEach($scope.appInfo.webPayment.WebPaymentWithContext.Credits, function (credit, index)
            {
                if (credit.Selected)
                    theValue = parseFloat(theValue) + parseFloat(credit.UnAppAmount);
            });
            return theValue;
        };

    })
    .controller("ScheduledPaymentHistorySideMenuCtrl", function ($scope, $http, $q, PaymentService, WebFunctions)
    {
        'use strict';
        $scope.initialize = function ()
        {
            $scope.locationInfo.sideMenuData.paymentHistory = {
                scheduled: 'true',
                processed: 'false',
                canceled: 'false'
            };
            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.maxNumOfDays = 90;
            $scope.dateSelector.selection = 13;
            PaymentService.getScheduledPaymentHistoryContext($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                $scope.locationInfo.sideMenuData.paymentHistory = {
                    scheduled: true,
                    processed: false,
                    canceled: false,
                    accounts: data.CustomerPaymentAccounts
                };
            });
        };
    })
    .controller("ScheduledPaymentHistoryCtrl", function ($scope, $http, $q, PaymentService, WebFunctions, $filter, templates, validatorSvc, localStrings, globalizationSvc)
    {
        'use strict';

        $scope.scheduledPayments = [];
        $scope.kendo = {};
        $scope.kendo.pmntGrid = {};
        $scope.kendo.pmntGridOptions = {
            autoBind: false,
            columns: [
                {
                    title: localStrings.getLocalizedString("Account"),
                    field: "CustPmtAcctDescription",
                    width: 150,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Amount",
                    title: localStrings.getLocalizedString("Amount"),
                    width: 100,
                    format: "{0:c}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "FormattedDate",
                    title: localStrings.getLocalizedString("PaymentDate"),
                    width: 100,
                    format: "{0:MM/dd/yyyy}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "StatusDescription",
                    title: localStrings.getLocalizedString("Status"),
                    width: 75,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "FormattedActivityDate",
                    title: localStrings.getLocalizedString("LastActivity"),
                    width: 100,
                    format: "{0:MM/dd/yyyy}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Key",
                    title: localStrings.getLocalizedString("Confirmation"),
                    width: 90,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Created",
                    title: localStrings.getLocalizedString("Entered"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<a href='\\#/Account/Payments/MakeAPayment/{{dataItem.Key}}'><i class='fa fa-search' ng-if='dataItem.Status > 0'></i><i class='fa fa-pencil' ng-if='dataItem.Status === 0'></i></a>",
                    width: 50
                },
                {
                    template: "<a href='' ng-click='confirmCancelPayment(dataItem.Key)'><i class='fa fa-trash-o' ng-if='dataItem.Status === 0'></i></a>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {mode: "row", extra: false},
            //height: $(document).height() - 375,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.pmntGridInit = function ()
        {
            $scope.scheduledPayments.each(function (payment) {
                payment.Amount = globalizationSvc.formatNumeric(payment.Amount);
            });
            
            $scope.kendo.pmntGridDS = new kendo.data.DataSource({
                data: $scope.scheduledPayments,
                schema: {
                    model: {
                        fields: {
                            CustPmtAcctDescription: {
                                editable: false
                            },
                            Amount: {
                                editable: false
                            },
                            FormattedDate: {
                                editable: false
                            },
                            StatusDescription: {
                                editable: false
                            },
                            FormattedActivityDate: {
                                editable: false
                            },
                            Key: {
                                editable: false
                            },
                            Created: {
                                editable: false
                            }
                        }
                    }
                },
                batch: true
            });

            $scope.kendo.pmntGridDS.read();
            $scope.kendo.pmntGrid.dataSource.data($scope.kendo.pmntGridDS.data());
        };

        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPayments))
            {
                $scope.goTo("/Account/Payments/ViewPaymentAccounts");
            }  

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu"); //'account/payments/paymentSideMenu.tpl.html';
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "4";
            $scope.getWebProfileMessage(19);
            $scope.scheduledPayments = [];
            $scope.predicate = 'FormattedDate';
            $scope.reverse = true;
            $scope.cancelPaymentKey = -1;
            $scope.cancelConfirm = false;
        };

        $scope.$parent.applyAction = function ()
        {
            if (!validatorSvc.executeValidations()) return;
            if (!($scope.locationInfo.sideMenuData.paymentHistory.scheduled ||
                $scope.locationInfo.sideMenuData.paymentHistory.processed ||
                $scope.locationInfo.sideMenuData.paymentHistory.canceled))
            {
                $scope.notifier.show(localStrings.getLocalizedString("AtLeastOneOption"), 'error');
                return;
            }

            PaymentService.getScheduledPayments($scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.locationInfo.sideMenuData.paymentHistory.selectedAccount,
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate,
                $scope.locationInfo.sideMenuData.paymentHistory.scheduled,
                $scope.locationInfo.sideMenuData.paymentHistory.processed,
                $scope.locationInfo.sideMenuData.paymentHistory.canceled).then(function (data)
                {
                    if (data.CustomerScheduledPayments.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("ScheduledPaymentsHist"));
                    }
                    $scope.scheduledPayments = data.CustomerScheduledPayments;
                    angular.forEach($scope.scheduledPayments, function (element)
                    {
                        element.Amount = globalizationSvc.formatCurrency(element.Amount);
                        element.FormattedDate = $filter('date')(element.PaymentDate, 'MM/dd/yyyy');
                        element.FormattedActivityDate = $filter('date')(element.LastModifiedDateTime, 'MM/dd/yyyy');
                        element.FormattedCreatedDate = $filter('date')(element.CreatedDateTime, 'MM/dd/yyyy');
                        element.Created = element.FormattedCreatedDate + ' ' + localStrings.getLocalizedString("By") + ' ' + element.CreatedByCustContEmailAddress;
                    });

                    $scope.kendo.pmntGridInit();
                });
        };

        $scope.confirmCancelPayment = function (paymentKey)
        {
            $scope.cancelPaymentKey = paymentKey;
            $scope.cancelConfirm = true;
        };

        $scope.cancelPayment = function (paymentKey)
        {
            PaymentService.getPayment($scope.appInfo.webUser.CurrentAuthorization.CustKey, paymentKey).then(function (data)
            {
                var WebPaymentWithContext = data;
                WebPaymentWithContext.CustomerScheduledPayment.Status = 1;
                WebPaymentWithContext.CustomerScheduledPayment.LastModifiedByCustContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;
                PaymentService.savePayment(WebPaymentWithContext).then(function (data)
                {
                    $scope.$parent.applyAction();
                });
            });
            $scope.cancelConfirm = false;
        };
    })
    .controller("paymentSideMenuCtrl", function ($scope, WebFunctionGroups, PaymentService, $rootScope, $interval)
    {
        'use strict';
        if (!$scope.checkCurrentUserPermissions(WebFunctionGroups.Payments))
        {
            $scope.goTo("/Home");
        }

        /*
            Use $interval to periodically refresh the locks.
            When the user navigates away from the page the $destroy listener is triggered to kill off the $interval.
            Likewise, the $interval is stopped when the payment/invoices are unlocked.
            This is paired with a query in CustomerRepository in EntWeb that wipes out Petronet Locks if they are > 20 minutes old.
        */

        var intervalPromise;
        var lockData;
        var invoicesLocked;
        var intervalTime = 120000;//Every 2 mins

        function refreshLock() {
            if (!angular.isUndefinedOrNullOrEmpty(lockData)) {
                if (!invoicesLocked) {
                    console.log("Payments lock refreshed");
                    PaymentService.lockPayments(lockData.CustContKey, lockData.EmailAddress, lockData.CustomerKey, true);
                } else {
                    console.log("Payments and Invoices lock refreshed");
                    PaymentService.lockPaymentsAndInvoices(lockData.CustContKey, lockData.EmailAddress, lockData.CustomerKey);
                }
            }
        }

        function resetValues() {
            invoicesLocked = false;
            lockData = null;
        }

        $scope.startLockInterval = function (data) {
            $scope.stopLockInterval();
            intervalPromise = $interval(refreshLock, intervalTime);
        };

        $scope.stopLockInterval = function () {
            $interval.cancel(intervalPromise);
        };

        //Start periodic refresh on lock
        var paymentLockListener = $rootScope.$on('event:PaymentLocked', function (event, data) {
            if (!angular.isUndefinedOrNullOrEmpty(data)) {
                lockData = data;
                $scope.startLockInterval();
            }
        });

        var invoiceLockListener = $rootScope.$on('event:InvoiceLocked', function (event, data) {
            if (!angular.isUndefinedOrNullOrEmpty(data)) {
                lockData = data;
                invoicesLocked = true;
            }
        });

        //Stop the interval when unlocked
        var paymentUnlockListener = $rootScope.$on('event:PaymentUnlocked', function () {
            $scope.stopLockInterval();
            resetValues();
        });

        var invoiceUnlockListener = $rootScope.$on('event:InvoiceUnlocked', function () {
            invoicesLocked = false;
        });

        //Destroy the listeners and intervals when we navigate away from this page 
        $scope.$on('$destroy', function () {
            $scope.stopLockInterval();
            paymentUnlockListener();
            invoiceUnlockListener();
            paymentLockListener();
            invoiceLockListener();
            resetValues();
        });

    })
    .controller("PaymentAccountsCtrl", function ($scope, $http, $q, $rootScope, $route, PaymentAccountService, WebFunctions, $filter, templates, localStrings, globalizationSvc)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.acctGrid = {};
        $scope.kendo.acctGridOptions = {
            autoBind: false,
            columns: [
                {
                    title: localStrings.getLocalizedString("AccountName"),
                    field: "Description",
                    attributes: {class: "alignLeft"},
                    template: "<a href='\\#/Account/Payments/EditPaymentAccount/{{dataItem.Key}}'  ng-bind='dataItem.Description'><i class='fa fa-external-link-square'></i></a>",
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    title: localStrings.getLocalizedString("Status"),
                    field: "AccountStatusDesc",
                    attributes: {class: "alignLeft"},
                    width: 150,
                    template: "<span ng-if='dataItem.AccountStatus != 4'>{{dataItem.AccountStatusDesc}}</span> <a href='\\#/Account/Payments/PaymentAccountVerification/{{dataItem.Key}}' ng-if='dataItem.AccountStatus == 4'>{{dataItem.AccountStatusDesc}}</a>",
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    title: localStrings.getLocalizedString("LastPayment"),
                    field: "FormattedActivityDate",
                    width: 150,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    title: localStrings.getLocalizedString("NextScheduledPayment"),
                    field: "NextPaymentText",
                    width: 200,
                    attributes: {class: "alignLeft"},
                    template: "<a href='\\#/Account/Payments/MakeAPayment/{{dataItem.AccountNextPaymentKey}}' ng-if='dataItem.AccountNextPaymentKey !== 0'>{{dataItem.NextPaymentText}}</a>",
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<a href='\\#/Account/Payments/EditPaymentAccount/{{dataItem.Key}}'> <i class='fa fa-pencil' style='cursor: pointer;' ng-if='dataItem.AccountStatus === 7'></i></a>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {mode: "row", extra: false},
            //height: $(document).height() - 375,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.acctGridInit = function ()
        {
            $scope.kendo.acctGridDS = new kendo.data.DataSource({
                data: $scope.WebPaymentAccounts,
                schema: {
                    model: {
                        fields: {
                            Description: {
                                editable: false
                            },
                            AccountStatusDesc: {
                                editable: false
                            },
                            FormattedActivityDate: {
                                editable: false
                            },
                            NextPaymentText: {
                                editable: false
                            }
                        }
                    }
                },
                batch: true
            });

            $scope.kendo.acctGridDS.read();
            $scope.kendo.acctGrid.dataSource.data($scope.kendo.acctGridDS.data());
        };

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
            {
                $scope.goTo("/Home");
            }  

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");
            $scope.predicate = 'SortOrder';
            $scope.reverse = false;
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "5";
            $scope.getWebProfileMessage(12);
            $scope.refresh();
        };

        $scope.refresh = function ()
        {
            PaymentAccountService.getPaymentAccountList($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                $scope.WebPaymentAccounts = data;
                angular.forEach($scope.WebPaymentAccounts, function (element)
                {
                    element.FormattedActivityDate = element.AccountLastActivity === '0001-01-01T00:00:00' ? null : $filter('date')(element.AccountLastActivity, 'MM/dd/yyyy');
                    element.FormattedPaymentDate = element.AccountNextPayment === '0001-01-01T00:00:00' ? null : $filter('date')(element.AccountNextPayment, 'MM/dd/yyyy');
                    element.NextPaymentText = element.FormattedPaymentDate === null ? null : element.FormattedPaymentDate + ' ' + localStrings.getLocalizedString("For") + ' ' + globalizationSvc.formatCurrency(element.AccountNextPaymentAmmount);
                });
                $scope.kendo.acctGridInit();
            });
        };
    })
    .controller("EditAccountCtrl", function ($scope, $http, $q, $routeParams, $filter, PaymentAccountService, validatorSvc, WebFunctions, templates, localStrings)
    {
        'use strict';
        $scope.editableAccount = false;
        $scope.paymentAccountKey = 0; 
        $scope.customText = "";
        $scope.initializeEditAcount = function ()
        {
            $scope.AccountTypes =
            [
                { key: 0, descr: localStrings.getLocalizedString("Checking") },
                { key: 1, descr: localStrings.getLocalizedString("Savings") }
            ];

            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
            {
                $scope.goTo("/Home");
            }  

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");
            validatorSvc.clearValidationScopes();
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "7";

            if ($routeParams.paymentAccountKey)
            {
                $scope.paymentAccountKey = $routeParams.paymentAccountKey;
            }

            if ($scope.paymentAccountKey < 0)
            {//Edit from verify page
                $scope.WebPaymentAccount = PaymentAccountService.WebPaymentAccount;
                $scope.editableAccount = $scope.WebPaymentAccount.AccountStatus <= 1 || $scope.WebPaymentAccount.AccountStatus === 5;
            } else if ($scope.paymentAccountKey > 0)
            {//Existing
                PaymentAccountService.getPaymentAccount($scope.paymentAccountKey, $scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
                {
                    $scope.WebPaymentAccount = data;
                    $scope.WebPaymentAccount.AccountTypeDescr = $scope.AccountTypes[$scope.WebPaymentAccount.AccountType].descr;
                    $scope.WebPaymentAccount.LastModifiedByCustContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;
                    $scope.editableAccount = $scope.WebPaymentAccount.AccountStatus <= 1 || $scope.WebPaymentAccount.AccountStatus === 5;
                });
            } else if ($scope.paymentAccountKey === 0)
            {//New
                $scope.WebPaymentAccount = new CustomerPaymentAccount(
                    {
                        CustKey: $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                        CreatedByCustContKey: $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                        LastModifiedByCustContKey: $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                        AccountStatus: 0,
                        AccountStatusDesc: localStrings.getLocalizedString("New"),
                        Key: 0
                    }
                );
                $scope.editableAccount = true;
            }
        };

        $scope.routingNumberIsValid = false;

        $scope.setAccountStatus = function (status)
        {
            if (status !== 0 && status !== 8)
                return;
            PaymentAccountService.getPaymentAccount($scope.WebPaymentAccount.Key, $scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                $scope.WebPaymentAccount = data;
                $scope.WebPaymentAccount.AccountStatus = status;
                PaymentAccountService.savePaymentAccount($scope.WebPaymentAccount).then(
                    function (data)
                    {
                        $scope.WebPaymentAccount = data;//refresh once you save to update timestamps
                        $scope.goTo('Account/Payments/ViewPaymentAccounts');
                    },
                    function (data)
                    {
                        $scope.modalifier.show(localStrings.getLocalizedString("AddAccount"), localStrings.getLocalizedString("ErrorSavingAccount") + data);
                    }
                );
            });
        };

        $scope.verifyPaymentAccount = function ()
        {
            if ($scope.editableAccount)
            {
                $scope.WebPaymentAccount.AccountTypeDescr = $scope.AccountTypes[$scope.WebPaymentAccount.AccountType].descr;
                PaymentAccountService.validateRoutingNumber($scope.WebPaymentAccount.BankRoutingNo).then(
                    function (data)
                    {
                        $scope.routingNumberIsValid = true;
                        $scope.verifyPaymentAccountOverride();
                    },
                    function (error)
                    {
                        $scope.routingNumberIsValid = false;
                        $scope.verifyPaymentAccountOverride();
                    });
            } else
            {
                $scope.verifyPaymentAccountOverride();
            }
        };

        $scope.verifyPaymentAccountOverride = function ()
        {
            if (!validatorSvc.executeValidations())
                return;

            PaymentAccountService.WebPaymentAccount = $scope.WebPaymentAccount;
            $scope.$cancelPromptToSave();
            $scope.goTo("/Account/Payments/VerifyPaymentAccount");
        };

        var routingNumberValidation =
        {
            name: 'validateRoutingNumber',
            validationMessage: localStrings.getLocalizedString("InvalidRoutingNumber"),
            validatorError: function (val)
            {
                return $scope.routingNumberIsValid;
            }
        };

        $scope.validateRoutingNumber = new pdi.ng.validate.PdiValidation(routingNumberValidation);

        $scope.confirmDeactivate = function ()
        {
            var yes = function ()
            {
                $scope.setAccountStatus(8);
            };

            var no = function ()
            {
                return;
            };

            $scope.modalifier.confirm(localStrings.getLocalizedString("DeactivateAccount"), yes, no);
        };

        $scope.confirmClose = function ()
        {
            var no = function ()
            {
                $scope.goTo('Account/Payments/ViewPaymentAccounts');
            };

            if ($scope.acctInfo.$dirty)
            {
                $scope.modalifier.confirm(localStrings.getLocalizedString("UnsavedChanges"), $scope.verifyPaymentAccount, no);
            }
            else
                no();
        };
    })
    .controller("VerifyAccountCtrl", function ($scope, $http, $q, $routeParams, $filter, PaymentAccountService, validatorSvc, WebFunctions, templates, localStrings)
    {
        'use strict';
        $scope.customText = "";
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
            {
                $scope.goTo("/Home");
            } 

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "8";

            $scope.WebPaymentAccount = PaymentAccountService.WebPaymentAccount;
        };

        $scope.routingNumberIsValid = false;

        $scope.confirmPaymentAccount = function ()
        {
            PaymentAccountService.savePaymentAccount($scope.WebPaymentAccount).then(
                function (data)
                {
                    $scope.goTo("/Account/Payments/ConfirmPaymentAccount/" + data.Key);
                },
                function (data)
                {
                    $scope.modalifier.show(localStrings.getLocalizedString("AddAccount"), localStrings.getLocalizedString("ErrorSavingAccount") + data);
                }
            );
        };

        $scope.changePaymentAccount = function ()
        {
            $scope.goTo("/Account/Payments/EditPaymentAccount/-1");
        };
    })
    .controller("ConfirmAccountCtrl", function ($scope, $http, $q, $routeParams, $filter, PaymentAccountService, WebFunctions, templates, localStrings)
    {
        'use strict';
        $scope.AccountTypes =
            [
                { key: 0, descr: localStrings.getLocalizedString("Checking") },
                { key: 1, descr: localStrings.getLocalizedString("Savings") }
            ];
        $scope.paymentAccountKey = 0;
        $scope.customText = "";
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
            {
                $scope.goTo("/Home");
            }  

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "9";

            if ($routeParams.paymentAccountKey)
            {
                $scope.paymentAccountKey = $routeParams.paymentAccountKey;
            }

            if ($routeParams.paymentAccountKey <= 0)
            {
                $scope.goTo("/Home");
            } else if ($routeParams.paymentAccountKey > 0)
            {
                PaymentAccountService.getPaymentAccount($scope.paymentAccountKey, $scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
                {
                    $scope.WebPaymentAccount = data;
                    $scope.WebPaymentAccount.AccountTypeDescr = $scope.AccountTypes[$scope.WebPaymentAccount.AccountType].descr;
                    $scope.WebPaymentAccount.AccountNoVerify = $scope.WebPaymentAccount.AccountNo;
                });
            }
        };
    })
    .controller("AccountVerificationCtrl", function ($scope, PaymentAccountService, $filter, validatorSvc, WebFunctions, $routeParams, templates)
    {
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
                $scope.goTo("/Home");

            if ($scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms === 0 ||
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentTerms !== $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms)
                $scope.goTo("/Account/Payments/TermsAndConditions/");

            $scope.appInfo.verifyAccount = {};
            validatorSvc.clearValidationScopes();
            if ($routeParams.paymentAccountKey)
                $scope.appInfo.verifyAccount.webPaymentAccountKey = $routeParams.paymentAccountKey;
            else
                $scope.goTo("/Account/Payments/ViewPaymentAccounts");

            $scope.appInfo.verifyAccount = {};
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "5";

            PaymentAccountService.getPaymentAccountList($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                $scope.appInfo.verifyAccount.webPaymentAccountDesc = $filter("filter")(data, {Key: $scope.appInfo.verifyAccount.webPaymentAccountKey})[0].Description;
                $scope.appInfo.verifyAccount.WebPaymentAccounts = $filter("filter")(data, {AccountStatus: 4});
            });
            $scope.getWebProfileMessage(15);
        };

        $scope.verifyPaymentAccount = function ()
        {
            if (!validatorSvc.executeValidations())
                return;
            $scope.goTo("/Account/Payments/PaymentAccountVerificationVerify/" + $routeParams.paymentAccountKey);
        };
    })
    .controller("AccountVerificationVerificationCtrl", function ($scope, PaymentAccountService, $filter, validatorSvc, WebFunctions, $routeParams, templates)
    {
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
                $scope.goTo("/Home");

            if ($routeParams.paymentAccountKey)
                $scope.appInfo.verifyAccount.webPaymentAccountKey = $routeParams.paymentAccountKey;
            else
                $scope.goTo("/Account/Payments/ViewPaymentAccounts");

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "5";

            $scope.getWebProfileMessage(15);
        };

        $scope.confirmPaymentAccount = function ()
        {
            PaymentAccountService.getPaymentAccount($scope.appInfo.verifyAccount.webPaymentAccountKey, $scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                var amt1 = parseFloat($scope.appInfo.verifyAccount.amount1);
                var amt2 = parseFloat($scope.appInfo.verifyAccount.amount2);
                if ((data.VerificationAmount1 !== amt1 && data.VerificationAmount1 !== amt2) ||
                    (data.VerificationAmount2 !== amt1 && data.VerificationAmount2 !== amt2))
                {
                    data.AccountStatus = 6;
                    PaymentAccountService.savePaymentAccount(data);
                    $scope.goTo("/Account/Payments/PaymentAccountVerificationFailure/");
                    return;
                }
                data.AccountStatus = 7;
                PaymentAccountService.savePaymentAccount(data);
                $scope.goTo("/Account/Payments/PaymentAccountVerificationConfirmation/" + $routeParams.paymentAccountKey);
            });
        };

        $scope.verifyPaymentAccount = function ()
        {
            $scope.goTo("/Account/Payments/PaymentAccountVerification/" + $routeParams.paymentAccountKey);
        };

    })
    .controller("AccountVerificationConfirmationCtrl", function ($scope, PaymentAccountService, $filter, validatorSvc, WebFunctions, $routeParams, templates)
    {
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts) || !$scope.paymentsEnabled())
                $scope.goTo("/Home");

            if (!$routeParams.paymentAccountKey)
                $scope.goTo("/Account/Payments/ViewPaymentAccounts");

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "5";

            PaymentAccountService.getPaymentAccountList($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                $scope.appInfo.verifyAccount.WebPaymentAccounts = $filter("filter")(data, {AccountStatus: 4});
            });
            $scope.getWebProfileMessage(16);
        };
    })
    .controller("AccountVerificationFailCtrl", function ($scope, PaymentAccountService, $filter, validatorSvc, WebFunctions, $routeParams, templates)
    {
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddandEditWebPaymentAccounts))
                $scope.goTo("/Home");

            $scope.appInfo.verifyAccount = {};
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 11;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaymentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "5";

            PaymentAccountService.getPaymentAccountList($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                $scope.appInfo.verifyAccount.WebPaymentAccounts = $filter("filter")(data, {AccountStatus: 4});
            });
            $scope.getWebProfileMessage(17);
        };
    });