/**
 * Created by rhampton on 7/11/2014.
 */
angular.module('services.customers', ['core.ajax']);

angular.module('services.customers',[]).service('customerService', function($q, apiService) {
    'use strict';
    function execute(apiSvcSettings, deferred){
        apiService.execute(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );
    }

    function executeAndNotify(apiSvcSettings, deferred){
        apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );
    }
    return {
        saveCustomerAccountSettings: function (custKey, contactKey, alertAmount, sendAlerts)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SaveCustomerARWebAccountSettings/',
                    data: {
                        CustomerKey: custKey,
                        PrimaryContactKey: contactKey,
                        AlertAmount: alertAmount,
                        SendBalanceAlerts: sendAlerts
                    }
                }
            );
            executeAndNotify(apiSvcSettings, deferred);
            return deferred.promise;
        },
        getCustomerLocations: function (custKey, locType) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetCustomerLocations/',
                    data: {
                        CustKey:custKey,
                        LocationType:locType
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },

        saveCustomerLocations: function (recStatus, location, custContKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SaveCustomerLocations/',
                    data: {
                        CustContKey: custContKey,
                        RecStatus:recStatus,
                        Location:location
                    }
                }
            );
            apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});