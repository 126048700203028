/**
 * Created by rhampton on 8/13/2014.
 */
angular.module("pnAdmin", ['ngSanitize', 'core.objects', 'services.payments', 'core.directives', 'core.enums','core.security'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider.when("/Account/Profile/YourProfile",
            {
                templateUrl: templateProvider.getTemplate("YourProfile"),
                controller: "EditUserCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        ).when("/Account/Profile/YourProfile/:contactKey",
            {
                templateUrl: templateProvider.getTemplate("YourProfile"),
                controller: "EditUserCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        ).when("/Account/Profile/Contacts",
            {
                templateUrl: templateProvider.getTemplate("Contacts"),
                controller: "ListContactsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        ).when("/Account/Profile/AddContact",
            {
                templateUrl: templateProvider.getTemplate("AddContact"),
                controller: "AddUserCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        ).when("/Account/Profile/AccountSettings",
            {
                templateUrl: templateProvider.getTemplate("AccountSettings"),
                controller: "AccountSettingsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        ).when("/Account/Profile/ResetPassword",
            {
                templateUrl: templateProvider.getTemplate("ProfileResetPassword"),
                controller: "SetPasswordCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        ).when("/Account/Profile/AuditLog",
            {
                templateUrl: templateProvider.getTemplate("AuditLog"),
                controller: "auditLogCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
                    }
                }
            }
        );
    })//AccountSettings
    .controller("ProfileSideMenuCtrl", function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {

        };

    })
    .controller("ListContactsCtrl", function ($scope, userAccountService, WebFunctions, $filter, templates, localStrings)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.contactsGrid = {};
        $scope.kendo.contactsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var selectedRows = this.select();
                var dataItem = this.dataItem(selectedRows[0]);
                $scope.goTo("/Account/Profile/YourProfile/" + dataItem.Key);
                $scope.$apply();
            },
            columns: [
                {
                    field: "DisplayName",
                    title: localStrings.getLocalizedString("Contact"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "EmailAddress",
                    title: localStrings.getLocalizedString("Email"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "WebActive",
                    title: localStrings.getLocalizedString("Active"),
                    width: 50,
                    template: "<i class='fa fa-check' ng-if='dataItem.WebActive === true'></i>",
                    filterable: false
                },
                {
                    template: "<i class='fa fa-pencil'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            //height: $(document).height() - 375,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.contactsGridInit = function ()
        {
            $scope.kendo.contactsGridDS = new kendo.data.DataSource({
                data: $scope.CustomerContacts,
                schema: {
                    model: {
                        fields: {
                            DisplayName: {
                                editable: false
                            },
                            EmailAddress: {
                                editable: false
                            },
                            WebActive: {
                                editable: false
                            }
                        }
                    }
                },
                batch: true
            });

            $scope.kendo.contactsGridDS.read();
            $scope.kendo.contactsGrid.dataSource.data($scope.kendo.contactsGridDS.data());
        };

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.ManageContactsAndSecurity))
            {
                $scope.goTo("/Home");
            }

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenu = templates.getTemplate("ProfileSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "2";
            $scope.CustomerContacts = [];
            userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                if (data.Successful)
                {
                    if (data.CustomerContacts.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("Contacts"));
                        return;
                    }

                    angular.forEach(data.CustomerContacts, function (item)
                    {
                        item.DisplayName = item.FirstName + ' ' + item.LastName;
                    });
                    $scope.CustomerContacts = $filter('orderBy')(data.CustomerContacts, 'DisplayName');
                    $scope.kendo.contactsGridInit();
                }
                else
                    $scope.modalifier.showSetupError();
            });
        };
    })
    .controller("EditUserCtrl", function ($q, $scope, $filter, WebFunctions, userAccountService, constantLists, $route, $routeParams, WebUserSvc, validatorSvc, $location, templates, localStrings)
    {
        'use strict';
        $scope.data = {};
        $scope.kendo = {};

        function expandWFGrids(expand)
        {
            $scope.showAccountFNs = expand;
            $scope.showFuelFNs = expand;
            $scope.showOrdersFNs = expand;
            $scope.showPaperworkFNs = expand;
            $scope.showCustomPagesFNs = expand;
        }

        function setDependancies()
        {
            if (angular.isUndefinedOrNullOrEmpty($scope.data.CustomerContact) || $scope.data.CustomerContact.AllWebFunctions)
                return;

            var wbfns = $scope.data.CustomerContact.CustomerContactWebFunctions;

            angular.forEach(wbfns, function (item)
            {
                switch (item.WebFnKey)
                {
                    case 5:
                        //View Cards => Edit cards
                        var view = $filter('filter')(wbfns, {WebFnKey: 4}, true)[0];
                        if (view.Selected)
                        {
                            item.Disabled = false;
                        }
                        else
                        {
                            item.Selected = false;
                            item.Disabled = true;
                        }
                        break;

                    case 6:
                        //View Card Transactions => View Card Transactions Alerts
                        var trans = $filter('filter')(wbfns, {WebFnKey: 3}, true)[0];
                        if (trans.Selected)
                        {
                            item.Disabled = false;
                        }
                        else
                        {
                            item.Selected = false;
                            item.Disabled = true;
                        }
                        break;

                    case 15:
                        //Acount Activity => View Credit Info
                        var acct = $filter('filter')(wbfns, {WebFnKey: 1}, true)[0];
                        if (acct.Selected)
                        {
                            item.Disabled = false;
                        }
                        else
                        {
                            item.Selected = false;
                            item.Disabled = true;
                        }
                        break;
                }
            });
        }

        $scope.$watch("data.CustomerContact.CustomerContactWebFunctions", setDependancies, true);

        $scope.textMessagePhoneNumbers = [
            {
                value: -1,
                text: localStrings.getLocalizedString("None")
            },
            {
                value: 0,
                text: localStrings.getLocalizedString("Phone")
            },
            {
                value: 1,
                text: localStrings.getLocalizedString("AltPhone")
            },
            {
                value: 2,
                text: localStrings.getLocalizedString("AltPhone2")
            }
        ];

        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            $scope.checkForAppliedProfile();

            if (!$scope.appInfo) $scope.appInfo = {};

            $scope.appInfo.editUser = {
                modalMultiSave: {
                    shown: false
                },
                modalCopySecurity: {
                    shown: false,
                    copyContact: {},
                    clearSecurity: false
                },
                modalResetPassword: {
                    shown: false,
                    controls: {
                        password: '',
                        verify: '',
                        displayTitle: localStrings.getLocalizedString("EnterVerifyPassword")
                    },
                    webUser: {
                        Timestamp: '',
                        Answer: '',
                        AnswerClear: '',
                        GUID: '',
                        Id: '',
                        Key: '',
                        Password: '',
                        PasswordClear: '',
                        Question: '',
                        Reset: '',
                        Validated: ''
                    },
                    error: {
                        display: false,
                        text: ''
                    }
                },
                modalChangeEmail: {
                    shown: $location.hash() === 'Email',
                    email: "",
                    pendingSave: false
                }
            };
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenu = templates.getTemplate("ProfileSideMenu");
            $scope.locationInfo.sideMenuData = {
                subMenu: "1"
            };

            $scope.phoneTypes = constantLists.phoneTypes(localStrings);
            $scope.secretQuestions = constantLists.secretQuestions(localStrings);
            $scope.data = {};
            $scope.kendo = {};

            $scope.clearButton = {
                clearButton: false
            };

            $scope.canResetPassword = (!angular.isUndefinedOrNullOrEmpty($scope.appInfo.passwordComplexity) && !$scope.appInfo.passwordComplexity.EnforcePasswordComplexity) ||
                (WebUserSvc.getNextPasswordResetDate($scope.appInfo.webUser) < 0);

            $scope.hideActiveCheckbox = $scope.hideWebActiveControl($scope.$parent.webFunctions);

            if ($routeParams.contactKey)
            {
                $scope.customerContactKey = $routeParams.contactKey;
                $scope.editWebUser = parseInt($scope.customerContactKey, 10) === $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.Key;
                $scope.locationInfo.sideMenuData.subMenu = "2";
                //If we are managing someone other than ourselves we need to check to see if the user has permissions.
                if (!$scope.checkCurrentUserPermission(WebFunctions.ManageContactsAndSecurity))
                {
                    $scope.goTo("/Home");
                }
            }
            else
            {
                $scope.editWebUser = true;
                $scope.customerContactKey = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.Key;
                $scope.appInfo.webUser.AnswerClear = $scope.appInfo.webUser.Answer;
                $scope.appInfo.webUser.AnswerClear2 = $scope.appInfo.webUser.Answer;
                $scope.copyUser();
            }

            userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                if (data.Successful)
                {
                    if (data.CustomerContacts.length === 0)
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("Contacts"));

                    angular.forEach(data.CustomerContacts, function (item)
                    {
                        item.DisplayName = item.FirstName + ' ' + item.LastName;
                    });
                    $scope.CopyContacts = $filter('orderBy')(data.CustomerContacts, 'DisplayName');
                }
                else
                    $scope.modalifier.showSetupError();
            });

            userAccountService.loadContactContext($scope.customerContactKey).then(function (data)
            {
                data.CustomerContact.WebQuestionAnswer2 = data.CustomerContact.WebQuestionAnswer;
                if(data.CustomerContact.TextAddressPhone == null) data.CustomerContact.TextAddressPhone = -1;

                $scope.data = data;
                $scope.setAllWebFunctions(false);
                setTimeout(function ()
                {
                    if (!angular.isUndefinedOrNullOrEmpty($scope.editUserForm)) {
                        $scope.$apply($scope.editUserForm.$setPristine());
                    }
                }, 1500);
            });
        };

        $scope.copyUser = function ()
        {
            $scope.appInfo.editUser.modalResetPassword.webUser =
            {
                Timestamp: $scope.appInfo.webUser.Timestamp,
                Answer: $scope.appInfo.webUser.Answer,
                AnswerClear: $scope.appInfo.webUser.AnswerClear,
                GUID: $scope.appInfo.webUser.Guid,
                Id: $scope.appInfo.webUser.Id,
                Key: $scope.appInfo.webUser.Key,
                Password: $scope.appInfo.webUser.Password,
                PasswordClear: $scope.appInfo.webUser.Password,
                Question: $scope.appInfo.webUser.Question,
                Reset: true,
                Validated: false
            };
        };

        $scope.hideWebActiveControl = function (wbFns) {
            return $scope.checkCurrentUserPermission(WebFunctions.ManageContactsAndSecurity) ? false : true;
        };

        $scope.setAllWebFunctions = function (expand)
        {
            if ($scope.data.CustomerContact.AllWebFunctions)
            {
                angular.forEach($scope.data.CustomerContact.CustomerContactWebFunctions, function (item)
                {
                    item.Selected = true;
                    item.Disabled = true;
                });
            }
            else
            {
                angular.forEach($scope.data.CustomerContact.CustomerContactWebFunctions, function (item)
                {
                    item.Disabled = false;
                });
            }
            expandWFGrids(expand);
        };

        $scope.saveContact = function ()
        {
            validatorSvc.executeValidations();

            //Validations for both Your Profile and Contacts pages
            if ($('#editUserAltEmail').hasClass('validation-error') || $('#editUserEmail').hasClass('validation-error'))
                return;

            //Validations only for the Your Profile page
            if ($scope.editWebUser)
            {
                if ($scope.kendo.secretQuestion.$angular_scope.scopeValidationErrors.length > 0)
                {
                    return;
                }

            }

            if ($scope.data.MatchingContactCount > 1)
            {
                $scope.appInfo.editUser.modalMultiSave.shown = true;
                return;
            }
            $scope.saveContactOnly();
        };

        /*
         * Save contat only and save all contacts will still update the names on all contacts sharing the
         * current email address.
         * */
        $scope.saveContactOnly = function ()
        {
            $scope.data.CustomerContact.ApplyToAll = false;
            $scope.appInfo.editUser.modalMultiSave.shown = false;
            $scope.save();
        };

        $scope.saveAllContacts = function ()
        {
            $scope.data.CustomerContact.ApplyToAll = true;
            $scope.appInfo.editUser.modalMultiSave.shown = false;
            $scope.save();
        };

        $scope.save = function ()
        {
            var contact = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact;
            $scope.data.CustomerContact.EditorName = contact.FirstName + " " + contact.LastName;
            $scope.data.CustomerContact.EditorEmailAddress = $scope.appInfo.webUser.Id;

            $scope.data.CustomerContact.PhoneFormatted = $filter('tel')($scope.data.CustomerContact.Phone);
            $scope.data.CustomerContact.AltPhoneFormatted = $filter('tel')($scope.data.CustomerContact.AltPhone);
            $scope.data.CustomerContact.AltPhone2Formatted = $filter('tel')($scope.data.CustomerContact.AltPhone2);
            $scope.data.CustomerContact.FAXFormatted = $filter('tel')($scope.data.CustomerContact.FAX);

            userAccountService.saveContact($scope.data.CustomerContact).then(function (data)
            {
                $scope.data.CustomerContact = data.CustomerContact;
                if ($scope.appInfo.webUser.Answer !== $scope.appInfo.webUser.AnswerClear)
                    $scope.saveWebUser();
                else
                {
                    if($scope.appInfo.editUser.modalChangeEmail.pendingSave)
                        $scope.logout();
                    else
                        $route.reload();
                }

            });
        };

        //This part get's messy because we need to save the web user and relaod it into the current application scope
        //with out bothering the users...
        $scope.saveWebUser = function ()
        {
            /*If this isn;t the current logged in contact dont update the web user informtion.
             This shouldn;t be possible anyway.*/
            if (!$scope.editWebUser) return;

            var webUser = angular.copy($scope.appInfo.webUser);
            webUser.Id = $scope.data.CustomerContact.EmailAddress;
            webUser.Question = $scope.appInfo.webUser.Question;
            webUser.AnswerClear = $scope.appInfo.webUser.AnswerClear;

            var token = WebUserSvc.getLoggedInWebUserToken();
            WebUserSvc.retrieveWebUserData(token, $scope.appInfo.profileKey).then(function (data) {
                webUser.Timestamp = data.Timestamp;
                webUser.GUID = null;

                WebUserSvc.saveWebUser(webUser, false).then(function (data) {
                    var currentCustomerId = $scope.appInfo.webUser.CurrentAuthorization.Customer.ID;
                    WebUserSvc.retrieveSelectedProfile($scope.appInfo.webUser.Id, $scope.appInfo.profileKey).then(function (data) {
                        $scope.appInfo.webUser = data;

                        if ($scope.appInfo.editUser.modalChangeEmail.pendingSave)
                            $scope.logout();
                        else
                            $route.reload();
                    });
                });
            });
        };

        $scope.setEditUserSelectedAuthorization = function (id)
        {
            for (var x = 0; x < $scope.appInfo.webUser.WebAuthorizations.length; x++)
            {
                if ($scope.appInfo.webUser.WebAuthorizations[x].Customer.ID === id)
                {
                    $scope.appInfo.webUser.CurrentAuthorization = $scope.appInfo.webUser.WebAuthorizations[x];
                    return;
                }
            }
        };

        $scope.updateContactSecurity = function ()
        {
            var customerContactKey = $scope.appInfo.editUser.modalCopySecurity.copyContact;
            userAccountService.loadContactContext(customerContactKey).then(function (data)
            {
                var webFunctions = data.CustomerContact.CustomerContactWebFunctions;
                var currentContactWebFunctions = $scope.data.CustomerContact.CustomerContactWebFunctions;
                $scope.data.CustomerContact.AllWebFunctions = true;
                for (var i = 0; i < webFunctions.length; i++)
                {
                    for (var x = 0; x < currentContactWebFunctions.length; x++)
                    {
                        if (currentContactWebFunctions[x].WebFnKey === webFunctions[i].WebFnKey)
                        {
                            if ($scope.appInfo.editUser.modalCopySecurity.clearSecurity)
                                currentContactWebFunctions[x].Selected = false;
                            if (webFunctions[i].Selected)
                                currentContactWebFunctions[x].Selected = true;
                            if (!currentContactWebFunctions[x].Selected)
                                $scope.data.CustomerContact.AllWebFunctions = false;
                        }
                    }
                }
                expandWFGrids(false);
                $scope.appInfo.editUser.modalCopySecurity.shown = false;
            });
        };

        $scope.resetPassword = function ()
        {
            $scope.goTo('/Account/Profile/ResetPassword');
        };

        $scope.questionChanged = function (e)
        {
            if ($scope.kendo.secretQuestion.$angular_scope.$parent.editUserForm.question.$dirty)
            {
                $scope.appInfo.webUser.AnswerClear = '';
                $scope.appInfo.webUser.AnswerClear2 = '';
            }
        };

        $scope.showChangeEmailModal = function()
        {
            if ($location.hash() === 'Email')
            {
                $route.reload();
            } else
            {
                $location.hash('Email');
            }
        };

        $scope.hideChangeEmailModal = function()
        {
            $location.hash('');
        };

        $scope.saveChangeEmail = function()
        {
            validatorSvc.executeValidations();
            if ($('#changeEmail').hasClass('validation-error'))
                return;

            $scope.appInfo.editUser.modalChangeEmail.shown = false;
            $scope.appInfo.editUser.modalChangeEmail.pendingSave = true;
            $scope.data.CustomerContact.EmailAddress = $scope.appInfo.editUser.modalChangeEmail.email;
            $scope.saveContact();
        };
    })
    .controller("AddUserCtrl", function ($q, $scope, $filter, WebFunctions, userAccountService, validatorSvc, $routeParams, WebUserSvc, templates, localStrings)
    {
        'use strict';
        $scope.appInfo.addUser = {
            shown: true,
            copyContacts: [],
            contact: userAccountService.getBlankContact()
        };
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.ManageContactsAndSecurity))
            {
                $scope.goTo("/Home");
            }

            validatorSvc.clearValidationScopes();
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenu = templates.getTemplate("ProfileSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "3";

            userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                if (data.Successful)
                {
                    if (data.CustomerContacts.length === 0)
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("AddContact"));

                    angular.forEach(data.CustomerContacts, function (item)
                    {
                        item.DisplayName = item.FirstName + ' ' + item.LastName;
                    });
                    $scope.appInfo.addUser.copyContacts = $filter('orderBy')(data.CustomerContacts, 'DisplayName');
                }
                else
                    $scope.modalifier.showSetupError();
            });
            $scope.appInfo.addUser.shown = true;
        };

        $scope.createContact = function ()
        {
            var validationsPassed = true;
            if (!validatorSvc.executeValidations()) {
                //Bypass shared scope validations with Contacts/Your Profile pages; we only care about add new contact validations
                angular.forEach($scope.scopeValidationErrors, function (value, key) {
                    if (value.model !== "data.CustomerContact.EmailAddress")
                        validationsPassed = false;
                });
            }

            if (!validationsPassed)
                return;

            if ($scope.updateContactSecurity())
                return;

            var contact = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact;
            $scope.appInfo.addUser.contact.EditorName = contact.FirstName + " " + contact.LastName;
            $scope.appInfo.addUser.contact.EditorEmailAddress = $scope.appInfo.webUser.Id;
            $scope.appInfo.addUser.contact.CustKey = $scope.appInfo.webUser.CurrentAuthorization.CustKey;

            userAccountService.saveContact($scope.appInfo.addUser.contact).then(function (data)
            {
                $scope.appInfo.addUser.shown = false;

                if(data.CustomerContact && data.CustomerContact.CustKey === 0)
                {
                    $scope.goTo("Account/Profile/Contacts");
                    $scope.modalifier.show(localStrings.getLocalizedString("AddNewContact"),
                        localStrings.getLocalizedString("NewContactNotCreated"));
                }
                else
                {
                    $scope.goTo("/Account/Profile/YourProfile/" + data.CustomerContact.Key);
                }
            });

        };

        $scope.updateContactSecurity = function ()
        {
            if (!$scope.appInfo.addUser.copyContact) return false;
            //if (!$scope.appInfo.addUser.copyContact.Key) return false;

            var customerContactKey = $scope.appInfo.addUser.copyContact;
            userAccountService.loadContactContext(customerContactKey).then(function (data)
            {
                $scope.appInfo.addUser.contact.CustomerContactWebFunctions = data.CustomerContact.CustomerContactWebFunctions;
                $scope.appInfo.addUser.contact.AllWebFunctions = data.CustomerContact.AllWebFunctions;
                var contact = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact;
                $scope.appInfo.addUser.contact.EditorName = contact.FirstName + " " + contact.LastName;
                $scope.appInfo.addUser.contact.EditorEmailAddress = $scope.appInfo.webUser.Id;
                $scope.appInfo.addUser.contact.CustKey = $scope.appInfo.webUser.CurrentAuthorization.CustKey;
                userAccountService.saveContact($scope.appInfo.addUser.contact).then(function (data)
                {
                    $scope.appInfo.addUser.shown = false;

                    if (data.CustomerContact && data.CustomerContact.CustKey === 0) {
                        $scope.goTo("Account/Profile/Contacts");
                        $scope.modalifier.show(localStrings.getLocalizedString("AddNewContact"),
                            localStrings.getLocalizedString("NewContactNotCreated"));
                    }
                    else {
                        $scope.goTo("/Account/Profile/YourProfile/" + data.CustomerContact.Key);
                    }
                });
            });
            return true;
        };

        $scope.closeAddUser = function ()
        {
            $scope.appInfo.addUser.shown = false;
            $scope.appInfo.addUser = {};
            $scope.goTo("/Account/Profile/Contacts");
        };
    })
    .controller("AccountSettingsCtrl", function ($scope, $filter, WebFunctions, userAccountService, validatorSvc, customerService, WebUserSvc, templates, localStrings, globalizationSvc)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.ViewCreditInformation))
            {
                $scope.goTo("/Home");
            }

            validatorSvc.clearValidationScopes();
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenu = templates.getTemplate("ProfileSideMenu");
            $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "4";
            $scope.appInfo.pageInfo = {
                contacts: [],
                primaryCreditContact: 0,
                sendBalanceAlerts: $scope.appInfo.webUser.CurrentAuthorization.Customer.SendBalanceAlerts !== 0,
                sendBalanceAlertText: localStrings.getLocalizedString("SendAlertsBalanceExceeds"),
                balanceAlertThreshold: $scope.appInfo.webUser.CurrentAuthorization.Customer.BalanceAlertThreshold,
                prepaidBalance: $scope.appInfo.webUser.CurrentAuthorization.Customer.CrPrePaidBalance !== 0,
                balanceAlertThresholdDisplay: globalizationSvc.formatCurrency($scope.appInfo.webUser.CurrentAuthorization.Customer.BalanceAlertThreshold)
            };
            userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data)
            {
                if (data.Successful)
                {
                    if (data.CustomerContacts.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("AccountSettings"));
                    }
                    else
                    {
                        angular.forEach(data.CustomerContacts, function (item) {
                            item.DisplayName = item.FirstName + ' ' + item.LastName;

                            if ($scope.appInfo.webUser.CurrentAuthorization.Customer.CreditContKey === item.Key) {
                                $scope.appInfo.pageInfo.primaryCreditContact = item.Key;
                            }
                        });
                        $scope.appInfo.pageInfo.contacts = $filter('orderBy')(data.CustomerContacts, 'DisplayName');
                        $scope.appInfo.pageInfo.primaryCreditContact = ($scope.appInfo.pageInfo.primaryCreditContact === 0) ? $scope.appInfo.pageInfo.contacts[0].Key
                                                                                                                            : $scope.appInfo.pageInfo.primaryCreditContact;
                    }
                }
                else
                    $scope.modalifier.showSetupError();
            });
            if ($scope.appInfo.pageInfo.prepaidBalance)
                $scope.appInfo.pageInfo.sendBalanceAlertText = localStrings.getLocalizedString("SendAlertsBalanceBelow");
        };

        // This method is used to format the text of the input box whenever we lose focus
        $scope.formatCurrency = function ()
        {
            // If the text is empty or null then do not try to format it
            if (angular.isUndefinedOrNullOrEmpty($scope.appInfo.pageInfo.balanceAlertThresholdDisplay))
                return;

            // Convert the string to a number and parse it as a float
            var num = parseFloat(globalizationSvc.convertStringToNumber($scope.appInfo.pageInfo.balanceAlertThresholdDisplay));

            // Format the number back into a currency string to be displayed
            num = globalizationSvc.formatCurrency(num);

            // Set the display value to the currency string
            $scope.appInfo.pageInfo.balanceAlertThresholdDisplay = num;
        };

        $scope.saveAccountSettings = function ()
        {
            validatorSvc.clearAllValidations();

            if ($scope.appInfo.pageInfo.sendBalanceAlerts && !validatorSvc.executeValidations())
                return;

            // Convert the display value to a number and parse it as a float so we can save a number and not some currency string
            $scope.appInfo.pageInfo.balanceAlertThreshold = parseFloat(globalizationSvc.convertStringToNumber($scope.appInfo.pageInfo.balanceAlertThresholdDisplay));

            customerService.saveCustomerAccountSettings($scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.appInfo.pageInfo.primaryCreditContact,
                $scope.appInfo.pageInfo.balanceAlertThreshold,
                $scope.appInfo.pageInfo.sendBalanceAlerts).then(function (data)
                {
                    $scope.resetDirtyFlags($scope.settingsForm);
                    //Reload the web user once customer data has been changed.
                    var currentCustomerId = $scope.appInfo.webUser.CurrentAuthorization.Customer.ID;
                    WebUserSvc.retrieveSelectedProfile($scope.appInfo.webUser.Id, $scope.appInfo.profileKey).then(function (data)
                    {
                        $scope.appInfo.webUser = data;
                        for (var x = 0; x < $scope.appInfo.webUser.WebAuthorizations.length; x++)
                        {
                            if ($scope.appInfo.webUser.WebAuthorizations[x].Customer.ID === currentCustomerId)
                            {
                                $scope.appInfo.webUser.CurrentAuthorization = $scope.appInfo.webUser.WebAuthorizations[x];
                                return;
                            }
                        }
                        $scope.initialize();
                    });
                    // This will function will call will take whatever was saved and convert it back to a currency string to be displayed after save
                    $scope.formatCurrency();
                });
        };
    })
    .controller("SetPasswordCtrl", function ($scope, Authentication, $filter, WebFunctions, userAccountService, validatorSvc, customerService, WebUserSvc, WebProfileService, templates, localStrings)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.appInfo) $scope.appInfo = {};
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenu = templates.getTemplate("ProfileSideMenu");
            $scope.locationInfo.sideMenuData = {
                subMenu: "1"
            };

            var profileKey = ($scope.appInfo && $scope.appInfo.profileKey) ? $scope.appInfo.profileKey : 0;
            WebProfileService.getWebProfileMessage(profileKey, 27).then(function (data)
            {
                $scope.customMessage = data.isBlank() ? localStrings.getLocalizedString("ExpiredPassword") : data;
            });

            $scope.appInfo.pageInfo = {
                controls: {
                    oldPassword: '',
                    password: '',
                    verify: '',
                    displayTitle: localStrings.getLocalizedString("EnterVerifyPassword")
                },
                webUser: {
                    Timestamp: $scope.appInfo.webUser.Timestamp,
                    Answer: $scope.appInfo.webUser.Answer,
                    AnswerClear: $scope.appInfo.webUser.AnswerClear,
                    GUID: $scope.appInfo.webUser.Guid,
                    Id: $scope.appInfo.webUser.Id,
                    Key: $scope.appInfo.webUser.Key,
                    Password: $scope.appInfo.webUser.Password,
                    OldPassword: '',
                    PasswordClear: '',
                    Question: $scope.appInfo.webUser.Question,
                    Reset: false, // password reset is only true for forgot password and false for change password
                    Validated: false
                },
                error: {
                    display: false,
                    text: ''
                },
                success: false
            };
        };

        $scope.submitPassword = function ()
        {
            //$scope.resetPassword.error = [];

            if (!validatorSvc.executeValidations())
                return;

            $scope.setNewPassword();
        };

        $scope.setNewPassword = function ()
        {

            $scope.appInfo.pageInfo.webUser.OldPassword = $scope.appInfo.pageInfo.controls.oldPassword;
            $scope.appInfo.pageInfo.webUser.PasswordClear = $scope.appInfo.pageInfo.controls.password;

            WebUserSvc.resetWebUserPassword($scope.appInfo.pageInfo.webUser).then(
                function (data)
                {
                    //store the new webuser record so the timestamps match the db
                    $scope.appInfo.pageInfo.webUser = data;

                    if (data.Validated === true)
                    {
                        // If password reset was successful then we need to login again since we dont store anything in local storage so we need a new token.
                        Authentication.login($scope.appInfo.webUser.Id, $scope.appInfo.pageInfo.controls.password, "").then(function (data) {
                            var user = data;
                            var token = user.Token;
                            amplify.store('pnuser-token', token);

                            //Reload the web user once the password has been set.
                            var currentCustomerId = $scope.appInfo.webUser.CurrentAuthorization.Customer.ID;
                            WebUserSvc.retrieveWebUserData(token, $scope.appInfo.profileKey).then(function (data) {
                                $scope.appInfo.webUser = data;
                                for (var x = 0; x < $scope.appInfo.webUser.WebAuthorizations.length; x++) {
                                    if ($scope.appInfo.webUser.WebAuthorizations[x].Customer.ID === currentCustomerId) {
                                        $scope.appInfo.webUser.CurrentAuthorization = $scope.appInfo.webUser.WebAuthorizations[x];
                                        return;
                                    }
                                }
                            },
                            function (err) {
                            });

                        });
                        //display the conformation page
                        $scope.appInfo.pageInfo.success = true;

                    }
                },
                function (err)
                {
                    angular.forEach(err, function (item)
                    {
                        $scope.notifier.show(item, localStrings.getLocalizedString("Error"));
                    });
                }
            );
        };

        $scope.clearErrors = function ()
        {
            validatorSvc.clearAllValidations();
        };
    })
    .controller("auditLogCtrl", function ($scope, templates, localStrings) {
        'use strict';

        $scope.locationInfo.sideMenuData = {
            auditLog: {
                auditResults: []
            }
        };
        $scope.kendo = {};
        $scope.kendo.auditLogGrid = {};
        $scope.kendo.auditLogGridOptions = {
            autoBind: false,
            change: function (e) {
                var selectedRows = this.select();
                var dataItem = this.dataItem(selectedRows[0]);
                $scope.goTo("/Account/Profile/YourProfile/" + dataItem.Key);
                $scope.$apply();
            },
            columns: [
                {
                    field: "AudEntryUserID",
                    title: localStrings.getLocalizedString("ChangedBy"),
                    sortable: true,
                    width: 120,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntryDateTime",
                    title: localStrings.getLocalizedString("DateTime"),
                    sortable: true,
                    width: 130,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntryAction",
                    title: localStrings.getLocalizedString("Type"),
                    width: 50,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntryMenuCode",
                    title: localStrings.getLocalizedString("SubjArea"),
                    sortable: true,
                    width: 80,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntryDescription",
                    title: localStrings.getLocalizedString("Description"),
                    sortable: true,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntDtlsFieldDesc",
                    title: localStrings.getLocalizedString("FieldName"),
                    sortable: true,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntDtlsOldValue",
                    title: localStrings.getLocalizedString("OldValue"),
                    sortable: true,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "AudEntDtlsNewValue",
                    title: localStrings.getLocalizedString("NewValue"),
                    sortable: true,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.$parent.auditLogGridInit = function () {
            $scope.kendo.auditLogGrid.dataSource.data($scope.locationInfo.sideMenuData.auditLog.auditResults);
        };

        $scope.initialize = function () {
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenu = templates.getTemplate("ProfileSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "5";
        };
    })
    .controller("auditLogSideMenuCtrl", function ($scope, validatorSvc, userAccountService, WebFunctions, reportingService, WebProfileService, localizationSvc) {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
        $scope.initialize = function () {

            validatorSvc.clearValidationScopes();
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 12;
            $scope.locationInfo.sideMenuLocation = 5;
            setTimeout(function() {
                $scope.locationInfo.sideMenuData.auditLog = {
                    includeAdds: true,
                    includeChanges: true,
                    includeDeletes: true,
                    includeActions: true,
                    subjectArea: null,
                    changedBy: null,
                    showContacts: false
                };
            }, 0);
            $scope.subjectAreas = [
                { Key: null, Description: localStrings.getLocalizedString("All") },
                { Key: "ARW", Description: localStrings.getLocalizedString("Account") },
                { Key: "CPW", Description: localStrings.getLocalizedString("FuelCards") },
                { Key: "ODW", Description: localStrings.getLocalizedString("Orders") },
                { Key: "DRW", Description: localStrings.getLocalizedString("Paperwork") },
                { Key: "UAL", Description: localStrings.getLocalizedString("Login") }
            ];
            $scope.contactList = [
                { EmailAddress: null, DisplayName: localStrings.getLocalizedString("Any") }
            ];
            if ($scope.checkCurrentUserPermission(WebFunctions.ManageContactsAndSecurity)) {
                userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(function (data) {
                    if (data.Successful) {
                        if (data.CustomerContacts.length === 0) {
                            $scope.modalifier.show(localStrings.getLocalizedString("Contacts"), localStrings.getLocalizedString("NoResultsFound"));
                            return;
                        }

                        angular.forEach(data.CustomerContacts, function (item) {
                            item.DisplayName = item.EmailAddress;
                        });
                        $scope.contactList = data.CustomerContacts
                            .sortBy('EmailAddress')
                            .unique('EmailAddress')
                            .add({ EmailAddress: null, DisplayName: localStrings.getLocalizedString("Any") }, 0);
                    }
                    else
                        $scope.modalifier.showSetupError();
                });
                $scope.locationInfo.sideMenuData.auditLog.showContacts = true;
            }
            else
            {
                $scope.locationInfo.sideMenuData.auditLog.changedBy = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress;
            }
            $scope.$root.reportSettings.reportKey = 33;
        };

        $scope.applyAction = function () {
            if (!validatorSvc.executeValidations()) return;
            if(!$scope.locationInfo.sideMenuData.auditLog.includeAdds &&
                !$scope.locationInfo.sideMenuData.auditLog.includeChanges &&
                !$scope.locationInfo.sideMenuData.auditLog.includeDeletes &&
                !$scope.locationInfo.sideMenuData.auditLog.includeActions)
            {
                $scope.modalifier.show(localStrings.getLocalizedString("AuditLog"), localStrings.getLocalizedString("AtLeastOneType"));
                return;
            }
            var subjectArea = $scope.locationInfo.sideMenuData.auditLog.subjectArea;
            var changedBy = $scope.locationInfo.sideMenuData.auditLog.changedBy;
            if(subjectArea === 'null')
                subjectArea = null;
            if(changedBy === 'null')
                changedBy = null;
            WebProfileService.getWebAudits(
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate,
                subjectArea,
                $scope.locationInfo.sideMenuData.auditLog.includeAdds,
                $scope.locationInfo.sideMenuData.auditLog.includeChanges,
                $scope.locationInfo.sideMenuData.auditLog.includeDeletes,
                $scope.locationInfo.sideMenuData.auditLog.includeActions,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                changedBy
            ).then(function (data) {
                    $scope.locationInfo.sideMenuData.auditLog.auditResults = data.WebAuditEntries.each(function(item) {
                        item.AudEntryDateTime = Date.create(item.AudEntryDateTime).format('{MM}/{dd}/{yyyy} {12hr}:{mm} {tt}');
                    });
                    if (data.WebAuditEntries.length === 0) {
                        $scope.modalifier.show(localStrings.getLocalizedString("AuditLog"), localStrings.getLocalizedString("NoResultsFound"));
                        return;
                    }
                    $scope.auditLogGridInit();
                });
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
            $scope.locationInfo.sideMenuData.auditLog.includeAdds = $scope.reportSettings.loadOptions.includeAdds;
            $scope.locationInfo.sideMenuData.auditLog.includeChanges = $scope.reportSettings.loadOptions.includeChanges;
            $scope.locationInfo.sideMenuData.auditLog.includeDeletes = $scope.reportSettings.loadOptions.includeDeletes;
            $scope.locationInfo.sideMenuData.auditLog.includeActions = $scope.reportSettings.loadOptions.includeActions;

            var subjectArea = $scope.reportSettings.loadOptions.subjectArea;
            var changedBy = $scope.reportSettings.loadOptions.changedBy;
            if(subjectArea === 'null')
                subjectArea = null;
            if(changedBy === 'null')
                changedBy = null;
            $scope.locationInfo.sideMenuData.auditLog.subjectArea = subjectArea;
            $scope.locationInfo.sideMenuData.auditLog.changedBy = changedBy;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
            $scope.reportSettings.saveOptions.includeAdds = $scope.locationInfo.sideMenuData.auditLog.includeAdds;
            $scope.reportSettings.saveOptions.includeChanges = $scope.locationInfo.sideMenuData.auditLog.includeChanges;
            $scope.reportSettings.saveOptions.includeDeletes = $scope.locationInfo.sideMenuData.auditLog.includeDeletes;
            $scope.reportSettings.saveOptions.includeActions = $scope.locationInfo.sideMenuData.auditLog.includeActions;
            $scope.reportSettings.saveOptions.subjectArea = $scope.locationInfo.sideMenuData.auditLog.subjectArea;
            $scope.reportSettings.saveOptions.changedBy = $scope.locationInfo.sideMenuData.auditLog.changedBy;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            var subjectArea = $scope.locationInfo.sideMenuData.auditLog.subjectArea;
            var changedBy = $scope.locationInfo.sideMenuData.auditLog.changedBy;
            var qs = "reportName=" + 'AuditLog' +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-StartDate=" + $scope.dateSelector.startDate +
                "&RP-EndDate=" + $scope.dateSelector.endDate +
                "&RP-IncludeAdds=" + $scope.locationInfo.sideMenuData.auditLog.includeAdds +
                "&RP-IncludeChanges=" + $scope.locationInfo.sideMenuData.auditLog.includeChanges +
                "&RP-IncludeDeletes=" + $scope.locationInfo.sideMenuData.auditLog.includeDeletes +
                "&RP-IncludeActions=" + $scope.locationInfo.sideMenuData.auditLog.includeActions;
            if(subjectArea !== 'null')
                qs = qs + "&RP-SubjectArea=" + subjectArea;
            if(changedBy !== 'null')
                qs = qs + "&RP-ChangedBy=" + changedBy;

            reportingService.exportReport(qs, args.format);
        });
    });
