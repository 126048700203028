/* ==============================================================================
 services.logging MODULE
 ============================================================================= */
angular.module('services.logging', ['core.ajax']);

/* ==============================================================================
 LoggingService
 ============================================================================= */
angular.module('services.logging', []).factory('LoggingService', function ($q, apiService) {
    'use strict';

    return {
        logErrors: function(error) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/Logging/',
                    data: {
                        ExceptionMessage: error.ExceptionMessage,
                        Stacktrace: error.Stacktrace
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function(data) {
                    deferred.resolve(data);
                }
            );
            return deferred.promise;
        }
    };
});