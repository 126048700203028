/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.paperwork', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.paperwork',[]).factory('PaperworkService', function($q, apiService, $filter, $rootScope) {
    'use strict';
    return{
        getPaperworkContext : function(customerContactKey, ignoreStatus){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkContext/',
                    data: {
                        CustomerContactKey:customerContactKey,
                        IgnoreStatus: ignoreStatus
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        getNextPaperworkDate : function(siteKey, ignoreStatus){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetNextPaperworkDate/',
                    data: {
                        SiteKey: siteKey,
                        IgnoreStatus: ignoreStatus
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data.NextPaperworkDate);
                }
            );
            return deferred.promise;
        },
        getPaperworkBatchesByDate : function(siteKey, startDate, endDate, unposted){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkBatchesByDate/',
                    data: {
                        SiteKey:siteKey,
                        StartDate:startDate,
                        EndDate:endDate,
                        Unposted:unposted
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data.PaperworkBatches);
                }
            );
            return deferred.promise;
        },
        OpenPaperworkBatch : function(siteKey, paperworkDate, customerContactKey, emailAddress, readOnly){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkBatchForEdit/',
                    data: {
                        BatchKey : 0,//Batch key is 0 to ensure the paperwork opens by site and date.(New Paperwork)
                        SiteKey : typeof(siteKey) === "object" ?
                            typeof(siteKey.SiteKey) === "number" ? siteKey.SiteKey : 0 :
                            typeof(siteKey) === "number" ? siteKey : 0,
                        PaperworkDate : paperworkDate,
                        ContactKey: customerContactKey,
                        EmailAddress : emailAddress,
                        ReadOnly : readOnly
                        },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        OpenPaperworkBatchByKey : function(batchKey, customerContactKey, emailAddress, readonly){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkBatchForEditByKey/',
                    data: {
                        BatchKey : batchKey,
                        ContactKey: customerContactKey,
                        EmailAddress : emailAddress,
                        ReadOnly : readonly
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        SubmitPaperwork : function(submitPaperworkCmdParms){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SubmitPaperworkBatch/',
                    data: submitPaperworkCmdParms
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    $rootScope.$broadcast('event:PaperworkSubmitted', {});
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        ValidatePaperworkSiteDateForContact : function(siteKey, paperworkDate, customerContactKey, forEntry){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/ValidatePaperworkSiteDateForContact/',
                    data: {
                        SiteKey : siteKey,
                        PaperworkDate : paperworkDate,
                        ContactKey: customerContactKey,
                        ForEntry: forEntry
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        RefreshPaperworkLock: function (paperworkBatchKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/RefreshPaperworkLock/',
                    data: {
                        PaperworkBatchKey: paperworkBatchKey,
                        RefreshLock: true
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});