/**
 * Created by jvitoria on 1/30/2015.
 */

//helper functions
var getAttrTextFromNodeMap = function (element, attr) {
    // get html element attribute by name from a NamedNodeMap
    return $('#' + element )[0].attributes.getNamedItem(attr).value;
};

angular.module("pnDateSelector", ['core.objects'])
    .config(function ($provide, templateProvider) {
       $provide.constant('templates', templateProvider);
    })
    .controller("dateSelectorCtrl", function ($scope)
    {
        'use strict';
    })
    .directive('dateSelector', function (templates)
    {
        return {
            restrict: 'E',
            scope: false,
            controller: function ($scope, $rootScope, $filter, localizationSvc)
            {
                var localStrings = localizationSvc.getCachedLocalizationsContainer("DateSelectorLocalization");  
                $scope.initDateSelector = function ()
                {
                    // set defaults
                    if (!$scope.dateSelector) {
                        $scope.dateSelector = {};
                    }
                    
                    // reset dateSelector.options based on hideAllDays flag
                    $scope.dateSelector.options = [];
                    if ($scope.dateSelector.hideAllDays !== true) {
                        $scope.dateSelector.options.push(
                        {name: localStrings.getLocalizedString("AllDays"), value: 13});
                    }
                    $scope.dateSelector.options.push(
                        {name: localStrings.getLocalizedString("Today"), value: 0},
                        {name: localStrings.getLocalizedString("Yesterday"), value: 1},
                        {name: localStrings.getLocalizedString("Tomorrow"), value: 2},
                        {name: localStrings.getLocalizedString("ThisWeek"), value: 3},
                        {name: localStrings.getLocalizedString("ThisMonth"), value: 4},
                        {name: localStrings.getLocalizedString("ThisYear"), value: 5},
                        {name: localStrings.getLocalizedString("PriorWeek"), value: 6},
                        {name: localStrings.getLocalizedString("PriorMonth"), value: 7},
                        {name: localStrings.getLocalizedString("PriorYear"), value: 8},
                        {name: localStrings.getLocalizedString("LastSevenDays"), value: 9},
                        {name: localStrings.getLocalizedString("LastThirtyDays"), value: 10},
                        {name: localStrings.getLocalizedString("SelectDates"), value: 11},
                        {name: localStrings.getLocalizedString("SelectDays"), value: 12});

                    $scope.dateSelector.startMin = '1/1/1900';
                    $scope.dateSelector.startMax = '6/6/2079';
                    $scope.dateSelector.endMin = '1/1/1900';
                    $scope.dateSelector.endMax = '6/6/2079';
                    $scope.dateSelector.disabled = false;
                    $scope.dateSelector.hideAllDays = false;

                    // override parameer data if flag is false
                    if ($scope.dateSelector.overrideDefaults) {
                        $scope.dateSelector.overrideDefaults = false;
                    }
                    else
                    {
                        $scope.dateSelector.displaySelector = true;
                        $scope.dateSelector.selection = 9;
                        $scope.dateSelector.startDate = '';
                        $scope.dateSelector.endDate = '';
                        $scope.dateSelector.numOfDays = 7;
                        $scope.dateSelector.maxNumOfDays = 90;
                        $scope.dateSelector.valid = false;
                    }
                    $scope.onChange();
                };

                $scope.validateStartAndEndDate = function ()
                {
                    $scope.dateSelector.valid = Date.parse($scope.dateSelector.startDate) <= Date.parse($scope.dateSelector.endDate);
                    if (!$scope.dateSelector.valid)
                    {
                        // (htmlElement, attribute) 
                        $scope.notifier.show(getAttrTextFromNodeMap('dateSelectorFromThru', 'pdi-date-is-greater-equal-to-msg'), 'error');
                    }
                };

                $scope.onChange = function ()
                {
                    var today = new Date();
                    var year = new Date().getFullYear();
                    //month is the only zero based date variable
                    var month = new Date().getMonth();
                    var date = new Date().getDate();
                    var days = new Date().getDay();

                    switch (parseInt($scope.dateSelector.selection, 10))
                    {
                        case 0: //today
                        case 14: //single date selector
                            $scope.dateSelector.startDate = $filter('date')(today, 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $scope.dateSelector.startDate;
                            break;
                        case 1: //yesterday
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date - 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $scope.dateSelector.startDate;
                            break;
                        case 2: //tomorrow
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date + 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $scope.dateSelector.startDate;
                            break;
                        case 3: //this week
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date - days), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(year, month, date - days + 6), 'MM/dd/yyyy');
                            break;
                        case 4: //this month
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(year, month + 1, 0), 'MM/dd/yyyy');
                            break;
                        case 5: //this year
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, 0, 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(year, 11, 31), 'MM/dd/yyyy');
                            break;
                        case 6: //previous week
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date - days - 7), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(year, month, date - days - 1), 'MM/dd/yyyy');
                            break;
                        case 7: //previous month
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month - 1, 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(year, month, 0), 'MM/dd/yyyy');
                            break;
                        case 8: //previous year
                            $scope.dateSelector.startDate = $filter('date')(new Date(year - 1, 0, 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(year - 1, 11, 31), 'MM/dd/yyyy');
                            break;
                        case 9: //last 7 days
                        case 11: //select dates
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date - 6), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(), 'MM/dd/yyyy');
                            break;
                        case 10: //last 30 days
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date - 29), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(), 'MM/dd/yyyy');
                            break;
                        case 12: //select days
                            $scope.dateSelector.numOfDays = ($scope.dateSelector.numOfDays > $scope.dateSelector.maxNumOfDays) ? $scope.dateSelector.maxNumOfDays : $scope.dateSelector.numOfDays;
                            $scope.dateSelector.startDate = $filter('date')(new Date(year, month, date - $scope.dateSelector.numOfDays + 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(), 'MM/dd/yyyy');
                            break;
                        case 13: //all days
                            $scope.dateSelector.startDate = $filter('date')(new Date(1900, 0, 1), 'MM/dd/yyyy');
                            $scope.dateSelector.endDate = $filter('date')(new Date(2079, 0, 1), 'MM/dd/yyyy');
                            break;
                    }

                    /*var debug = '<b>Date Changed</b><br/>Start Date: '+ $scope.dateSelector.startDate + '<br/>End Date:  '+ $scope.dateSelector.endDate;
                    $scope.notifier.show(debug ,'info');*/
                };

            },
            templateUrl: templates.getTemplate("DateSelector")
        };
    });