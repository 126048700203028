/* ==============================================================================

 SERVICES.FuelCards MODULE

 Defines the module namespace for the Fuel Cards services

 ============================================================================= */
angular.module('services.FuelCards', ['core.ajax']);

/* ==============================================================================

 FuelCards SERVICE

 Contains the factories and services that pertain to retrieving
 and saving data to the database.

 ============================================================================= */
angular.module('services.FuelCards', [])
    .factory('FuelCardsService', function ($q, apiService)
    {
        'use strict';
        function setDatesToNull(obj)
        {
            for (var prop in obj)
            {
                var value = obj[prop];
                if (typeof value === 'object')
                {
                    setDatesToNull(value);
                }
                else
                {
                    if (['1900-01-01T00:00:00', '0001-01-01T00:00:00', '2079-06-06T00:00:00'].indexOf(obj[prop]) > -1)
                        obj[prop] = null;
                }
            }
        }

        return {
            getCards: function (customerContactKey, customerKey, cardFormat, cardNumDesc, isActive, isExpired, isPending, isLocked, isCancelled, department, template)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetCards/',
                        data: {
                            CustomerContactKey: customerContactKey,
                            CustomerKey: customerKey,
                            CardFormatKey: cardFormat,
                            CardNumDesc: cardNumDesc,
                            IsActive: isActive,
                            IsExpired: isExpired,
                            IsPending: isPending,
                            IsLocked: isLocked,
                            IsCancelled: isCancelled,
                            Department: department,
                            Template: template
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        setDatesToNull(data.Data);
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getCardDetails: function (cardKey, canViewCardNumbers)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetCardDetails/',
                        data: {
                            CardKey: cardKey,
                            CanViewCardNumbers:canViewCardNumbers
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        setDatesToNull(data.Data);
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getCardsContext: function (customerKey, compKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetCardsContext/',
                        data: {
                            CustomerKey: customerKey,
                            CompanyKey: compKey
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getTaxAuthorities: function (custKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetTaxAuthorities/',
                        data: {
                            CustomerKey: custKey
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            addCard: function (recStatus, card, cardCF, custContKey)
            {
                setDatesToNull(card);
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/SaveCards/',
                        data: {
                            Card: card,
                            CardCF: cardCF,
                            recStatus: recStatus,
                            CustContKey: custContKey
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            saveCards: function (recStatus, card, cardCF, custContKey)
            {
                setDatesToNull(card);
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/SaveCards/',
                        data: {
                            Card: card,
                            CardCF: cardCF,
                            recStatus: recStatus,
                            CustContKey: custContKey
                        }
                    }
                );
                apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getLookupPrices: function (custContKey, daysBack)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetCardPriceNotices/',
                        data: {
                            CustContKey: custContKey,
                            DaysBack: daysBack
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getPriceNoticesSettings: function (custContKey, cpPriceNoticeRuleKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetPriceNoticesSettings/',
                        data: {
                            CustContKey: custContKey,
                            CPPriceRuleKey: cpPriceNoticeRuleKey
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getSearchSites: function (custContKey, city, state, postal, limit)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetSearchSites/',
                        data: {
                            CustContKey: custContKey,
                            City: city,
                            State: state,
                            Zip: postal,
                            Limit: limit
                        },
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            savePriceNoticeSettings: function (custContKey, sites, groups, methods)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/SavePriceNoticesSettings/',
                        data: {
                            CustContKey: custContKey,
                            PriceNoticesSettingsSites: sites,
                            PriceNoticesSettingsGroups: groups,
                            PriceNoticesSettingsMethods: methods
                        }
                    }
                );
                apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            blankLocation: {
                Key: 0,
                ID: '',
                Description: '',
                Address1: '',
                Address2: '',
                City: '',
                County: '',
                StateKey: 0,
                PostalCode: '',
                Active: -1,
                Name: '',
                Phone: '',
                PhoneFormatted: '',
                Email: ''
            },

            getCardsDepartments: function (custKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/GetCardsDepartments/',
                        data: {
                            CustKey: custKey
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            saveCardsDepartments: function (recStatus, custKey, depts, fields, vals, custContKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/SaveCardsDepartments/',
                        data: {
                            RecStatus: recStatus,
                            CustKey: custKey,
                            CustContKey: custContKey,
                            Departments: depts,
                            Fields: fields,
                            Values: vals
                        }
                    }
                );
                apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            }
        };
    });
