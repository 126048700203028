/**
 * Created by rhampton on 6/17/2014.
 */


/* ==============================================================================

 CORE.DIRECTIVES MODULE

 Defines the module namespace for the core.directives

 ============================================================================= */
angular.module('core.directives', []);

/* ==============================================================================

 Modal Dialog

 Shows and hides a modal dialog centered on the page.

 ============================================================================= */
angular.module('core.directives')
    .directive('modalDialog', function ($rootScope)
    {
        return {
            restrict: 'E',
            scope: {
                show: '=',
                closeDialog: '&',
                closeText: '@',
                autoClose: '@'
            },
            replace: true, // Replace with the template below
            transclude: true, // we want to insert custom content inside the directive
            link: function (scope, element, attrs)
            {
                if (angular.isUndefinedOrNullOrEmpty(attrs.autoClose))
                {
                    attrs.autoClose = true;
                } else
                {
                    attrs.autoClose = scope.$eval(attrs.autoClose);
                }

                scope.dialogStyle = {};
                if (attrs.width)
                    scope.dialogStyle.width = attrs.width;
                if (attrs.height)
                    scope.dialogStyle.height = attrs.height;


            },
            controller: function ($scope, $element, $attrs)
            {

                $scope.hideModal = function (source)
                {
                    if ((source === 1) && (!$attrs.autoClose))
                    {
                        return;
                    } // if clicked outside of modal and autoClose is set to false then don't close modal

                    if (!angular.isUndefinedOrNullOrEmpty($scope.closeDialog()))
                    {
                        $scope.closeDialog();
                    }

                    $scope.show = false;
                };
            },
            template: "<div class='ng-modal' ng-show='show'><div class='ng-modal-overlay' ng-click='hideModal(1)'></div><div class='ng-modal-dialog' ng-style='dialogStyle'><div class='ng-modal-dialog-content' ng-transclude></div><div class='ng-modal-close' ng-click='hideModal(0)'><button>{{closeText}}</button></div></div></div>"
        };
    })
    .directive('parseInt', [function ()
    {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, controller)
            {
                controller.$formatters.push(function (modelValue)
                {
                    return '' + modelValue;
                });

                controller.$parsers.push(function (viewValue)
                {
                    return parseInt(viewValue, 10);
                });
            }
        };
    }])
    .directive('parseFloat', [function ()
    {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, controller)
            {
                controller.$formatters.push(function (modelValue)
                {
                    return '' + modelValue;
                });

                controller.$parsers.push(function (viewValue)
                {
                    return parseFloat(viewValue);
                });
            }
        };
    }])
    .directive('parseDate', function ()
    {
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl)
            {
                function fromUser(text)
                {
                    var transformedInput = text.replace(/[^0-9/]/, '');
                    if (transformedInput !== text)
                    {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }

                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    })
    .directive('dynamicHtml', function ($compile)
    {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, ele, attrs)
            {
                scope.$watch(attrs.dynamic, function (html)
                {
                    ele.html(html);
                    $compile(ele.contents())(scope);
                });
            }
        };
    })
    .directive('money', function ($filter)
    {
        'use strict';
        var NUMBER_REGEXP = /^\s*(\-|\+)?(\d+|(\d*(\.\d*)))\s*$/;

        function link(scope, el, attrs, ngModelCtrl)
        {
            var min = parseFloat(attrs.min || 0);
            var precision = parseFloat(attrs.precision || 2);
            var symbol = attrs.symbol || '';
            var lastValidValue;

            function unFormatNumber(number)
            {
                number += '';

                if (number.toString().indexOf(',') > -1)
                {
                    number = number.replace(/,/g, "");
                }
                if (number.toString().indexOf('$') > -1)
                {
                    number = number.replace(/\$/g, "");
                }
                if (number.length === 0)// || isNaN(number))
                    return "0";
                return number;
            }

            function formatViewValue(value)
            {
                value = unFormatNumber(value);
                value = ngModelCtrl.$isEmpty(value) ? '' : '' + symbol + formatNumber(value, precision);
                lastValidValue = value;
                return value;
            }

            ngModelCtrl.$formatters.push(formatViewValue);

            function formatNumber(val, decimalPlaces)
            {
                val = unFormatNumber(val);
                val = parseFloat(val).toFixed(decimalPlaces);
                val = addCommas(val);
                return val;
            }

            function addCommas(nStr)
            {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1))
                {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                return x1 + x2;
            }

            ngModelCtrl.$parsers.push(function (value)
            {
                value = unFormatNumber(value);
                if (angular.isUndefined(value))
                {
                    value = '';
                }
                //                value = unFormatNumber(value);
                // Handle leading decimal point, like ".5"
                if (value.indexOf('.') === 0)
                {
                    value = '0' + value;
                }

                // Allow "-" inputs only when min < 0
                if (value.indexOf('-') === 0)
                {
                    if (min >= 0)
                    {
                        value = null;
                        ngModelCtrl.$setViewValue('');
                        ngModelCtrl.$render();
                    } else if (value === '-')
                    {
                        value = '';
                    }
                }

                var empty = ngModelCtrl.$isEmpty(value);
                if (empty || NUMBER_REGEXP.test(value))
                {
                    lastValidValue = (value === '') ? null : (empty ? value : parseFloat(value));
                } else
                {
                    // Render the last valid input in the field
                    ngModelCtrl.$setViewValue(formatViewValue(lastValidValue));
                    ngModelCtrl.$render();
                }

                ngModelCtrl.$setValidity('number', true);
                return lastValidValue;
            });

            function applyFormat()
            {
                var value = unFormatNumber(ngModelCtrl.$modelValue);
                if (!isNaN(value))
                {
                    ngModelCtrl.$viewValue = formatViewValue(value);
                    lastValidValue = ngModelCtrl.$viewValue;
                    ngModelCtrl.$render();
                }
            }

            el.bind('blur', applyFormat);
            el.bind('change', applyFormat);

            return applyFormat();
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
    })
    .directive('customAutofocus', function ()
    {
        return {
            restrict: 'A',
            link: function (scope, element, attrs)
            {
                scope.$watch(function ()
                {
                    return scope.$eval(attrs.customAutofocus);
                }, function (newValue)
                {
                    if (newValue === true)
                    {
                        element[0].select();
                        element[0].focus();
                        //use focus function instead of autofocus attribute to avoid cross browser problem.
                        // And autofocus should only be used to mark an element to be focused when page loads.
                    }
                });
            }
        };
    })
    .directive("toolTips", function ($compile)
    {
        var clone = $compile('<div class="toolTipText">{{text}}</div>');

        function link(scope, el, attr)
        {
            if (scope.text && scope.text.length > 0)
            {
                el.qtip({
                    position: {
                        at: "right middle"
                    },
                    style: {
                        tip: {
                            corner: "left top"
                        },
                        classes: "toolTipStyle"
                    },
                    content: {
                        text: function ()
                        {
                            return scope.$apply(function ()
                            {
                                return clone(scope);
                            });
                        }
                    }
                });
            }
        }

        return {
            restrict: 'A',
            link: link,
            scope: {
                text: "=toolTips"
            }
        };
    })
    .directive('strength', ['$parse', function ($parse)
    {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                passwordComplexity: "=",
                passwordMeterScore: "="
            },
            link: function (scope, elem, attrs, ctrl)
            {
                ctrl.$parsers.unshift(function (viewValue)
                {
                    var pwdValidLength, pwdHasLetter, pwdHasNumber, pwdHasSpecial;

                    if (scope.passwordComplexity.EnforcePasswordComplexity)
                    {
                        pwdValidLength = (viewValue && viewValue.length >= scope.passwordComplexity.PasswordMinLength ? true : false);
                        pwdHasLetter = (viewValue && (viewValue.match(/[A-Z]/g) && viewValue.match(/[A-Z]/g).length >= scope.passwordComplexity.PasswordMinUpperCase)) ? true : false;
                        pwdHasNumber = (viewValue && (viewValue.match(/\d/g) && viewValue.match(/\d/g).length >= scope.passwordComplexity.PasswordMinNumbers)) ? true : false;
                        pwdHasSpecial = (viewValue && (viewValue.match(/[^a-zA-Z0-9]/g) && viewValue.match(/[^a-zA-Z0-9]/g).length >= scope.passwordComplexity.PasswordMinUpperCase)) ? true : false;

                    } else
                    {
                        pwdValidLength = (viewValue && viewValue.length >= 7 ? true : false);
                        pwdHasLetter = (viewValue && /[A-Z]/.test(viewValue)) ? true : false;
                        pwdHasNumber = (viewValue && /\d/.test(viewValue)) ? true : false;
                        pwdHasSpecial = (viewValue && /[^a-zA-Z0-9]/.test(viewValue)) ? true : false;
                    }

                    var score = +(viewValue.length) +
                        (/[A-Z]/.test(viewValue) ? 1 : 0 ) +
                        (/[a-z]/.test(viewValue) ? 1 : 0 ) +
                        (/\d/.test(viewValue) ? 1 : 0) +
                        (/[^a-zA-Z0-9]/.test(viewValue) ? 1 : 0);

                    var total = viewValue.length > 7 ? viewValue.length + 4 : 11;
                    scope.passwordMeterScore = score / total;

                    if (pwdValidLength && pwdHasLetter && pwdHasNumber && pwdHasSpecial)
                    {
                        ctrl.$setValidity('pwd', true);
                    } else
                    {
                        ctrl.$setValidity('pwd', false);
                    }
                    return viewValue;
                });
            }
        };
    }])
    .directive('match', ['$parse', function ($parse)
    {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attrs, ctrl)
            {
                scope.$watch(function ()
                {
                    return (ctrl.$pristine && angular.isUndefined(ctrl.$modelValue)) || $parse(attrs.match)(scope) === ctrl.$modelValue;
                }, function (currentValue)
                {
                    ctrl.$setValidity('match', currentValue);
                });
            }
        };
    }])
    .directive('imageSlider', ['$interval', '$timeout', function ($interval, $timeout)
    {
        return {
            restrict: 'E',
            scope: {
                imageArray: '='
            },
            template: "<div><ul class='slides'><span ng-repeat='image in imageArray'><input type='radio' name='radio-btn' id='img-{{$index}}' value='{{$index}}' ng-model='slider.index' checked/><li id='slideContainer' class='slide-container' ng-show='slider.index === {{$index}}'><div class='slide'><img ng-src='data:image/JPEG;base64,{{image.Data}}'/></div><div class='nav'><label class='prev' ng-click='getPrevImage($index)' ng-if='imageArray.length > 1'>&#x2039;</label><label class='next' ng-click='getNextImage($index)' ng-if='imageArray.length > 1'>&#x203a;</label></div></li></span></ul></div>",
            link: function (scope, elem, attrs, ctrl)
            {
                var start,
                    stop,
                    advance;

                // reverse thru the array and remove non-active images
                for (var i = scope.imageArray.length - 1; i >= 0; i--)
                {
                    if (!scope.imageArray[i].Active)
                        scope.imageArray.splice(i, 1);
                }

                scope.slider = {"index": 0};

                start = function ()
                {
                    if (advance)
                        return;

                    advance = $interval(function ()
                    {
                        if (scope.slider.index === scope.imageArray.length - 1)
                            scope.slider.index = 0;
                        else
                            ++scope.slider.index;
                    }, 7000);
                };

                start();

                stop = function ()
                {
                    if (advance)
                    {
                        $interval.cancel(advance);
                        advance = undefined;
                    }
                };

                scope.getPrevImage = function (index)
                {
                    scope.pauseAutoAdvance();
                    if (index === 0)
                        scope.slider.index = scope.imageArray.length - 1;
                    else
                        scope.slider.index = index - 1;
                };

                scope.getNextImage = function (index)
                {
                    scope.pauseAutoAdvance();
                    if (index === scope.imageArray.length - 1)
                        scope.slider.index = 0;
                    else
                        scope.slider.index = index + 1;
                };

                scope.pauseAutoAdvance = function ()
                {
                    if (advance)
                    {
                        stop();
                        $timeout(start, 20000);
                    }
                };

                scope.$on('$destroy', function ()
                    {
                        stop();
                    }
                );

            }
        };
    }])
    .directive('alphaNumeric', function ()
    {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl)
            {
                modelCtrl.$parsers.push(function (inputValue)
                {
                    // this next if is necessary for when using ng-required on your input.
                    // In such cases, when a letter is typed first, this parser will be called
                    // again, and the 2nd time, the value will be undefined
                    if (inputValue === undefined) return '';
                    var transformedInput = attrs.alphaNumeric === '0' ? inputValue.replace(/[^0-9]/g, '') : inputValue.replace(/[^a-zA-Z0-9]/g, '');
                    if (transformedInput !== inputValue)
                    {
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                    }

                    return transformedInput;
                });
            }
        };
    })
    .directive('autoresize', function ($window)
    {
        return function ($scope)
        {
            $scope.initializeWindowSize = function ()
            {
                $scope.maxHeight = Math.max(
                    document.body.scrollHeight, document.documentElement.scrollHeight,
                    document.body.offsetHeight, document.documentElement.offsetHeight,
                    document.body.clientHeight, document.documentElement.clientHeight,
                    window.innerHeight
                );
                $scope.windowHeight = $window.innerHeight;
                return $scope.windowWidth = $window.innerWidth;
            };
            $scope.initializeWindowSize();
            $scope.$watch('__height', function (newHeight, oldHeight)
            {
                $scope.initializeWindowSize();
            });
            return angular.element($window).bind('resize', function ()
            {
                $scope.initializeWindowSize();
                return $scope.$apply();
            });
        };
    })
    .directive('customContent', function ($compile)
    {
        return function (scope, el, attrs)
        {
            el.replaceWith($compile(attrs.html)(scope));
        };
    })
    .directive('onCloseFn', function ()
    {
        /*
         on-close-fn="myClosingActions()"
         on-close-message='Thanks for visiting out site.'

         $scope.myClosingActions returns: true to display the stock browser 'Leaving Page' with your message,
         false displays nothing
         */
        return {
            scope: {
                onCloseFn: '&',
                onCloseMessage: '@'
            },
            link: function ($scope, elem, attrs)
            {
                window.onbeforeunload = function ()
                {
                    if ($scope.onCloseFn())
                    {
                        return $scope.onCloseMessage || ' ';
                    }
                };

                $scope.$on('$destroy', function ()
                {
                    window.onbeforeunload = null;
                });
            }
        };
    })
    .directive('onLeaveFn', function ()
    {
        /*
         on-leave-fn="myClosingActions()"
         on-leave-message='You have unsaved changes. Leaving the page will result in data lose.'

         $scope.myClosingActions returns: true to display the stock browser 'Leaving Page' with your message,
         false displays nothing
         */
        return {
            scope: {
                onLeaveIfFn: '&',
                onLeaveMessage: '@'
            },
            link: function ($scope, elem, attrs)
            {

                var $locationChangeStartUnbind = $scope.$on('$locationChangeStart', function (event, next, current)
                {
                    if ($scope.onLeaveIfFn())
                    {
                        if (!confirm($scope.onLeaveMessage || 'Leave this page?'))
                        {
                            event.preventDefault();
                        }
                    }
                });

                $scope.$on('$destroy', function ()
                {
                    $locationChangeStartUnbind();
                });
            }
        };
    })
    .directive('promptToSave', ['$timeout', function ($timeout)
    {
        return {
            link: function ($scope, elem, attrs, ctrl)
            {
                var menu = $scope.locationInfo.menuLocation,
                    subMenu = $scope.locationInfo.subMenuLocation,
                    sideMenu = $scope.locationInfo.sideMenuData.subMenu;

                $scope.$cancelPromptToSave = $scope.$on('$locationChangeStart', function (event, next, current)
                {
                    var result = elem.find(".ng-dirty:not(form)").length + elem.find(".k-dirty-cell").length + elem.find(".k-dirty").length;

                    if (result > 0)
                    {
                        var confirmPopUpText = "This page may have unsaved changes.\n\nContinue without saving?";
                        if ($scope.localizationFilesInitialized)
                        {
                            var localizedString = $scope.pnLocalizationCtrl.localizations.getCachedLocalizationsContainer("AppLocalization");
                            confirmPopUpText = localizedString.stringFromCharacterEntity(localizedString.getLocalizedString("UnsavedChanges") + "\n\n" + localizedString.getLocalizedString("ContinueWithoutSaving"));
                        }

                        var response = confirm(confirmPopUpText);

                        if (!response)
                        {
                            event.preventDefault();
                            $scope.locationInfo.menuLocation = menu;
                            $scope.locationInfo.subMenuLocation = subMenu;
                            if ($scope.locationInfo.sideMenuData.pendingNav)
                                $timeout.cancel($scope.locationInfo.sideMenuData.pendingNav);
                            $scope.locationInfo.sideMenuData.subMenu = sideMenu;
                        }
                    }
                });

                $scope.$on('$destroy', function ()
                {
                    $scope.$cancelPromptToSave();
                });
            }
        };
    }])
    .directive('processingOverlay', function ($rootScope, $window, $timeout)
    {
        return {
            restrict: 'EA',
            replace: true,
            link: function (scope, element, attrs)
            {
                var overlayContainer = document.getElementById('processingOverlay');
                overlayContainer.style.width = '994px';

                var mainContent = document.getElementsByClassName("main-content")[0];

                $rootScope.$watch('mainContent.clientHeight', function ()
                {
                    setHeight();
                });

                $rootScope.$watch('showLoader', function (show)
                {
                    if (show)
                    {
                        overlayContainer.style.display = 'block';
                        $timeout(function ()
                        {
                            setHeight();
                        });
                    }
                    else
                    {
                        overlayContainer.style.display = 'none';
                    }
                });

                function setHeight()
                {
                    $("#processingOverlay").height(mainContent.clientHeight);
                }
            },
            template: '<div id="processingOverlay" class="ng-processing-overlay" ></div>'


        };
    })
    .directive('postRender', ['$timeout', function ($timeout) {
        // Use this directive when you need to reliably call a function after the element renders
        return {
            scope: {
                postRender: '&'
            },
            restrict: 'A',
            terminal: true,
            transclude: true,
            link: function (scope, element, attrs) {
                $timeout(scope.postRender, 0);
            }
        };
    }])
;