/**
 * Created by rhampton on 7/11/2014.
 */
angular.module('services.warehousePriceNotices', ['core.ajax']);

angular.module('services.warehousePriceNotices',[]).service('warehousePriceNoticesService', function($q, apiService) {
    'use strict';
    return {
        loadWarehousePrices: function(custKey, startDate, endDate) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetWarehousePriceNotices/',
                    data: {
                        CustKey:custKey,
                        StartDate:startDate,
                        EndDate:endDate
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});