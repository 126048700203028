/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.payments', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.payments',[]).factory('PaymentService', function($q, ajax, apiService, $rootScope) {

    'use strict';
    return{
        getPayment : function(customerKey, webPaymentKey){
            var deferred = $q.defer();
            var apiSvcSettings = {
                    method:'POST',
                    url:'../api/RetrieveWebPayment/',
                    data: {
                        CustomerKey:customerKey,
                        WebPaymentKey:webPaymentKey
                    }
                };
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );
            // Let's return our promise back to the caller
            return deferred.promise;
        },
        getBudgetPaymentContext: function (customerKey) {
            var deferred = $q.defer();
            var apiSvcSettings = {
                method: 'POST',
                url: '../api/BudgetWebPaymentContext/',
                data: {
                    CustomerKey: customerKey
                }
            };
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        savePayment : function(payment){
            var deferred = $q.defer();
            var apiSvcSettings = {
                method:'POST',
                url:'../api/WebPayment/',
                data: {
                    CustomerScheduledPayment : payment.CustomerScheduledPayment,
                    Invoices : payment.Invoices,
                    Credits : payment.Credits
                }
            };
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        getScheduledPaymentHistoryContext: function(customerKey){
            var deferred = $q.defer();
            var apiSvcSettings = {
                method:'POST',
                url:'../api/WebPaymentHistoryContext/',
                data: {
                    CustomerKey:customerKey
                }
            };
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        getScheduledPayments: function(customerKey, accountKey, startDate, endDate, scheduled, processed, cancelled){
            var deferred = $q.defer();
            var apiSvcSettings = {
                method:'POST',
                url:'../api/WebPaymentHistory/',
                data: {
                    CustomerKey: customerKey,
                    AccountKey: accountKey,
                    StartDate: startDate,
                    EndDate: endDate,
                    Scheduled: scheduled,
                    Canceled: cancelled,
                    Processed: processed
                }
            };
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        lockPayments: function (custContKey, emailAddress, custKey, skipBroadcast) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/LockCustomerPayments/',
                    data: {
                        CustContKey: custContKey,
                        EmailAddress: emailAddress,
                        CustomerKey: custKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function(data){
                    if(data.Data.CmdSuccess)
                    {
                        if (!skipBroadcast) { $rootScope.$broadcast('event:PaymentLocked', { CustContKey: custContKey, EmailAddress: emailAddress, CustomerKey: custKey }); }
                        deferred.resolve(data.Data);
                    }else{
                        deferred.reject(data.Data);
                    }
                }
            );
            return deferred.promise;
        },
        unLockPayments: function(custContKey, emailAddress, custKey){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method:'POST',
                    url:'../api/UnLockCustomerPayments/',
                    data: {
                        CustContKey: custContKey,
                        EmailAddress: emailAddress,
                        CustomerKey: custKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    $rootScope.$broadcast('event:PaymentUnlocked', {});
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        lockInvoices: function (custContKey, emailAddress, custKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method:'POST',
                    url:'../api/LockCustomerInvoices/',
                    data: {
                        CustContKey: custContKey,
                        EmailAddress: emailAddress,
                        CustomerKey: custKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function(data){
                    if(data.Data.CmdSuccess)
                    {
                        $rootScope.$broadcast('event:InvoiceLocked', { CustContKey: custContKey, EmailAddress: emailAddress, CustomerKey: custKey });
                        deferred.resolve(data.Data);
                    }else{
                        deferred.reject(data.Data);
                    }
                }
            );
            return deferred.promise;
        },
        unockInvoices: function(custContKey, emailAddress, custKey){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method:'POST',
                    url:'../api/UnLockCustomerInvoices/',
                    data: {
                        CustContKey: custContKey,
                        EmailAddress: emailAddress,
                        CustomerKey: custKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    $rootScope.$broadcast('event:InvoiceUnlocked', {});
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        lockPaymentsAndInvoices: function (custContKey, emailAddress, custKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/LockBothPaymentsAndInvoices/',
                    data: {
                        CustContKey: custContKey,
                        EmailAddress: emailAddress,
                        CustomerKey: custKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    if (data.Data.CmdSuccess) {
                        deferred.resolve(data.Data);
                    } else {
                        deferred.reject(data.Data);
                    }
                }
            );
            return deferred.promise;
        },
        getTermsAndConditions: function(termsKey){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method:'POST',
                    url:'../api/GetLegalTerms/',
                    data: {
                        LegalTermKey: termsKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );            return deferred.promise;
        },
        acceptTermsAndConditions: function(termsKey, custKey, contactKey){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method:'POST',
                    url:'../api/AcceptLegalTerms/',
                    data: {
                        LegalTermKey: termsKey,
                        CustomerKey: custKey,
                        CustomerContactKey: contactKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );            return deferred.promise;
        }
    };
});