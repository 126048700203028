angular.module('services.reporting', ['core.ajax'])
.service('reportingService', function ($q, apiService) {
    'use strict';

    function renderReportApi (parameters) {
        var deferred = $q.defer();
        var apiSvcSettings = new ApiServiceSettings(
            {
                method: 'POST',
                url: '../api/RenderSSRS',
                data: {
                    Parameters: parameters
                }
            }
        );
        apiService.execute(apiSvcSettings).then(
            function (data) {
                deferred.resolve(data.Data);
            }
        );
        return deferred.promise;
    }

    /**
     * the blob object is only valid for the window it is on,
     * once the window closes the url if captured is no longer valid
     * @param {any} report
    **/
    function createBlob(report) {
        var bytes = report.ReportBytes;
        var type = report.MimeType;

        var raw = atob(bytes);
        var uint8Array = new Uint8Array(raw.length);
        for (var i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }
        var blob = new Blob([uint8Array], { type: type });

        return blob;
    }

    function download(blobUrl, filename) {
        var a = document.createElement("a");
        if (a.click) {
            // Use a.click() if available. Otherwise, Chrome might show
            // "Unsafe JavaScript attempt to initiate a navigation change
            //  for frame with URL" and not open the PDF at all.
            // Supported by (not mentioned = untested):
            // - Firefox 6 - 19 (4- does not support a.click, 5 ignores a.click)
            // - Chrome 19 - 26 (18- does not support a.click)
            // - Opera 9 - 12.15
            // - Internet Explorer 6 - 10
            // - Safari 6 (5.1- does not support a.click)
            a.href = blobUrl;
            a.target = "_parent";
            // Use a.download if available. This increases the likelihood that
            // the file is downloaded instead of opened by another PDF plugin.
            if ("download" in a) {
                a.download = filename;
            }
            // <a> must be in the document for IE and recent Firefox versions.
            // (otherwise .click() is ignored)
            (document.body || document.documentElement).appendChild(a);
            a.click();
            a.parentNode.removeChild(a);
        } else {
            if (window.top === window &&
                blobUrl.split("#")[0] === window.location.href.split("#")[0]) {
                // If _parent == self, then opening an identical URL with different
                // location hash will only cause a navigation, not a download.
                var padCharacter = blobUrl.indexOf("?") === -1 ? "?" : "&";
                blobUrl = blobUrl.replace(/#|$/, padCharacter + "$&");
            }
            window.open(blobUrl, "_parent");
        }
    }

    /**
     * Takes the specified parameters and renders a report in the pdfjs viewer
     * @param {string} parms
    **/
    var renderReport = function (parms) {
        var dataParams = parms + "&export=False";

        renderReportApi(dataParams).then(function (report) {
            if (report) {
                var url = "static/pdfjs/web/viewer.html?file=";

                var blob = createBlob(report);
                var file = URL.createObjectURL(blob);
                url = url + encodeURIComponent(file);

                $("#Main").attr("src", url);
            }
        });
    };

    /**
     * Takes the specified parameters and report format and
     * generates a report that is downloaded
     * @param {string} parms
     * @param {string} format
    **/
    var exportReport = function (parms, format) {
        var dataParams = parms + "&export=True&exportFormat=" + format;

        renderReportApi(dataParams).then(function (report) {
            if (report) {
                var blob = createBlob(report);
                var isIE = false || !!document.documentMode;
                if (isIE) { // support for ie11
                    window.navigator.msSaveBlob(blob, report.Filename);
                } else {
                    var file = URL.createObjectURL(blob);
                    var filename = report.Filename;
                    download(file, filename);

                    // Releases the resource after the file is downloaded
                    URL.revokeObjectURL(file);
                }
            }
        });
    };

    return {
        renderReport: renderReport,
        exportReport: exportReport
    };
});