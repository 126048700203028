/**
 * Created by rhampton on 3/6/14.
 */

angular.module('petronetApp', [
    'core.providers',
    'kendo.directives',
    'services.localizations',
    'ngRoute',
    'ngAnimate',
    'pnContactUs',
    'pnLogin',
    'pnHeader',
    'pnHome',
    'pnAdmin',
    'pnAccountReports',
    'pnAccountPayments',
    'pnFuelCardsCards',
    'pnFuelCardsPrices',
    'pnFuelCardsTransactions',
    'pnOrders',
    'pnODPricing',
    'pnPaperwork',
    'pnDocuments',
    'pnDocAccount',
    'pnDateSelector',
    'pnReportSettings',
    'pnPages',
    'core.directives',
    'core.filters',
    'core.enums',
    'core.constants',
    'core.error',
    'core.objects',
    'core.security',
    'services.browser',
    'services.bulkFuelPriceNotices',
    'services.encrypt',
    'services.FuelCards',
    'services.contactUs',
    'services.documents',
    'services.home',
    'services.globalizations',
    'services.orders',
    'services.paperwork',
    'services.paperworkMeters',
    'services.paperworkTanks',
    'services.paperworkDeliveries',
    'services.paperworkDealerForms',
    'services.paperworkReports',
    'services.payments',
    'services.paymentAccounts',
    'services.userAccounts',
    'services.warehousePriceNotices',
    'services.WebProfile',
    'services.customers',
    'services.pages',
    'services.logging',
    'services.reporting',
    'services.edoc',
    'pdi.validate',
    'pdi.extensions',
    'angulartics',
    'angulartics.google.analytics'
])
    .config(function ($routeProvider, $httpProvider, $locationProvider, templateProvider)
    {
        // as of angularjs 1.6.0 they append ! to hashbang urls which causes undesired behavior
        // reset that functionlity by adding this hashPrefix change
        $locationProvider.hashPrefix('');

        $routeProvider
            .when("/",
            {
                templateUrl: templateProvider.getTemplate("LogIn"),
                controller: 'loginCtrl',
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("LoginLocalization");
                    }
                }
            }
        )
            .when("/Account/Alerts/BalanceAlertSettings",
            {
                templateUrl: "account/alerts/balanceAlertSettings/balanceAlertSettings.tpl.html",
                controller: "VerifyPaymentCtrl"
            }
        )
            .otherwise({
                templateUrl: templateProvider.getTemplate("Home"),
                controller: "homeCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("HomeLocalization");
                    }
                }
            });

        // Add our custom Interceptors
        $httpProvider.interceptors.push('httpInterceptor');
        $httpProvider.interceptors.push('AuthInterceptor');

        // For backwards compatibility to old browsers, set this to false
        //$locationProvider.html5Mode(false).hashPrefix('!');
    })
    .factory('httpInterceptor', function ($rootScope, $q, $injector, localizationSvc)
    {

        return {

            request: function (config)
            {

                config.headers = config.headers || {};


                if ($rootScope.loginInfo && $rootScope.loginInfo.userIsImpersonated) {
                    config.headers['customerweb-user-id'] = $rootScope.loginInfo.userId;
                    config.headers['customerweb-user-key'] = $rootScope.loginInfo.userKey;
                    config.headers['customerweb-user-type'] = $rootScope.loginInfo.userType;
                    config.headers['customerweb-impersonator-id'] = $rootScope.loginInfo.impersonatorUserId;
                    config.headers['customerweb-impersonator-key'] = $rootScope.loginInfo.impersonatorUserKey;
                    config.headers['customerweb-impersonator-type'] = $rootScope.loginInfo.impersonatorUserType;
                }

                // Show our ajax spinner
                $rootScope.showLoader = true;

                return config || $q.when(config);
            },

            response: function (response)
            {

                var $http = $http || $injector.get('$http');

                // If all the pending http requests are done or
                // the user is not authenticated
                if ($http.pendingRequests.length < 1 || $rootScope.isAuthenticated === false)
                {
                    // Hide the ajax spinner
                    $rootScope.showLoader = false;
                }

                return response || $q.when(response);

            },

            responseError: function (response)
            {
                var localStrings = localizationSvc.getCachedLocalizationsContainer("AppLocalization");
                switch (response.status)
                {
                    case 500:
                        if (response.data.ExceptionMessage && response.data.ExceptionMessage.match("UNIQUE KEY constraint"))
                        {
                            if (response.data.ExceptionMessage.match("AR_Web_Settings"))
                                $rootScope.notifier.show('<b>' + localStrings.getLocalizedString("SaveFailed") + '</b><br/>' + localStrings.getLocalizedString("UniqueNameRequired"), 'error');
                            else
                                $rootScope.notifier.show('<b>' + localStrings.getLocalizedString("SaveFailed") + '</b><br/>' + localStrings.getLocalizedString("CannotInsertDuplicate"), 'error');
                        }

                        if (response.data.ExceptionMessage && response.data.ExceptionMessage.match("Timeout expired"))
                        {
                            $rootScope.notifier.show(localStrings.getLocalizedString("ConnectionTimedOut"), 'error');
                        }
                }

                // An error has occurred, hide the ajax spinner
                $rootScope.showLoader = false;

                return $q.reject(response);

            }

        };

    })
    .controller("indexCtrl", function ($scope, $http, $q, $rootScope, Authentication, WebUserSvc, $location,
        WebFunctionGroups, WebFunctions, $window, BrowserService, WebProfileService, $timeout, $filter, encryptData,
        WebFunctionModules, PaymentService, PaperworkService, localizationSvc, LoggingService, EdocService)
    {
        'use strict';

        var localStrings = localizationSvc.getCachedLocalizationsContainer("AppLocalization");
        var localDateRangeStrings = localizationSvc.getCachedLocalizationsContainer("DateSelectorLocalization");
        $scope.initialize = function ()
        {
            $scope.appInfo = $scope.appInfo || {
                    profile: {},
                    webUser: {},
                    paperwork: {},
                    fuelCards: {},
                    userLoaded: false,
                    profileKey: -1,
                    customText: "",
                    backgroundImage: {},
                    syscfg: {}
            };

            if (angular.isUndefinedOrNullOrEmpty($rootScope.loginInfo))
            {
                $rootScope.loginInfo = {};
                $rootScope.loginInfo.userId = "";
                $rootScope.loginInfo.password = "";
                $rootScope.loginInfo.userKey = -1;
                $rootScope.loginInfo.userType = 1;
                $rootScope.loginInfo.userIsImpersonated = false;
                $rootScope.loginInfo.impersonatorUserId = "";
                $rootScope.loginInfo.impersonatorUserKey = -1;
                $rootScope.loginInfo.impersonatorUserType = 0;
                $rootScope.loginInfo.impersonationAccessGuid = undefined;

            }

            if ($location.path().search(/(SignUp|ResetPassword|unsubscribe|SiteDisabled)/i) === -1)
            {
                if ($location.path().indexOf("SetPassword") >= 0)
                {

                    $scope.applySystemSettings();

                }
                else if (!$scope.appInfo.userLoaded || $scope.appInfo.webUser === undefined)
                {
                    if (WebUserSvc.getLoggedInWebUserToken() === undefined)
                        $scope.logout();
                }
            }
        };

        $scope.getAuthenticationToken = function ()
        {
            return Authentication.userToken();
        };

        $scope.logout = function ()
        {
            $rootScope.sessionExpired = false;
            $scope.clearInfo();
            $scope.goTo('/');
        };

        $scope.clearInfo = function ()
        {
            amplify.store("pnuser-token", null);
            amplify.store("pnprofile", null);
            amplify.store("pnWebProfile", null);
            amplify.store("pnAuthIndex", null);
            $rootScope.isAuthenticated = false;
            $rootScope.loginInfo = {};
            $rootScope.loginInfo.userId = "";
            $rootScope.loginInfo.password = "";
            $rootScope.loginInfo.rememberMe = false;
            $scope.locationInfo = {};
            $scope.appInfo = {};
        };

        $scope.functionGroup = WebFunctionGroups;
        $scope.webFunctions = WebFunctions;
        $scope.locationInfo = {
            menuLocation: 0,
            subMenuLocation: -1,
            sideMenu: "",
            sideMenuData: {}
        };

        //default actions for side menu apply buttons
        $scope.applyAction = function ()
        {
            //todo: set error condition indicating there is no assigned action
        };


        $scope.applySystemSettings = function ()
        {
            WebProfileService.initialize().then(function (data)
            {
                $scope.appInfo.syscfg = data.Configuration;
                $scope.appInfo.softwareVersion = data.SoftwareVersion;
                $scope.appInfo.ContactUsText = data.ContactUsText;
                $scope.appInfo.poweredByPdiText = data.PoweredByPdiText;
                $scope.appInfo.countryInfo = data.Country;
                $scope.appInfo.passwordComplexity = data.PasswordComplexity;

                if (!$scope.appInfo.userLoaded)
                {
                    $scope.appInfo.LandingLogoImage = data.LandingLogoImage;
                    $scope.appInfo.backgroundImage = data.LandingBannerImage;
                }

                if ($scope.appInfo.syscfg.UseGoogleAnalytics === 'True' && !$scope.appInfo.syscfg.GoogleAnalyticsKey.isBlank())
                {
                    ga('create', $scope.appInfo.syscfg.GoogleAnalyticsKey, 'auto'); // 'none' while you are working on localhost else 'auto'
                }
            });
        };

        $scope.applySystemSettingsSiteDisabled = function ()
        {
            WebProfileService.initializeSiteDisabled().then(function (data)
            {
                $scope.appInfo.syscfg = data.Configuration;
                $scope.appInfo.softwareVersion = data.SoftwareVersion;
                $scope.appInfo.poweredByPdiText = data.PoweredByPdiText;
                $scope.appInfo.countryInfo = data.Country;
                $scope.appInfo.passwordComplexity = data.PasswordComplexity;
                if (!$scope.appInfo.userLoaded)
                {
                    $scope.appInfo.LandingLogoImage = data.LandingLogoImage;
                    $scope.appInfo.backgroundImage = data.LandingBannerImage;
                }

            });
        };

        $scope.applySelectedProfile = function () {
            $scope.appInfo.webUser = WebUserSvc.getWebUser();
            if (!angular.isUndefinedOrNullOrEmpty($scope.appInfo.webUser)) {
                if (!angular.isUndefinedOrNullOrEmpty($scope.appInfo.webUser.CurrentWebProfile)) {
                    $scope.appInfo.profile = $scope.appInfo.webUser.CurrentWebProfile;
                    $scope.appInfo.profileKey = $scope.appInfo.webUser.CurrentWebProfile.Key;
                    WebProfileService.setProfileColor($scope.appInfo.webUser.CurrentWebProfile.PrimaryColor);
                }

                if (angular.isUndefinedOrNullOrEmpty($scope.appInfo.authorizationIndex))
                    $scope.appInfo.authorizationIndex = WebUserSvc.getCurrentAuthorization();
                if (!angular.isUndefinedOrNullOrEmpty($scope.appInfo.authorizationIndex))
                    $scope.appInfo.webUser.CurrentAuthorization = $scope.appInfo.webUser.WebAuthorizations[$scope.appInfo.authorizationIndex];

                $scope.appInfo.backgroundImage = $scope.appInfo.webUser.MenuBackgroundImage;
                $scope.appInfo.userLoaded = true;
            }
        };

        $scope.checkForAppliedProfile = function () {
            if (angular.isUndefinedOrNullOrEmpty($scope.appInfo.webUser) ||
                angular.isUndefinedOrNullOrEmpty($scope.appInfo.webUser.CurrentWebProfile) ||
                angular.isUndefinedOrNullOrEmpty($scope.appInfo.webUser.CurrentAuthorization)) {
                $scope.applySelectedProfile();
            }
        };

        $scope.setSubMenu = function (value, location)
        {
            $scope.locationInfo.sideMenuData.pendingNav = $timeout(function ()
            {
                $scope.locationInfo.sideMenuData.subMenu = value;
                $scope.locationInfo.sideMenuData.pendingNav = undefined;
            }, 0);
        };

        $scope.setWebProfile = function (profileKey)
        {
            $scope.appInfo.profileKey = profileKey;
            WebProfileService.setWebProfile(profileKey).then(function (data)
            {
                $scope.appInfo.profile = data.WebProfile;
            });
        };

        $scope.setSystemConfiguration = function ()
        {
            WebProfileService.setSystemConfiguration().then(function (data)
            {

            });
        };

        $scope.checkCurrentUserPermissions = function (value, contact)
        {
            $scope.checkForAppliedProfile();

            if (!contact)
            {
                if (!$scope.appInfo.webUser) return false;
                if (!$scope.appInfo.webUser.CurrentAuthorization) return false;
                contact = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact;
            }
            var webFunctions = contact.CustomerContactWebFunctions;
            for (var x = 0; x < value.length; x++)
            {
                var hasModuleAccess = $scope.checkCurrentCustomerModules(value[x]);

                for (var i = 0; i < webFunctions.length; i++)
                    //check to see if the current customer has the allowed function.
                    //then check the contact security.
                    if (webFunctions[i].WebFnKey === value[x])
                        if (hasModuleAccess && webFunctions[i].Selected)
                            return true;
            }
            return false;
        };

        $scope.checkCurrentUserPermission = function (value, contact)
        {
            $scope.checkForAppliedProfile();

            if (!contact)
            {
                if (!$scope.appInfo.webUser) return false;
                if (!$scope.appInfo.webUser.CurrentAuthorization) return false;
                contact = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact;
            }

            //check to see if the current customer has the allowed function.
            if (!$scope.checkCurrentCustomerModules(value))
                return false;

            if (contact.AllWebFunctions) return true;

            var webFunctions = contact.CustomerContactWebFunctions;
            for (var i = 0; i < webFunctions.length; i++)
                if (webFunctions[i].WebFnKey === value)
                {
                    return webFunctions[i].Selected;
                }
            return false;
        };

        $scope.checkCurrentCustomerModules = function (value)
        {
            var currentCustomer = $scope.appInfo.webUser.CurrentAuthorization.Customer;
            var isBulkFuel = currentCustomer.BulkFuelCustomer !== 0;
            var isCardLock = currentCustomer.CardLockCustomer !== 0;
            var isRetail = currentCustomer.RetailCustomer !== 0;
            var isWholesale = currentCustomer.WholesaleCustomer !== 0;
            var isWebEft = (currentCustomer.WebPmtEFTGrpKey !== null && currentCustomer.WebPmtEFTGrpKey !== 0);

            //check to see if the current customer has the allowed function.
            return WebFunctionModules.checkWebFunctionModules(value, isBulkFuel, isCardLock, isRetail, isWholesale, isWebEft, currentCustomer);
        };

        $scope.openEdocByRefNo = function (type, key, refNo, custKey)
        {
            var qs = {
                "EdocDetailsKey" : 0,
                "Type" : type,
                "Key" : key,
                "RefNo" : refNo,
                "CustKey" : custKey
            };

            EdocService.renderEdocByRefNo(JSON.stringify(qs))
                .then(function (edoc) {
                    var url = EdocService.createUrl(edoc);
                    window.open(url, '_blank', 'menubar=no,resizable=no,height=600,width=770,toolbar=no');
                });
        };

        function openNewWindow(url)
        {
            window.open(url, localStrings.getLocalizedString("DocumentViewer"), 'location=no,menubar=no,resizable=no,scrollbars=no,status=no ,toolbar=no, height=600, width=770');
        }

        $scope.openEdoc = function (edocKey)
        {
            EdocService.renderEdoc(edocKey)
                .then(function (edoc){
                    openNewWindow(EdocService.createUrl(edoc));
                });
        };

        $scope.openEdocEmail = function (edocKey, senderAddress)
        {
            if (senderAddress && senderAddress.length > 0)
            {
                var qs = "EdocDetailsKey=" + edocKey +
                    "senderAddress=" + senderAddress;

                encryptData.encrypt(qs).then(function (data)
                {
                    var url = '../EdocDetailsEmail/Index/?x=' + encodeURIComponent(data) + '&y=' + encodeURIComponent(Authentication.userToken());
                    openNewWindow(url);
                });
                return;
            }
            $scope.openEdoc(edocKey);
        };

        $scope.openAttachment = function (attachmentGuid)
        {
            var url = '../api/Attachment/?x=' + attachmentGuid + '&y=' + encodeURIComponent(Authentication.userToken());
            openNewWindow(url);
        };

        $scope.getWebProfileMessage = function (messageKey)
        {
            var profileKey = ($scope.appInfo && $scope.appInfo.profileKey) ? $scope.appInfo.profileKey : 0;

            WebProfileService.getWebProfileMessage(profileKey, messageKey).then(function (data)
            {
                $scope.appInfo.customText = data;
            });
        };

        //spin up the webprofile service to make sure future calls will load correctly
        $scope.getWebProfileMessage(1);

        $scope.$on('$locationChangeStart', function (event, next, current) {

            if ($location.path().search(/(login|SignUp|ResetPassword|SetPassword|SiteDisabled|unsubscribe)/i) > -1)
                return;

            if (angular.isUndefinedOrNullOrEmpty($scope.appInfo) ||
                angular.isUndefinedOrNullOrEmpty(WebUserSvc.getLoggedInWebUserToken())) {
                $scope.goTo('/');
            }

            $scope.checkForAppliedProfile();

            if (!next || !current || !$scope.appInfo.webUser) return;

            if (next.indexOf('AccountVerifyPayment') < 0 &&
                next.indexOf('MakeAPayment') < 0 &&
                (current && current.indexOf('/Payments/') >= 0)) {
                PaymentService.unLockPayments($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                    $scope.appInfo.webUser.CurrentAuthorization.CustKey);
                PaymentService.unockInvoices($scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                    $scope.appInfo.webUser.CurrentAuthorization.CustKey);
            }

            if (next.indexOf('EnterPaperwork') < 0 &&//If we are not navigating to metere
                next.indexOf('EnterPaperworkTanks') < 0 &&//OR tanks
                next.indexOf('EnterPaperworkDeliveries') < 0 &&//OR deliveries
                next.indexOf('EnterPaperworkDealerForms') < 0 &&//or forms
                next.indexOf('InventorySummary') < 0 &&//or inventory summary
                (current && current.indexOf('/Paperwork/') >= 0))//and we are comming from a paperwork url
            {
                if (!$scope.appInfo.paperwork) return;//if we dont have a paperwork set then return.
                if (!$scope.appInfo.paperwork.batch) return;//if we dont have a paperwork set then return.
                var submitPaperworkCmdParms =
                {
                    SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                    PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                    EmailAddress: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                    SubmitPaperwork: false,//If this is hit, then close only.
                    PreviouslySubmitted: false
                };
                PaperworkService.SubmitPaperwork(submitPaperworkCmdParms);
            }
        });

        $scope.$on('$routeChangeError', function (event, current, previous, rejection)
        {
            switch (rejection)
            {

                // Handle invalid auth token errors on route change
                case 'authentication:auth-token not valid':
                    $scope.goTo('/login');
                    $scope.notifier.show(localStrings.getLocalizedString("InvalidCredentials"), 'error');
                    break;

                default:
                    // Display error message to user
                    $scope.notifier.show('Invalid path', 'error');
            }
        });

        $scope.urlFormatDate = function (aDate, ignoreIe)
        {
            if (typeof(aDate) !== 'string' || aDate.isBlank()) return;

            var browser = BrowserService.getBrowserType();
            var aDate2 = new Date();
            if (Object.prototype.toString.call(aDate) !== '[object Date]')
            {
                aDate = aDate.substr(0, 10);
                aDate2 = new Date(Date.parse(aDate.replace(/-/g, "/").replace(/T/g, " ")));
                if (isNaN(aDate2.getTime()))
                {
                    aDate2 = new Date();
                }
            } else
            {
                aDate2 = aDate;
            }

            if (browser === 'ie' && !ignoreIe)
                return ("0" + (aDate2.getMonth() + 1)).slice(-2) + '/' + ("0" + aDate2.getDate()).slice(-2) + '/' + aDate2.getFullYear();
            return aDate2.getFullYear() + '-' + ("0" + (aDate2.getMonth() + 1)).slice(-2) + '-' + ("0" + aDate2.getDate()).slice(-2);
        };

        $scope.formatDate = function (aDate)
        {
            return $filter('date')(aDate, 'MM/dd/yyyy');
        };

        $scope.formatDateTime = function (aDate)
        {
            return $filter('date')(aDate, 'MM/dd/yyyy hh:mm a');
        };

        $scope.addDaysToDate = function addDays(date, days)
        {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        };

        var successEventListener = $rootScope.$on('event:Success', function (event, data)
        {
            $scope.notifier.show(localStrings.getLocalizedString("ChangesSaved"), "success");

            if (data.opResults.HasWarnings)
            {
                angular.forEach(data.opResults.Warnings, function (item)
                {
                    $scope.notifier.show(item, "warning");
                });
            }
        });

        $scope.$on('$destroy', successEventListener);

        var failedEventListener = $rootScope.$on('event:Failed', function (event, data)
        {
            if (data.opResults.HasErrors)
            {
                // Account lock errors go into a custom kendo notification widget
                var _accountLockError = localStrings.getLocalizedString("AccountLockError");

                angular.forEach(data.opResults.Errors, function (item)
                {
                    if (item === _accountLockError) {
                        $scope.lockedAccount.show(item, "error");
                    }
                    else
                        $scope.notifier.show(item, "error");
                });
            }

            if (data.opResults.HasWarnings)
            {
                angular.forEach(data.opResults.Warnings, function (item)
                {
                    $scope.notifier.show(item, "warning");
                });
            }
        });

        $scope.$on('$destroy', failedEventListener);

        var errorEventListener = $rootScope.$on('event:Error', function (obj, data) {
            var errors = { ExceptionMessage: data.opResults.ExceptionMessage, Stacktrace: data.opResults.StackTrace };

            LoggingService.logErrors(errors).then(function(data) {
                console.log("Exception was logged please check SIEL in Enterprise Web.");
            });
        });

        $scope.$on('$destroy', errorEventListener);

        $scope.goTo = function (path)
        {
            $location.path(path);
        };

        $scope.setFocusOnReady = function setFocusOnReady(elemID)
        {
            setTimeout(function ()
            {
                $scope.$apply(function ()
                {
                    $(elemID).focus();
                });
            }, 100);
        };

        $scope.paymentsEnabled = function ()
        {
            return $scope.appInfo.webUser.CurrentWebProfile.WebPaymentsEnabled &&
                $scope.appInfo.webUser.CurrentAuthorization.Customer.UsesWebEFT !== '0' &&
                $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.CurrentProfileTerms > 0 &&
                ($scope.checkCurrentUserPermission($scope.webFunctions.AddandEditWebPaymentAccounts) ||
                ($scope.checkCurrentUserPermission($scope.webFunctions.AddandEditWebPayments) && $scope.appInfo.webUser.CurrentAuthorization.CustWebPaymentSettings.ActiveAccountCount > 0 ));
        };

        $scope.execOnReady = function execOnReady(func)
        {
            setTimeout(function ()
            {
                $scope.$apply(func());
            }, 0);
        };

        $scope.resetDirtyFlags = function resetDirtyFlags(form)
        {
            $timeout(function ()
            {
                form.$setPristine();
                $('.k-dirty').removeClass('k-dirty');
                $('.k-dirty-cell').removeClass('k-dirty-cell');
            }, 0);
        };

        $scope.resizeKendoGrid = function resizeKendoGrid(grid, count, offset)
        {
            var widowHeight = $(window).innerHeight() - offset;
            var dataHeight = count * 35;

            if (dataHeight > widowHeight)
            {
                grid.setOptions({
                    height: widowHeight,
                    scrollable: true
                });
            }
            else
            {
                grid.setOptions({
                    height: 'inherit',
                    scrollable: false
                });
            }
        };

        $scope.initializeDateSelector = function() {
            $scope.dateSelector = {
                displaySelector: true,
                selection: 9,
                startDate: '',
                startMin: '1/1/1900',
                startMax: '6/6/2079',
                endDate: '',
                endMin: '1/1/1900',
                endMax: '6/6/2079',
                numOfDays: 7,
                maxNumOfDays: 90,
                options: [
                    { name: localDateRangeStrings.getLocalizedString("Today"), value: 0 },
                    { name: localDateRangeStrings.getLocalizedString("Yesterday"), value: 1 },
                    { name: localDateRangeStrings.getLocalizedString("Tomorrow"), value: 2 },
                    { name: localDateRangeStrings.getLocalizedString("ThisWeek"), value: 3 },
                    { name: localDateRangeStrings.getLocalizedString("ThisMonth"), value: 4 },
                    { name: localDateRangeStrings.getLocalizedString("ThisYear"), value: 5 },
                    { name: localDateRangeStrings.getLocalizedString("PriorWeek"), value: 6 },
                    { name: localDateRangeStrings.getLocalizedString("PriorMonth"), value: 7 },
                    { name: localDateRangeStrings.getLocalizedString("PriorYear"), value: 8 },
                    { name: localDateRangeStrings.getLocalizedString("LastSevenDays"), value: 9 },
                    { name: localDateRangeStrings.getLocalizedString("LastThirtyDays"), value: 10 },
                    { name: localDateRangeStrings.getLocalizedString("SelectDates"), value: 11 },
                    { name: localDateRangeStrings.getLocalizedString("SelectDays"), value: 12 },
                    { name: localDateRangeStrings.getLocalizedString("AllDays"), value: 13 }
                ],
                valid: false,
                overrideDefaults: false,
                hideAllDays: false,
                disabled: false
            };

            $scope.dateSelectorInitialized = true;
        };

        $scope.$on('$viewContentLoaded', function () {
            $timeout(function () {

                //Build localized strings for the entire application once all localization files are rendered
                if (!$scope.localizationFilesInitialized) {
                    var localizationFilesListener = $scope.$watch($('#DateSelectorLocalization innerText'), $timeout(function () {
                        localizationFilesListener();//Destroy the $watch
                        localizationSvc.setCachedLocalizations();
                        $scope.localizationFilesInitialized = true;//Only initialize once
                    }, true), 0);
                }

                // Initialize the dateselector control if it has not been initialized
                // From one of the other javascript files
                if (!$scope.dateSelectorInitialized) {
                    $scope.initializeDateSelector();
                }

                $scope.modalifier = {
                    display: false,
                    title: '',
                    text: '',
                    showConfirm: false,
                    closeBtnTxt: localStrings.getLocalizedString("Close"),
                    confirmBtnTxt: localStrings.getLocalizedString("Yes"),
                    show: function (title, text)
                    {
                        $scope.modalifier.title = title;
                        $scope.modalifier.text = text;
                        $scope.modalifier.showConfirm = false;
                        $scope.modalifier.display = true;
                    },
                    hide: function ()
                    {
                        $scope.modalifier.display = false;
                    },
                    showSetupError: function ()
                    {
                        $scope.modalifier.title = localStrings.getLocalizedString("PageSetupError");
                        $scope.modalifier.text = localStrings.getLocalizedString("SomeDataFailedLoad") + '<br/>' +
                            localStrings.getLocalizedString("PleaseRefresh");
                        $scope.modalifier.showConfirm = false;
                        $scope.modalifier.display = true;
                    },
                    showSavedError: function () {
                        $scope.modalifier.title = localStrings.getLocalizedString("PageSetupError");
                        $scope.modalifier.text = localStrings.getLocalizedString("DailySettlementFailed") + '<br/>' +
                            localStrings.getLocalizedString("FuelMetersSaveFailed");
                        $scope.modalifier.showConfirm = false;
                        $scope.modalifier.display = true;
                    },
                    showNoResults: function (page)
                    {
                        $scope.modalifier.title = page;
                        $scope.modalifier.text = localStrings.getLocalizedString("CriteriaNoResults");
                        $scope.modalifier.showConfirm = false;
                        $scope.modalifier.display = true;
                    },
                    confirm: function (text, fnYes, fnNo, title)
                    {
                        $scope.modalifier.title = title || localStrings.getLocalizedString("Confirm");
                        $scope.modalifier.closeBtnTxt = localStrings.getLocalizedString("No");
                        $scope.modalifier.confirmBtnTxt = localStrings.getLocalizedString("Yes");
                        $scope.modalifier.text = text;
                        $scope.modalifier.confirmYes = function ()
                        {
                            $scope.modalifier.display = false;
                            if (!angular.isUndefined(fnYes))
                                fnYes();
                        };
                        $scope.modalifier.confirmNo = function ()
                        {
                            $scope.modalifier.display = false;
                            if (!angular.isUndefined(fnNo))
                                fnNo();
                        };
                        $scope.modalifier.showConfirm = true;
                        $scope.modalifier.display = true;
                    },
                    confirmNo: null,
                    confirmYes: null
                };
            }, 0);
        });
    })
    .directive('stringNumber', [function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, controller) {
                controller.$formatters.push(function (modelValue) {
                    return Number(modelValue); /*convert to number, for going from to view*/
                });
                controller.$parsers.push(function (viewValue) {
                    return '' + viewValue; /*convert to string, for going from view to model*/
                });
            }
        };
    }]);
