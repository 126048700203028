angular.module("pnPages", ['core.objects', 'services.pages', 'core.directives'])
    .config(function ($routeProvider, templateProvider) {
        $routeProvider
            .when("/Pages/",
                {
                    templateUrl: templateProvider.getTemplate("Pages"),
                    controller: "customPagesCtrl",
                    controllerAs: "ctrl"
                })
            .when("/Pages/Detail/:key",
                {
                    templateUrl: templateProvider.getTemplate("PagesDetail"),
                    controller: "customPagesDetailCtrl",
                    controllerAs: "customPagesDetailCtrl",
                    resolve: {
                        localStrings: function (localizationSvc) {
                            return localizationSvc.getCachedLocalizationsContainer("PagesLocalization");
                        }
                    }
                })
            ;
    })
    .filter('encodeURIComponent', ['$window', function ($window) {
        return function (input) {
            return encodeURIComponent(input);
        };
    }])
    .controller('customPagesCtrl', function ($scope, templates, PagesService, WebFunctions) {
        'use strict';
        var ctrl = this;
        ctrl.Context = {
            Pages: [],
            Tokens: []
        };

        ctrl.initialize = function () {
            if (!$scope.checkCurrentUserPermission(WebFunctions.ViewCustomPages)) {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 7;
            $scope.locationInfo.sideMenu = templates.getTemplate("PagesSideMenu");
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "0";

            $scope.checkForAppliedProfile();
            PagesService.getWebProfileSiteLayout($scope.appInfo.profileKey).then(function (response) {
                if (response.Successful) {
                    //get custom pages key
                    var customPagesMenuNode = response.SiteLayout.MenuNodes.filter(function (val, index) {
                        return val.Name === 'Custom Pages';
                    });
                    if (!angular.isUndefinedOrNullOrEmpty(customPagesMenuNode) && customPagesMenuNode.length === 1) {
                        ctrl.Context.Pages = response.SiteLayout.MenuNodes.filter(function (val, index) {
                            return val.ParentKey === customPagesMenuNode[0].Key;
                        });
                    }
                }
            });
        };
    })
    .controller('customPagesDetailCtrl', function ($scope, $routeParams, PagesService, $sce, WebFunctions, templates, $http, localStrings, $rootScope, $timeout, $interval) {
        'use strict';
        var ctrl = this;
        ctrl.reloadInterval = 0;
        ctrl.selectedPage = {};
        ctrl.initialize = function (conf) {
            if (!$scope.checkCurrentUserPermission(WebFunctions.ViewCustomPages)) {
                $scope.goTo("/Home");
            }

            $scope.locationInfo.menuLocation = 7;
            $scope.locationInfo.sideMenu = templates.getTemplate("PagesSideMenu");
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "0";
            PagesService.getWebProfileSiteLayout($scope.appInfo.profileKey).then(function (response) {
                if (response.Successful) {
                    //get custom pages key
                    var customPagesMenuNode = response.SiteLayout.MenuNodes.filter(function (val, index) {
                        return val.Name === 'Custom Pages';
                    });
                    if (!angular.isUndefinedOrNullOrEmpty(customPagesMenuNode) && customPagesMenuNode.length === 1) {
                        var pages = response.SiteLayout.MenuNodes.filter(function (val, index) {
                            return val.ParentKey === customPagesMenuNode[0].Key;
                        });
                        var key = $routeParams.key;
                        if (!angular.isUndefinedOrNullOrEmpty(key)) {
                            var selectedIndex = 0;
                            var foundPage = pages.filter(function (val, index) {
                                if (val.Key.toString() === key) {
                                    selectedIndex = index;
                                    return true;
                                }
                                return false;
                            });
                            if (!angular.isUndefinedOrNullOrEmpty(foundPage) && foundPage.length > 0) {
                                ctrl.selectedPage = foundPage[0];
                                $scope.locationInfo.sideMenuData.subMenu = selectedIndex;
                                retrieveToken(conf);
                            }
                        }
                    }
                }
            });
        };

        function retrieveToken(conf) {
            PagesService.getAccessToken($scope.appInfo.profileKey, ctrl.selectedPage.Key).then(
                function (response) {
                    if (response && response.Successful && response.AccessToken.Success) {
                        if (angular.isUndefinedOrNullOrEmpty(response.MenuNode.ClientAuthMethod)) {
                            var url = $sce.trustAsResourceUrl(ctrl.selectedPage.PageURL);
                            angular.element("#customPageWrapper").attr('src', url);
                        }
                        else {
                            if (!angular.isUndefinedOrNullOrEmpty(response.AccessToken.access_token)) {
                                switch (response.MenuNode.ClientAuthMethod) {
                                    case conf.AuthMethods.Header:
                                        var req = {
                                            method: 'GET',
                                            url: ctrl.selectedPage.PageURL,
                                            headers: {
                                                'Authorization': 'Bearer ' + response.AccessToken.access_token
                                            }
                                        };
                                        $http(req).then(
                                            function (response) {
                                                // A response status code between 200 and 299 is considered a success status
                                                if (response.status >= 200 && response.status < 300) {
                                                    var data = response.data || '';
                                                    if (response.data && response.data.indexOf('<base') === -1) {
                                                        data = response.data.replace('<head>', '<head><base href="' + ctrl.selectedPage.PageURL+'">');
                                                    }
                                                    var frame = document.querySelector('#customPageWrapper');
                                                    
                                                    frame.contentWindow.document.open('text/html', 'replace');
                                                    frame.contentWindow.document.write(data);
                                                    frame.contentWindow.document.close();
                                                }
                                            },
                                            function (failed) {
                                                var msg = localStrings.getLocalizedString("UnableProcessPage").replace('{0}', ctrl.selectedPage.Name);
                                                $rootScope.notifier.show(msg, 'error');
                                            }
                                        );
                                        break;
                                    case conf.AuthMethods.URL:
                                        var glue = (ctrl.selectedPage.PageURL.includes('?') ? '&' : '?');
                                        var params = glue + 'access_token=' + encodeURIComponent(response.AccessToken.access_token) + '&token_type=' + response.AccessToken.token_type;
                                        angular.element("#customPageWrapper").attr('src', $sce.trustAsResourceUrl(ctrl.selectedPage.PageURL + params));
                                        break;
                                }
                            }
                            else {
                                var msg = localStrings.getLocalizedString("UnableProcessPage").replace('{0}', ctrl.selectedPage.Name);
                                $rootScope.notifier.show(msg, 'error');
                            }
                        }
                        // register auto refresh - force to reload iFrame when approaching token expiration
                        if (!angular.isUndefinedOrNullOrEmpty(response.AccessToken.expires_in) && !angular.isUndefinedOrNullOrEmpty(response.AccessToken.access_token)) {
                            $timeout(function () {
                                retrieveToken(conf);
                            }, (response.AccessToken.expires_in * 1000));
                        }
                    }
                },
                function (failure) {
                    var msg = localStrings.getLocalizedString("UnableProcessPage").replace('{0}', ctrl.selectedPage.Name);
                    $rootScope.notifier.show(msg, 'error');
                });
        }
    })
    .controller('customPagesSideMenuCtrl', function ($scope, $rootScope, $routeParams, PagesService) {
        'use strict';
        var ctrl = this;
        ctrl.Context = {
            Pages: []
        };
        ctrl.isSelected = function (index) {
            var retval = $scope.locationInfo.sideMenuData.subMenu === index;
            if (retval) {
                ctrl.selectedPage = ctrl.Context.Pages[index];
            }
            return retval;
        };
        ctrl.initialize = function () {
            PagesService.getWebProfileSiteLayout($scope.appInfo.profileKey).then(function (response) {
                if (response.Successful) {
                    //get custom pages key
                    var customPagesMenuNode = response.SiteLayout.MenuNodes.filter(function (val, index) {
                        return val.Name === 'Custom Pages';
                    });
                    if (!angular.isUndefinedOrNullOrEmpty(customPagesMenuNode) && customPagesMenuNode.length === 1) {
                        ctrl.Context.Pages = response.SiteLayout.MenuNodes.filter(function (val, index) {
                            return val.ParentKey === customPagesMenuNode[0].Key;
                        });
                    }
                }
            });
        };
    });
