/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 Profile SERVICE

 Contains the core profile related code

 ============================================================================= */
angular.module('services.reports', ['core.ajax']);

angular.module('services.reports', []).factory('ReportServiceService', function ($q, $rootScope, ajax, apiService)
{
    'use strict';
    return {
        encrypt: function(data) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/EncryptString/',
                    data: data
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});