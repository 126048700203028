angular.module('core.objects', [])
    .factory("Payment",function(HttpUtil){
        'use strict';
        var paymentInfo = {};
        return {
            paymentInfo : paymentInfo
        };
    })
    .factory("HttpUtil",function($http, $q){
        'use strict';
        return {};
    });