/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================
 services.home MODULE
 ============================================================================= */
angular.module('services.home', ['core.ajax']);

/* ==============================================================================
 homeService
 ============================================================================= */
angular.module('services.home',[]).factory('homeService', function($q, ajax, apiService) {

    'use strict';
    return{
        loadHomePageInformation : function(custContKey){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'post',
                    url: '../api/Home/',
                    data: {
                        CustomerContactKey: custContKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});