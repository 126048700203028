angular.module('services.edoc', ['core.ajax'])
    .service('EdocService', function ($q, apiService) {
        'use strict';
        
        /**
         * the blob object is only valid for the window it is on,
         * once the window closes the url if captured is no longer valid
         * @param {any} edoc
         * @returns {blob}
         **/
        function createBlob(edoc) {
            var bytes = edoc.EdocStream;
            var type = edoc.MimeType;

            var raw = atob(bytes);
            var uint8Array = new Uint8Array(raw.length);
            for (var i = 0; i < raw.length; i++) {
                uint8Array[i] = raw.charCodeAt(i);
            }
            var blob = new Blob([uint8Array], { type: type });

            return blob;
        }

        /**
         * Takes the specified parameters and generates a edoc blob url
         * @param {number} edocKey
         * @returns {Promise<any>}
         **/
        var renderEdoc = function (edocKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/EdocDetailsSecure',
                    data: {
                        EdocKey: edocKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        };

        /**
         * Takes the specified parameters and generates a edoc blob url
         * @param {string} parameters
         * @returns {Promise<any>}
         */
        var renderEdocByRefNo = function (parameters) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/EdocDetailsSecure/GetByRefNo',
                    data: {
                        Parameters: parameters
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        };

        /**
         * Take the edoc stream and turn it into a blob url and then display it in our pdf viewer
         * If mime type is text/html then that means the document doesnt exist and we need to redirect 
         * to the DocumentNotAvailable endpoint
         * @param {any} edoc
         * @returns {string}
         **/
        var createUrl = function (edoc){
            if (edoc) {
                var url;
                
                if (edoc.MimeType === 'text/html'){
                    url = '../api/DocumentNotAvailable';
                    return url;
                }
                
                var blob = createBlob(edoc);
                var file = URL.createObjectURL(blob);
                url = "static/pdfjs/web/viewer.html?file=" + encodeURIComponent(file);

                return url;
            }
        };

        return {
            renderEdoc: renderEdoc,
            renderEdocByRefNo: renderEdocByRefNo,
            createUrl: createUrl
        };
    });