/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.encrypt', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.encrypt',[]).service('encryptData', function($q, apiService) {
    'use strict';
    var encrypt = function(value) {
        var deferred = $q.defer();
        var apiSvcSettings = new ApiServiceSettings(
            {
                method: 'POST',
                url: '../api/EncryptString/',
                data: {
                    Value: value
                }
            }
        );
        apiService.execute(apiSvcSettings).then(
            function (data) {
                deferred.resolve(data.Data);
            }
        );
        return deferred.promise;
    };

    return {
        encrypt: encrypt
    };
});