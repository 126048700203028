/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
function CustomerPaymentAccount(account){
    this.AccountNo = account.AccountNo;
    this.AccountNoVerify = account.AccountNoVerify;
    this.AccountNoMasked = account.AccountNoMasked;
    this.AccountStatus = account.AccountStatus;
    this.AccountStatusDesc = account.AccountStatusDesc;
    this.AccountType = account.AccountType;
    this.BankRoutingNo = account.BankRoutingNo;
    this.CreatedDateTime = account.CreatedDateTime;
    this.CreatedByCustContKey = account.CreatedByCustContKey;
    this.CustKey = account.CustKey;
    this.Description = account.Description;
    this.Key = account.Key;
    this.LastModifiedDateTime = account.LastModifiedDateTime;
    this.LastModifiedByCustContKey = account.LastModifiedByCustContKey;
    this.PrenoteDate = account.PrenoteDate;
    this.RejectedEmailSent = account.RejectedEmailSent;
    this.VerificationAmount1 = account.VerificationAmount1;
    this.VerificationAmount2 = account.VerificationAmount2;
    this.VerificationDate = account.VerificationDate;
    this.TimeStamp = account.TimeStamp;
    this.IsDefault = account.IsDefault;
    this.AccountLastActivity = account.AccountLastActivity;
    this.AccountNextPaymentKey = account.AccountNextPaymentKey;
    this.AccountNextPayment = account.AccountNextPayment;
    this.AccountNextPaymentAmmount = account.AccountNextPaymentAmmount;
    this.ScheduledPaymentCount = account.ScheduledPaymentCount;
}



angular.module('services.paymentAccounts', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.paymentAccounts',[]).factory('PaymentAccountService', function($q, ajax, apiService) {
    'use strict';
    var WebPaymentAccount = {};
    return{
        WebPaymentAccount:WebPaymentAccount,
        getPaymentAccountList : function(customerKey){
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/WebPaymentAccounts/' + customerKey,
                    params: {}
                });
            var deferred = $q.defer();
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        getPaymentAccount : function(customerPaymentAccountKey, customerKey){
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/WebPaymentAccount/' + customerPaymentAccountKey + '/' + customerKey,
                    params: {}
                });
            var deferred = $q.defer();
            apiService.execute(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        savePaymentAccount : function(webPaymentAccount){
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method:'POST',
                    url:'../api/WebPaymentAccount',
                    data: webPaymentAccount
                }
            );
            var deferred = $q.defer();
            apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                function(data){
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        validateRoutingNumber : function(routingNumber){
            var deferred = $q.defer();
            ajax.get('../api/WebPaymentAccountValidateRoutingNumber', routingNumber)
                .then(function(data, status, config, headers){
                    var routingNubmerisValid = true;
                    deferred.resolve(routingNubmerisValid);
                })
                .catch(function(data, status, config, headers){
                    var routingNubmerisValid = data;
                    deferred.reject(routingNubmerisValid);
                });
            return deferred.promise;
        }
    };
});