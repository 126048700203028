// pdi.ng namespace setup
var pdi = pdi || {};
pdi.ng = pdi.ng || {};
pdi.ng.validate = pdi.ng.validate || {};

//***************************************************************************************
// helper functions
//***************************************************************************************

angular.isUndefinedOrNullOrEmpty = function(val) {
    'use strict';
    return (angular.isUndefined(val) || val === null || val === '' || val.length === 0);
};

String.format = function () {
    'use strict';

    // The string containing the format items (e.g. "{0}")
    // will and always has to be the first argument.
    // analogous to the C# String.format function
    var theString = arguments[0];

    if (angular.isUndefined(theString)) return;

    // start with the second argument (i = 1)
    for (var i = 1; i < arguments.length; i++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
        theString = theString.replace(regEx, arguments[i]);
    }

    return theString;
};

function toBoolean(value) {
    'use strict';
    if (typeof value === 'function' || angular.isUndefined(value)) {
        value = true;
    } else if (value && value.length !== 0) {
        var v = angular.$$lowercase("" + value);
        value = !(v === 'f' || v === '0' || v === 'false' || v === 'no' || v === 'n' || v === '[]');
    } else {
        value = false;
    }
    return value;
}

pdi.ng.validate.PdiValidation =
    function (validation, parms) {
        'use strict';
        return function() {
            return {
                name: validation.name,
                validatorParms: parms || {},
                validationMessage: validation.validationMessage || 'Error: Validation message is not defined for ' + validation.name,
                validatorError: validation.validatorError,
                validationMessageWrn: validation.validationMessageWrn || 'Error: Validation message is not defined for ' + validation.name,
                validatorWarning: validation.validatorWarning,
                executionExplicit: validation.executionExplicit || true  // if true then the validation is not executed on model value changed and must be explicitly executed through 'executeValidations' broadcast
            };
        };
    };

//validation UI default functions
pdi.ng.validate.processElementErrorNotification = function(validationAttr, hasError, element, attrs) {
    'use strict';

    if (attrs.ngModel === undefined) { return; }

    var propertyName = attrs.ngModel.split('.').join('-');

    var validationValidationMsg = $('#' + validationAttr + '-' + propertyName);

    function errFocusHdlr() {
        $("#error-" + validationAttr + "-" + propertyName).attr('style', 'display: inline-block !important;');
    }

    function errBlurHdlr() {
        $("#error-" + validationAttr + "-" + propertyName).attr('style', 'display: none !important;');
    }

    function errClickHdlr() {
        $("#error-" + validationAttr + "-" + propertyName).attr('style', 'display: inline-block !important;');

        function hideError() {
            $("#error-" + validationAttr + "-" + propertyName).attr('style', 'display: none !important;');
        }

        setTimeout(hideError, 3000);
    }

    validationValidationMsg.toggle(hasError);

    var kendoDropDwnElm;
    var currentElement = $(element);

    if (hasError) {
        element.addClass("validation-error");
        validationValidationMsg.attr('style', 'display: inline-block !important;');

        if (currentElement.data("kendoComboBox")) {
            //var kendoDropDwnElm = angular.element(element[0].previousSibling.firstChild); // input element of kendo combobox with class k-input
            kendoDropDwnElm = kendo.widgetInstance($(element[0]), kendo.ui);

            kendoDropDwnElm.input.bind("focus", errFocusHdlr);
            kendoDropDwnElm.input.bind("blur", errBlurHdlr);

        } else if (currentElement.data("kendoDropDownList")) {
            kendoDropDwnElm = kendo.widgetInstance($(element[0]), kendo.ui);

            kendoDropDwnElm.span.bind("click", errClickHdlr);

        } else {
            element.bind("focus", errFocusHdlr);
            element.bind("blur", errBlurHdlr);
        }

    } else {

        element.removeClass("validation-error");
        validationValidationMsg.attr('style', 'display: none !important;');

        if (currentElement.data("kendoComboBox")) {

            kendoDropDwnElm = kendo.widgetInstance($(element[0]), kendo.ui);

            kendoDropDwnElm.input.unbind("focus", errFocusHdlr);
            kendoDropDwnElm.input.unbind("blur", errBlurHdlr);

        } else if (currentElement.data("kendoDropDownList")) {
            kendoDropDwnElm = kendo.widgetInstance($(element[0]), kendo.ui);

            kendoDropDwnElm.span.unbind("click", errClickHdlr);

        } else {
            element.unbind("focus", errFocusHdlr);
            element.unbind("blur", errBlurHdlr);
        }
    }

    return;
};

pdi.ng.validate.processElementWarningNotification = function(validationAttr, hasWarning, element, attrs) {
    'use strict';

    if (attrs.ngModel === undefined) { return; }

    var propertyName = attrs.ngModel.split('.').join('-');

    var validationValidationMsg = $('#' + validationAttr + '-' + propertyName + '-wrn');

    function wrnFocusHdlr() {
        $("#warning-" + validationAttr + "-" + propertyName).attr('style', 'display: inline-block !important;');
    }

    function wrnBlurHdlr() {
        $("#warning-" + validationAttr + "-" + propertyName).attr('style', 'display: none !important;');
    }

    var currentElement = $(element);

    if (hasWarning) {
        element.addClass("validation-warning");
        validationValidationMsg.attr('style', 'display: inline-block !important;');

        if ((currentElement.data("kendoComboBox")) || (currentElement.data("kendoDropDownList"))) {
            var kendoDropDwnElm = kendo.widgetInstance($(element[0]), kendo.ui);

            kendoDropDwnElm.input.bind("focus", wrnFocusHdlr);
            kendoDropDwnElm.input.bind("blur", wrnBlurHdlr);
        } else {
            element.bind("focus", wrnFocusHdlr);
            element.bind("blur", wrnBlurHdlr);
        }


    } else {

        element.removeClass("validation-warning");
        validationValidationMsg.attr('style', 'display: none !important;');

        if ((currentElement.data("kendoComboBox")) || (currentElement.data("kendoDropDownList"))) {
            var kendoDropDwnElm1 = kendo.widgetInstance($(element[0]), kendo.ui);

            kendoDropDwnElm1.input.unbind("focus", wrnFocusHdlr);
            kendoDropDwnElm1.input.unbind("blur", wrnBlurHdlr);
        } else {
            element.unbind("focus", wrnFocusHdlr);
            element.unbind("blur", wrnBlurHdlr);
        }
    }

    return;
};

pdi.ng.validate.processValidationNotification = function(element, attrs, compile, scope) {
    'use strict';

    element.removeClass("summaryerror");
    element.removeClass("summarywarning");
    element.removeClass("gridvalidationsummary");

    var output = "";
    function returnOutput(){
        return output;
    }

    if (scope.scopeValidationErrors.length > 0) {

        for (var i = 0; i <= scope.scopeValidationErrors.length - 1; i++) {

            if (attrs.name === scope.scopeValidationErrors[i].name) {
                element.addClass("gridvalidationsummary");
                element.addClass("summaryerror");

                var validationErr = scope.scopeValidationErrors[i];
                output += "<div class='field-validation-error'>" + validationErr.msg + "</div>";

                element.qtip({
                    content: returnOutput(),
                    position: {
                        viewport: $(window),
                        my: 'left center',
                        at: 'top right',
                        collision: 'fit flip',
                        adjust: { y: 7, screen: true }
                    },
                    show: { delay: 0 },
                    hide: { fixed: true },
                    style: { classes: 'ui-tooltip-pdiError' }
                });
            }
        }



    }
    else if (scope.scopeValidationWarnings.length > 0) {

        for (var x = 0; x <= scope.scopeValidationWarnings.length - 1; x++) {

            if (attrs.name === scope.scopeValidationWarnings[x].name) {

                element.addClass("gridvalidationsummary");
                element.addClass("summarywarning");

                var validationWrn = scope.scopeValidationWarnings[x];
                output += "<div class='field-validation-warning'>" + validationWrn.msg + "</div>";

                element.qtip({
                    content: returnOutput(),
                    position: {
                        viewport: $(window),
                        my: 'left center',
                        at: 'top right',
                        collision: 'fit flip',
                        adjust: { y: 7, screen: true }
                    },
                    show: { delay: 0 },
                    hide: { fixed: true },
                    style: { classes: 'ui-tooltip-pdiWarning' }
                });
            }
        }
    }

    compile(element)(scope);

    return;
};

pdi.ng.validate.processValidationsSummaryNotification = function(element, attrs, compile, scope) {
    'use strict';

    element.removeClass("summaryerror");
    element.removeClass("summarywarning");
    element.removeClass("gridvalidationsummary");

    if (scope.scopeValidationErrors.length > 0) {
        element.addClass("gridvalidationsummary");
        element.addClass("summaryerror");

        element.qtip({
            content: function() {
                var output = "";

                for (var i = 0; i <= scope.scopeValidationErrors.length - 1; i++) {

                    var validationErr = scope.scopeValidationErrors[i];

                    output += "<div class='field-validation-error'>" + validationErr.msg + "</div>";

                }

                return output;
            },
            position: {
                viewport: $(window),
                my: 'left center',
                at: 'top right',
                collision: 'fit flip',
                adjust: { y: 7, screen: true }
            },
            show: { delay: 0 },
            hide: { fixed: true },
            style: { classes: 'ui-tooltip-pdiError' }
        });

    }
    else if (scope.scopeValidationWarnings.length > 0) {
        element.addClass("gridvalidationsummary");
        element.addClass("summarywarning");

        element.qtip({
            content: function() {
                var output = "";

                for (var i = 0; i <= scope.scopeValidationWarnings.length - 1; i++) {

                    var validationWrn = scope.scopeValidationWarnings[i];

                    output += "<div class='field-validation-warning'>" + validationWrn.msg + "</div>";

                }

                return output;
            },
            position: {
                viewport: $(window),
                my: 'left center',
                at: 'top right',
                collision: 'fit flip',
                adjust: { y: 7, screen: true }
            },
            show: { delay: 0 },
            hide: { fixed: true },
            style: { classes: 'ui-tooltip-pdiWarning' }
        });
    }

    compile(element)(scope);

    return;
};

pdi.ng.validate.createElementValidationMessageError = function(validationAttr, validationMsg, element, attrs, scope, compile) {
    'use strict';

    var propertyName = attrs.ngModel.split('.').join('-');
    var currentElement = $(element);

    var validationMessageElement = angular.element(
            '<div id="' + validationAttr + '-' + propertyName + '" data-custom-validation-priorityIndex=1' +
            ' data-custom-validation-attribute=' + validationAttr +
            ' class="validationBar pdiError onTop" tabindex="-1">' +
            '<div id="error-' + validationAttr + '-' + propertyName + '" style="display: none"><ul>' +
            '<li class="error">' + validationMsg + '</li>' +
            '</ul></div></div>');
    if (currentElement.data("kendoComboBox")) {
        element.after(validationMessageElement);
    } else if (currentElement.data("kendoDropDownList")) {
        $(element[0]).parent().after(validationMessageElement);
    } else if (currentElement.data("kendoDatePicker")) {
        $(element[0]).closest(".k-datepicker").after(validationMessageElement);
    } else if (currentElement.data("kendoDateTimePicker")) {
        $(element[0]).closest(".k-datetimepicker").after(validationMessageElement);
    } else {
        //check to see if element has a clear button to clear contents
        // if so add error after clear button instead of element
        var clearBtnElm = element.siblings(".clear-input-button");
        if (clearBtnElm.length > 0) {
            clearBtnElm.after(validationMessageElement);
        } else {
            element.after(validationMessageElement);
        }
    }
    compile(validationMessageElement)(scope);
    validationMessageElement.hide();

    return;
};

pdi.ng.validate.createElementValidationMessageWarning = function (validationAttr, validationMsg, element, attrs, scope, compile) {
    'use strict';

    var propertyName = attrs.ngModel.split('.').join('-');
    var currentElement = $(element);

    var validationMessageElement = angular.element(
            '<div id="' + validationAttr + '-' + propertyName + '-wrn' + '" data-custom-validation-priorityIndex=1' +
            ' data-custom-validation-attribute=' + validationAttr +
            ' class="validationBar pdiWarning onTop" tabindex="-1">' +
            '<div id="warning-' + validationAttr + '-' + propertyName + '" style="display: none"><ul>' +
            '<li class="warning">' + validationMsg + '</li>' +
            '</ul></div></div>');

    if (currentElement.data("kendoComboBox")) {
        element.after(validationMessageElement);
    } else if (currentElement.data("kendoDropDownList")) {
        $(element[0]).parent().after(validationMessageElement);
    } else if (currentElement.data("kendoDatePicker")) {
        $(element[0]).closest(".k-datepicker").after(validationMessageElement);
    } else if (currentElement.data("kendoDateTimePicker")) {
        $(element[0]).closest(".k-datetimepicker").after(validationMessageElement);
    } else {
        //check to see if element has a clear button to clear contents
        // if so add error after clear button instead of element
        var clearBtnElm = element.siblings(".clear-input-button");
        if (clearBtnElm.length > 0) {
            clearBtnElm.after(validationMessageElement);
        } else {
            element.after(validationMessageElement);
        }
    }
    compile(validationMessageElement)(scope);
    validationMessageElement.hide();

    return;
};

pdi.ng.validate.removeElementValidationMessage = function(element, attrs, scope, compile) {
    'use strict';

    var currentElement = $(element);

    if (element.hasClass('summaryerror')) {
        element.removeClass("summaryerror");
        element.removeClass("summarywarning");
        element.removeClass("gridvalidationsummary");
    }

    if (currentElement.data("kendoDropDownList")) {
        element.parent().siblings().each(function() {
            var $this = $(this);

            if ($this.hasClass('validationBar')) {
                $this.remove();
            }

            compile()(scope);
        });
    } else {
        element.parent().children().each(function() {
            var $this = $(this);

            if ($this.hasClass('validationBar')) {
                $this.remove();
            }

            compile()(scope);
        });
    }

    return;
};

//pdi validation defaults

pdi.ng.validate.requiredMsgDefault = '';
pdi.ng.validate.minLengthMsgDefault = '';
pdi.ng.validate.maxLengthMsgDefault = '';
pdi.ng.validate.valueRuleRngMsgDefault = '';
pdi.ng.validate.valueRuleGtMsgDefault = '';
pdi.ng.validate.valueRuleLtMsgDefault = '';
pdi.ng.validate.ptrnEmailMsgDefault = '';

var pdiRequiredExecutionExplicitDefault = true;
var pdiMinLengthExecutionExplicitDefault = true;
var pdiMaxLengthExecutionExplicitDefault = true;
var pdiMaxLengthEnforceMaxConstraintDefault = false;
var pdiValueRuleRangeExecutionExplicitDefault = true;
var pdiValueRuleGreaterThanExecutionExplicitDefault = true;
var pdiValueRuleLessThanExecutionExplicitDefault = true;
var pdiEmailValidataionExecutionExplicitDefault = true;


function evaluateIfConditions(ifExpr, scope) {
    'use strict';
    return toBoolean(scope.$eval(ifExpr));
}

angular.module('pdi.validate', [])
    .service('validatorSvc', [
        '$rootScope', function($rootScope) {

            $rootScope.scopesToValidate = [];
            $rootScope.formModelWatchers = [];

            function executeValidationsForScope(scope) {
                'use strict';

                for (var cs = scope.$$childHead; cs; cs=cs.$$nextSibling) {
                    executeValidationsForScope(cs);
                }

                if (angular.isUndefinedOrNullOrEmpty(scope.scopeValidations)) {
                    return true;
                }

                // broadcast event for elements to clear their errors/warnings and notification UI
                scope.$broadcast('clearElementValidationNotifications', {});
                scope.$broadcast('clearSummaryValidationNotifications', {});

                var validation;

                //clear arrays
                scope.scopeValidationErrors = [];
                scope.scopeValidationWarnings.length = [];

                var hadError = false;
                var validatorResult = {};

                for (var i = 0; i <= scope.scopeValidations.length - 1; i++) {
                    validation = scope.scopeValidations[i];

                    hadError = false;

                    // check to see if element already has an error if so don't eval addition element validations
                    if (validation.attrs.errors.length === 0) {
                        //validate errors
                        validatorResult = validation.validator().validateErrors();

                        if (validatorResult !== undefined) {
                            if (validatorResult.hasError) {
                                scope.scopeValidationErrors.push(validatorResult);
                                validation.attrs.errors.push(validatorResult);

                                if (validation.attrs.ngModelCtrl.$setValidity !== undefined) {
                                    validation.attrs.ngModelCtrl.$setValidity(validatorResult.name, false);
                                }

                                hadError = true;
                            }
                        }

                        if (!hadError) {
                            validatorResult = validation.validator().validateWarnings();

                            if (validatorResult !== undefined) {
                                if (validatorResult.hasWarning) {
                                    scope.scopeValidationWarnings.push(validatorResult);
                                    validation.attrs.warnings.push(validatorResult);
                                }
                            }
                        }
                    }
                }

                scope.$broadcast('processElementValidationNotifications', {});
                scope.$broadcast('processSummaryValidationNotifications', {});

                return (scope.scopeValidationErrors.length === 0);
            }

            return {
                executeValidations: function() {
                    'use strict';

                    var isValid = true;

                    for (var i = 0; i <= $rootScope.scopesToValidate.length - 1; i++) {
                        var validatorResult = executeValidationsForScope($rootScope.scopesToValidate[i].scope);
                        isValid = isValid && validatorResult;
                    }

                    return isValid;
                },
                executeValidationsForScope: executeValidationsForScope,
                addScopeToValidate: function(scope, view) {
                    'use strict';

                    var addScope = true;

                    //check to see if scope already added to validate
                    for (var i = 0; i <= $rootScope.scopesToValidate.length - 1; i++) {
                        if ($rootScope.scopesToValidate[i].scopeID === scope.$id) {
                            addScope = false;
                            break;
                        }
                    }

                    if (addScope) {
                        $rootScope.scopesToValidate.push({ scopeID: scope.$id, scope: scope, view: view });

                        for (var cs = scope.$$childHead; cs; cs=cs.$$nextSibling) {
                            $rootScope.scopesToValidate.push({ scopeID: cs.$id, scope: cs, view: view });
                        }
                    }
                },
                clearValidationScopes: function() {
                    'use strict';
                    $rootScope.scopesToValidate = [];
//                    $rootScope.formModelWatchers = [];
                },
                clearAllValidations: function() {
                    $rootScope.$broadcast('clearElementValidationNotifications', {});
                }
            };
        }
    ])

    .directive('pdiValidations', ['validatorSvc', '$compile', '$timeout', function (validatorSvc, $compile, $timeout) {

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ctrl) {
                'use strict';
                if ((!ctrl) || ( attrs.pdiValidations==='')) { return; }

                attrs.ngModelCtrl = ctrl;

                var model = attrs.ngModel;

                var pdivalidation = scope.$eval(attrs.pdiValidations);

                var validatorFn = function() {

                    return {
                        validateErrors: function() {

                            if (pdivalidation.validatorError === undefined) { return; }

                            var validatorParms = pdivalidation.validatorParms;

                            var elementValue = scope.$eval(model); //element.val().trimRight();
                            var expression = pdivalidation.validatorError(elementValue, validatorParms);

                            if (angular.isObject(expression) && angular.isFunction(expression.then)) {
                                // expression is a promise
                                var deferred = $q.defer();
                                expression.then(function() {
                                    deferred.resolve("");
                                }, function() {
                                    var error =  { name: pdivalidation.name, model: model, msg: pdivalidation.validationMessage, hasError: true };
                                    deferred.reject(error);
                                });
                                return deferred.promise;
                            } else if (!expression) {
                                // expression false
                                return  { name: pdivalidation.name, model: model, msg: pdivalidation.validationMessage, hasError: true };
                            }
                        },
                        validateWarnings: function() {

                            if (pdivalidation.validatorWarning === undefined) { return; }

                            var validatorParms = pdivalidation.validatorParms;

                            var elementValue = scope.$eval(model); //element.val().trimRight();
                            var expression = pdivalidation.validatorWarning(elementValue, validatorParms);


                            if (!expression) {
                                return  { name: pdivalidation.name, model: model, msg: pdivalidation.validationMessageWrn, hasWarning: true };
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: pdivalidation.name, model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

                /*angular.forEach(attrs.pdiValidations, function(index) {

                 var validateErrors = (attrs.pdiValidations[index].validatorError) ? true : false;
                 var validateWarnings = (attrs.pdiValidations[index].validatorWarning) ? true : false;

                 var validatorFn = function() {

                 return {
                 validateErrors: function() {

                 if (!validateErrors) return;

                 var elementValue = element.val().trimRight();
                 var expression = pdivalidation.validatorError(elementValue);

                 if (!expression) {
                 return  { name: pdivalidation.name + key, model: model, msg: pdivalidation.validationMessage, hasError: true };
                 }
                 },
                 validateWarnings: function() {

                 if (!validateWarnings) return;

                 var elementValue = element.val().trimRight();
                 var expression = pdivalidation.validatorWarning(elementValue);

                 if (angular.isObject(expression) && angular.isFunction(expression.then)) {
                 // expression is a promise
                 expression.then(function() {
                 }, function() {
                 attrs.warnings.push({ name: pdivalidation.name + key, model: model, msg: pdivalidation.validationMessageWrn });
                 });
                 return;
                 } else if (!expression) {
                 // expression false
                 attrs.warnings.push({ name: pdivalidation.name + key, model: model, msg: pdivalidation.validationMessageWrn });
                 return;
                 }
                 }
                 };
                 };

                 scope.scopeValidations.push({ name: pdivalidation.name + key, model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

                 //attrs.validations.push(({ name: pdivalidation.name + key, model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 }));

                 });*/
            },
            controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                'use strict';

                $scope.scopeValidations = $scope.scopeValidations || [];
                $scope.scopeValidationErrors = $scope.scopeValidationErrors || [];
                $scope.scopeValidationWarnings = $scope.scopeValidationWarnings || [];

                validatorSvc.addScopeToValidate($scope);

                $attrs.validations = [];
                $attrs.errors = [];
                $attrs.warnings = [];

                $attrs.ngModelCtrl = {};

                $attrs.hasWarnings = false;

                this.addValidation = function(validation) {
                    $attrs.validations.push(validation);
                };

                $scope.$on('executeValidationsForScope', function () {
                    validatorSvc.executeValidationsForScope($scope);
                });

                //used to forced process the error/waring and display the notification UI for the element'
                $scope.$on('processElementValidationNotifications', function () {
                    if (!angular.isUndefined($attrs.showValidationNotification) && !$scope.$eval($attrs.showValidationNotification)) { return; }

                    if ($attrs.errors.length > 0) {
                        pdi.ng.validate.createElementValidationMessageError($attrs.errors[0].name, $attrs.errors[0].msg, $element, $attrs, $scope, $compile);
                        pdi.ng.validate.processElementErrorNotification($attrs.errors[0].name, true, $element, $attrs);
                    } else if ($attrs.warnings.length > 0) {
                        pdi.ng.validate.createElementValidationMessageWarning($attrs.warnings[0].name, $attrs.warnings[0].msg, $element, $attrs, $scope, $compile);
                        pdi.ng.validate.processElementWarningNotification($attrs.warnings[0].name, true, $element, $attrs);
                    }
                });

                //used to forced clear element error/warning and clear the notification UI'
                $scope.$on('clearElementValidationNotifications', function () {

                    pdi.ng.validate.removeElementValidationMessage($element, $attrs, $scope, $compile);

                    if ($attrs.errors.length > 0) {
                        pdi.ng.validate.processElementErrorNotification($attrs.errors[0].name, false, $element, $attrs);
                    }

                    if ($attrs.warnings.length > 0) {
                        pdi.ng.validate.processElementWarningNotification($attrs.warnings[0].name, false, $element, $attrs);
                    }

                    //clear element arrays
                    $attrs.errors.length = 0;
                    $attrs.warnings.length = 0;
                });
            }]
        };
    }
    ])

    .directive('pdiValidation', ['$rootScope', 'validatorSvc', '$compile', '$timeout', function ($rootScope, validatorSvc, $compile, $timeout) {

        return {
            restrict: 'E',
            replace: true,
            terminal: true,
            template: '<div></div>',
            link: function (scope, element, attrs) {
                'use strict';

                if (attrs.errorValidatorFn !== undefined || attrs.warningValidatorFn !== undefined) {

                    var ctrlIndex = scope.scopeValidations.length + 1;

                    attrs.name = 'pdiValidation' + ctrlIndex.toString();

                    var validatorFn = function () {

                        return {
                            validateErrors: function () {

                                if (attrs.errorValidatorFn === undefined) return;

                                var validatorResult = scope.$eval(attrs.errorValidatorFn);

                                if (validatorResult) { //has errors
                                    return { name: 'pdiValidation' + ctrlIndex.toString(), msg: attrs.validationErrorMsg, hasError: true };
                                }

                                return;
                            },
                            validateWarnings: function () {

                                if (attrs.warningValidatorFn === undefined) return;

                                var validatorResult = attrs.warningValidatorFn();

                                if (validatorResult) { //has warnings
                                    return { name: 'pdiValidation' + ctrlIndex.toString(), msg: attrs.validationWarningMsg, hasWarning: true };
                                }

                                return;
                            }
                        };
                    };

                    scope.scopeValidations.push({ name: 'pdiValidation' + ctrlIndex.toString(), element: element, attrs: attrs, validator: validatorFn, priority: 1 });

                }
            },
            controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                'use strict';

                $scope.scopeValidations = $scope.scopeValidations || [];
                $scope.scopeValidationErrors = $scope.scopeValidationErrors || [];
                $scope.scopeValidationWarnings = $scope.scopeValidationWarnings || [];

                validatorSvc.addScopeToValidate($scope);

                $attrs.validations = [];
                $attrs.errors = [];
                $attrs.warnings = [];

                $attrs.ngModelCtrl = {};

                $attrs.hasWarnings = false;

                //used to forced process the error/waring and display the notification UI for the element'
                $scope.$on('processElementValidationNotifications', function () {
                    pdi.ng.validate.processValidationNotification($element, $attrs, $compile, $scope);
                });

                //used to forced clear element error/warning and clear the notification UI'
                $scope.$on('clearElementValidationNotifications', function () {

                    pdi.ng.validate.processValidationNotification($element, $attrs, $compile, $scope);
                    pdi.ng.validate.removeElementValidationMessage($element, $attrs, $scope, $compile);

                    //clear element arrays
                    $attrs.errors.length = 0;
                    $attrs.warnings.length = 0;
                });
            }]
        };
    }])

    .directive('pdiValidationsSummary', ['$rootScope', 'validatorSvc', '$compile', '$timeout', function ($rootScope, validatorSvc, $compile, $timeout) {

        return {
            restrict: 'E',
            replace: true,
            terminal: true,
            template: '<div></div>',
            link: function (scope, element, attrs) {
                'use strict';

                if (attrs.data !== undefined && attrs.formModel !== undefined) {
                    var newScope = $rootScope.$new();

                    var appHtml = $('[ng-controller]').html();
                    var ele = angular.element(appHtml);

                    newScope[attrs.formModel] = scope.$eval(attrs.data);

                    var view = $compile(ele)(newScope);

                    validatorSvc.addScopeToValidate(newScope, view);

                    //used to forced process the error/waring and display the notification UI for the element'
                    newScope.$on('processSummaryValidationNotifications', function () {
                        pdi.ng.validate.processValidationsSummaryNotification(element, attrs, $compile, newScope);
                    });

                    //used to forced clear element error/warning and clear the notification UI'
                    newScope.$on('clearSummaryValidationNotifications', function () {
                        pdi.ng.validate.processValidationsSummaryNotification(element, attrs, $compile, newScope);
                    });

                    var addFormModelWatcher = true;

                    //check to see if form model already added to array
                    for (var i = 0; i <= $rootScope.formModelWatchers.length - 1; i++) {
                        if ($rootScope.formModelWatchers[i].formModel === attrs.formModel) {
                            addFormModelWatcher = false;
                            break;
                        }
                    }

                    if (addFormModelWatcher) {

                        var watchUnBinder =$rootScope.$watch(function () {
                            return scope.$eval(attrs.formModel);
                        }, function (newValue, oldValue) {
                            if (oldValue === undefined) {
                                return;
                            }

                            $timeout(function() {
                                scope.$parent.$broadcast('clearElementValidationNotifications', {});
                                validatorSvc.executeValidations();
                                scope.$parent.$broadcast('processElementValidationNotifications', {});
                            });
                        });

                        $rootScope.formModelWatchers.push({ formModel: attrs.formModel, unBinderFn: watchUnBinder });
                    }
                }
            }
        };
    }])

    .directive('pdiRequired', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        //    required-validation-msg
        //    required-execution-explicit
        //    required-if-expr
        //    required-validation-msg-wrn
        //    required-if-expr-wrn

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.requiredValidationMsg) || ((!attrs.requiredValidationMsg) && (!attrs.requiredValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.requiredValidationMsgWrn) ? true : false;

                var requiredValidationMsg = attrs.requiredValidationMsg || pdi.ng.validate.requiredMsgDefault || 'required validation undefined';
                var requiredValidationMsgWrn = attrs.requiredValidationMsgWrn;
                var requiredExecutionExplicit = attrs.requiredExecutionExplicit || pdiRequiredExecutionExplicitDefault;

                var validatorFn = function () {

                    return {
                        validateErrors: function () {

                            if (attrs.disabled) { return; } // if element is disabled then don't enforce validations on the element

                            if (!validateErrors) return;

                            if (evaluateIfConditions(attrs.requiredIfExpr, scope) &&
                                (ctrl.$isEmpty(ctrl.$viewValue))) {

                                return { name: 'pdiRequired', model:model, msg: requiredValidationMsg, hasError: true };
                            }

                            return;
                        },
                        validateWarnings: function () {

                            if (!validateWarnings) return;

                            if (evaluateIfConditions(attrs.requiredIfExprWrn, scope) &&
                                (ctrl.$isEmpty(ctrl.$viewValue))) {

                                return { name: 'pdiRequired', model: model, msg: requiredValidationMsgWrn, hasWarning: true };
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiRequired', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

            }
        };
    }])

    .directive('pdiMinlength', ['validatorSvc', function (validatorSvc) {

        //usage
        //minlengthValidationMsg: '=',
        //    minlengthExecutionExplicit: '=',
        //  minlengthIfExpr: '@',

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.minlengthValidationMsg) || ((!attrs.minlengthValidationMsg) && (!attrs.minlengthValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.minlengthValidationMsgWrn) ? true : false;

                var minlengthValidationMsg = attrs.minlengthValidationMsg || 'minLength validation undefined';
                var minlengthValidationMsgWrn = attrs.minlengthValidationMsgWrn;
                var minlengthExecutionExplicit = attrs.minlengthExecutionExplicit || pdiMinLengthExecutionExplicitDefault;

                minlengthValidationMsg = String.format(minlengthValidationMsg, attrs.pdiMinlength);
                minlengthValidationMsgWrn = String.format(minlengthValidationMsgWrn, attrs.pdiMinlength);

                var validatorFn = function() {
                    return {
                        validateErrors: function() {

                            if (!validateErrors) return;

                            if (evaluateIfConditions(attrs.minlengthIfExpr, scope) &&
                                (ctrl.$isEmpty(ctrl.$viewValue) || ctrl.$viewValue.length < attrs.pdiMinlength)) {

                                return { name: 'pdiMinlength', model: model, msg: minlengthValidationMsg, hasError: true };
                            }

                            return;
                        },
                        validateWarnings: function() {

                            if (!validateWarnings) return;

                            if (evaluateIfConditions(attrs.minlengthIfExprWrn, scope) &&
                                (ctrl.$isEmpty(ctrl.$viewValue) || ctrl.$viewValue.length < attrs.pdiMinlength)) {

                                return { name: 'pdiMinlength', model: model, msg: minlengthValidationMsgWrn, hasWarning: true };
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiMinlength', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });
            }
        };
    }])

    .directive('pdiMaxlength', ['validatorSvc', function (validatorSvc) {

        //usage
        //maxlengthValidationMsg: '=',
        //    maxlengthExecutionExplicit: '=',
        //    maxlengthIfExpr: '@',
        //    enforceMaxConstraint: '='

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function(scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.maxlengthValidationMsg) || ((!attrs.maxlengthValidationMsg) && (!attrs.maxlengthValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.maxlengthValidationMsgWrn) ? true : false;

                var enforceMaxConstraint = attrs.enforcemaxconstraint || pdiMaxLengthEnforceMaxConstraintDefault;

                var ifConditions = evaluateIfConditions(attrs.maxlengthIfExpr, scope);

                // if enforceMaxConstraint is true then the max length will be enforce through the UI by preventing
                // input of more that specified length and not through validation.
                if (enforceMaxConstraint && ifConditions) {
                    element.bind('keypress', function(event) {
                        // Once the limit has been met or exceeded, prevent all keypresses from working
                        if (element.val().length >= attrs.pdiMaxlength) {
                            // Except backspace
                            if (event.keyCode !== 8) {
                                event.preventDefault();
                            }
                        }
                    });
                } else {

                    var maxlengthValidationMsg = attrs.maxlengthValidationMsg || 'maxLength validation undefined';
                    var maxlengthValidationMsgWrn = attrs.maxlengthValidationMsgWrn;
                    var maxlengthExecutionExplicit = attrs.maxlengthExecutionExplicit || pdiMaxLengthExecutionExplicitDefault;

                    maxlengthValidationMsg = String.format(maxlengthValidationMsg, attrs.pdiMaxlength);
                    maxlengthValidationMsgWrn = String.format(maxlengthValidationMsgWrn, attrs.pdiMaxlength);

                    var validatorFn = function() {
                        return {
                            validateErrors: function() {

                                if (!validateErrors) return;

                                if (ifConditions && (ctrl.$viewValue.length > attrs.pdiMaxlength)) {

                                    return { name: 'pdiMaxlength', model: model, msg: maxlengthValidationMsg, hasError: true };
                                }

                                return;
                            },
                            validateWarnings: function() {

                                if (!validateWarnings) return;

                                if (ifConditions && (ctrl.$viewValue.length > attrs.pdiMaxlength)) {

                                    return { name: 'pdiMaxlength', model: model, msg: maxlengthValidationMsgWrn, hasWarning: true };
                                }

                                return;
                            }
                        };
                    };

                    scope.scopeValidations.push({ name: 'pdiMaxlength', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });
                }
            }
        };
    }])

    .directive('pdiValueruleRange', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        // valuerule-rng-validation-msg
        // valuerule-rng-execution-explicit
        // valuerule-rng-if-expr
        // valuerule-rng-validation-msg-wrn
        // valuerule-rng-if-expr-wrn

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.valueruleRngValidationMsg) || ((!attrs.valueruleRngValidationMsg) && (!attrs.valueruleRngValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.valueruleRngValidationMsgWrn) ? true : false;

                var rangeParms = attrs.pdiValueruleRange.split(',');

                if (rangeParms.length !== 2) return;

                var lowerLimit = rangeParms[0].toNumber();
                var upperLimit = rangeParms[1].toNumber();

                var valueRuleRngValidationMsg = attrs.valueruleRngValidationMsg || 'valueRuleRng validation undefined';
                var valueRuleRngValidationMsgWrn = attrs.valueruleRngValidationMsgWrn;
                var valueRuleRngExecutionExplicit = attrs.valueruleRngExecutionExplicit || pdiValueRuleRangeExecutionExplicitDefault;

                valueRuleRngValidationMsg = String.format(valueRuleRngValidationMsg, lowerLimit, upperLimit);
                valueRuleRngValidationMsgWrn = String.format(valueRuleRngValidationMsgWrn, lowerLimit, upperLimit);

                var validatorFn = function () {

                    return {
                        validateErrors: function () {

                            if (!validateErrors) return;

                            if (evaluateIfConditions(attrs.valueruleRngIfExpr, scope) &&
                                (ctrl.$viewValue.toNumber() < lowerLimit || ctrl.$viewValue.toNumber() > upperLimit)) {

                                return { name: 'pdiValueruleRange', model: model, msg: valueRuleRngValidationMsg, hasError: true };
                            }

                            return;
                        },
                        validateWarnings: function () {

                            if (!validateWarnings) return;

                            if (evaluateIfConditions(attrs.valueruleRngIfExprWrn, scope) &&
                                (parseInt(ctrl.$viewValue, 10) < lowerLimit || parseInt(ctrl.$viewValue, 10) > upperLimit)) {

                                return { name: 'pdiValueruleRange', model: model, msg: valueRuleRngValidationMsgWrn, hasWarning: true };
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiValueruleRange', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

            }
        };
    }])

    .directive('pdiValueruleGreaterthan', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        // valuerule-gt-validation-msg
        // valuerule-gt-execution-explicit
        // valuerule-gt-if-expr
        // valuerule-gt-validation-msg-wrn
        // valuerule-gt-if-value-expr

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.valueruleGtValidationMsg) || ((!attrs.valueruleGtValidationMsg) && (!attrs.valueruleGtValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.valueruleGtValidationMsgWrn) ? true : false;

                var valueruleGtValidationMsg = attrs.valueruleGtValidationMsg || 'valueRuleGT validation undefined';
                var valueruleGtValidationMsgWrn = attrs.valueruleGtValidationMsgWrn;
                var valueRuleGtExecutionExplicit = attrs.valueruleGtExecutionExplicit || pdiValueRuleGreaterThanExecutionExplicitDefault;

                valueruleGtValidationMsg = String.format(valueruleGtValidationMsg, attrs.pdiValueruleGreaterthan);
                valueruleGtValidationMsgWrn = String.format(valueruleGtValidationMsgWrn, attrs.pdiValueruleGreaterthan);

                var validatorFn = function () {

                    return {
                        validateErrors: function () {

                            if (!validateErrors) return;

                            if (evaluateIfConditions(attrs.valueruleGtIfExpr, scope) &&
                                (parseFloat(ctrl.$viewValue) <= attrs.pdiValueruleGreaterthan)) {

                                return { name: 'pdiValueruleGreaterthan', model: model, msg: valueruleGtValidationMsg, hasError: true };
                            }

                            return;
                        },
                        validateWarnings: function () {

                            if (!validateWarnings) return;

                            if (evaluateIfConditions(attrs.valueruleGtIfExprWrn, scope) &&
                                (parseFloat(ctrl.$viewValue) <= attrs.pdiValueruleGreaterthan)) {

                                return { name: 'pdiValueruleGreaterthan', model: model, msg: valueruleGtValidationMsgWrn, hasWarning: true };
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiValueruleGreaterthan', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

            }
        };
    }])

    .directive('pdiValueruleLessthan', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        // valuerule-lt-validation-msg
        // valuerule-lt-execution-explicit
        // valuerule-lt-if-expr
        // valuerule-lt-validation-msg-wrn
        // valuerule-lt-if-expr-wrn

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.valueruleLtValidationMsg) || ((!attrs.valueruleLtValidationMsg) && (!attrs.valueruleLtValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.valueruleLtValidationMsgWrn) ? true : false;

                var valueruleLtValidationMsg = attrs.valueruleLtValidationMsg || 'valueRuleLT validation undefined';
                var valueruleLtValidationMsgWrn = attrs.valueruleLtValidationMsgWrn;
                var valueRuleLtExecutionExplicit = attrs.valueruleLtExecutionExplicit || pdiValueRuleLessThanExecutionExplicitDefault;

                valueruleLtValidationMsg = String.format(valueruleLtValidationMsg, attrs.pdiValueruleLessthan);
                valueruleLtValidationMsgWrn = String.format(valueruleLtValidationMsgWrn, attrs.pdiValueruleLessthan);

                var validatorFn = function () {

                    return {
                        validateErrors: function () {

                            if (!validateErrors) return;

                            if (evaluateIfConditions(attrs.valueruleLtIfExpr, scope) &&
                                (parseInt(ctrl.$viewValue, 10) >= attrs.pdiValueruleLessthan)) {

                                return { name: 'pdiValueruleLessthan', model: model, msg: valueruleLtValidationMsg, hasError: true };
                            }

                            return;
                        },
                        validateWarnings: function () {

                            if (!validateWarnings) return;

                            if (evaluateIfConditions(attrs.valueruleLtIfExprWrn, scope) &&
                                (parseInt(ctrl.$viewValue, 10) >= attrs.pdiValueruleLessthan)) {

                                return { name: 'pdiValueruleLessthan', model: model, msg: valueruleLtValidationMsgWrn, hasWarning: true };
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiValueruleLessthan', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

            }
        };
    }])

    .directive('pdiValueIsNumeric', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        //    pdi-value-is-numeric
        //    pdi-value-is-numeric-msg

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller
                var model = attrs.ngModel;

                var validateErrors = (attrs.pdiValueIsNumeric || (!attrs.pdiValueIsNumeric && !attrs.pdiValueIsNumeric)) ? true : false;
                var validateWarnings = true;
                var ptrnValidationMsg = attrs.pdiValueIsNumericMsg || 'pdiValueIsNumeric validation undefined';

                var validatorFn = function () {
                    return {
                        validateErrors: function () {
                            if (!validateWarnings) return;

                            if (!isNaN(ctrl.$viewValue)) {
                                return;
                            }

                            return { name: 'pdiValueIsNumeric', model: model, msg: ptrnValidationMsg, hasError: true };
                        },
                        validateWarnings: function () {
                            if (!validateWarnings || angular.isUndefinedOrNullOrEmpty(ctrl.$viewValue)) return;
                        }
                    };
                };
                scope.scopeValidations.push({ name: 'pdiValueIsNumeric', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });
            }
        };
    }])

        .directive('pdiDateIsGreaterEqualTo', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        //    pdi-date-is-greater-equal-to
        //    pdi-date-is-greater-equal-to-from-date
        //    pdi-date-is-greater-equal-to-msg

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller
                var model = attrs.ngModel;

                var validateErrors = (attrs.pdiDateIsGreaterEqualToMsg || (!attrs.pdiDateIsGreaterEqualToMsg && !attrs.pdiDateIsGreaterEqualToMsgWrn)) ? true : false;
                var validateWarnings = true;
                var ptrnValidationMsg = attrs.pdiDateIsGreaterEqualToMsg || 'pdiDateIsGreaterEqualTo validation undefined';

                var validatorFn = function () {
                    var fromDate = attrs.pdiDateIsGreaterEqualToFromDate;
                    var thruDate = ctrl.$viewValue;

                    return {
                        validateErrors: function () {
                            if (!validateWarnings) return;
                            if (Date.parse(thruDate) >= Date.parse(fromDate)) {
                                return;
                            }
                            return { name: 'pdiDateIsGreaterEqualTo', model: model, msg: ptrnValidationMsg, hasError: true };
                        },
                        validateWarnings: function () {
                            if (!validateWarnings || angular.isUndefinedOrNullOrEmpty(ctrl.$viewValue)) return;
                        }
                    };
                };
                scope.scopeValidations.push({ name: 'pdiDateIsGreaterEqualTo', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });
            }
        };
    }])

    .directive('pdiPatternEmail', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        //    ptrn-email-validation-msg
        //    ptrn-email-execution-explicit
        //    ptrn-email-validation-msg-wrn

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                'use strict';

                if (ctrls.length !== 2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;

                var validateErrors = (attrs.ptrnEmailValidationMsg) || ((!attrs.ptrnEmailValidationMsg) && (!attrs.ptrnEmailValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.ptrnEmailValidationMsgWrn) ? true : false;

                var ptrnValidationMsg = attrs.ptrnEmailValidationMsg || 'ptrnEmail validation undefined';
                var ptrnValidationMsgWrn = attrs.ptrnEmailValidationMsgWrn;
                var ptrnExecutionExplicit = attrs.prtnEmailExecutionExplicit || pdiEmailValidataionExecutionExplicitDefault;

                var regExPattern = new RegExp(/^[A-Za-z0-9_\+-]+(\.[A-Za-z0-9_\+-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.([A-Za-z]+)$/);

                var validatorFn = function () {

                    return {
                        validateErrors: function () {

                            if (!validateErrors || angular.isUndefinedOrNullOrEmpty(ctrl.$viewValue)) return;

                            var emails = ctrl.$viewValue.split(';');

                            // process errors on element
                            for (var i = 0 ; i <= emails.length - 1; i++) {
                                if (!regExPattern.test(emails[i].trim())){
                                    ptrnValidationMsg = String.format(ptrnValidationMsg, emails[i]);
                                    return { name: 'pdiPatternEmail', model: model, msg: ptrnValidationMsg, hasError: true };
                                }
                            }

                            return;
                        },
                        validateWarnings: function () {

                            if (!validateWarnings || angular.isUndefinedOrNullOrEmpty(ctrl.$viewValue)) return;

                            var emails = ctrl.$viewValue.split(';');

                            // process errors on element
                            for (var i = 0 ; i <= emails.length - 1; i++) {
                                if (!regExPattern.test(emails[i])){
                                    ptrnValidationMsgWrn = String.format(ptrnValidationMsgWrn, emails[i]);

                                    return { name: 'pdiPatternEmail', model: model, msg: ptrnValidationMsgWrn, hasWarning: true };
                                }
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiPatternEmail', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });
            }
        };
    }])
    .directive('pdiValueruleMatchElement', ['validatorSvc', function (validatorSvc) {

        //*********************************************
        // usage
        //*********************************************
        // valuerule-match-element-validation-msg
        // valuerule-match-element-execution-explicit
        // valuerule-match-element-if-expr
        // valuerule-match-element-validation-msg-wrn
        // valuerule-match-element-if-expr-wrn
        // valuerule-ng-model-match   (this is the model value to compare against)

        return {
            restrict: 'A',
            require: ['pdiValidations', 'ngModel'],
            link: function (scope, element, attrs, ctrls, $parse) {
                'use strict';

                if (ctrls.length !==2) return;

                var validationsctrl = ctrls[0]; // pdiValidations Controller
                var ctrl = ctrls[1]; // ngModel Controller

                var model = attrs.ngModel;
                var match = attrs.valueruleNgModelMatch;

                var validateErrors = (attrs.valueruleMatchElementValidationMsg) || ((!attrs.valueruleMatchElementValidationMsg) && (!attrs.valueruleMatchElementValidationMsgWrn)) ? true : false;
                var validateWarnings = (attrs.valueRuleMatchElementValidationMsgWrn) ? true : false;

                var matchElementValidationMsg = attrs.valueruleMatchElementValidationMsg || 'validation undefined';
                var matchElementValidationMsgWrn = attrs.valueruleMatchElementValidationMsgWrn;

                var validatorFn = function () {

                    return {
                        validateErrors: function () {
                            if (!validateErrors) return;

                            if (evaluateIfConditions(attrs.valueruleMatchElementIfExpr, scope)) {
                                var matchValue = scope.$eval(match);
                                var modelValue = scope.$eval(model);
                                if (matchValue !== modelValue) {
                                    return { name: 'pdiValueruleMatchElement', model: model, msg: matchElementValidationMsg, hasError: true };
                                }
                            }

                            return;
                        },
                        validateWarnings: function () {
                            if (!validateWarnings) return;

                            if (evaluateIfConditions(attrs.valueruleMatchElementIfExprWrn, scope)) {
                                var matchValue = scope.$eval(match);
                                var modelValue = scope.$eval(model);
                                if (matchValue !== modelValue) {
                                    return { name: 'pdiValueruleMatchElement', model: model, msg: matchElementValidationMsgWrn, hasWarning: true };
                                }
                            }

                            return;
                        }
                    };
                };

                scope.scopeValidations.push({ name: 'pdiValueruleMatchElement', model: model, element: element, attrs: attrs, validator: validatorFn, priority: 1 });

            }
        };
    }]);
