/* ==============================================================================

 CORE.SECURITY MODULE

 Contains all security related code

 ============================================================================= */
angular.module('core.security', ['core.ajax']);

/* ==============================================================================

 AUTH INTERCEPTOR

 Adds the Auth Token to the Http headers collection
 if the token is present.

 ============================================================================= */
angular.module('core.security').factory('AuthInterceptor', function ($q, $rootScope, $location, $interval)
{
    function logout()
    {
        $rootScope.sessionExpired = true;
    }

    return {

        request: function (config)
        {
            $interval.cancel($rootScope.sessionTimeout);
            config.headers = config.headers || {};

            // If the auth token is present
            if (amplify.store('pnuser-token') !== undefined)
            {
                // Add it to the http headers collection to be read by our
                // server-side authentication code
                var token = amplify.store('pnuser-token');
                config.headers.Authentication = token;
                //Update expiration time for the user token
                amplify.store('pnuser-token', token, {expires: $rootScope.timeout});
                if ($rootScope.timeout > 0)
                {
                    $rootScope.sessionTimeout = $interval(function ()
                    {
                        logout();
                    }, $rootScope.timeout);
                }
            } else
            {
                if ($location.$$path !== "/" &&
                    $location.$$path.search(/(SignUp|ResetPassword|SetPassword|ContactUs|SiteDisabled|unsubscribe)/i) === -1)
                    logout();
            }

            return config || $q.when(config);
        }

    };

});

/* ==============================================================================

 AUTHENTICATION SERVICE

 Contains the core authentication related code

 ============================================================================= */
angular.module('core.security').factory('Authentication', function ($q, $rootScope, apiService, $location, $analytics)
{

    return {
        isAuthenticated: function ()
        {
            var deferred = $q.defer(),
                token = amplify.store('pnuser-token');

            if (token === undefined)
            {
                $rootScope.isAuthenticated = false;
                deferred.reject('authentication:auth-token not valid');
            }
            else
            {
                $rootScope.isAuthenticated = true;
                deferred.resolve('authentication:auth-token is valid');
            }
            return deferred.promise;
        },
        login: function (userId, password, impersonationGuid)
        {
            var deferred = $q.defer();
            var data = {
                "WebUserId": userId || '',
                "WebUserPassword": password || '',
                'ImpersonationGuid': impersonationGuid || ''
            };
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/Login',
                    data: data
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    $rootScope.loginInfo.userId = data.Data.ImpersonatedUserId;
                    $rootScope.loginInfo.userKey = data.Data.ImpersonatedKey;
                    $rootScope.loginInfo.userType = data.Data.ImpersonatedType;
                    $rootScope.loginInfo.impersonatorUserId = data.Data.ImpersonatorUserId;
                    $rootScope.loginInfo.impersonatorUserKey = data.Data.ImpersonatorKey;
                    $rootScope.loginInfo.impersonatorUserType = data.Data.ImpersonatorType;

                    // Create Google Analytics Login event with Web User Key
                    $analytics.eventTrack('Successful Login', { category: 'User Access', label: data.Data.Key });
                    deferred.resolve(data.Data);
                },
                function (data)
                {
                    // Create Google Analytics Login event with Web User Key
                    $analytics.eventTrack('Failed Login', { category: 'User Access', label: data.Errors[0] || 'Valid login credentials required' });
                    deferred.reject(data.ErrorMessages);
                }
            );
            
            return deferred.promise;
        },
        userToken: function ()
        {
            if (amplify.store('pnuser-token') !== undefined)
            {
                return amplify.store('pnuser-token');
            } else
            {
                $location.path('/');
            }
        }
    };
});

/* ==============================================================================

 USER SERVICE

 Contains the core USER related code

 ============================================================================= */
angular.module('core.security')
    .factory('WebUserSvc', function ($q, $rootScope, Authentication, apiService)
    {
        var webUser;

        function removeEmptySiteMenuEntries(data)
        {
            if (!data.Data.WebUser || !data.Data.WebUser.CurrentAuthorization || !data.Data.WebUser.CurrentAuthorization.SiteLayout)
            {
                return;
            }

            data.Data.WebUser.CurrentAuthorization.SiteLayout.SubMenu.remove(function (menu)
            {
                return !data.Data.WebUser.CurrentAuthorization.SiteLayout.SideMenu.any({ParentKey:menu.Key});
            });
        }

        function retrieveWebUserFromAPI(user)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetWebUser/' + user,
                    params: {}
                });
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    removeEmptySiteMenuEntries(data);
                    webUser = data.Data.WebUser;
                    deferred.resolve(webUser);
                }
            );
            return deferred.promise;
        }

        function retrieveWebUserFromAPIWithToken(token, profile) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetWebUserByToken/',
                    data: {
                        ProfileKey: profile,
                        Token: token
                    }
                });
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    removeEmptySiteMenuEntries(data);
                    webUser = data.Data.WebUser;
                    deferred.resolve(webUser);
                }
            );
            return deferred.promise;
        }

        function retrieveSelectedProfileFromAPI(user, profile)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetWebUserAndProfile/' + user + '/' + profile,
                    params: {}
                });
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    removeEmptySiteMenuEntries(data);
                    webUser = data.Data.WebUser;
                    deferred.resolve(webUser);
                }
            );
            return deferred.promise;
        }

        function getWebUser()
        {
            return webUser;
        }

        return {
            getLoggedInWebUserToken: function ()
            {
                var token = amplify.store('pnuser-token');

                if (token === undefined)
                {
                    $rootScope.isAuthenticated = false;
                    return;
                }
                $rootScope.isAuthenticated = true;
                
                return token;
            },
            getWebUserProfile: function () {
                return amplify.store('pnWebProfile');
            },
            getCurrentAuthorization: function () {
                return amplify.store('pnAuthIndex');
            },
            retrieveWebUser: function (user)
            {
                var deferred = $q.defer();
                retrieveWebUserFromAPI(user).then(function (data) {
                    deferred.resolve(data);
                });
                return deferred.promise;
            },
            retrieveWebUserData: function (token, profile) {
                var deferred = $q.defer();
                retrieveWebUserFromAPIWithToken(token, profile).then(function (data) {
                    deferred.resolve(data);
                });
                return deferred.promise;
            },
            retrieveWebUserFromAPI: retrieveWebUserFromAPI,
            retrieveWebUserFromAPIWithToken: retrieveWebUserFromAPIWithToken,
            retrieveSelectedProfile: function (user, profile)
            {
                var deferred = $q.defer();
                retrieveSelectedProfileFromAPI(user, profile).then(function (data) {
                    deferred.resolve(data);
                });
                return deferred.promise;
            },
            getWebUserForSignup: function (webUser)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'GET',
                        url: '../api/GetWebUserForSignup/' + webUser.GUID,
                        params: {}
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.reject(data.Errors);
                    }
                );
                return deferred.promise;
            },
            signupWebUser: function (webUser)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/SignupWebUser/',
                        data: webUser
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.reject(data.Errors);
                    }
                );
                return deferred.promise;
            },
            getWebUserForPasswordReset: function (webUser)
            {
                var deferred = $q.defer(webUser);
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'GET',
                        url: '../api/GetWebUserForPasswordReset/' + webUser.GUID,
                        params: {}
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.reject(data.Errors);
                    }
                );
                return deferred.promise;
            },
            verifyWebUserSecretQuestionAnswer: function (webUser)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'GET',
                        url: '../api/VerifyWebUserSecretQuestionAnswer/' + webUser.GUID + '/' + webUser.AnswerClear,
                        params: {}
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.reject(data.Errors);
                    }
                );
                return deferred.promise;
            },
            resetWebUserPassword: function (webUser)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/ResetWebUserPassword/',
                        data: webUser
                    }
                );
                apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                    function (data)
                    {
                        webUser = data.Data;
                        deferred.resolve(webUser);
                    },
                    function (data)
                    {
                        deferred.reject(data.Errors);
                    }
                );
                return deferred.promise;
            },
            processForgotMyPassword: function (email)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'GET',
                        url: '../api/ProcessWebUserResetPassword/',
                        params: {
                            webUserId: email
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data);
                    },
                    function (data)
                    {
                        deferred.reject(data.ErrorMessages);
                    }
                );
                return deferred.promise;
            },
            saveWebUser: function (webUser, quiet)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/SaveWebUser/',
                        data: webUser
                    }
                );
                if (quiet)
                {
                    apiService.execute(apiSvcSettings).then(
                        function (data)
                        {
                            webUser = data.Data.WebUser;
                            deferred.resolve(data.Data);
                        },
                        function (data)
                        {
                            deferred.reject(data.Errors);
                        }
                    );
                } else
                {
                    apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                        function (data)
                        {
                            webUser = data.Data.WebUser;
                            deferred.resolve(data.Data);
                        },
                        function (data)
                        {
                            deferred.reject(data.Errors);
                        }
                    );
                }
                return deferred.promise;
            },
            validatePasswordComplexity: function (webUserKey, password, isLogin)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'POST',
                        url: '../api/ValidatePasswordComplexity/',
                        data: {
                            WebUserKey: webUserKey,
                            Password: password,
                            IsLogin: isLogin,
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    },
                    function (data)
                    {
                        deferred.resolve(data.Errors);
                    }
                );
                return deferred.promise;
            },
            getNextPasswordResetDate: function (webUser)
            {
                var one_day = 1000 * 60 * 60 * 24;
                // Convert both dates to milliseconds
                var date1_ms = new Date(Date.parse(webUser.PWCreatedDate));
                var date2_ms = new Date().getTime();
                var difference_ms = date1_ms - date2_ms;
                return Math.round(difference_ms / one_day);
            },
            getWebUser: getWebUser,
            resolveWebUser: function () {
                var deferred = $q.defer();
                var _user = this.getWebUser();
                var _token = this.getLoggedInWebUserToken();
                var _profile = this.getWebUserProfile();

                // We have the user already
                if (!angular.isUndefinedOrNullOrEmpty(_user)) deferred.resolve();

                // Get the user if we can
                else if (!angular.isUndefinedOrNullOrEmpty(_token) && !angular.isUndefinedOrNullOrEmpty(_profile))
                    this.retrieveWebUserData(_token, _profile).then(function () { deferred.resolve(); });

                // User is not able to be retrieved
                else deferred.resolve();

                return deferred.promise;
            }
        };
    }
);