angular.module('services.localizations', [])
    .factory('localizationSvcFactory', function ($rootScope) {
        var _cachedLocalizations = [];

        var service = {
            setLocalizations: function (localizations) {
                _cachedLocalizations[localizations] = $rootScope.$eval(localizations);
            },
            setCachedLocalizations: function () {
                var localizationFilesContainer = $("#localizationFiles");

                if (!angular.isUndefinedOrNullOrEmpty(localizationFilesContainer)) {
                    angular.forEach(localizationFilesContainer.children(), function (item) {
                        _cachedLocalizations[item.id] = $rootScope.$eval(item.innerText);
                    });
                }
            },
            getCachedLocalizationsContainer: function (page) {
                return {
                    getLocalizedString: function (string, replacementParms) {
                        var doesNotExist = angular.isUndefinedOrNullOrEmpty;

                        var replaceString = function (result, replacementParms) {
                            for (var i = 0; i < replacementParms.length; i++) {
                                result = result.replace("{" + i.toString() + "}", replacementParms[i]);
                            }
                            return result;
                        };

                        var decodeToUTF8 = function (text) {
                            return text.replace(/&#(\d+);/g, function (match, number) {
                                return String.fromCharCode(number);
                            });
                        };

                        var getCachedLocalizationsFor = function (page) {

                            // load all the localizations defined in the index page
                            if (doesNotExist(_cachedLocalizations)) {
                                service.setCachedLocalizations();
                            }

                            var result = _cachedLocalizations[page];

                            // if page not found in cache try to recreate it 
                            return result ? result : service.setLocalizations(page);
                        };

                        var fmtResult = function (r, rp) {
                            return (rp && rp.constructor === Array) ? replaceString(r, rp) : r;
                        };

                        var getStringFrom = function (page) {
                            return fmtResult(getCachedLocalizationsFor(page)[string], replacementParms);
                        };

                        var result = getStringFrom(page);

                        // if we can't find the string check the shared AppLocalization
                        result = result ? result : getStringFrom(['AppLocalization']);

                        // if we found a string return its decoded version to UTF 8 otherwise return the id
                        return result ? decodeToUTF8(result) : string;

                    },
                    stringFromCharacterEntity: function (str) {
                        // If we need translated string for an input field (e.g. secret questions editable combo-box) 
                        // we can not use ng-bind-html or k-value-template that fixing encoding issues.
                        // We need to fix encoding issues via this function. 
                        // If someone finds a better solution for fixing the encoding issue, where it will be possible to get directly corrected text, 
                        // do not hesitate and change this.
                        return $('<text/>').html(str).text();
                    }
                };
            }
        };

        return service;
    })
    .factory('localizationSvc', function (localizationSvcFactory)
    {
        return localizationSvcFactory;
    })
    .directive('pnLocalizationsSvc', function (templates, localizationSvc)
    {
        return {
            restrict: 'E',
            replace: true,
            link: function (scope, element, attrs)
            {
                localizationSvc.setLocalizations(element.text().trim());
            },
            templateUrl: function(element, attrs) {
                return templates.getTemplate(attrs.moduleName);
            }
        };
    })
    .directive('pnLocalizations', function (templates, localizationSvcFactory)
    {
        return {
            restrict: 'E',
            replace: true,
            controllerAs: 'pnLocalizationCtrl',
            link: function (scope, element, attrs)
            {
                scope.pnLocalizationCtrl.localizations.setLocalizations(element.text().trim());
            },
            templateUrl: function(element, attrs) {
                return templates.getTemplate(attrs.moduleName);
            },
            controller: function () {
                var self = this;
                self.$onInit = function () {
                    self.localizations = localizationSvcFactory;
                };
            }
        };
    })
    .directive('pnTemplate', function (templates)
    {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: function(element, attrs) {
                return templates.getTemplate(attrs.templateName);
            }
        };
    })
;