/**
 * Created by rhampton on 1/29/2015.
 */
angular.module("pnDocuments", ['ngSanitize', 'core.objects', 'services.payments', 'core.directives'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider.when("/Dyn/:header/Home",
            {
                templateUrl: templateProvider.getTemplate("Dyn"),
                controller: "DocumentCtrl"
            }
        )
            .when("/Dyn/:header/:sub/Home",
            {
                templateUrl: templateProvider.getTemplate("Dyn"),
                controller: "DocumentCtrl"
            }
        )
            .when("/Dyn/:header/:sub/:side",
            {
                templateUrl: templateProvider.getTemplate("Dyn"),
                controller: "DocumentBodyCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("DocumentsLocalization");
                    }
                }
            }
        );
    })
    .controller("DocumentSideMenuCtrl", function ($scope, $http, $q, $routeParams, $anchorScroll, $filter)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData.subMenu || $scope.locationInfo.sideMenuData.subMenu.length === 0)
            {
                var menu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.HeaderMenu, {Name: $routeParams.header})[0];
                if (menu)
                {
                    $scope.locationInfo.menuLocation = menu.Key;
                }
                var subMenu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SubMenu, {
                    ParentKey: $scope.locationInfo.menuLocation,
                    Name: $routeParams.sub
                })[0];
                if (subMenu)
                {
                    $scope.locationInfo.subMenuLocation = subMenu.Key;
                }
                var sideMenu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SideMenu, {
                    ParentKey: $scope.locationInfo.subMenuLocation,
                    Name: $routeParams.side
                })[0];
                if (sideMenu)
                {
                    $scope.locationInfo.sideMenuData.subMenu = sideMenu.Key;
                }
            }
        };

        $scope.setSiteMenuLocation = function (val)
        {
            $scope.locationInfo.sideMenuData.subMenu = val;
        };
    })
    .controller("DocumentCtrl", function ($scope, $http, $q, $routeParams, $anchorScroll, $filter, templates)
    {
        'use strict';
        $scope.locationInfo.menuObject = {};
        $scope.locationInfo.subMenuObject = {};
        $scope.initialize = function ()
        {
            $scope.locationInfo.sideMenu = '';
            $scope.locationInfo.menuText = $routeParams.header;
            $scope.locationInfo.subMenuText = $routeParams.sub;
            var subMenu = {};
            if (!$scope.locationInfo.subMenuText)
            {
                subMenu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SubMenu, {ParentKey: $scope.locationInfo.menuLocation})[0];
                if (subMenu)
                {
                    $scope.locationInfo.subMenuText = subMenu.Name;
                    $scope.locationInfo.subMenuLocation = subMenu.Key;
                }
            } else
            {
                subMenu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SubMenu, {
                    ParentKey: $scope.locationInfo.menuLocation,
                    Name: $scope.locationInfo.subMenuText
                })[0];
                if (subMenu)
                {
                    $scope.locationInfo.subMenuLocation = subMenu.Key;
                }

            }
            $scope.locationInfo.sideMenu = templates.getTemplate("DocumentSideMenu");
            $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.subMenu || $scope.locationInfo.sideMenuData.subMenu.length === 0)
            {
                var sideMenu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SideMenu, {ParentKey: $scope.locationInfo.subMenuLocation})[0];
                if (!sideMenu)
                {
                    $scope.goTo('Documents/Account/Statements');
                }

                if (sideMenu)
                {
                    $scope.locationInfo.sideMenuData.subMenu = sideMenu.Key;
                    $scope.goTo("/Dyn/" + $routeParams.header + "/" + $scope.locationInfo.subMenuText + "/" + sideMenu.Name);
                }
            }

            $scope.name = 'document';
        };
    })
    .controller("DocumentBodyCtrl", function ($scope, $http, $q, $routeParams, $anchorScroll, $filter, documentService, templates, localStrings)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.documentsGrid = {};
        $scope.kendo.documentsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var dataItem = this.dataItem(this.select()[0]);
                if (!dataItem.Guid)
                {
                    $scope.modalifier.show(localStrings.getLocalizedString("Documents"), localStrings.getLocalizedString("SelectedDocumentNoLonger"));
                    return;
                }
                else
                    $scope.openAttachment(dataItem.Guid);

                $scope.$apply();
            },
            columns: [
                {
                    field: "RefDateFormatted",
                    title: localStrings.getLocalizedString("ReferenceDate"),
                    width: 100,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "RefNo",
                    title: localStrings.getLocalizedString("ReferenceNo"),
                    width: 150,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Description",
                    title: localStrings.getLocalizedString("Description"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-file-o'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.documentsGridInit = function (ds)
        {
            $scope.kendo.documentsGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.documentsGridDS.read();
            $scope.kendo.documentsGrid.dataSource.data($scope.kendo.documentsGridDS.data());
        };

        function getDocuments(ref, desc, start, end, custKey, docType, contKey)
        {
            documentService.listDocuments(ref, desc, start, end, custKey, docType, contKey).then(function (data)
            {
                if (data.length === 0)
                {
                    $scope.modalifier.showNoResults($scope.locationInfo.documents.name);
                    return;
                }

                angular.forEach(data, function (element)
                {
                    element.RefDateFormatted = element.RefDate === "0001-01-01T00:00:00" ? null : $filter('date')(element.RefDate, 'MM/dd/yyyy');
                });

                $scope.kendo.documentsGridInit(data);
            });
        }

        $scope.initialize = function ()
        {
            $scope.checkForAppliedProfile();

            //required to rebuild dymanic sub and side menus in case of user pressing refresh.
            if($scope.locationInfo.menuLocation === 0)
            {
                $scope.goTo('Documents/Account/Statements');
            }

            $scope.locationInfo.sideMenu = templates.getTemplate("DocumentSideMenu");

            $scope.locationInfo.menuText = $routeParams.header;
            $scope.locationInfo.subMenuText = $routeParams.sub;
            if (!$scope.locationInfo.documents) $scope.locationInfo.documents = {};
            $scope.locationInfo.documents.minDate = new Date(1990, 0, 1);
            $scope.locationInfo.documents.name = $routeParams.side;

            //auto load non-transaction document types
            var thisMenu = $scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SideMenu.find({Name:$scope.locationInfo.documents.name});
            if(thisMenu && thisMenu.SourceType === 0)
            {
                getDocuments(
                    '',
                    '',
                    '01/01/1900',
                    '06/01/2075',
                    $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.CustKey,
                    thisMenu.SourceKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CustContKey
                );
            }

        };

        $scope.$parent.applyAction = function (documentType)
        {
            getDocuments($scope.locationInfo.sideMenuData.documents.referenceNumber,
                $scope.locationInfo.sideMenuData.documents.description,
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate,
                $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.CustKey,
                documentType,
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            );
        };
    })
    .controller("SectionSideMenuCtrl", function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.documents) $scope.locationInfo.sideMenuData.documents = {};
            $scope.locationInfo.sideMenuData.documents.referenceNumber = '';
            $scope.locationInfo.sideMenuData.documents.description = '';
        };
    });