/**
 * Created by rhampton on 3/11/14.
 */
angular.module('pnHome', ['core.ajax', 'ngSanitize'])
    .config(['$routeProvider', function ($routeProvider) {
        var originalWhen = $routeProvider.when;
        var globalResolves = {
            webUser: function (WebUserSvc, WebProfileService) {
                return WebUserSvc.resolveWebUser().then(function () {
                    var _user = WebUserSvc.getWebUser();
                    if (!angular.isUndefinedOrNullOrEmpty(_user) &&
                        !angular.isUndefinedOrNullOrEmpty(_user.CurrentWebProfile)) {
                        WebProfileService.setProfileColor(_user.CurrentWebProfile.PrimaryColor);
                    }
                    return _user;
                });
            }
        };
        $routeProvider.when = function (path, route) {
            route.resolve = route.resolve || (route.resolve = {});
            angular.extend(route.resolve, globalResolves);
            return originalWhen.call($routeProvider, path, route);
        };
    }])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider.when("/Home",
            {
                templateUrl: templateProvider.getTemplate("Home"),
                controller: "homeCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("HomeLocalization");
                    }
                }
            }
        );
    })
    .controller('homeCtrl', function ($scope, $rootScope, ajax, $q, homeService, localStrings, $location, globalizationSvc)
    {
        'use strict';
        $scope.locationInfo.menuLocation = 0;
        $scope.locationInfo.subMenuLocation = -1;
        $scope.locationInfo.sideMenu = '';
        $scope.modalInfo = {};
        $scope.curArticle = 0;
        $scope.checkForAppliedProfile();

        //Remove the impersonation GUID from the URL - we no longer need it once we get to Home
        $location.search('impersonationGuid', null);

        $scope.toggleModal = function (modalHeader, modalContent)
        {
            $scope.modalInfo.header = modalHeader;
            $scope.modalInfo.content = modalContent;
            $scope.modalInfo.shown = !$scope.modalInfo.shown;
        };

        $scope.updatePageInfo = function ()
        {
            if ($scope.appInfo.webUser && $scope.appInfo.webUser.CurrentAuthorization) {
                homeService.loadHomePageInformation($scope.appInfo.webUser.CurrentAuthorization.CustContKey).then(function (data)
                {
                    $scope.PageInfo = data;
                    $scope.formatArticles();
                    $scope.formatCurrencies();
                });
            }
            else
                $scope.logout();
        };

        var accountChangeListener = $rootScope.$on('event:AccountChange', function (event, data)
        {
            $scope.updatePageInfo();
        });

        $scope.$on('$destroy', accountChangeListener);

        $scope.updatePageInfo();

        $scope.showLastCreditNotification = function (item)
        {
            if (angular.isUndefinedOrNullOrEmpty(item) || angular.isUndefinedOrNullOrEmpty(item.DispType))
                return;

            switch ($scope.PageInfo.HomeInfo.LastCreditNotification.DispType) {
                case 0:
                    $scope.emailPopUp = item;
                    $scope.emailPopUp.show = true;
                    break;

                case 1:
                case 2:
                    if (item.EdocDtlKey > 0) $scope.openEdoc(item.EdocDtlKey);
                    else
                        scope.notifier.show(localStrings.getLocalizedString("DocumentNotRetrieved"), 'error');
                    break;

                case 3:
                    if (item.AttachGUID !== null) $scope.openAttachment(item.AttachGUID);
                    else
                        scope.notifier.show(localStrings.getLocalizedString("AttachmentNotRetrieved"), 'error');
                    break;
            }

        };

        $scope.showAccountSummary = function ()
        {
            if ($scope.checkCurrentUserPermission($scope.webFunctions.ViewCreditInformation))
                return true;

            if (($scope.PageInfo.HomeInfo.DisplayPastDueMsgAt > 0 && ($scope.PageInfo.HomeInfo.Customer.CrStatus === 1 ||
                $scope.PageInfo.HomeInfo.Customer.CrStatus === 6 || $scope.PageInfo.HomeInfo.Customer.CrStatus === 7 ||
                $scope.PageInfo.HomeInfo.Customer.CrStatus === 8 || $scope.PageInfo.HomeInfo.Customer.CrStatus === 9)))
                return true;

            return ($scope.checkCurrentUserPermission($scope.webFunctions.AddandEditWebPaymentAccounts) && $scope.PageInfo.HomeInfo.AccountNeedingVerificationv);
        };

        $scope.showRecentActivity = function ()
        {
            if (!$scope.PageInfo) return false;
            if (!$scope.PageInfo.HomeInfo) return false;
            var homeInfo = $scope.PageInfo.HomeInfo;

            if (homeInfo.ScheduledPayment && homeInfo.ScheduledPayment.Amount > 0) return true;
            if (homeInfo.PendingEft && homeInfo.PendingEft.Amount > 0) return true;
            if (homeInfo.PostedEft && homeInfo.PostedEft.Amount > 0) return true;
            if (homeInfo.LastInvoice && homeInfo.LastInvoice.Amount > 0) return true;
            if (homeInfo.CustomerActivity && homeInfo.CustomerActivity.LastRcptAmt > 0) return true;
            if (homeInfo.CustomerActivity && homeInfo.CustomerActivity.LastStmtAmt > 0) return true;
            if (homeInfo.CustomerPriceNotice && homeInfo.CustomerPriceNotice.EdocDetails && homeInfo.CustomerPriceNotice.EdocDetails.DtlKey > 0) return true;
            if (homeInfo.LastCreditNotification && homeInfo.LastCreditNotification.DispType > 0) return true;
            return false;
        };

        $scope.getArticle = function (dir)
        {
            switch (dir) {
                case 'p':
                    --$scope.curArticle;
                    if ($scope.curArticle < 0)
                        $scope.curArticle = $scope.PageInfo.HomeInfo.Announcements.length - 1;
                    break;
                case 'n':
                    ++$scope.curArticle;
                    if ($scope.curArticle === $scope.PageInfo.HomeInfo.Announcements.length)
                        $scope.curArticle = 0;
                    break;
            }
        };

        $scope.formatArticles = function ()
        {
            if (angular.isUndefinedOrNullOrEmpty($scope.PageInfo.HomeInfo) || angular.isUndefinedOrNullOrEmpty($scope.PageInfo.HomeInfo.Announcements))
                return;

            angular.forEach($scope.PageInfo.HomeInfo.Announcements, function (item)
            {
                item.Text = item.Text.trim().unescapeHTML().replace(/&nbsp;/gi, ' ');
            });

        };

        // Format all of the currency fields on the home page to whatever the browser locale is
        $scope.formatCurrencies = function ()
        {
            var homeInfo = $scope.PageInfo.HomeInfo;
            
            var lastInv = homeInfo.LastInvoice.Amount;
            var lastStmt = homeInfo.CustomerActivity.LastStmtAmt*-1;
            var lastEft = homeInfo.PostedEft.Amount;
            var lastPmnt = homeInfo.CustomerActivity.LastRcptAmt;
            var pendEft = homeInfo.PendingEft.Amount;
            var nextSchPmnt = homeInfo.ScheduledPayment.Amount;
            var acctBalance = homeInfo.Customer.AccountBalance;
            var unbilledActivity = homeInfo.Customer.UnshippedBalance;
            var creditLimit = homeInfo.Customer.CrLimitTotal;
            var availableCred = homeInfo.Customer.AvailableCredit;

            homeInfo.LastInvoice.AmountDisplay = globalizationSvc.formatCurrency(lastInv);
            homeInfo.CustomerActivity.LastStmtAmtDisplay = globalizationSvc.formatCurrency(lastStmt);
            homeInfo.PostedEft.AmountDisplay = globalizationSvc.formatCurrency(lastEft);
            homeInfo.CustomerActivity.LastRcptAmtDisplay = globalizationSvc.formatCurrency(lastPmnt);
            homeInfo.PendingEft.AmountDisplay = globalizationSvc.formatCurrency(pendEft);
            homeInfo.ScheduledPayment.AmountDisplay = globalizationSvc.formatCurrency(nextSchPmnt);
            homeInfo.Customer.AccountBalanceDisplay = globalizationSvc.formatCurrency(acctBalance);
            homeInfo.Customer.UnshippedBalanceDisplay = globalizationSvc.formatCurrency(unbilledActivity);
            homeInfo.Customer.CrLimitTotalDisplay = globalizationSvc.formatCurrency(creditLimit);
            homeInfo.Customer.AvailableCreditDisplay = globalizationSvc.formatCurrency(availableCred);
        };
    });