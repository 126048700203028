/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 Profile SERVICE

 Contains the core profile related code

 ============================================================================= */
angular.module('services.WebProfile', ['core.ajax']);

angular.module('services.WebProfile', []).factory('WebProfileService', function ($q, $rootScope, ajax, apiService)
{
    'use strict';
    function retrieveWebProfileFromAPI(profile)
    {
        var deferred = $q.defer();
        var apiSvcSettings = new ApiServiceSettings(
            {
                method: 'post',
                url: '../api/WebProfile/',
                data: {
                    WebProfileKey: profile
                }
            }
        );
        apiService.execute(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );
        return deferred.promise;
    }

    function retrieveWebProfileMessageFromAPI(webProfileKey, messageType)
    {
        var deferred = $q.defer();

        if (webProfileKey.toNumber() < 1)
        {
            deferred.resolve({
                Key: 0,
                Text: '',
                Timestamp: null,
                Type: null,
                WebProfileKey: 0
            });
        }
        else
        {
            var ca = ($rootScope.$$childHead.appInfo && $rootScope.$$childHead.appInfo.webUser && $rootScope.$$childHead.appInfo.webUser.CurrentAuthorization) ?
                $rootScope.$$childHead.appInfo.webUser.CurrentAuthorization : null;
            var custKey = ca ? ca.CustKey : null;
            var contactKey = ca ? ca.CustContKey : null;

            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'post',
                    url: '../api/WebProfileMessage/',
                    data: {
                        WebProfileKey: webProfileKey,
                        MessageType: messageType,
                        CustomerKey: custKey,
                        ContactKey: contactKey
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    deferred.resolve(data.Data.WebProfileMessage);
                }
            );
        }

        return deferred.promise;
    }

    function iniializeFromApi()
    {
        var deferred = $q.defer();
        var apiSvcSettings = {};

        apiSvcSettings = new ApiServiceSettings(
            {
                method: 'GET',
                url: '../api/Init/',
                params: {}
            });

        apiService.execute(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );

        return deferred.promise;
    }

    function getWebAudits(beginDate, endDate, menuCode, includeAdds, includeChanges, includeDeletes, includeEvents, custKey, nameEmail)
    {
        var deferred = $q.defer();
        var apiSvcSettings = new ApiServiceSettings(
            {
                method: 'POST',
                url: '../api/GetWebAuditEntries/',
                data: {
                    BeginDate : beginDate,
                    EndDate: endDate,
                    MenuCode: menuCode,
                    IncludeAdds: includeAdds,
                    IncludeChanges: includeChanges,
                    IncludeDeletes: includeDeletes,
                    IncludeEvents: includeEvents,
                    CustKey: custKey,
                    NameEmail: nameEmail,
                    MaxRows: 500
                }
            });
        apiService.execute(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );

        return deferred.promise;
    }

    function getARCustWebSheet()
    {
        return [].slice.call(document.styleSheets).find({title: "ARCustWebCSS"});
    }

    function setProfileColor(pcolor)
    {
        if (!$rootScope.profileClasses) {
            $rootScope.profileClasses = [];
        }
        else {
            resetStyle('backgroundColor', "rgb(254, 254, 254)", $rootScope.profileClasses);
            resetStyle('color', "rgb(254, 254, 254)", $rootScope.profileClasses);
        }
        cssOverrideStyle('backgroundColor', "rgb(254, 254, 254)", '#' + pcolor, $rootScope.profileClasses);
        cssOverrideStyle('color', "rgb(254, 254, 254)", '#' + pcolor, $rootScope.profileClasses);
    }

    function setLandingColor(pcolor)
    {
        if (!$rootScope.landingClasses) {
            $rootScope.landingClasses = [];
        }
        else {
            if ($rootScope.profileClasses) {
                resetStyle('backgroundColor', "rgb(254, 254, 254)", $rootScope.profileClasses);
                resetStyle('color', "rgb(254, 254, 254)", $rootScope.profileClasses);
            }
            resetStyle('backgroundColor', "rgb(253, 253, 253)", $rootScope.landingClasses);
            resetStyle('color', "rgb(253, 253, 253)", $rootScope.landingClasses);
        }

        cssOverrideStyle('backgroundColor', "rgb(253, 253, 253)", '#' + pcolor, $rootScope.landingClasses);
        cssOverrideStyle('color', "rgb(253, 253, 253)", '#' + pcolor, $rootScope.landingClasses);
    }

    function cssOverrideStyle(attribute, match, value, classNames)
    {
        var pdiSheet = getARCustWebSheet();
        if (!angular.isUndefinedOrNullOrEmpty(pdiSheet)) {
            var rules = pdiSheet.cssRules || pdiSheet.rules;
            for (var i = 0; i < rules.length; ++i) {
                if (!angular.isUndefinedOrNullOrEmpty(rules[i].style) && rules[i].style[attribute] === match) {
                    rules[i].style[attribute] = value;
                    classNames.push([rules[i].selectorText, rules[i].style[attribute]]);
                }
            }
        }
        else
         $scope.notifier.show('Stylesheet could not be loaded.', 'error');

    }

    function resetStyle(attribute, match, classNames)
    {
        var pdiSheet = getARCustWebSheet();
        if (!angular.isUndefinedOrNullOrEmpty(pdiSheet)) {
            var rules = pdiSheet.cssRules || pdiSheet.rules;
            for (var x = 0; x < classNames.length; x++) {
                for (var i = 0; i < rules.length; ++i) {
                    if (!angular.isUndefinedOrNullOrEmpty(rules[i].style) && rules[i].selectorText === classNames[x][0] && rules[i].style[attribute] === classNames[x][1]) {
                        rules[i].style[attribute] = match;
                    }
                }
            }
        }
    }

    function cssOverrideAllStyle(attribute, value)
    {
        var pdiSheet = getARCustWebSheet();
        if (!angular.isUndefinedOrNullOrEmpty(pdiSheet)) {
            var rules = pdiSheet.cssRules || pdiSheet.rules;

            for (var i = 0; i < rules.length; ++i) {
                if (attribute in rules[i].style)
                    rules[i].style[attribute] = value;
            }
        }
    }

    function cssAddStyle(selector, attribute, value)
    {
        var pdiSheet = getARCustWebSheet();
        if (!angular.isUndefinedOrNullOrEmpty(pdiSheet)) {
            var rules = pdiSheet.cssRules || pdiSheet.rules;
            var index = rules.length;

            if ("insertRule" in pdiSheet) {
                pdiSheet.insertRule(selector + "{ " + attribute + ": " + value + " }", index);
            }
            else {
                pdiSheet.addRule(selector, attribute + ": " + value, index);
            }
        }
    }

    return {
        setProfileColor: setProfileColor,
        setLandingColor: setLandingColor,
        setWebProfile: function (profile)
        {
            var deferred = $q.defer();
            if (amplify.store("pnprofile")) {
                setLandingColor(amplify.store("pnprofile").ArWebProfile.PrimaryColor);
                deferred.resolve(amplify.store("pnprofile"));
            } else {
                retrieveWebProfileFromAPI(profile).then(function (data)
                {
                    var webProfileWithContext = angular.fromJson(JSON.parse(data)).WebProfileWithContext;
                    setLandingColor(webProfileWithContext.ArWebProfile.PrimaryColor);
                    amplify.store("pnprofile", webProfileWithContext);
                    deferred.resolve(webProfileWithContext);
                });
            }
            return deferred.promise;
        },
        initialize: function ()
        {
            var deferred = $q.defer();
            var sCfg;
            var timeout = parseInt(20, 10) * 60 * 1000;

            iniializeFromApi().then(function (data)
            {
                sCfg = data.Configuration;
                setLandingColor(sCfg.LandingPrimaryColor);
                timeout = parseInt(sCfg.Timeout, 10) * 60 * 1000;
                $rootScope.timeout = timeout;
                deferred.resolve(data);
            });
            
            return deferred.promise;
        },
        initializeSiteDisabled: function ()
        {
            var deferred = $q.defer();

            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetSiteDisabledSystemSettings/',
                    params: {}
                });

            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    setLandingColor(data.Data.Configuration.LandingPrimaryColor);
                    deferred.resolve(data.Data);
                }
            );

            return deferred.promise;
        },
        getWebProfileMessage: function (webProfileKey, messageType)
        {
            var deferred = $q.defer();
            {
                retrieveWebProfileMessageFromAPI(webProfileKey, messageType).then(function (data)
                {
                        deferred.resolve(data.Text);
                });
            }
            return deferred.promise;
        },
        getWebAudits: getWebAudits
    };
});
