/**
 * Created by jvitoria on 9/5/14.
 */
angular.module("pnFuelCardsPrices", ['core.objects'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/FuelCards/Prices/LookupPrices",
            {
                templateUrl: templateProvider.getTemplate("LookupPrices"),
                controller: "pricesLookupCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsPricesLocalization");
                    }
                }
            })
            .when("/FuelCards/Prices/PriceNoticeSettings",
            {
                templateUrl: templateProvider.getTemplate("PriceNoticeSettings"),
                controller: "priceNoticeSettingsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsPricesLocalization");
                    }
                }
            });
    })

    .controller('fuelCardsPricesSideMenuCtrl', function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
        };
    })

    .controller("pricesLookupCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CPPriceNotices))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 42;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsPricesSideMenu");
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "0";
        };
    })

    .controller("pricesLookupSideMenuCtrl", function ($scope, $filter, FuelCardsService, reportingService, localizationSvc, $timeout)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("FuelCardsPricesLocalization");
        $scope.initialize = function ()
        {

            // instantiate variables
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.lookupPrices = {
                reportType: 'PricesLookup',
                site: 0,
                product: 0,
                groupBy: 4
            };
            $scope.context = {
                sites: [{ CustContCPSite_Site_Key: 0, Site_Description: localStrings.getLocalizedString("Any") }],
                products: [{ ProdAliasGrp_Key: 0, ProdAliasGrp_Description: localStrings.getLocalizedString("Any") }],
                maxCPDays: 7
            };

            //set save / load settings parameter
            $scope.$root.reportSettings.reportKey = 30;

            //get context data for drop-downs
            FuelCardsService.getPriceNoticesSettings(
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.Customer.CPPrcNtcRuleKey).then(
                function (data)
                {
                    if (data.Successful)
                    {
                        if (data.PriceNoticesSettingsSites.length > 0)
                        {
                            $scope.context.sites = $scope.context.sites.concat($filter('filter')(data.PriceNoticesSettingsSites, {Site_Selected: '-1'}));
                            $scope.context.sites = $filter('orderBy')($scope.context.sites, 'Site_Description');
                        }
                        else
                        {
                            $scope.notifier.show(localStrings.getLocalizedString("NoSitesConfigured"), 'warning');
                        }

                        if (data.PriceNoticesSettingsGroups.length > 0)
                        {
                            //pull All Products record from the array and handle separately
                            data.PriceNoticesSettingsGroups.splice(0, 1);

                            $scope.context.products = $scope.context.products.concat($filter('filter')(data.PriceNoticesSettingsGroups, {ProdAliasGrp_Selected: '-1'}));
                            $scope.context.products = $filter('orderBy')($scope.context.products, 'ProdAliasGrp_Description');
                        }
                        else
                        {
                            $scope.notifier.show(localStrings.getLocalizedString("NoProductsConfigured"), 'warning');
                        }

                        $scope.context.maxCPDays = data.CPPriceRuleMaxDays;
                    }
                    else
                        $scope.notifier.show(localStrings.getLocalizedString("FailedLoadContextualData"), 'error');
                }
            );

            //Override default date selector options
            $scope.dateSelector.selection = 13;
            $scope.dateSelector.maxNumOfDays = $scope.context.maxCPDays || 7;
            $scope.dateSelector.overrideDefaults = true;
            $timeout(function () {
                $scope.dateSelector.options = [
                    { name: localStrings.getLocalizedString("AllDays"), value: 13 },
                    { name: localStrings.getLocalizedString("Today"), value: 0 },
                    { name: localStrings.getLocalizedString("Yesterday"), value: 1 },
                    { name: localStrings.getLocalizedString("Tomorrow"), value: 2 },
                    { name: localStrings.getLocalizedString("LastSevenDays"), value: 9 },
                    { name: localStrings.getLocalizedString("SelectDays"), value: 12 }];
            }, 0);
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.lookupPrices = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.lookupPrices;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            // Notify the user their date selection exceeds the configured limit
            var maxCPDays = [$scope.context.maxCPDays];
            if ((Date.parse($scope.dateSelector.endDate) - Date.parse($scope.dateSelector.startDate)) > ($scope.context.maxCPDays * 86400000))
            {
                $scope.notifier.show(localStrings.getLocalizedString("PricingLimitedTo", maxCPDays), 'warning');
                $scope.dateSelector.numOfDays = $scope.context.maxCPDays;
            }

            var qs = "reportName=" + 'CPPriceNotices' +
                "&RP-CustContKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustContKey +
                "&RP-FromDate=" + $scope.dateSelector.startDate +
                "&RP-ThruDate=" + $scope.dateSelector.endDate +
                "&RP-SiteKey=" + $scope.locationInfo.sideMenuData.lookupPrices.site +
                "&RP-ProductKey=" + $scope.locationInfo.sideMenuData.lookupPrices.product +
                "&RP-GroupBy=" + $scope.locationInfo.sideMenuData.lookupPrices.groupBy;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })

    .controller("priceNoticeSettingsCtrl", function ($scope, FuelCardsService, WebFunctions, $filter, templates, localStrings)
    {
        'use strict';
        $scope.kendo = {};
        $scope.kendo.productsGrid = {};
        $scope.kendo.productsGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "ProdAliasGrp_Selected",
                    title: localStrings.getLocalizedString("Select"),
                    width: 70,
                    attributes: {class: "alignCenter"},
                    filterable: false,
                    template: "<input type='checkbox' ng-model='dataItem.ProdAliasGrp_Selected' ng-true-value=\"'-1'\" ng-false-value=\"'0'\" ng-disabled='appInfo.fuelCards.priceNoticesSettings.allProducts.ProdAliasGrp_Selected === \"-1\"'/>"
                },
                {
                    field: "ProdAliasGrp_Description",
                    title: localStrings.getLocalizedString("Product"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                }
            ],
            dataSource: [],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };
        $scope.kendo.sitesGrid = {};
        $scope.kendo.sitesGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "Site_Description",
                    title: localStrings.getLocalizedString("SiteDescription"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Site_Address1",
                    title: localStrings.getLocalizedString("Address"),
                    width: 150,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Site_City",
                    title: localStrings.getLocalizedString("City"),
                    width: 150,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "State_Code",
                    title: localStrings.getLocalizedString("State"),
                    width: 60,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Site_Zip",
                    title: localStrings.getLocalizedString("Zip"),
                    width: 60,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-trash-o' ng-click='removeSite(dataItem)'></i>",
                    width: 50
                }
            ],
            dataSource: [],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };
        $scope.kendo.siteSearchGrid = {};
        $scope.kendo.siteSearchGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "ProdAliasGrp_Selected",
                    title: localStrings.getLocalizedString("Select"),
                    width: 70,
                    attributes: {class: "alignCenter"},
                    filterable: false,
                    template: "<input type='checkbox' ng-model='dataItem.Site_Selected' ng-true-value=\"'-1'\" ng-false-value=\"'0'\" />"
                },
                {
                    field: "Site_Description",
                    title: localStrings.getLocalizedString("Description"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Site_Address1",
                    title: localStrings.getLocalizedString("Address"),
                    width: 150,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Site_City",
                    title: localStrings.getLocalizedString("City"),
                    width: 150,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "State_Code",
                    title: localStrings.getLocalizedString("State"),
                    width: 60,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Site_Zip",
                    title: localStrings.getLocalizedString("Zip"),
                    width: 60,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: true,
            selectable: "row",
            sortable: true,
            height:250 
        };

        $scope.kendo.siteSearchGridInit = function (ds)
        {
            $scope.kendo.siteSearchGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.siteSearchGridDS.read();
            $scope.kendo.siteSearchGrid.dataSource.data($scope.kendo.siteSearchGridDS.data());
        };

        $scope.kendo.sitesGridInit = function (ds)
        {
            $scope.kendo.sitesGridDS = new kendo.data.DataSource({
                data: ds,
                schema: {
                    model: {
                        fields: {
                            Site_Description: {
                                editable: false
                            },
                            Site_Address1: {
                                editable: false
                            },
                            Site_City: {
                                editable: false
                            },
                            State_Code: {
                                editable: false
                            },
                            Site_Zip: {
                                editable: false
                            },
                            Site_Selected: {
                                editable: false
                            }
                        }
                    }
                },
                //filter: [
                //    {field: "Site_Selected", operator: "eq", value: "'-1'"}
                //],
                batch: true
            });

            $scope.kendo.sitesGridDS.read();
            $scope.kendo.sitesGrid.dataSource.data($scope.kendo.sitesGridDS.data());
        };

        $scope.kendo.productsGridInit = function (ds)
        {
            $scope.kendo.productsGridDS = new kendo.data.DataSource({
                data: ds,
                schema: {
                    model: {
                        fields: {
                            ProdAliasGrp_Selected: {
                                editable: false
                            },
                            ProdAliasGrp_Description: {
                                editable: false
                            }
                        }
                    }
                },
                batch: true
            });

            $scope.kendo.productsGridDS.read();
            $scope.kendo.productsGrid.dataSource.data($scope.kendo.productsGridDS.data());
        };

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CPPriceNotices))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 42;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsPricesSideMenu");
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "1";
            $scope.getWebProfileMessage(5);
            $scope.initPriceNoticesSettings();
            $scope.initModalSiteSelector();
        };

        $scope.initPriceNoticesSettings = function ()
        {
            $scope.appInfo.fuelCards.priceNoticesSettings = {};
            $scope.appInfo.fuelCards.priceNoticesSettings.methods = {};
            $scope.appInfo.fuelCards.priceNoticesSettings.allProducts = {
                CustContCPProd_Key: -1,
                CustContCPProd_CustCont_Key: null,
                ProdAliasGrp_Key: -1,
                ProdAliasGrp_ID: "All",
                ProdAliasGrp_Description: localStrings.getLocalizedString("AllProducts"),
                ProdAliasGrp_Selected: "0",
                ts: null
            };
            $scope.refreshPriceNoticesSettings();
        };

        $scope.refreshPriceNoticesSettings = function ()
        {

            FuelCardsService.getPriceNoticesSettings(
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            ).then
            (
                function (data)
                {
                    //pull All Products record from the array and handle separately
                    if (data.PriceNoticesSettingsGroups.length > 0)
                        $scope.appInfo.fuelCards.priceNoticesSettings.allProducts = data.PriceNoticesSettingsGroups.splice(0, 1)[0] || [];

                    $scope.kendo.sitesGridInit(data.PriceNoticesSettingsSites.sortBy('Site_Description'));
                    $scope.kendo.productsGridInit(data.PriceNoticesSettingsGroups.sortBy('ProdAliasGrp_Description'));
                    $scope.appInfo.fuelCards.priceNoticesSettings.methods = data.PriceNoticesSettingsMethods;

                    $scope.kendo.sitesGrid.dataItems().forEach(function (item)
                    {
                        if (item.Site_Selected === '0')
                        {
                            $("tr[data-uid='" + item.uid + "']").hide();
                        }
                    });
                }
            );

            if (!$scope.appInfo.fuelCards.company)
            {
                FuelCardsService.getCardsContext(
                    $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CompKey
                ).then(
                    function (data)
                    {
                        $scope.appInfo.fuelCards.states = data.States;
                        $scope.appInfo.fuelCards.company = data.Company;
                    }
                );
            }
        };

        $scope.initModalSiteSelector = function ()
        {
            $scope.appInfo.fuelCards.modalSiteSelector = {};
            $scope.appInfo.fuelCards.modalSiteSelector.shown = false;
            $scope.appInfo.fuelCards.modalSiteSelector.siteSearch = {};
            $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.city = null;
            $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.state = '';
            $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.postal = null;
            $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.limit = false;
            $scope.appInfo.fuelCards.modalSiteSelector.toManyResults = false;
            $scope.appInfo.fuelCards.modalSiteSelector.maxResults = 100;
        };

        $scope.showModalSiteSelector = function ()
        {
            $scope.initModalSiteSelector();
            $scope.appInfo.fuelCards.modalSiteSelector.shown = true;
        };

        $scope.retrieveSites = function ()
        {
            FuelCardsService.getSearchSites(
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.city,
                $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.state,
                $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.postal,
                $scope.appInfo.fuelCards.modalSiteSelector.siteSearch.limit
            ).then
            (
                function (data)
                {
                    $scope.kendo.siteSearchGridInit(data.SearchSites.sortBy('Site_Description'));

                    if (data.SearchSites.length === 0)
                    {
                        $scope.notifier.show(localStrings.getLocalizedString("NoResults"), 'warning');
                        return;
                    }

                  
                    if ($scope.kendo.siteSearchGridDS.data().length > $scope.appInfo.fuelCards.modalSiteSelector.maxResults)
                        $scope.appInfo.fuelCards.modalSiteSelector.toManyResults = true;
                    else
                        $scope.appInfo.fuelCards.modalSiteSelector.toManyResults = false;
                }
            );
        };

        $scope.checkAllProducts = function ()
        {
            angular.forEach($scope.kendo.productsGridDS.data(), function (item)
            {
                item.ProdAliasGrp_Selected = $scope.appInfo.fuelCards.priceNoticesSettings.allProducts.ProdAliasGrp_Selected || '0';
            });
        };

        $scope.disableNotices = function ()
        {
            angular.forEach($scope.appInfo.fuelCards.priceNoticesSettings.methods, function (item)
            {
                item.EmailSelected = '0';
                item.AltEmailSelected = '0';
                item.PhoneTextSelected = '0';
            });
        };

        $scope.addSites = function ()
        {
            angular.forEach($scope.kendo.siteSearchGridDS.data(), function (site)
            {
                if (site.CustContCPSite_Key === 0 && site.Site_Selected === '-1')
                {
                    $scope.kendo.sitesGridDS.add(site);
                }
            });
            $scope.initModalSiteSelector();
        };

        $scope.removeSite = function (row)
        {
            row.Site_Selected = '0';
            $("tr[data-uid='" + row.uid + "']").hide();
        };

        $scope.savePriceNoticeSettings = function ()
        {
            var products = $scope.kendo.productsGridDS.data().toJSON();
            var sites = $scope.kendo.sitesGridDS.data().toJSON();
            var hasProducts = products.any({ProdAliasGrp_Selected: '-1'});
            var hasSites = sites.any({Site_Selected: '-1'});
            if (!hasProducts || !hasSites)
            {
                $scope.modalifier.show(localStrings.getLocalizedString("PriceNoticeSettings"), localStrings.getLocalizedString("AtLeastOneProduct"));
                return;
            }

            //put All Products back in its array for save
            products.unshift($scope.appInfo.fuelCards.priceNoticesSettings.allProducts);

            FuelCardsService.savePriceNoticeSettings(
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                sites,
                products,
                $scope.appInfo.fuelCards.priceNoticesSettings.methods
            ).then
            (
                function (data)
                {
                    $scope.initialize();
                }
            );
        };
    })
;