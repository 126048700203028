/**
 * Created by rhampton on 5/27/2014.
 */
angular.module("pnLogin", [
    'ngRoute',
    'core.enums',
    'core.security'
])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/SignUp/:request?",
            {
                templateUrl: templateProvider.getTemplate("SignUp"),
                controller: "signupCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("LoginLocalization");
                    }
                }
            })
            .when("/ResetPassword/:request?",
            {
                templateUrl: templateProvider.getTemplate("ResetPassword"),
                controller: "resetPasswordCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("LoginLocalization");
                    }
                }
            })
            .when("/SetPassword/:key",
            {
                templateUrl: templateProvider.getTemplate("GetPasswordTemplate"),
                controller: "setNewPasswordCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("LoginLocalization");
                    }
                }
            })
            .when("/unsubscribe/:cont?",
            {
                templateUrl: templateProvider.getTemplate("Unsubscribe"),
                controller: "unsubscribeCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("LoginLocalization");
                    }
                }
            })
            .when("/SiteDisabled",
            {
                templateUrl: templateProvider.getTemplate("SiteDisabled"),
                controller: "siteDisabledCtrl"
            });
    })
    .controller("loginCtrl", function loginCtrl($scope, $http, $q, $rootScope, $timeout, Authentication, WebUserSvc, WebProfileService, validatorSvc, constantLists, $routeParams, $location, localStrings)
    {
        'use strict';
        $scope.logout();
        $scope.showLogin = false;
        $scope.showProfileSelector = false;
        $scope.showForgotPassword = false;

        $scope.initializeLogin = function ()
        {
            validatorSvc.clearValidationScopes();

            $scope.applySystemSettings();
            $scope.showLogin = true;

            if ($routeParams.impersonationGuid) {
                $rootScope.loginInfo.impersonationAccessGuid = $routeParams.impersonationGuid;
                $rootScope.loginInfo.userIsImpersonated = true;
                $rootScope.automatedLogin = true;
            }

            $scope.getRememberMe();
        };

        $scope.checkForAutomatedLogin = function () {
            if ($rootScope.automatedLogin) {
                $scope.loginInfo.userId = $rootScope.automatedLogin.userId || "";
                $scope.loginInfo.password = $rootScope.automatedLogin.password || "";
                $scope.authenticateUser();
                $rootScope.automatedLogin = undefined;
            }
        };

        $scope.authenticateUser = function ()
        {
            // Omit automated logins from triggering empty password / userid validations on elements
            if (!$rootScope.automatedLogin && !validatorSvc.executeValidations())
                return;

            $scope.error = "";
            $rootScope.passwordExpired = {};
            $rootScope.passwordDays = {};
            $scope.setRememberMe();

            // Reset the locked account display
            $scope.lockedAccount.hide();

            // Attempt to log the user in
            Authentication.login($scope.loginInfo.userId, $scope.loginInfo.password, $rootScope.loginInfo.impersonationAccessGuid).then(function (data)
            {
                // Store the token via LocalStorage (more secure than normal cookies)
                var user = data;
                var token = user.Token;
                amplify.store('pnuser-token', token, { expires: $rootScope.timeout });

                WebUserSvc.validatePasswordComplexity(user.Key, $scope.loginInfo.password, true).then(function (errorList) {
                    if (errorList.length > 0)
                    {
                        $scope.notifier.show(localStrings.getLocalizedString("PasswordComplexityEnforced"), 'error');
                        return;
                    }
                    else
                    {
                        $scope.retrieveWebUser(user.Key);
                    }
                });

            }, function (err)
            {
                $scope.error = err;
            });
        };

        $scope.retrieveWebUser = function (webUserKey)
        {
            if (Authentication.isAuthenticated())
            {
                WebUserSvc.retrieveWebUser(webUserKey).then(function (data)
                {
                    data.Answer2 = data.Answer;
                    $scope.appInfo.webUser = data;

                    var _webProfileCount = $scope.appInfo.webUser.WebProfiles.length;
                    if (_webProfileCount === 0) {
                        $scope.showLogin = true;
                        $scope.modalifier.show(
                            localStrings.getLocalizedString("LogIn"),
                            localStrings.getLocalizedString("NoWebProfile"));
                        return;
                    }

                    if (_webProfileCount > 1) {
                        $scope.showLogin = false;
                        $scope.showProfileSelector = true;
                        $scope.appInfo.profileKey = $scope.appInfo.webUser.WebProfiles[0].Key;
                        return;
                    }

                    if ( _webProfileCount === 1 ) {
                        $scope.appInfo.profileKey = $scope.appInfo.webUser.WebProfiles[0].Key;
                        $scope.loadSelectedProfile();
                    } 
                    return;
                });
            }
        };

        $scope.loadSelectedProfile = function ()
        {
            if (angular.isUndefinedOrNullOrEmpty($scope.appInfo.profileKey) || $scope.appInfo.profileKey < 0) {
                $scope.appInfo.profileKey = WebUserSvc.getWebUserProfile();
            }

            WebUserSvc.retrieveSelectedProfile($scope.appInfo.webUser.Id, $scope.appInfo.profileKey).then(function (data)
            {
                amplify.store('pnAuthIndex', WebUserSvc.getCurrentAuthorization() || 0);
                amplify.store('pnWebProfile', $scope.appInfo.profileKey);
                $scope.applySelectedProfile();
                $scope.goTo('/Home');
            });
        };

        $scope.showForgotMyPassword = function ()
        {
            validatorSvc.clearValidationScopes();
            validatorSvc.addScopeToValidate($scope);

            $scope.showLogin = !$scope.showLogin;
            $scope.showForgotPassword = !$scope.showForgotPassword;
        };

        $scope.submitForgotMyPassword = function ()
        {
            if (!validatorSvc.executeValidations())
                return;

            WebUserSvc.processForgotMyPassword($scope.loginInfo.userId).then(function (data)
            {
                if (data.Success)
                    $scope.notifier.show(localStrings.getLocalizedString("RequestBeingProcessed"), 'success');
                else
                    $scope.notifier.show(localStrings.getLocalizedString("RequestNotProcessed"), 'error');
            });
            $scope.showForgotMyPassword();
        };

        $scope.setRememberMe = function ()
        {
            if ($scope.loginInfo.rememberMe)
                amplify.store('pnLoginEmail', $rootScope.loginInfo.userId);
            else
                amplify.store('pnLoginEmail', null);
        };

        $scope.getRememberMe = function ()
        {
            var result = amplify.store('pnLoginEmail');
            if (angular.isUndefinedOrNullOrEmpty(result))
            {
                $rootScope.loginInfo.userId = '';
                $rootScope.loginInfo.rememberMe = false;
            }
            else
            {
                $rootScope.loginInfo.userId = result;
                $rootScope.loginInfo.rememberMe = true;
            }
        };
     })
    .controller("signupCtrl", function ($scope, $rootScope, $routeParams, WebUserSvc, validatorSvc, constantLists, localStrings)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();

            //grab the branding information for the site
            $scope.applySystemSettings();

            $scope.signup = {
                displayControls: false,
                webUser: {
                    Timestamp: '',
                    Answer: '',
                    AnswerClear: '',
                    GUID: '',
                    Id: '',
                    Key: '',
                    Password: '',
                    PasswordClear: '',
                    Question: '',
                    QuestionClear: '',
                    Reset: '',
                    Validated: ''
                },
                error: [],
                controls: {
                    password: '',
                    confirmPassword: '',
                    question: '',
                    answer: '',
                    confirmAnswer: '',
                    valid: true,
                    secretQuestions: constantLists.secretQuestions
                }
            };

            $scope.kendo = {instance: null};

            $scope.getWebUser();
        };

        $scope.getWebUser = function ()
        {
            if ($routeParams.request)
            {
                $scope.signup.webUser.GUID = $routeParams.request;
                WebUserSvc.getWebUserForSignup($scope.signup.webUser).then(
                    function (data)
                    {
                        if (!angular.isUndefinedOrNullOrEmpty(data.Id))
                        {
                            $scope.signup.webUser = data;
                            $scope.signup.displayControls = true;
                            return;
                        }
                        else
                        {
                            $scope.signup.displayControls = false;
                            $scope.notifier.show(localStrings.getLocalizedString("InvalidRequestContact"), 'error');
                            $scope.goTo('/');
                        }
                    }
                );
            }
            else
            {
                $scope.GUID = 0;
                $scope.notifier.show(localStrings.getLocalizedString("URLInvalid"), "error");
                $scope.goTo('/');
            }
        };

        $scope.sendWebUser = function ()
        {
            if (!validatorSvc.executeValidations())
                return;

            $scope.signup.webUser.PasswordClear = $scope.signup.controls.password;
            $scope.signup.webUser.QuestionClear = $scope.signup.controls.question;
            $scope.signup.webUser.AnswerClear = $scope.signup.controls.answer;

            WebUserSvc.signupWebUser($scope.signup.webUser).then(
                function (data)
                {
                    if (data.Validated === true)
                    {
                        $rootScope.automatedLogin = {userId: data.Id, password: $scope.signup.controls.password};
                        $scope.goTo("/");
                    }
                },
                function (err)
                {
                    $scope.signup.errors = [];

                    angular.forEach(err, function (item)
                    {
                        $scope.notifier.show(item, 'error');
                    });
                }
            );

        };

        $scope.clearErrors = function ()
        {
            validatorSvc.clearAllValidations();
        };
    })
    .controller("resetPasswordCtrl", function ($scope, $rootScope, $routeParams, WebUserSvc, validatorSvc, localStrings)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();

            //grab the branding information for the site
            $scope.applySystemSettings();

            $scope.resetPassword = {
                displayTitle: '',
                displayQuestion: false,
                displayPassword: false,
                displayButton: false,
                webUser: {
                    Timestamp: '',
                    Answer: '',
                    AnswerClear: '',
                    GUID: '',
                    Id: '',
                    Key: '',
                    Password: '',
                    PasswordClear: '',
                    Question: '',
                    QuestionClear: '',
                    Reset: '',
                    Validated: ''
                },
                error: [],
                controls: {
                    password: '',
                    confirmPassword: '',
                    answer: '',
                    valid: true,
                    buttonText: 'Submit'
                }
            };

            $scope.getWebUser();
        };

        $scope.getWebUser = function ()
        {
            if ($routeParams.request)
            {
                $scope.resetPassword.webUser.GUID = $routeParams.request;
                WebUserSvc.getWebUserForPasswordReset($scope.resetPassword.webUser).then(
                    function (data)
                    {
                        if (!angular.isUndefinedOrNullOrEmpty(data.Id))
                        {
                            $scope.resetPassword.webUser = data;
                            $scope.resetPassword.displayTitle = localStrings.getLocalizedString("AnswerSecurityQuestion");
                            if ($rootScope.passwordExpired === true)
                            {
                                $scope.resetPassword.displayTitle = localStrings.getLocalizedString("PasswordExpired") + '<br/>' + $scope.resetPassword.displayTitle;
                            }
                            $scope.resetPassword.displayQuestion = true;
                            $scope.resetPassword.displayButton = true;
                        }
                        else
                        {
                            $scope.resetPassword.displayQuestion = false;
                            $scope.resetPassword.displayPassword = false;
                            $scope.resetPassword.displayButton = false;
                            $scope.resetPassword.displayTitle = localStrings.getLocalizedString("InvalidRequest");
                            $scope.notifier.show(localStrings.getLocalizedString("ContactAdmin"), "error");
                            $scope.goTo('/');
                        }
                    }
                );
            }
            else
            {
                $scope.GUID = 0;
                $scope.notifier.show(localStrings.getLocalizedString("URLInvalid"), "error");
                $scope.goTo('/');
            }
        };

        $scope.sendAnswer = function ()
        {
            $scope.resetPassword.webUser.AnswerClear = $scope.resetPassword.controls.answer;
            WebUserSvc.verifyWebUserSecretQuestionAnswer($scope.resetPassword.webUser).then(
                function (data)
                {
                    if (data === 1)
                    {
                        $scope.resetPassword.displayQuestion = false;
                        $scope.resetPassword.displayTitle = localStrings.getLocalizedString("EnterConfirmNewPassword");
                        $scope.resetPassword.controls.buttonText = localStrings.getLocalizedString("SaveAndLogin");
                        $scope.resetPassword.displayPassword = true;
                    }
                    else
                    {
                        $scope.notifier.show(localStrings.getLocalizedString("AnswerNotCorrect"), "error");
                    }
                },
                function (err)
                {
                }
            );

        };

        $scope.setNewPassword = function ()
        {
            $scope.resetPassword.webUser.PasswordClear = $scope.resetPassword.controls.password;
            WebUserSvc.resetWebUserPassword($scope.resetPassword.webUser).then(
                function (data)
                {
                    if (data.Validated === true)
                    {
                        $rootScope.automatedLogin = {userId: data.Id, password: $scope.resetPassword.controls.password};
                        $scope.goTo("/");
                    }
                },
                function (err)
                {
                    $scope.resetPassword.errors = [];

                    angular.forEach(err, function (item)
                    {
                        $scope.notifier.show(item, "error");
                    });
                }
            );
        };

        $scope.submitInfo = function ()
        {
            $scope.resetPassword.error = [];

            if (!validatorSvc.executeValidations())
                return;

            if ($scope.resetPassword.displayQuestion)
            {
                $scope.sendAnswer();
            }

            if ($scope.resetPassword.displayPassword)
            {
                $scope.setNewPassword();
            }
        };

        $scope.clearErrors = function ()
        {
            validatorSvc.clearAllValidations();
        };
    })
    .controller("setNewPasswordCtrl", function ($scope, $rootScope, $routeParams, WebUserSvc, validatorSvc, WebProfileService, localStrings)
    {
        'use strict';
        $scope.webUserKey = -1;
        $scope.webUserToUpdate = {};

        $scope.initialize = function ()
        {
            var profileKey = ($scope.appInfo && $scope.appInfo.profileKey) ? $scope.appInfo.profileKey : 0;

            WebProfileService.getWebProfileMessage(profileKey, 27).then(function (data)
            {
                $scope.customMessage = data.isBlank() ? localStrings.getLocalizedString("PasswordExpiredNew") : data;
            });

            if ($routeParams.key)
            {
                $scope.webUserToUpdate = {
                    Timestamp: '',
                    Answer: '',
                    AnswerClear: '',
                    GUID: '',
                    Id: '',
                    Key: $routeParams.key,
                    Password: '',
                    PasswordClear: '',
                    Question: '',
                    QuestionClear: '',
                    Reset: '',
                    Validated: ''
                };
            }

            validatorSvc.clearValidationScopes();

        };

        $scope.submitPassword = function ()
        {
            //$scope.resetPassword.error = [];

            if (!validatorSvc.executeValidations())
                return;

            $scope.setNewPassword();
        };

        $scope.setNewPassword = function ()
        {
            $scope.webUserToUpdate.OldPassword = $scope.appInfo.pageInfo.controls.oldPassword;
            $scope.webUserToUpdate.PasswordClear = $scope.appInfo.pageInfo.controls.password;

            WebUserSvc.resetWebUserPassword($scope.webUserToUpdate).then(
                function (data)
                {
                    $scope.appInfo.pageInfo.success = true;
                    if (data.Validated === true)
                    {
                        $rootScope.automatedLogin = {userId: data.Id, password: $scope.webUserToUpdate.PasswordClear};
                        $scope.goTo("/");
                    }
                },
                function (err)
                {
                    angular.forEach(err, function (item)
                    {
                        $scope.notifier.show(item, "error");
                    });
                }
            );
        };

        $scope.clearErrors = function ()
        {
            validatorSvc.clearAllValidations();
        };
    })
    .controller("unsubscribeCtrl", function ($scope, $routeParams, validatorSvc, userAccountService, localStrings)
    {
        'use strict';

        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();

            //apply the branding information for the site
            $scope.applySystemSettings();

            $scope.resultMessage = localStrings.getLocalizedString("UnsubscribeRequest");

            if ($routeParams.cont)
            {
                userAccountService.unsubscribeContact($routeParams.cont).then(
                    function (data)
                    {
                        if (data.Successful && !data.Unsubscibed)
                        {
                            $scope.resultMessage = localStrings.getLocalizedString("UnsubscribeSuccessful");
                        }
                        else
                        {
                            $scope.resultMessage = localStrings.getLocalizedString("UnsubscribeNotSuccessful");
                        }
                    },
                    function (data) //http request failed
                    {
                        $scope.resultMessage = localStrings.getLocalizedString("UnsubscribeNotSuccessful");
                    });
            }
            else
            {
                $scope.notifier.show(localStrings.getLocalizedString("InvalidRequestContact"), 'error');
                $scope.goTo('/Home');
            }
        };
    })
    .controller("siteDisabledCtrl", function siteDisabledCtrl($scope, $window)
    {
        'use strict';

        $scope.initializePage = function ()
        {
            $scope.applySystemSettingsSiteDisabled();
        };
    });