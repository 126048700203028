/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.paperworkDealerForms', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.paperworkDealerForms',[]).service('PaperworkDealerFormsService', function($q, apiService, $filter) {
    'use strict';
    var netReadings = 0;
    var beginningAndEndingReadings = 1;
    var calculatedBasedOnUnitsAndPerUnitAmount = 2;

    return {
        loadDealerForms: function (paperworkBatchKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkDealerForms/',
                    data: {
                        PaperworkBatchKey:paperworkBatchKey
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        saveDealerForms:function(header, forms){
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SavePaperworkDealerForms/',
                    data: {
                        DealerFormsHeader:header,
                        DealerForms:forms
                    }
                }
            );
            apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});