/* ==============================================================================

Core Enums

 Contains the core enum related code

 ============================================================================= */
angular.module('core.enums', []).service('Modules', function ()
{

    var All = 'All';
    var BulkFuel = 'BulkFuel';
    var CardLock = 'CardLock';
    var Retail = 'Retail';
    var Wholesale = 'Wholesale';

    return {
        All: All,
        BulkFuel: BulkFuel,
        CardLock: CardLock,
        Retail: Retail,
        Wholesale: Wholesale
    };
}).service('WebFunctions', function ()
{
    var AccountActivity = 1;
    var PaperworkEntry = 2;
    var CardTransactions = 3;
    var ViewCards = 4;
    var EditCards = 5;
    var CardAlerts = 6;
    var AddNewCards = 7;
    var AddandEditWebPaymentAccounts = 8;
    var AddandEditWebPayments = 9;
    //var ViewCreditBalanceInformation = 10;
    var CPPriceNotices = 11;
    var ViewCardNumbers = 12;
    var ViewNotificationsSenttoOtherContacts = 13;
    var ManageContactsAndSecurity = 14;
    var ViewCreditInformation = 15;
    var ViewOrderHistoryandPriceNotices = 16;
    var AddAndEditBulkFuelOrders = 17;
    var ViewCustomPages = 18;
    return {
        AccountActivity: AccountActivity,
        PaperworkEntry: PaperworkEntry,
        CardTransactions: CardTransactions,
        ViewCards: ViewCards,
        EditCards: EditCards,
        CardAlerts: CardAlerts,
        AddNewCards: AddNewCards,
        AddandEditWebPaymentAccounts: AddandEditWebPaymentAccounts,
        AddandEditWebPayments: AddandEditWebPayments,
        //ViewCreditBalanceInformation : ViewCreditBalanceInformation,
        CPPriceNotices: CPPriceNotices,
        ViewCardNumbers: ViewCardNumbers,
        ViewNotificationsSenttoOtherContacts: ViewNotificationsSenttoOtherContacts,
        ManageContactsAndSecurity: ManageContactsAndSecurity,
        ViewCreditInformation: ViewCreditInformation,
        ViewOrderHistoryandPriceNotices: ViewOrderHistoryandPriceNotices,//BulkFuel
        AddAndEditBulkFuelOrders: AddAndEditBulkFuelOrders,//BulkFuel
        ViewCustomPages: ViewCustomPages

    };
}).service('WebFunctionGroups', function (WebFunctions)
{
    var x = WebFunctions;
    var All = [x.AccountActivity, x.PaperworkEntry, x.CardTransactions, x.ViewCards, x.EditCards, x.CardAlerts,
        x.AddNewCards, x.AddandEditWebPaymentAccounts, x.AddandEditWebPayments, x.ViewCreditInformation,
        x.CPPriceNotices, x.ViewCardNumbers, x.ViewNotificationsSenttoOtherContacts, x.ManageContactsAndSecurity,
        x.ViewOrderHistoryandPriceNotices, x.AddAndEditBulkFuelOrders];
    var Account = [x.AccountActivity, x.CardTransactions, x.AddandEditWebPaymentAccounts, x.AddandEditWebPayments];
    var Payments = [x.AddandEditWebPaymentAccounts, x.AddandEditWebPayments];
    var Orders = [/*x.AccountActivity,*/ x.ViewOrderHistoryandPriceNotices, x.AddAndEditBulkFuelOrders];//BulkFuel && Warehouse
    var Paperwork = [x.PaperworkEntry];
    var FuelCards = [x.CardTransactions, x.ViewCards, x.EditCards, x.CardAlerts, x.AddNewCards, x.CPPriceNotices];
    var Comments = All;
    var Documents = All;
    var AccountReports = [x.AccountActivity];
    var AccountPayments = [x.AddandEditWebPaymentAccounts, x.AddandEditWebPayments];
    var AccountAlerts = [x.AddandEditWebPayments];
    var FuelCardsCards = [x.ViewCards, x.EditCards, x.AddNewCards];
    var FuelCardsTrans = [x.CardTransactions];
    var FuelCardsPrices = [x.CPPriceNotices];
    var CustomPages = [x.ViewCustomPages];
    return {
        All: All,
        Account: Account,
        Orders: Orders,
        Paperwork: Paperwork,
        Payments: Payments,
        FuelCards: FuelCards,
        Comments: Comments,
        Documents: Documents,
        AccountReports: AccountReports,
        AccountPayments: AccountPayments,
        AccountAlerts: AccountAlerts,
        FuelCardsCards: FuelCardsCards,
        FuelCardsTrans: FuelCardsTrans,
        FuelCardsPrices: FuelCardsPrices,
        CustomPages: CustomPages
    };
}).service('WebFunctionModules', function (WebFunctions)
{
    return {
        checkWebFunctionModules: function (webFunction, isBulkFuel, isCardLock, isRetail, isWholesale, isWebEft, customer)
        {
            if (webFunction === WebFunctions.CardTransactions) return isCardLock;
            if (webFunction === WebFunctions.ViewCards) return isCardLock;
            if (webFunction === WebFunctions.EditCards) return isCardLock;
            if (webFunction === WebFunctions.CardAlerts) return isCardLock;
            if (webFunction === WebFunctions.AddNewCards) return isCardLock;
            if (webFunction === WebFunctions.AddandEditWebPaymentAccounts) return isWebEft;
            if (webFunction === WebFunctions.AddandEditWebPayments) return isWebEft;
            if (webFunction === WebFunctions.CPPriceNotices) return isCardLock && (customer.CPPrcNtcRuleKey !== null && customer.CPPrcNtcRuleKey > 0);
            if (webFunction === WebFunctions.ViewCardNumbers) return isCardLock;
            if (webFunction === WebFunctions.ViewOrderHistoryandPriceNotices) return (isBulkFuel || isWholesale);
            if (webFunction === WebFunctions.AddAndEditBulkFuelOrders) return (isBulkFuel || isWholesale);
            //for all other web functions assume that any module access is fine.
            return true;
        }
    };
});