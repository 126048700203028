/**
 * Created by rhampton on 7/25/2014.
 */
angular.module("pnODPricing", ['core.objects', 'services.payments', 'core.directives'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/Orders/BulkFuelPrices",
            {
                templateUrl: templateProvider.getTemplate("BulkFuelPrices"),
                controller: "bulkFuelPricesCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
                    }
                }
            }
        )
            .when("/Orders/WarehousePrices",
            {
                templateUrl: templateProvider.getTemplate("WarehousePrices"),
                controller: "warehousePricesCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
                    }
                }
            }
        );
    })
    .controller("odPricingSideMenuCtrl", function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
        };
    })
    .controller("bulkFuelPricesCtrl", function ($scope, bulkFuelPriceNoticesService, $filter, templates, localStrings)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.bulkPricesGrid = {};
        $scope.kendo.bulkPricesGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var selectedRows = this.select();
                var dataItem = this.dataItem(selectedRows[0]);
                $scope.openEdoc(dataItem.EdocDtlKey);
                $scope.$apply();
            },
            columns: [
                {
                    field: "EffDateTimeFormatted",
                    title: localStrings.getLocalizedString("ReportDate"),
                    width: 100,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Location",
                    title: localStrings.getLocalizedString("Location"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-file-o'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.bulkPricesGridInit = function ()
        {
            $scope.kendo.bulkPricesGridDS = new kendo.data.DataSource({
                data: $scope.appInfo.bulkFuelPrices
            });

            $scope.kendo.bulkPricesGridDS.read();
            $scope.kendo.bulkPricesGrid.dataSource.data($scope.kendo.bulkPricesGridDS.data());
        };

        $scope.initialize = function ()
        {
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 21;
            $scope.locationInfo.sideMenu = templates.getTemplate("OdPricingSideMenu");
            $scope.locationInfo.sideMenuData = {
                subMenu: "1",
                bulkFuelPrices: {
                    location: 0
                }
            };
            if (!$scope.appInfo.bulkFuelPrices) $scope.appInfo.bulkFuelPrices = [];
        };

        $scope.$parent.applyAction = function ()
        {
            $scope.modalifier.hide();
            $scope.predicate = 'EffDateTimeFormatted';
            $scope.reverse = false;
            bulkFuelPriceNoticesService.loadBulkFuelPrices($scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.locationInfo.sideMenuData.bulkFuelPrices.location,
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate).then(function (data)
                {
                    if (data.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("BulkFuelPrices"));
                    }
                    $scope.appInfo.bulkFuelPrices = data;
                    angular.forEach($scope.appInfo.bulkFuelPrices, function (element)
                    {
                        element.EffDateTimeFormatted = $filter('date')(element.EffDateTime, 'MM/dd/yyyy');
                    });
                    $scope.kendo.bulkPricesGridInit();
                });
        };
    })
    .controller("bulkFuelPricesSideMenuCtrl", function ($scope, templates, customerService, $filter, localizationSvc)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
        $scope.initialize = function ()
        {
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 21;
            $scope.locationInfo.sideMenu = templates.getTemplate("OdPricingSideMenu");
            $scope.locationInfo.sideMenuData = {
                subMenu: "1",
                bulkFuelPrices: {
                    location: 0
                }
            };
            $scope.$root.reportSettings.reportKey = 16;
            customerService.getCustomerLocations($scope.appInfo.webUser.CurrentAuthorization.CustKey, 1).then(function (data)
            {
                $scope.locations = $filter('filter')(data.Locations, {Active: '!0'});
                $scope.locations.unshift({
                    Active: -1,
                    Description: localStrings.getLocalizedString("All"),
                    Key: 0
                });
                $scope.locationInfo.sideMenuData.bulkFuelPrices.location = 0;
            });
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.bulkFuelPrices = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.bulkFuelPrices;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };
    })
    .controller("warehousePricesCtrl", function ($scope, warehousePriceNoticesService, $filter, templates, localStrings)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.warehousePricesGrid = {};
        $scope.kendo.warehousePricesGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var selectedRows = this.select();
                var dataItem = this.dataItem(selectedRows[0]);
                $scope.openEdoc(dataItem.EdocDtlKey);
                $scope.$apply();
            },
            columns: [
                {
                    field: "EffDateTimeFormatted",
                    title: localStrings.getLocalizedString("ReportDate"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-file-o'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.warehousePricesGridInit = function ()
        {
            $scope.kendo.warehousePricesGridDS = new kendo.data.DataSource({
                data: $scope.appInfo.warehousePrices
            });

            $scope.kendo.warehousePricesGridDS.read();
            $scope.kendo.warehousePricesGrid.dataSource.data($scope.kendo.warehousePricesGridDS.data());
        };

        $scope.initialize = function ()
        {
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 21;
            $scope.locationInfo.sideMenu = templates.getTemplate("OdPricingSideMenu");
            $scope.locationInfo.sideMenuData = {
                subMenu: "2",
                warehousePrices: {}
            };
            if (!$scope.appInfo.warehousePrices) $scope.appInfo.warehousePrices = [];
        };

        $scope.$parent.applyAction = function ()
        {
            $scope.modalifier.hide();
            $scope.predicate = 'EffDateTimeFormatted';
            $scope.reverse = false;
            warehousePriceNoticesService.loadWarehousePrices($scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate).then(function (data)
                {
                    if (data.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("WarehousePrices"));
                    }
                    $scope.appInfo.warehousePrices = data;
                    angular.forEach($scope.appInfo.warehousePrices, function (element)
                    {
                        element.EffDateTimeFormatted = $filter('date')(element.EffDateTime, 'MM/dd/yyyy');
                    });
                    $scope.kendo.warehousePricesGridInit();
                });
        };


    })
    .controller("warehousePricesSideMenuCtrl", function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {
            $scope.$root.reportSettings.reportKey = 31;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.warehousePrices = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.warehousePrices;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };
    })
;