

/* ==============================================================================

 CORE.ERROR MODULE

 Defines the error module and it all it's module dependencies

 ============================================================================= */
angular.module('core.error', []);



/* ==============================================================================

 CORE.ERROR SERVICE

 Contains helpful error handling and publishing methods

 ============================================================================= */
angular.module('core.error').factory('NotificationService', function ($rootScope) {
    'use strict';
    function handle(event, settings, opResults, status, headers, config) {
        var data = {
            settings: settings,
            opResults: opResults,
            status: status,
            headers: headers,
            config: config
        };
        $rootScope.$broadcast('event:' + event, data);
    }
    return {
        handleError: function (settings, opResults, status, headers, config) {
            handle('Error', settings, opResults, status, headers, config);
        },
        handleFailure: function (settings, opResults, status, headers, config) {
            handle('Failed', settings, opResults, status, headers, config);
        },
        handleSuccess: function (settings, opResults, status, headers, config) {
            handle('Success', settings, opResults, status, headers, config);
        }
    };
});