angular.module('services.browser', [])
    .factory('BrowserService', function ($window)
    {
        return {
            getBrowserType: function ()
            {
                var userAgent = $window.navigator.userAgent;
                var browsers = {chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /mozilla/i};
                for (var key in browsers) {
                    if (browsers[key].test(userAgent)) {
                        return key;
                    }
                }
                return 'ie';
            }
        };
    });