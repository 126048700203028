/**
 * Created by jvitoria on 9/5/14.
 */
angular.module("pnFuelCardsCards", ['core.objects'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/FuelCards/Cards/ViewCards",
            {
                templateUrl: templateProvider.getTemplate("ViewCards"),
                controller: "viewCardsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsCardsLocalization");
                    }
                }
            })
            .when("/FuelCards/Cards/AddCards",
            {
                templateUrl: templateProvider.getTemplate("AddCards"),
                controller: "addCardsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsCardsLocalization");
                    }
                }
            })
            .when("/FuelCards/Cards/AddTemplates",
            {
                templateUrl: "fuelCards/cards/addTemplates/addTemplates.tpl.html",
                controller: "addTemplatesCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsCardsLocalization");
                    }
                }
            })
            .when("/FuelCards/Cards/Departments",
            {
                templateUrl: templateProvider.getTemplate("Departments"),
                controller: "departmentsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsCardsLocalization");
                    }
                }
            })
            .when("/FuelCards/Cards/DepartmentSetup",
            {
                templateUrl: templateProvider.getTemplate("DepartmentSetup"),
                controller: "departmentSetupCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsCardsLocalization");
                    }
                }
            })
            .when("/FuelCards/Cards/Locations",
            {
                templateUrl: templateProvider.getTemplate("Locations"),
                controller: "locationsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("FuelCardsCardsLocalization");
                    }
                }
            })
        ;
    })
    .controller('fuelCardsCardsSideMenuCtrl', function ($scope, $rootScope, FuelCardsService)
    {
        'use strict';

        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
        };
    })
    .controller("viewCardsCtrl", function ($scope, FuelCardsService, WebFunctions, WebFunctionGroups, customerService, validatorSvc, $filter, $q, $timeout, templates, localStrings)
    {
        'use strict';

        if (!$scope.appInfo.fuelCards)
            $scope.appInfo.fuelCards = {};
        $scope.appInfo.fuelCards.viewCards = {shown: true};
        $scope.appInfo.fuelCards.modalEdit = {shown: false};
        $scope.appInfo.fuelCards.modalPin = {shown: false};
        $scope.appInfo.fuelCards.modalReissue = {shown: false};
        $scope.appInfo.fuelCards.modalDeactivate = {shown: false};
        $scope.appInfo.fuelCards.modalChangeCardNo = {shown: false, mode: 'edit'};

        $scope.kendo = {};
        $scope.kendo.viewCardsGrid = {};
        $scope.kendo.viewCardsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var dataItem = this.dataItem(this.select()[0]);
                $scope.displayFuelCard(dataItem.Key);
                $scope.$apply();
            },
            columns: [
                {
                    field: "Description",
                    title: localStrings.getLocalizedString("CardDescription"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Status",
                    title: localStrings.getLocalizedString("Status"),
                    width: 100,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "ActivationDate",
                    title: localStrings.getLocalizedString("ActivationDate"),
                    width: 100,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "ExpirationDate",
                    title: localStrings.getLocalizedString("ExpirationDate"),
                    width: 100,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i ng-if='checkCurrentUserPermission(webFunctions.EditCards)' class='fa fa-pencil fa-lg'></i> <i ng-if='!checkCurrentUserPermission(webFunctions.EditCards)' class='fa fa-search fa-lg'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            //height: $(document).height() - 375,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.viewCardsGridInit = function ()
        {
            $scope.kendo.viewCardsGridDS = new kendo.data.DataSource({
                data: $scope.appInfo.fuelCards.viewCards.cards
            });

            $scope.kendo.viewCardsGridDS.read();
            $scope.kendo.viewCardsGrid.dataSource.data($scope.kendo.viewCardsGridDS.data());
        };

        $scope.$parent.applyAction = function ()
        {
            $scope.appInfo.fuelCards.viewCards.cards = [];
            $scope.modalifier.hide();
            $scope.appInfo.fuelCards.viewCards.shown = true;
            $scope.appInfo.fuelCards.modalEdit.shown = false;
            $scope.appInfo.fuelCards.modalReissue.shown = false;
            $scope.appInfo.fuelCards.modalReissue.shown = false;
            $scope.appInfo.fuelCards.modalDeactivate.shown = false;
            FuelCardsService.getCards
            (
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.locationInfo.sideMenuData.viewCards.cardFormat,
                $scope.locationInfo.sideMenuData.viewCards.cardNumber,
                $scope.locationInfo.sideMenuData.viewCards.active === "True" ? -1 : 0,
                $scope.locationInfo.sideMenuData.viewCards.expired === "True" ? -1 : 0,
                $scope.locationInfo.sideMenuData.viewCards.pending === "True" ? -1 : 0,
                $scope.locationInfo.sideMenuData.viewCards.locked === "True" ? -1 : 0,
                $scope.locationInfo.sideMenuData.viewCards.cancelled === "True" ? -1 : 0,
                $scope.locationInfo.sideMenuData.viewCards.department,
                $scope.locationInfo.sideMenuData.viewCards.template
            ).then
            (
                function (data)
                {
                    if (data.Cards.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("Cards"));
                        return;
                    }

                    $scope.appInfo.fuelCards.viewCards.cards = [];
                    angular.forEach(data.Cards, function (item)
                    {
                        var desc = item.NumberMasked + ' - ' + item.Description;
                        desc += item.Template === -1 ? ' (Template)' : '';

                        var status = $filter('filter')($scope.appInfo.fuelCards.statusCodes, {Key: item.Status});

                        var act = $filter('date')(item.ActivationDate, 'MM/dd/yyyy') || '';
                        var exp = $filter('date')(item.ExpirationDate, 'MM/dd/yyyy') || '';

                        $scope.appInfo.fuelCards.viewCards.cards.push({
                            Key: item.Key,
                            Sort: item.Description,
                            Description: desc,
                            Status: status[0].Description,
                            ActivationDate: act,
                            ExpirationDate: exp
                        });
                    });
                    $scope.kendo.viewCardsGridInit();
                }
            );
        };

        $scope.initialize = function ()
        {
            //if user doesnt have permission for the section landing page, test to see if they can land on another one
            //before sending them to the home page
            if (!$scope.checkCurrentUserPermissions(WebFunctionGroups.FuelCardsCards))
            {
                if ($scope.checkCurrentUserPermissions(WebFunctionGroups.FuelCardsTrans))
                    $scope.goTo("/FuelCards/Transactions/TransactionDetails");
                else if ($scope.checkCurrentUserPermissions(WebFunctionGroups.FuelCardsPrices))
                    $scope.goTo("/FuelCards/Prices/LookupPrices");
                else
                    $scope.goTo("/Home");
            }

            validatorSvc.clearValidationScopes();
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 40;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsCardsSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "0";

            $scope.appInfo.fuelCards.statusCodes = [
                { Key: '0', Description: localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("Active")) },
                { Key: '1', Description: localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("Cancelled")) },
                { Key: '2', Description: localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("Expired")) },
                { Key: '3', Description: localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("Locked")) },
                { Key: '4', Description: localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("Pending")) }
            ];
            $scope.appInfo.fuelCards.viewCards.cards = [];
            FuelCardsService.getCardsContext(
                $scope.appInfo.webUser.CurrentAuthorization.CustKey
            ).then(
                function (data) {
                    $scope.appInfo.fuelCards.formats = $filter('orderBy')(data.CardFormats, 'Description');
                    $scope.appInfo.fuelCards.embossingReasons = $filter('orderBy')(data.CardEmbossingReasons, 'Description');
                    $scope.appInfo.fuelCards.deactivateReasons = $filter('orderBy')(data.CardDeactivationReasons, 'Description');
                    $scope.appInfo.fuelCards.states = data.States;
                    $scope.appInfo.fuelCards.alertRules = $filter('orderBy')(data.CardAlertRules, 'ID');
                    $scope.appInfo.fuelCards.templates = $filter('orderBy')(data.CustomerCardTemplates, 'Description');
                    $scope.appInfo.fuelCards.company = data.Company;
                }
            );

            FuelCardsService.getCardsDepartments($scope.appInfo.webUser.CurrentAuthorization.CustKey)
                .then(function (data) {
                    if (data.Successful) {
                        $scope.appInfo.fuelCards.departments = $filter('orderBy')(data.Departments, 'Description');
                    }
                }
                );

            customerService.getCustomerLocations($scope.appInfo.webUser.CurrentAuthorization.CustKey, 2)
                .then(function (data) {
                    if (data.Successful) {
                        $scope.appInfo.fuelCards.locations = $filter('orderBy')(data.Locations, 'Description');
                    }
                }
                );

            $scope.hideModals();
        };

        $scope.saveCards = function (state)
        {
            var recStatus = 0;
            var closeModal = false;
            var card = $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0];

            if (card.Description.isBlank())
            {
                $scope.notifier.show(localStrings.getLocalizedString("Required"), 'error');
                $scope.setFocusOnReady('#efc-description');
                return;
            }

            switch (state)
            {
                case 'save':
                    recStatus = 16;
                    break;

                case 'saveAndClose':
                    recStatus = 16;
                    closeModal = true;
                    break;

                case 'saveCN':
                    recStatus = 16;
                    card.Number_Formatted = null;
                    card.Number_Masked = null;
                    break;

                case 'add':
                    recStatus = 4;
                    break;

                case 'template':

                    if (!$scope.isValidForWebTemplate(card))
                        return;

                    recStatus = 4;
                    card.Number = 0;
                    card.NumberMasked = null;
                    card.Template = "-1";
                    card.PIN = null;
                    card.Activation_Date = null;
                    card.Expiration_Date = null;
                    card.Cards_Issued = '0';
                    card.Emboss_Date = null;
                    card.Emboss_Line_1 = null;
                    card.Emboss_Line_2 = null;
                    card.Emboss_Line_3 = null;
                    card.Export_To_Embosser = '0';
                    card.Marked_For_Export = '0';
                    card.Last_Emboss_Date = null;
                    card.CardDeactRsn_Key = 0;
                    card.Deactivation_Date = null;
                    card.HasTransactions = '0';
                    card.Key = 0;
                    card.TimeStamp = null;
                    card.Status = $scope.securityOptions.allowPending ? '4' : '0';
                    closeModal = true;
                    break;
            }

            FuelCardsService.saveCards
            (
                recStatus,
                card,
                $scope.appInfo.fuelCards.modalEdit.cardDetails.CardCFs[0],
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            ).then
            (
                function (data)
                {
                    if (!closeModal)
                        $scope.displayFuelCard(card.Key);
                    else
                        $scope.initialize();

                    $scope.resetDirtyFlags($scope.editCards);
                }
            );
        };

        $scope.displayFuelCard = function (key)
        {
            FuelCardsService.getCardDetails
            (
                key,
                $scope.checkCurrentUserPermission(WebFunctions.ViewCardNumbers)
            ).then
            (
                function (data)
                {
                    $scope.appInfo.fuelCards.modalEdit.cardDetails = data.CardDetails;
                    $scope.appInfo.fuelCards.modalEdit.origCardDetails = angular.copy(data.CardDetails);

                    $scope.setSecurityOptions();
                    $scope.resetDirtyFlags($scope.editCards);
                }
            );
        };

        $scope.displayModelPin = function (state)
        {
            switch (state)
            {
                case 'display':
                    $scope.saveChangesPrompt();
                    validatorSvc.clearAllValidations();
                    $scope.appInfo.fuelCards.modalPin.PIN1 = null;
                    $scope.appInfo.fuelCards.modalPin.PIN2 = null;
                    $scope.appInfo.fuelCards.modalPin.error = false;
                    $scope.appInfo.fuelCards.modalPin.shown = true;
                    break;

                case 'save':
                    if (!validatorSvc.executeValidations())
                        return;

                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].PIN = $scope.appInfo.fuelCards.modalPin.PIN1;
                    $scope.appInfo.fuelCards.modalPin.shown = false;
                    $scope.saveCards('save');
                    break;
            }
        };

        $scope.displayModelReissue = function (state)
        {
            switch (state)
            {
                case 'display':
                    $scope.saveChangesPrompt();
                    $scope.appInfo.fuelCards.modalReissue.selection = $scope.appInfo.fuelCards.embossingReasons[0].Key;
                    $scope.appInfo.fuelCards.modalReissue.shown = true;
                    break;

                case 'save':
                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].CardEmbRsn_Key = $scope.appInfo.fuelCards.modalReissue.selection;
                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].Export_To_Embosser = '-1';
                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].Emboss_Date = new Date();
                    $scope.appInfo.fuelCards.modalReissue.shown = false;
                    $scope.saveCards('save');
                    break;
            }
        };

        $scope.displayModelDeactReasons = function (state)
        {
            switch (state)
            {
                case 'display':
                    $scope.saveChangesPrompt();
                    $scope.appInfo.fuelCards.modalDeactivate.selection = $scope.appInfo.fuelCards.deactivateReasons[0].Key;
                    $scope.appInfo.fuelCards.modalDeactivate.shown = true;
                    break;

                case 'save':
                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].CardDeactRsn_Key = $scope.appInfo.fuelCards.modalDeactivate.selection;
                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].Status = '1';
                    $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].Deactivation_Date = new Date().toISOString().slice(0, 19);
                    $scope.appInfo.fuelCards.modalDeactivate.shown = false;
                    $scope.saveCards('save');
                    break;
            }
        };

        $scope.displayChangeCardNo = function (command)
        {
            switch (command)
            {
                case 'open':
                    $scope.saveChangesPrompt().then(
                        function ()
                        {
                            $scope.saveCards('save');
                            $scope.appInfo.fuelCards.modalChangeCardNo.mode = 'edit';
                            $scope.appInfo.fuelCards.modalChangeCardNo.shown = true;
                        },
                        function ()
                        {
                            $scope.appInfo.fuelCards.modalChangeCardNo.mode = 'edit';
                            $scope.appInfo.fuelCards.modalChangeCardNo.shown = true;
                        }
                    );
                    break;

                case 'save':
                    if ($scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0].HasTransactions === '-1')
                        $scope.appInfo.fuelCards.modalChangeCardNo.mode = 'confirm';
                    else
                    {
                        $scope.saveCards('saveCN');
                        $scope.displayChangeCardNo(localStrings.getLocalizedString("Close"));
                    }
                    break;

                case 'confirm':
                    $scope.saveCards('saveCN');
                    $scope.displayChangeCardNo(localStrings.getLocalizedString("Close"));
                    break;

                case 'close':
                    $scope.appInfo.fuelCards.modalChangeCardNo.shown = false;
                    $scope.appInfo.fuelCards.modalChangeCardNo.mode = 'edit';
                    break;
            }
        };

        $scope.hideModals = function ()
        {
            $scope.appInfo.fuelCards.viewCards.shown = true;
            $scope.appInfo.fuelCards.modalEdit.shown = false;
            $scope.appInfo.fuelCards.modalReissue.shown = false;
            $scope.appInfo.fuelCards.modalReissue.shown = false;
            $scope.appInfo.fuelCards.modalDeactivate.shown = false;
            $scope.appInfo.fuelCards.modalChangeCardNo.shown = false;
            validatorSvc.clearAllValidations();
        };

        $scope.isValidForWebTemplate = function (card)
        {
            var isValid = true;
            var format = $filter('filter')($scope.appInfo.fuelCards.formats, {Key: card.CardFmt_Key}, true)[0] || {};

            if (angular.isUndefinedOrNullOrEmpty(format) || format.VisibleOnWeb === 0)
            {
                $scope.notifier.show(localStrings.getLocalizedString("CardFormatInvalid"), 'error');
                return false;
            }

            if (card.Template === '-1')
            {
                angular.forEach($scope.appInfo.fuelCards.templates, function (item)
                {
                    if (card.Description === item.Description)
                    {
                        $scope.notifier.show(localStrings.getLocalizedString("TemplateDescriptionUnique"), 'error');
                        isValid = false;
                    }

                    //if(card.Number === item.Number)
                    //{
                    //    $scope.notifier.show('Template card number must be unique', 'error');
                    //    isValid = false;
                    //}
                });
            }

            //physical card w/o auto number or pending
            if (format.PhysicalCard === -1 && format.AutoNo === 0 && format.AllowPending === 0)
            {
                $scope.notifier.show(localStrings.getLocalizedString("PhysicalCardsError"), 'error');
                isValid = false;
            }

            //expiration dates
            if (format.UseExpirationDates === -1 && format.DefaultLifetimeSelection === 0 && format.AllowDefaultByCustomer === 0)
            {
                $scope.notifier.show(localStrings.getLocalizedString("ExpirationDateConfiguredError"), 'error');
                isValid = false;
            }

            //expiration dates
            if (format.UseExpirationDates === -1 && format.AllowDefaultByCustomer === -1)
            {

                var nextDate = angular.isUndefinedOrNullOrEmpty(card.Next_Exp_Date) ? new Date('01/01/1900') : new Date(card.Next_Exp_Date);
                var expDate = angular.isUndefinedOrNullOrEmpty(card.Expiration_Date) ? new Date('01/01/1900') : new Date(card.Expiration_Date);
                var today = new Date();

                if (expDate <= today && nextDate <= today)
                {
                    $scope.notifier.show(localStrings.getLocalizedString("MissingExpirationDate"), 'error');
                    isValid = false;
                }
            }

            return isValid;
        };

        $scope.saveChangesPrompt = function ()
        {
            var deferred = $q.defer();
            if ($scope.editCards.$dirty)
                $scope.modalifier.confirm(localStrings.getLocalizedString("UnsavedChanges"), deferred.resolve, deferred.reject);
            else
                deferred.reject();

            return deferred.promise;
        };

        $scope.setSecurityOptions = function ()
        {

            $scope.securityOptions = {
                readOnly: true,
                formTitle: '',

                viewCardNumber: false,
                displayCardNumber: '',
                canChangeCardNo: false,

                showVehicleInfo: false,
                showCardAuths: false,
                showProdSite: false,
                showTaxExemeptions: false,
                showEmbossingAlert: false,

                showChangePin: false,
                showReissue: false,
                reissueTitle: '',
                showDeactivate: false,
                showSaveTemplate: false,

                shipToLocation: '',
                cardType: '',
                department: '',
                format: '',
                state: '',
                cardStatus: '',
                deactivationReason: '',
                embossingReason: '',
                alertRule: ''
            };

            function keyLookup(list, match, field, value)
            {
                if(!Object.isArray(list) || list.length === 0 || angular.isUndefinedOrNullOrEmpty(match)|| angular.isUndefinedOrNullOrEmpty(field))
                    return value;

                var item = list.find({Key: match});

                if (item)
                    return item[field];
                else
                    return value;
            }

            var card = $scope.appInfo.fuelCards.modalEdit.cardDetails.CardInfos[0];
            var format = $filter('filter')($scope.appInfo.fuelCards.formats, {Key: card.CardFmt_Key}, true)[0];

            //sanity check
            if (!card || !format)
            {
                $scope.modalifier.show(localStrings.getLocalizedString("Cards"), localStrings.getLocalizedString("CardSelectionFailed"));
                return;
            }

            //form options
            $scope.securityOptions.readOnly = !($scope.checkCurrentUserPermission(WebFunctions.EditCards) &&
            (format.AllowEditAfterEmbossed === -1 ||
            card.Cards_Issued === '0'));
            $scope.securityOptions.formTitle = $scope.securityOptions.readOnly ? localStrings.getLocalizedString("ViewCard") : localStrings.getLocalizedString("EditCard");
            $scope.securityOptions.formTitle += card.Template === "-1" ? ' Template' : '';
            $scope.securityOptions.allowPending = format.AllowPending === -1;

            //card number options
            $scope.securityOptions.autoNo = format.AutoNo === -1;
            $scope.securityOptions.maxCardNoLength = format.Mask.match(/0/g).length || 20;
            $scope.securityOptions.canChangeCardNo = !$scope.securityOptions.readOnly && !$scope.securityOptions.autoNo && $scope.checkCurrentUserPermission(WebFunctions.ViewCardNumbers);

            // section visibility options
            $scope.securityOptions.showEmbossingAlert = card.Export_To_Embosser === '-1';
            $scope.securityOptions.showCardAuths = $scope.securityOptions.readOnly ||
                card.CardAlertRule_Key > 0 ||
                card.Max_Trans_PerDay > 0 ||
                $scope.appInfo.fuelCards.modalEdit.cardDetails.CardCFs.length > 0;
            $scope.securityOptions.showVehicleInfo = format.AllowVehicleInfo === -1;
            $scope.securityOptions.showProdSite = card.Auth_By_Product !== '0' && card.Single_Site_Auth_Site_Key !== 0;
            $scope.securityOptions.showTaxExemeptions = $scope.appInfo.fuelCards.modalEdit.cardDetails.CardTaxExs.length > 0;

            // button options
            $scope.securityOptions.showSave = !$scope.securityOptions.readOnly;
            $scope.securityOptions.showChangePin = !$scope.securityOptions.readOnly && format.AllowPIN !== 0;
            $scope.securityOptions.showReissue = !$scope.securityOptions.readOnly;
            $scope.securityOptions.reissueTitle = format.PhysicalCard === 0 ? localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("ActivateCard")) : localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("ReissueCard"));
            $scope.securityOptions.showDeactivate = !$scope.securityOptions.readOnly;
            $scope.securityOptions.showSaveTemplate = $scope.checkCurrentUserPermission(WebFunctions.AddNewCards);

            // child field lookups
            $scope.securityOptions.shipToLocation = keyLookup($scope.appInfo.fuelCards.locations, card.CustLocKey, 'ID', '(Default)');
            $scope.securityOptions.format = format.Description;
            $scope.securityOptions.department = keyLookup($scope.appInfo.fuelCards.departments, card.CardDptKey, 'Description', '');
            $scope.securityOptions.state = keyLookup($scope.appInfo.fuelCards.states, card.Vehicle_State_Key, 'Description', '');
            $scope.securityOptions.cardType = card.Type === "0" ? 'Card Holder' : 'Vehicle Card';
            $scope.securityOptions.cardStatus = keyLookup($scope.appInfo.fuelCards.statusCodes, card.Status, 'Description', '');
            $scope.securityOptions.deactivationReason = keyLookup($scope.appInfo.fuelCards.deactivateReasons, card.CardDeactRsn_Key, 'Description', '');
            $scope.securityOptions.embossingReason = keyLookup($scope.appInfo.fuelCards.embossingReasons, card.CardEmbRsn_Key, 'Description', '');
            $scope.securityOptions.alertRule = keyLookup($scope.appInfo.fuelCards.alertRules, card.CardAlertRule_Key, 'ID', '');

            //show the card detail screen
            $scope.appInfo.fuelCards.viewCards.shown = false;
            $scope.appInfo.fuelCards.modalEdit.shown = true;
        };
    })
    .controller("viewCardsSideMenuCtrl", function ($scope, FuelCardsService, WebFunctions, reportingService, $filter)
    {
        'use strict';
        $scope.cols = [];

        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.viewCards) $scope.locationInfo.sideMenuData.viewCards = [];
            $scope.locationInfo.sideMenuData.viewCards.reportType = 'ViewCards';
            $scope.locationInfo.sideMenuData.viewCards.cardFormat = '0';
            $scope.locationInfo.sideMenuData.viewCards.cardNumber = '';
            $scope.locationInfo.sideMenuData.viewCards.active = 'True';
            $scope.locationInfo.sideMenuData.viewCards.expired = 'False';
            $scope.locationInfo.sideMenuData.viewCards.pending = 'False';
            $scope.locationInfo.sideMenuData.viewCards.locked = 'False';
            $scope.locationInfo.sideMenuData.viewCards.cancelled = 'False';
            $scope.$root.reportSettings.reportKey = 20;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.viewCards.cardFormat = $scope.reportSettings.loadOptions.cardFormat;
            $scope.locationInfo.sideMenuData.viewCards.cardNumber = $scope.reportSettings.loadOptions.cardNumber;
            $scope.locationInfo.sideMenuData.viewCards.active = $scope.reportSettings.loadOptions.active;
            $scope.locationInfo.sideMenuData.viewCards.expired = $scope.reportSettings.loadOptions.expired;
            $scope.locationInfo.sideMenuData.viewCards.pending = $scope.reportSettings.loadOptions.pending;
            $scope.locationInfo.sideMenuData.viewCards.locked = $scope.reportSettings.loadOptions.locked;
            $scope.locationInfo.sideMenuData.viewCards.cancelled = $scope.reportSettings.loadOptions.cancelled;
            $scope.locationInfo.sideMenuData.viewCards.department = $scope.reportSettings.loadOptions.department;
            $scope.locationInfo.sideMenuData.viewCards.template = $scope.reportSettings.loadOptions.template;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.cardFormat = $scope.locationInfo.sideMenuData.viewCards.cardFormat;
            $scope.reportSettings.saveOptions.cardNumber = $scope.locationInfo.sideMenuData.viewCards.cardNumber;
            $scope.reportSettings.saveOptions.active = $scope.locationInfo.sideMenuData.viewCards.active;
            $scope.reportSettings.saveOptions.expired = $scope.locationInfo.sideMenuData.viewCards.expired;
            $scope.reportSettings.saveOptions.pending = $scope.locationInfo.sideMenuData.viewCards.pending;
            $scope.reportSettings.saveOptions.locked = $scope.locationInfo.sideMenuData.viewCards.locked;
            $scope.reportSettings.saveOptions.cancelled = $scope.locationInfo.sideMenuData.viewCards.cancelled;
            $scope.reportSettings.saveOptions.department = $scope.locationInfo.sideMenuData.viewCards.department;
            $scope.reportSettings.saveOptions.template = $scope.locationInfo.sideMenuData.viewCards.template;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            var format = $scope.locationInfo.sideMenuData.viewCards.cardFormat === '' ? '0' :
                $scope.locationInfo.sideMenuData.viewCards.cardFormat;
            var active = $scope.locationInfo.sideMenuData.viewCards.active === "True" ? -1 : 0;
            var expired = $scope.locationInfo.sideMenuData.viewCards.expired === "True" ? -1 : 0;
            var pending = $scope.locationInfo.sideMenuData.viewCards.pending === "True" ? -1 : 0;
            var locked = $scope.locationInfo.sideMenuData.viewCards.locked === "True" ? -1 : 0;
            var cancelled = $scope.locationInfo.sideMenuData.viewCards.cancelled === "True" ? -1 : 0;
            var department = $scope.locationInfo.sideMenuData.viewCards.department === '' ?  '0':
                $scope.locationInfo.sideMenuData.viewCards.department;

            var qs = "reportName=" + 'CustomerCardList' +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-CardFormatKey=" + format +
                "&RP-CardNumDesc=" + $scope.locationInfo.sideMenuData.viewCards.cardNumber +
                "&RP-IsActive=" + active +
                "&RP-IsExpired=" + expired +
                "&RP-IsPending=" + pending +
                "&RP-IsLocked=" + locked +
                "&RP-IsCancelled=" + cancelled +
                "&RP-Department=" + department +
                "&RP-Template=" + $scope.locationInfo.sideMenuData.viewCards.template +
                "&RP-CanViewCardNumbers=" + $scope.checkCurrentUserPermission(WebFunctions.ViewCardNumbers);

            reportingService.exportReport(qs, args.format);
        });
    })
    .controller("addCardsCtrl", function ($scope, $compile, FuelCardsService, WebFunctions, $filter, validatorSvc, templates, localStrings)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.columns = [
            {
                field: "description",
                title: localStrings.getLocalizedString("CardDescription"),
                attributes: {class: "alignLeft"},
                editor: function (element, options)
                {
                    $("<input/>")
                        .attr("name", options.field)
                        .attr("data-bind", "value:" + options.field)
                        .attr("class", "k-input k-textbox")
                        .attr("maxlength", 30)
                        .prop('required', true)
                        .attr("data-required-msg", localStrings.getLocalizedString("Required"))
                        .appendTo(element);

                    $('<span></span>')
                        .attr("data-for", options.field)
                        .attr("class", "k-invalid-msg")
                        .appendTo(element);
                }
            },
            {
                field: "number",
                title: localStrings.getLocalizedString("CardNumber"),
                width: 160,
                attributes: {class: "alignLeft"},
                editor: function (element, options)
                {
                    $("<input/>")
                        .attr("name", options.field)
                        .attr("data-bind", "value:" + options.field)
                        .attr("maxlength", $scope.context.maxCardNoLength)
                        .prop('required', true)
                        .attr("data-required-msg", localStrings.getLocalizedString("Required"))
                        .appendTo(element)
                        .kendoNumericTextBox({
                            decimals: 0,
                            format: "#",
                            min: 0,
                            spinners: false
                        });

                    $('<span></span>')
                        .attr("data-for", options.field)
                        .attr("class", "k-invalid-msg")
                        .appendTo(element);
                }
            },
            {
                field: "PIN",
                title: localStrings.getLocalizedString("PIN"),
                width: 120,
                attributes: {class: "alignLeft"},
                editor: function (element, options)
                {
                    $("<input/>")
                        .attr("name", options.field)
                        .attr("data-bind", "value:" + options.field)
                        .attr("maxlength", 16)
                        .appendTo(element)
                        .kendoNumericTextBox({
                            decimals: 0,
                            format: "#",
                            min: 0,
                            spinners: false
                        });
                }
            },
            {
                field: "vehicleID",
                title: localStrings.getLocalizedString("VehicleID"),
                width: 100,
                attributes: {class: "alignLeft"},
                editor: function (element, options)
                {
                    $("<input/>")
                        .attr("name", options.field)
                        .attr("data-bind", "value:" + options.field)
                        .attr("class", "k-input k-textbox")
                        .attr("maxlength", 20)
                        .appendTo(element);

                    $('<span></span>')
                        .attr("data-for", options.field)
                        .attr("class", "k-invalid-msg")
                        .appendTo(element);
                }
            },
            {
                field: "licenseNo",
                title: localStrings.getLocalizedString("LicenseNo"),
                width: 100,
                attributes: {class: "alignLeft"},
                editor: function (element, options)
                {
                    $("<input/>")
                        .attr("name", options.field)
                        .attr("data-bind", "value:" + options.field)
                        .attr("class", "k-input k-textbox")
                        .attr("maxlength", 15)
                        .appendTo(element);

                    $('<span></span>')
                        .attr("data-for", options.field)
                        .attr("class", "k-invalid-msg")
                        .appendTo(element);
                }
            },
            {
                field: "state",
                title: localStrings.getLocalizedString("LicenseState"),
                width: 60,
                template: "#=state.Code#",
                attributes: {class: "alignLeft"},
                editor: function (element, options)
                {
                    $scope.kendo.stateList = $("<input/>")
                        .attr("name", options.field)
                        .attr("data-bind", "value:" + options.field)
                        .appendTo(element)
                        .kendoDropDownList({
                            autoBind: false,
                            dataSource: $scope.context.states,
                            dataTextField: "Code",
                            dataValueField: "Key",
                            optionLabel: {
                                Key: null,
                                Code: ""
                            }
                        });
                }
            },
            {
                title: ' ',
                template: "<a class='k-grid-edit' href='\\#'><i class='fa fa-pencil'></i></a><a class='k-grid-update' href='\\#'><i class='fa fa-check'></i></a>",
                width: 50
            },
            {
                title: ' ',
                template: "<a class='k-grid-delete' href='\\#'><i class='fa fa-trash-o'></i></a><a class='k-grid-cancel' href='\\#'><i class='fa fa-times'></i></a>",
                width: 50
            }
        ];
        $scope.kendo.addCardsGridOptions = {
            autoBind: true,
            columns: $scope.kendo.columns,
            dataSource: new kendo.data.DataSource({
                data: [],
                schema: {
                    model: {
                        id: "description",
                        fields: {
                            key: {
                                type: "number",
                                editable: false,
                                nullable: false,
                                validation: false
                            },
                            description: {
                                type: "string",
                                editable: true,
                                nullable: true,
                                validation: false
                            },
                            number: {
                                type: "number",
                                editable: true,
                                nullable: true,
                                validation: {
                                    unique: function (input)
                                    {
                                        if (input.attr("name") === "number" && input.val() !== '')
                                        {
                                            var matches = $scope.kendo.addCardsGrid.dataSource.data().toJSON().findAll({number: input.val().toNumber()}) || [];
                                            input.attr("data-unique-msg", localStrings.getLocalizedString("MustBeUnique"));
                                            return matches.length <= 1;
                                        }

                                        return true;
                                    }
                                }
                            },
                            PIN: {
                                type: "number",
                                editable: true,
                                nullable: true,
                                validation: false
                            },
                            vehicleID: {
                                type: "string",
                                editable: true,
                                nullable: true,
                                validation: false
                            },
                            licenseNo: {
                                type: "string",
                                editable: true,
                                nullable: true,
                                validation: false
                            }, state: {
                                editable: true,
                                defaultValue: {Key: null, Code: ""}
                            }
                        }
                    }
                }
            }),
            editable: {
                mode: "inline",
                update: true,
                destroy: true,
                createAt: "bottom"
            },
            filterable: false,
            messages: {
                commands: {
                    cancel: "",
                    canceledit: "",
                    create: "",
                    destroy: "",
                    edit: "",
                    save: "",
                    select: "",
                    update: ""
                }
            },
            mobile: true,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: true,
            sortable: true
        };

        function addFooterBar()
        {
            var addCardAction = $('<div class="footerRow"><i class="fa fa-plus" ng-click="addRow()"></i></div>').appendTo($('#addCardsGrid'));
            $compile(addCardAction)($scope);
        }

        $scope.$on("kendoWidgetCreated", function (event, widget)
        {
            if (widget === $scope.kendo.addCardsGrid)
            {
                addFooterBar();

                $scope.kendo.addCardsGrid.table.on("keydown", function (e)
                {
                    if (e.keyCode === kendo.keys.TAB && e.target.closest("td").getAttribute("data-container-for") === "state")
                    {
                        $scope.addRow();
                        e.preventDefault();
                    }
                });

                $scope.kendo.addCardsGrid.tbody.on("click", "tr:not(.k-grid-edit-row)", function (e)
                {
                    $scope.kendo.addCardsGrid.editRow($scope.kendo.addCardsGrid.tbody.find("tr.k-state-selected"));
                });
            }
        });

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AddNewCards) || !$scope.checkCurrentUserPermission(WebFunctions.EditCards))
            {
                $scope.goTo("/Home");
                return;
            }

            validatorSvc.clearValidationScopes();

            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 40;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsCardsSideMenu");
            if (!$scope.locationInfo.sideMenuData)
            {
                $scope.locationInfo.sideMenuData = {};
            }
            $scope.locationInfo.sideMenuData.subMenu = '1';

            $scope.context = {
                template: {},
                format: {},
                showCardNo: false,
                showPIN: false,
                showVehInfo: false
            };

            FuelCardsService.getCardsContext($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    if (!data.Successful)
                    {
                        $scope.modalifier.showSetupError();
                        return;
                    }

                    $scope.context.formats = data.CardFormats;
                    $scope.context.states = data.States;
                    $scope.context.templates = data.CustomerCardTemplates.sortBy('Description');

                    validateTemplates();

                    if ($scope.context.templates.length === 0)
                    {
                        var msg = $scope.checkCurrentUserPermission(WebFunctions.AddNewCards) ? ' ' + localStrings.getLocalizedString("CreateOneOr") + ' ' : ' ';
                        $scope.modalifier.show(localStrings.getLocalizedString("AddCards"), localStrings.getLocalizedString("NoCardTemplates") + msg + localStrings.getLocalizedString("ContactAccountAdmin"));
                        $scope.context.templateKey = 0;
                    }
                    else
                        $scope.context.templateKey = $scope.context.templates[0].Key;

                    // Need to make sure the template details are loaded when the add cards page is loaded
                    if ($scope.context.templateKey > 0) {
                        $scope.getTemplate($scope.context.templateKey);
                    }
                });
        };

        $scope.addRow = function ()
        {
            if ($scope.kendo.templateDropDown.value().isBlank())
            {
                $scope.modalifier.show(localStrings.getLocalizedString("AddCards"), localStrings.getLocalizedString("ValidTemplateRequired"));
                return;
            }

            $scope.kendo.addCardsGrid.saveChanges();
            $scope.kendo.addCardsGrid.addRow();
        };

        $scope.getTemplate = function (key)
        {
            if (!key)
            {
                return;
            }

            FuelCardsService.getCardDetails(key).then(
                function (data)
                {
                    if (!data.Successful)
                    {
                        $scope.modalifier.showSetupError();
                        return;
                    }

                    //store complete card object
                    $scope.context.templateDetails = data.CardDetails;

                    //get selected template and format
                    $scope.context.template = $filter('filter')($scope.context.templates, {Key: key.toNumber()}, true)[0];
                    $scope.context.format = $filter('filter')($scope.context.formats, {Key: $scope.context.templateDetails.CardInfos[0].CardFmt_Key}, true)[0];
                    $scope.context.maxCardNoLength = $scope.context.format.Mask.match(/0/g).length || 20;

                    $scope.kendo.addCardsGrid.cancelRow();
                    var cols = $scope.kendo.columns.clone();

                    if ($scope.context.template.PhysicalCard !== -1)
                        cols.remove({field: 'number'});

                    if ($scope.context.template.AllowPIN === -1)
                        cols.remove({field: 'PIN'});

                    if ($scope.context.format.AllowVehicleInfo === -1)
                    {
                        cols.remove({field: 'vehicleID'});
                        cols.remove({field: 'licenseNo'});
                        cols.remove({field: 'state'});
                    }

                    $scope.kendo.addCardsGrid.setOptions({
                        columns: cols
                    });

                    addFooterBar();
                });
        };

        $scope.saveCards = function ()
        {
            $scope.kendo.addCardsGrid.saveChanges();

            if ($scope.kendo.addCardsGrid.tbody.find(".k-invalid").length > 0)
                return;

            var cards = $scope.kendo.addCardsGrid.dataSource.data();

            //make sure there is at least one card to save
            if (angular.isUndefinedOrNullOrEmpty(cards))
            {
                $scope.notifier.show(localStrings.getLocalizedString("NoCardsToSave"), 'error');
                return;
            }

            //process each card entry for save
            angular.forEach(
                cards,
                function (item)
                {
                    //create a complete card record from the selected template and push to the DB
                    var newCard = angular.copy($scope.context.templateDetails.CardInfos[0]);

                    newCard.Cust_Key = $scope.appInfo.webUser.CurrentAuthorization.CustKey;
                    newCard.Description = item.description;
                    newCard.Number = item.number;
                    newCard.PIN = item.PIN;
                    newCard.Expiration_Date = $scope.context.template.SetExpirationDate;
                    newCard.VehicleID = item.vehicleID;
                    newCard.Vehicle_License_No = item.licenseNo;
                    newCard.Vehicle_State_Key = item.state.Key;
                    newCard.Template = 0;
                    newCard.Status = newCard.CardFmt_Allow_Pending === '-1' ? '4' : '0';

                    FuelCardsService.addCard(
                        4,
                        newCard,
                        $scope.context.templateDetails.CardCFs[0],
                        $scope.appInfo.webUser.CurrentAuthorization.CustContKey).then(
                        function (data)
                        {
                            if (data.Successful)
                            {
                                if (!data.CardNumberValid)
                                //notify on failure
                                {
                                    $scope.notifier.show(localStrings.getLocalizedString("CardNumberUnique"), 'error');
                                }
                                else
                                {
                                    //remove saved cards
                                    var result = getCard();
                                    var i = cards.indexOf(result);
                                    cards.splice(i, 1);
                                    $scope.notifier.show(localStrings.getLocalizedString("ChangesSaved"), "success");
                                }
                            }
                            else
                            {
                                $scope.notifier.show(localStrings.getLocalizedString("RecordNotSaved"), 'error');
                            }
                        });

                    //javascript closure to capture index and hold it until the promise returns
                    /*jshint latedef: false */
                    var getCard = (function ()
                    {
                        var myItem = item;
                        return function ()
                        {
                            return myItem;
                        };
                    })();
                });
        };

        /*jshint latedef: false */
        var validateTemplates = function ()
        {
            for (var i = $scope.context.templates.length - 1; i >= 0; i--)
            {
                if ($scope.context.templates[i].PhysicalCard === -1 && !($scope.context.templates[i].AutoNoKey === -1 ||
                    $scope.context.templates[i].AllowPending === -1))
                {
                    $scope.context.templates.splice(i, 1);
                }
            }
        };

    })
    .controller("locationsCtrl", function ($scope, FuelCardsService, customerService, validatorSvc, $filter, WebFunctions, templates, localStrings)
    {
        'use strict';
        $scope.kendo = {};
        $scope.kendo.locationsGrid = {};
        $scope.kendo.locationsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var selectedRows = this.select();
                var dataItem = this.dataItem(selectedRows[0]);
                $scope.selectLocation(dataItem);
                $scope.$apply();
            },
            columns: [
                {
                    field: "ID",
                    title: localStrings.getLocalizedString("ID"),
                    width: 100,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Description",
                    title: localStrings.getLocalizedString("Description"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Address1",
                    title: localStrings.getLocalizedString("Address"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "City",
                    title: localStrings.getLocalizedString("City"),
                    width: 125,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "County",
                    title: localStrings.getLocalizedString("County"),
                    width: 125,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "State",
                    title: localStrings.getLocalizedString("StateProvince"),
                    width: 90,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "PostalCode",
                    title: localStrings.getLocalizedString("PostalCode"),
                    width: 90,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-trash-o' ng-click='deleteLocation(dataItem)' ng-if='checkCurrentUserPermission(webFunctions.EditCards)'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.locationsGridInit = function ()
        {
            $scope.kendo.locationsGridDS = new kendo.data.DataSource({
                data: $scope.appInfo.fuelCards.locations.results
            });

            $scope.kendo.locationsGridDS.read();
            $scope.kendo.locationsGrid.dataSource.data($scope.kendo.locationsGridDS.data());
        };

        $scope.initialize = function ()
        {
            $scope.checkForAppliedProfile();
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 40;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsCardsSideMenu");
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "5";
            if (!$scope.appInfo.fuelCards.states)
            {
                FuelCardsService.getCardsContext(
                    $scope.appInfo.webUser.CurrentAuthorization.CustKey
                ).then(
                    function (data)
                    {
                        $scope.appInfo.fuelCards.states = data.States;
                    }
                );
            }
            $scope.appInfo.fuelCards.locations = {
                results: [],
                selected: {},
                disableCtrls: true,
                LocIDFormat: 1,
                LocIDLength: 10
            };

            $scope.canAddorSave = $scope.checkCurrentUserPermission(WebFunctions.ViewCards) && ($scope.checkCurrentUserPermission(WebFunctions.AddNewCards) ||
                $scope.checkCurrentUserPermission(WebFunctions.EditCards));

            validatorSvc.clearValidationScopes();

            if (!$scope.appInfo.fuelCards.states)
            {
                FuelCardsService.getCardsContext(
                    $scope.appInfo.webUser.CurrentAuthorization.CustKey
                ).then(
                    function (data)
                    {
                        $scope.appInfo.fuelCards.states = data.States;
                        $scope.appInfo.fuelCards.states.unshift({Key: 0, Name: ' '});
                        $scope.pullLocations();
                    }
                );
            }
            else
                $scope.pullLocations();
            $scope.predicate = 'ID';
            $scope.reverse = false;
        };

        $scope.addLocation = function ()
        {
            $scope.clearLocation();
            $scope.appInfo.fuelCards.locations.disableCtrls = false;
        };

        $scope.clearLocation = function ()
        {
            angular.copy(FuelCardsService.blankLocation, $scope.appInfo.fuelCards.locations.selected);
            $scope.appInfo.fuelCards.locations.disableCtrls = true;
            validatorSvc.clearAllValidations();
        };

        $scope.deleteLocation = function (location)
        {
            var yes = function ()
            {
                $scope.pushLocation(8, location);
            };

            $scope.modalifier.confirm(localStrings.getLocalizedString("DeleteLocation"), yes);
        };

        $scope.saveLocation = function ()
        {
            if (!validatorSvc.executeValidations())
                return;

            if ($scope.appInfo.fuelCards.locations.selected.Key === 0)
            {
                $scope.appInfo.fuelCards.locations.selected.CustKey = $scope.appInfo.webUser.CurrentAuthorization.CustKey;
                $scope.appInfo.fuelCards.locations.selected.Type = 2;
                $scope.pushLocation(4, $scope.appInfo.fuelCards.locations.selected);
            } else
                $scope.pushLocation(16, $scope.appInfo.fuelCards.locations.selected);
        };

        $scope.selectLocation = function (location)
        {
            $scope.clearLocation();
            angular.copy(location, $scope.appInfo.fuelCards.locations.selected);
            $scope.appInfo.fuelCards.locations.disableCtrls = !$scope.checkCurrentUserPermission(WebFunctions.EditCards);
        };

        $scope.pullLocations = function ()
        {
            customerService.getCustomerLocations($scope.appInfo.webUser.CurrentAuthorization.CustKey, 2)
                .then(function (data)
                {
                    if (data.Successful)
                    {
                        if (data.Locations.length > 0)
                        {
                            $scope.appInfo.fuelCards.locations.LocIDFormat = data.LocIDFormat || 0;
                            $scope.appInfo.fuelCards.locations.LocIDLength = data.LocIDLength || 10;
                            $scope.appInfo.fuelCards.locations.results = data.Locations
                                .each(function (loc, i)
                                {
                                    var state = $scope.appInfo.fuelCards.states.find({Key: loc.StateKey});
                                    loc.State = state ? state.Code : null;
                                })
                                .sortBy('Description');
                            $scope.selectLocation($scope.appInfo.fuelCards.locations.results[0]);
                            $scope.kendo.locationsGridInit();
                            $scope.kendo.locationsGridInit();
                        }
                        else
                            $scope.notifier.show(localStrings.getLocalizedString("NoResults"), 'warning');
                    }
                    else
                        $scope.modalifier.showSetupError();
                }
            );

        };

        $scope.pushLocation = function (status, location)
        {
            customerService.saveCustomerLocations(
                status,
                location,
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            )
                .then(function (data)
                {
                    $scope.clearLocation();
                    $scope.pullLocations();
                    $scope.resetDirtyFlags($scope.appInfo.fuelCards.locations.locationsForm);
                }
            );
        };

        $scope.updatePhone = function ()
        {
            $scope.appInfo.fuelCards.locations.selected.Phone = $scope.appInfo.fuelCards.locations.selected.PhoneFormatted.replace(/[^0-9]/g, '');
        };
    })
    .controller("departmentsCtrl", function ($scope, $filter, $timeout, FuelCardsService, validatorSvc, WebFunctions, templates, localStrings)
    {
        'use strict';
        $scope.cols = [];
        $scope.kendo = {departmentsGridReady: false};
        $scope.kendo.departmentsGrid = {};
        $scope.kendo.departmentsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var dataItem = this.dataItem(this.select()[0]);
                $scope.selectRow(dataItem.deptKey);
                $scope.$apply();
            },
            columns: [
                {
                    field: "deptName",
                    title: localStrings.getLocalizedString("Description"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cell1Value",
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cell2Value",
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cell3Value",
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cell4Value",
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cell5Value",
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-trash-o' ng-click='selectRow(dataItem.deptKey); deleteDept();' ng-if='checkCurrentUserPermission(webFunctions.EditCards)'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            //height: $(document).height() - 375,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.departmentsGridInit = function ()
        {
            $scope.kendo.departmentsGridDS = new kendo.data.DataSource({
                data: $scope.tableArray
            });

            $scope.kendo.departmentsGridDS.read();
            $scope.kendo.departmentsGrid.dataSource.data($scope.kendo.departmentsGridDS.data());

            if ($scope.cols[0])
                $("#departmentsGrid th[data-field=cell1Value] a").html($scope.cols[0].FieldName);
            else
                $scope.kendo.departmentsGrid.hideColumn(1);

            if ($scope.cols[1])
                $("#departmentsGrid th[data-field=cell2Value] a").html($scope.cols[1].FieldName);
            else
                $scope.kendo.departmentsGrid.hideColumn(2);

            if ($scope.cols[2])
                $("#departmentsGrid th[data-field=cell3Value] a").html($scope.cols[2].FieldName);
            else
                $scope.kendo.departmentsGrid.hideColumn(3);

            if ($scope.cols[3])
                $("#departmentsGrid th[data-field=cell4Value] a").html($scope.cols[3].FieldName);
            else
                $scope.kendo.departmentsGrid.hideColumn(4);

            if ($scope.cols[4])
                $("#departmentsGrid th[data-field=cell5Value] a").html($scope.cols[4].FieldName);
            else
                $scope.kendo.departmentsGrid.hideColumn(5);
        };

        $scope.initialize = function ()
        {

            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 40;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsCardsSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "3";
            $scope.departments = [];
            $scope.fields = [];
            $scope.values = [];
            $scope.selDept = {};
            $scope.selVals = [];
            $scope.disableControls = true;

            $scope.canAddorSave = $scope.checkCurrentUserPermission(WebFunctions.ViewCards) && ($scope.checkCurrentUserPermission(WebFunctions.AddNewCards) ||
                $scope.checkCurrentUserPermission(WebFunctions.EditCards));

            validatorSvc.clearValidationScopes();
            $scope.pullDepartments();

            if (!angular.isUndefinedOrNullOrEmpty($scope.tableArray))
                $scope.selectRow($scope.tableArray[0].deptKey);
        };

        $scope.createObjects = function (data)
        {
            $scope.display = {
                hideCol1: true,
                hideCol2: true,
                hideCol3: true,
                hideCol4: true,
                hideCol5: true,
                disableControls: true
            };

            if (data.Successful)
            {
                $scope.departments = data.Departments || [];
                $scope.fields = data.Fields || [];
                $scope.values = data.Values || [];

                if ($scope.departments.length === 0 && $scope.fields.length === 0)
                {
                    $scope.notifier.show(localStrings.getLocalizedString("NoResults"), 'warning');
                }
            }
            else
            {
                $scope.modalifier.showSetupError();
                return;
            }

            //(rare) catch any empty field names and give them a default value
            angular.forEach($scope.fields, function (item)
            {
                if (item.Key > 0 && item.FieldName.isBlank())
                    item.FieldName = localStrings.getLocalizedString("DeptField") + ' ' + item.FieldNumber;
            });

            // No depts defined, create empty binding objs
            if ($scope.departments.length === 0)
            {
                $scope.selDept = {
                    "CustKey": $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                    "Description": "",
                    "Key": 0,
                    "TimeStamp": ""
                };

                // create a blank value obj for any defined fields
                angular.forEach($scope.fields, function (field)
                {
                    $scope.selVals.push({
                        "CardDptFldKey": field.Key,
                        "CardDptKey": 0,
                        "FieldValue": "",
                        "Key": 0,
                        "TimeStamp": ""
                    });
                });
            }
            else
            // depts exist, create any needed empty values for fields not previously defined
            {
                var temp = {};
                angular.forEach($scope.departments, function (dept)
                {
                    angular.forEach($scope.fields, function (field)
                    {
                        temp = ($filter('filter')($scope.values, {
                            CardDptKey: dept.Key,
                            CardDptFldKey: field.Key
                        }));
                        if (temp.length === 0)
                        {
                            $scope.values.push({
                                "CardDptFldKey": field.Key,
                                "CardDptKey": dept.Key,
                                "FieldValue": "",
                                "Key": 0,
                                "TimeStamp": ""
                            });
                        }
                    });
                });
            }

            if ($scope.departments.length > 0 && $scope.fields.length > 0)
                $scope.makeTable();

            if ($scope.departments.length > 0 && $scope.fields.length === 0)
                $scope.loadTable();

            if ($scope.departments.length === 0)
                $scope.tableArray = [];

            validatorSvc.clearAllValidations();

            $scope.kendo.departmentsGridInit();
        };

        $scope.deleteDept = function ()
        {
            var yes = function ()
            {
                $scope.pushDepartments(8);
            };

            $scope.modalifier.confirm(localStrings.getLocalizedString("DeleteDepartment"), yes);
        };

        $scope.pullDepartments = function ()
        {
            FuelCardsService.getCardsDepartments($scope.appInfo.webUser.CurrentAuthorization.CustKey)
                .then(function (data)
                {
                    $scope.createObjects(data);
                    $scope.resetDirtyFlags($scope.departmentForm);
                }
            );
        };

        $scope.pushDepartments = function (recStatus)
        {
            if (recStatus !== 8 && !validatorSvc.executeValidations())
                return;

            if (angular.isUndefinedOrNullOrEmpty(recStatus))
                recStatus = $scope.selDept.Key === 0 ? 4 : 16;

            $scope.selDept.Description = $scope.selDept.newDesc;

            FuelCardsService.saveCardsDepartments(
                recStatus,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                [$scope.selDept],
                null,
                $scope.selVals,
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            )
                .then(function (data)
                {
                    $scope.selDept = {};
                    $scope.selVals = [];
                    $scope.display.disableControls = true;
                    $scope.createObjects(data);
                    $scope.resetDirtyFlags($scope.departmentForm);
                }
            );

            $scope.selDept = {};
            $scope.selVals = [];
            $scope.display.disableControls = true;

        };

        $scope.addRow = function ()
        {
            $scope.display.disableControls = !$scope.canAddorSave;
            $scope.selDept = {
                CustKey: $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                Description: "",
                Key: 0,
                TimeStamp: ''
            };

            $scope.selVals = [];

            angular.forEach($scope.fields, function (item)
            {
                $scope.selVals.push({
                    CardDptFldKey: item.Key,
                    CardDptKey: 0,
                    FieldValue: '',
                    Key: 0,
                    TimeStamp: ''
                });
            });

            $scope.setFocusOnReady("#fc-dept-description");
        };

        $scope.selectRow = function (key)
        {
            validatorSvc.clearAllValidations();
            var dept = $filter('filter')($scope.departments, {Key: key}, true)[0];
            $scope.selDept = dept;
            $scope.selDept.newDesc = dept.Description;
            $scope.selVals = $scope.values.findAll({CardDptKey: dept.Key}).sortBy(function (n)
            {
                return $scope.fields.find({Key: n.CardDptFldKey}).FieldNumber;
            });
            $scope.display.disableControls = !$scope.canAddorSave;

            $scope.setFocusOnReady("#fc-dept-description");
        };

        $scope.makeTable = function ()
        {
            $scope.tableArray = [];

            //get columns into the righ torder
            $scope.cols = $filter('orderBy')($scope.fields, 'FieldNumber');

            angular.forEach($scope.departments, function (dept)
            {
                var row = {};
                row.deptKey = dept.Key;
                row.deptName = dept.Description;
                if (!angular.isUndefinedOrNullOrEmpty($scope.cols[0]))
                {
                    var cell1 = $filter('filter')($scope.values, {
                        CardDptKey: dept.Key,
                        CardDptFldKey: $scope.cols[0].Key
                    })[0];

                    if (!angular.isUndefinedOrNullOrEmpty(cell1))
                    {
                        row.cell1Key = cell1.Key;
                        row.cell1Value = cell1.FieldValue;
                        $scope.display.hideCol1 = false;
                    }
                }

                if (!angular.isUndefinedOrNullOrEmpty($scope.cols[1]))
                {
                    var cell2 = $filter('filter')($scope.values, {
                        CardDptKey: dept.Key,
                        CardDptFldKey: $scope.cols[1].Key
                    })[0];
                    if (!angular.isUndefinedOrNullOrEmpty(cell2))
                    {
                        row.cell2Key = cell2.Key;
                        row.cell2Value = cell2.FieldValue;
                        $scope.display.hideCol2 = false;
                    }
                }

                if (!angular.isUndefinedOrNullOrEmpty($scope.cols[2]))
                {
                    var cell3 = $filter('filter')($scope.values, {
                        CardDptKey: dept.Key,
                        CardDptFldKey: $scope.cols[2].Key
                    })[0];
                    if (!angular.isUndefinedOrNullOrEmpty(cell3))
                    {
                        row.cell3Key = cell3.Key;
                        row.cell3Value = cell3.FieldValue;
                        $scope.display.hideCol3 = false;
                    }
                }

                if (!angular.isUndefinedOrNullOrEmpty($scope.cols[3]))
                {
                    var cell4 = $filter('filter')($scope.values, {
                        CardDptKey: dept.Key,
                        CardDptFldKey: $scope.cols[3].Key
                    })[0];
                    if (!angular.isUndefinedOrNullOrEmpty(cell4))
                    {
                        row.cell4Key = cell4.Key;
                        row.cell4Value = cell4.FieldValue;
                        $scope.display.hideCol4 = false;
                    }
                }

                if (!angular.isUndefinedOrNullOrEmpty($scope.cols[4]))
                {
                    var cell5 = $filter('filter')($scope.values, {
                        CardDptKey: dept.Key,
                        CardDptFldKey: $scope.cols[4].Key
                    })[0];
                    if (!angular.isUndefinedOrNullOrEmpty(cell5))
                    {
                        row.cell5Key = cell5.Key;
                        row.cell5Value = cell5.FieldValue;
                        $scope.display.hideCol5 = false;
                    }
                }

                $scope.tableArray.push(row);
            });

            if (!angular.isUndefinedOrNullOrEmpty($scope.tableArray))
                $scope.tableArray = $filter('orderBy')($scope.tableArray, 'department');
        };

        $scope.loadTable = function ()
        {
            $scope.tableArray = [];
            angular.forEach($scope.departments, function (dept)
            {
                $scope.tableArray.push({deptKey: dept.Key, deptName: dept.Description});
            });
        };
    })
    .controller("departmentSetupCtrl", function ($scope, FuelCardsService, validatorSvc, $filter, WebFunctions, templates)
    {
        'use strict';

        $scope.initialize = function ()
        {

            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 40;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsCardsSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "4";

            $scope.formDisabled = $scope.checkCurrentUserPermission(WebFunctions.ViewCards) && !($scope.checkCurrentUserPermission(WebFunctions.AddNewCards) ||
                $scope.checkCurrentUserPermission(WebFunctions.EditCards));
            validatorSvc.clearValidationScopes();
            $scope.pullFields();
        };

        $scope.setFields = function ()
        {
            validatorSvc.clearAllValidations();
            $scope.fields = [{}, {}, {}, {}, {}];
            for (var index = 0; index < 5; ++index)
            {
                var match = $filter('filter')($scope.results, {FieldNumber: index + 1})[0];
                if (angular.isUndefinedOrNullOrEmpty(match))
                {
                    $scope.fields[index] = {
                        "CustKey": $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                        "FieldName": "",
                        "FieldNumber": index + 1,
                        "Key": 0,
                        "TimeStamp": ""
                    };
                }
                else
                {
                    $scope.fields[index] = match;
                }
            }

            $scope.setFocusOnReady("#fc-deptSU-content input:first");
        };

        $scope.clickCancel = function ()
        {
            $scope.setFields();
        };

        $scope.clickSave = function ()
        {
            //fire off validations
            if (!validatorSvc.executeValidations())
                return;

            //remove any empty records
            for (var i = $scope.fields.length - 1; i >= 0; i--)
            {
                if ($scope.fields[i].Key === 0 && $scope.fields[i].FieldName === '')
                    $scope.fields.splice(i, 1);
            }

            //save the fields
            $scope.pushFields();
        };

        $scope.pullFields = function ()
        {
            FuelCardsService.getCardsDepartments($scope.appInfo.webUser.CurrentAuthorization.CustKey)
                .then(function (data)
                {
                    if (data.Successful)
                    {
                        $scope.results = data.Fields || [];
                    }
                    else
                    {
                        $scope.modalifier.showSetupError();
                        $scope.results = [];
                    }
                    $scope.setFields();
                }
            );

        };

        $scope.pushFields = function ()
        {
            FuelCardsService.saveCardsDepartments(
                5,
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                null,
                $scope.fields,
                null,
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey
            )
                .then(function (data)
                {
                    if (data.Successful)
                    {
                        if (data.Successful)
                        {
                            $scope.results = data.Fields || [];
                            $scope.resetDirtyFlags($scope.deptSetupForm);
                        }
                        else
                        {
                            $scope.results = [];
                        }
                        $scope.setFields();
                    }
                }
            );
        };
    });