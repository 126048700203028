/**
 * Created by rhampton on 7/11/2014.
 */
angular.module('services.paperworkMeters', ['core.ajax'])

.service('PaperworkMetersService', function($q, apiService, globalizationSvc) {
    'use strict';

    var beginningAndEndingReadings = 1;
    var calculatedBasedOnUnitsAndPerUnitAmount = 2;

    function calculateNet(begin, end)
    {
        begin = globalizationSvc.convertStringToNumber(begin);
        end = globalizationSvc.convertStringToNumber(end);
        var diff = end - begin;

        if(parseFloat(end) < parseFloat(begin))
        {
            var max = begin.toString();
            var nMax = "";
            for(var i = 0;i< max.length; i++)
            {
                if(max[i] === ".")
                    break;
                nMax += "9";
            }
            var dMax = parseFloat(nMax) + 1;//Will wound to the next whole "10" id 99 to 100.
            diff = dMax - begin + end;
        }
        return parseFloat(diff);
    }

    return {
        loadMetersForEntry: function (paperworkBatchKey, profitcenterKey, paperworkDate) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkMeters/',
                    data: {
                        ProfitCenterKey:profitcenterKey,
                        PaperworkDate:paperworkDate,
                        PaperworkBatchKey:paperworkBatchKey

                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        netAmountCalc: function (meter) {
            if (meter.FuelPositionDollarReading !== calculatedBasedOnUnitsAndPerUnitAmount) return;
            var netUnits = globalizationSvc.convertStringToNumber(meter.NetUnits);
            var rtlPrice = globalizationSvc.convertStringToNumber(meter.RtlPrice);
            if (netUnits > 0 && rtlPrice > 0) {
                meter.NetAmount = rtlPrice * netUnits;
            }
        },
        calcPerUnit: function (meter, priceToleranceAmount, checkRetailPrice, checkAveragePrice, showRtlPrice) {
            //Check average price * compare to previous value
            var netUnits = globalizationSvc.convertStringToNumber(meter.NetUnits);
            var netAmounts = globalizationSvc.convertStringToNumber(meter.NetAmount);
            var rtlPrice = globalizationSvc.convertStringToNumber(meter.RtlPrice);
            if (netUnits > 0 && netAmounts > 0) {
                meter.CalcPrice = netAmounts / netUnits;
            } else {
                // If retail price is available then fall back to that like FIME 
                // Otherwise set the average to zero so we dont give them a bad calculation
                if (rtlPrice > 0 && showRtlPrice) {
                    meter.CalcPrice = rtlPrice;
                } else {
                    meter.CalcPrice = 0;
                }
            }
        },
        calculateNetAmountFromEnd: function (meter, index) {
            if (meter.FuelPositionDollarReading !== beginningAndEndingReadings) { return; }

            meter.NetAmount = calculateNet(meter.BeginAmount, meter.EndAmount);
        },
        calculateNetUnitsFromEnd: function (meter, index) {
            if (meter.FuelPositionUnitReading !== beginningAndEndingReadings) { return; }

            meter.NetUnits = calculateNet(meter.BeginUnits, meter.EndUnits);
        },
        calculatePageAmountTotals: function (meters) {
            var totalDollar = 0.0;
            meters.forEach((meter) => {
                var theVal = globalizationSvc.convertStringToNumber(meter.NetAmount);
                var pumpTestVal = globalizationSvc.convertStringToNumber(meter.TestAmount);
                totalDollar = totalDollar + parseFloat(theVal) - parseFloat(pumpTestVal);
            });
            return totalDollar;
        },
        calculatePageUnitTotals: function (meters) {
            var totalUnits = 0.0;
            meters.forEach((meter) => {
                var theVal = globalizationSvc.convertStringToNumber(meter.NetUnits);
                var pumpTestVal = globalizationSvc.convertStringToNumber(meter.TestUnits);
                totalUnits = totalUnits + parseFloat(theVal) - parseFloat(pumpTestVal);
            });
            return totalUnits;
        },
        updateMeters: function (meters) {
            meters.forEach((meter) => {
                meter.BeginUnits = typeof(meter.BeginUnits) === 'string' ? globalizationSvc.convertStringToNumber(meter.BeginUnits) : meter.BeginUnits;
                meter.BeginAmount = typeof(meter.BeginAmount) === 'string'? globalizationSvc.convertStringToNumber(meter.BeginAmount) : meter.BeginAmount;
                meter.TestUnits = typeof(meter.TestUnits) === 'string' ? globalizationSvc.convertStringToNumber(meter.TestUnits) : meter.TestUnits;
                meter.TestAmount = typeof(meter.TestAmount) === 'string' ? globalizationSvc.convertStringToNumber(meter.TestAmount) : meter.TestAmount;
            });
        },
        savePaperworkMeters: function(meterHeaders, meters)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SavePaperworkMeters/',
                    data: {
                        PaperworkMeterHeader: meterHeaders,
                        PaperworkMeters: meters
                    }
                }
            );
                apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                    function (data) {
                        deferred.resolve(data.Data);
                    }
                );
            return deferred.promise;
        }
    };
});