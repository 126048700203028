/**
 * Created by jvitoria on 7/12/2015.
 */
angular.module("pnDocAccount", ['ngSanitize', 'core.objects', 'core.directives'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider.when("/Documents/Account/Statements",
            {
                templateUrl: templateProvider.getTemplate("Statements"),
                controller: "statementsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("DocumentsLocalization");
                    }
                }
            }
        )
            .when("/Documents/Account/Notifications",
            {
                templateUrl: templateProvider.getTemplate("Notifications"),
                controller: "notificationsCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("DocumentsLocalization");
                    }
                }
            }
        )
            .when("/Documents/Account/Advice",
            {
                templateUrl: templateProvider.getTemplate("Advice"),
                controller: "adviceCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("DocumentsLocalization");
                    }
                }
            }
        )
        ;
    })
    .controller("docAccountSideMenuCtrl", function ($scope)
    {
        'use strict';

        $scope.initialize = function ()
        {
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;
        };
    })
    .controller("statementsSideMenuCtrl", function ($scope, localizationSvc)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("DateSelectorLocalization");        
        $scope.initialize = function ()
        {
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;

            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.maxNumOfDays = 90;
            $scope.dateSelector.options = [
                {name: localStrings.getLocalizedString("AllDays"), value: 13},
                {name: localStrings.getLocalizedString("Today"), value: 0},
                {name: localStrings.getLocalizedString("Yesterday"), value: 1},
                {name: localStrings.getLocalizedString("ThisWeek"), value: 3},
                {name: localStrings.getLocalizedString("ThisMonth"), value: 4},
                {name: localStrings.getLocalizedString("ThisYear"), value: 5},
                {name: localStrings.getLocalizedString("PriorWeek"), value: 6},
                {name: localStrings.getLocalizedString("PriorMonth"), value: 7},
                {name: localStrings.getLocalizedString("PriorYear"), value: 8},
                {name: localStrings.getLocalizedString("LastSevenDays"), value: 9},
                {name: localStrings.getLocalizedString("LastThirtyDays"), value: 10},
                {name: localStrings.getLocalizedString("SelectDates"), value: 11},
                {name: localStrings.getLocalizedString("SelectDays"), value: 12}];
        };
    })
    .controller("statementsCtrl", function ($scope, validatorSvc, WebFunctions, documentService, templates, localStrings)
    {
        'use strict';

        validatorSvc.clearValidationScopes();
        $scope.kendo = {};
        $scope.kendo.statementsGrid = {};
        $scope.kendo.statementsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var dataItem = this.dataItem(this.select()[0]);
                if (!dataItem.StmtEdocKey)
                {
                    $scope.modalifier.show(localStrings.getLocalizedString("Documents"), localStrings.getLocalizedString("SelectedDocumentNoLonger"));
                    return;
                }
                else
                    $scope.openEdoc(dataItem.StmtEdocKey);

                $scope.$apply();
            },
            columns: [
                {
                    field: "StmtDateTime",
                    title: localStrings.getLocalizedString("StatementDate"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-file-o'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.statementsGridInit = function (ds)
        {
            $scope.kendo.statementsGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.statementsGridDS.read();
            $scope.kendo.statementsGrid.dataSource.data($scope.kendo.statementsGridDS.data());
        };

        function getStatements()
        {
            documentService.getStatements(
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate).then(function (data)
                {
                    if (data.Success)
                    {
                        if (data.Data.length === 0)
                        {
                            $scope.modalifier.showNoResults(localStrings.getLocalizedString("Statements"));
                            return;
                        }

                        data.Data.each(function (stmt)
                        {
                            stmt.StmtDateTime = Date.create(stmt.StmtDateTime).format('{MM}/{dd}/{yyyy}');
                        });

                        $scope.kendo.statementsGridInit(data.Data);
                    }
                    else
                        $scope.modalifier.showSetupError();
                });
        }

        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Documents/Account/Notifications");
            }

            //set the side menu location info
            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenu = templates.getTemplate("AccountSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "1";
        };

        $scope.$parent.applyAction = function ()
        {
            if (!validatorSvc.executeValidations()) return;
            getStatements();
        };
    })
    .controller("notificationsSideMenuCtrl", function ($scope, userAccountService, localizationSvc)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("DocumentsLocalization");
        $scope.kendo = {};

        function getContactsContext()
        {
            userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data) {
                    if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.CustomerContacts)) {
                        $scope.modalifier.showSetupError();
                        return;
                    }

                    angular.forEach(data.CustomerContacts, function (item) {
                        item.fullName = item.FirstName + ' ' + item.LastName;
                    });

                    $scope.locationInfo.sideMenuData.notifications.contacts = data.CustomerContacts.findAll({ Active: -1 }).sortBy('fullName');
                    $scope.locationInfo.sideMenuData.notifications.contacts.add({Key: null, fullName: localStrings.getLocalizedString("Any")}, 0);
                });
        }

        $scope.initialize = function ()
        {

            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;
            $scope.locationInfo.sideMenuData.notifications = {
                contact: null,
                contacts: {},
                type: null,
                types: [{ key: null, value: localStrings.getLocalizedString("Any") },
                    { key: 0, value: localStrings.getLocalizedString("CreditLimit") },
                    { key: 1, value: localStrings.getLocalizedString("PrepaidBalance") },
                    { key: 2, value: localStrings.getLocalizedString("PastDuePayment") },
                    { key: 3, value: localStrings.getLocalizedString("OtherCreditCorrespondence") },
                    { key: 4, value: localStrings.getLocalizedString("ProductsServices") },
                    { key: 5, value: localStrings.getLocalizedString("GeneralBusinessCorrespondence") }]
            };
            getContactsContext();
        };
    })
    .controller("notificationsCtrl", function ($scope, validatorSvc, $sce, documentService, templates, localStrings)
    {
        'use strict';
        validatorSvc.clearValidationScopes();
        $scope.kendo = {};
        $scope.kendo.notificationsGrid = {};
        $scope.kendo.notificationsGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var dataItem = this.dataItem(this.select()[0]);
                if (!dataItem)
                {
                    $scope.modalifier.show(localStrings.getLocalizedString("Notifications"), localStrings.getLocalizedString("SelectedDocumentNoLonger"));
                    return;
                }

                $scope.handleRowClick(dataItem);
                $scope.$apply();
            },
            columns: [
                {
                    field: "NotcDescription",
                    title: localStrings.getLocalizedString("SubjectTitle"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "NotcType",
                    title: localStrings.getLocalizedString("Type"),
                    width: 200,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "RcptEmail",
                    title: localStrings.getLocalizedString("Recipient"),
                    width: 200,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "NotcDate",
                    title: localStrings.getLocalizedString("Date"),
                    width: 100,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-file-o' ng-if='dataItem.DispType !== 0'></i><i class='fa fa-envelope-o' ng-if='dataItem.DispType === 0'></i>",
                    width: 50
                }
            ],
            dataSource: [],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.notificationsGridInit = function (ds)
        {
            $scope.kendo.notificationsGridDS = new kendo.data.DataSource({
                data: ds
            });

            //$scope.resizeKendoGrid($scope.kendo.notificationsGrid, ds.length || 0, 295);

            $scope.kendo.notificationsGridDS.read();
            $scope.kendo.notificationsGrid.dataSource.data($scope.kendo.notificationsGridDS.data());
        };

        function getNotifications()
        {
            documentService.getNotifications(
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.locationInfo.sideMenuData.notifications.contact,
                $scope.locationInfo.sideMenuData.notifications.type,
                $scope.dateSelector.startDate,
                $scope.dateSelector.endDate
            ).then(function (data)
                {
                    if (data.Success)
                    {
                        if (data.Data.length === 0)
                        {
                            $scope.modalifier.showNoResults(localStrings.getLocalizedString("Notifications"));
                            return;
                        }

                        data.Data.each(function (note)
                        {
                            note.NotcDate = Date.create(note.NotcDate).format('{MM}/{dd}/{yyyy}');
                            note.EmailBody = $sce.trustAsHtml(note.EmailBody);
                            if (note.DispType === 1 || note.DispType === 3)
                            {
                                //note.NotcDescription = '';
                                note.NotcType = 'Attachment';
                                //note.RcptEmail = '';
                                //note.NotcDate = '';
                            }
                        });

                        $scope.kendo.notificationsGridInit(data.Data);
                    }
                    else
                        $scope.modalifier.showSetupError();
                });
        }

        $scope.$parent.applyAction = function () {
            if (!validatorSvc.executeValidations()) return;
            getNotifications();
        };

        $scope.handleRowClick = function handleRowClick(item)
        {
            switch (item.DispType)
            {
                case 0:
                    $scope.emailPopUp = item;
                    $scope.emailPopUp.show = true;
                    break;

                case 1:
                case 2:
                    if (item.EdocDtlKey > 0) $scope.openEdoc(item.EdocDtlKey);
                    else
                        scope.notifier.show(localStrings.getLocalizedString("DocumentNotRetrieved"), 'error');
                    break;

                case 3:
                    if (item.AttachGUID !== null) $scope.openAttachment(item.AttachGUID);
                    else
                        scope.notifier.show(localStrings.getLocalizedString("DocumentNotRetrieved"), 'error');
                    break;
            }
        };

        $scope.initialize = function ()
        {
            //set the side menu location info
            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenu = templates.getTemplate("AccountSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "2";
            $scope.emailPopUp = {show: false};
        };
    })
    .controller('adviceSideMenuCtrl', function ($scope, $filter, WebFunctions, validatorSvc, documentService, templates, localizationSvc)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("DocumentsLocalization");
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }

            validatorSvc.clearValidationScopes();

            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;
            $scope.locationInfo.sideMenu = templates.getTemplate("AccountSideMenu");

            $scope.locationInfo.sideMenuData.tenNinetyNineAdvice = {
                yearsBack: 5
            };
            $scope.exportReport = false;
        };
    })
    .controller("adviceCtrl", function ($scope, $filter, WebFunctions, templates, localStrings, validatorSvc, documentService)
    {
        'use strict';

        $scope.kendo = {};
        $scope.kendo.adviceGrid = {};
        $scope.kendo.adviceGridOptions = {
            autoBind: false,
            change: function (e)
            {
                var dataItem = this.dataItem(this.select()[0]);
                if(!dataItem.EdocDtlKey)
                {
                    $scope.modalifier.show(localStrings.getLocalizedString("Documents"), localStrings.getLocalizedString("SelectedDocumentNoLonger"));
                    return;
                }

                $scope.openEdoc(dataItem.EdocDtlKey);
                $scope.$apply();
            },
            columns: [
                {
                    field: "PmtYear",
                    title: localStrings.getLocalizedString("AdviceYear"),
                    width: 100,
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "DateTimeFormatted",
                    title: localStrings.getLocalizedString("ProcessDate"),
                    width: 150,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Name",
                    title: localStrings.getLocalizedString("BusinessEntity"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "PaymentType",
                    title: localStrings.getLocalizedString("PaymentType"),
                    width: 150,
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-file-o'></i>",
                    width: 50
                }
            ],
            dataSource: [],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.$parent.applyAction = function ()
        {
            if (!validatorSvc.executeValidations())
                return;

            $scope.predicate = 'DateTimeFormatted';
            $scope.reverse = false;
            $scope.modalifier.hide();

            documentService.Get1099AdviceList(
                $scope.appInfo.webUser.CurrentAuthorization.CustKey,
                $scope.locationInfo.sideMenuData.tenNinetyNineAdvice.yearsBack).then(
                function (data)
                {
                    if (data.Success)
                    {
                        if (data.Data.length === 0) {
                            $scope.modalifier.showNoResults(localStrings.getLocalizedString("TenNinetyNineKAdvice"));
                        }
                        else
                        {
                            data.Data.each(function (element)
                            {
                                element.DateTimeFormatted = $filter('date')(element.DateTime, 'MM/dd/yyyy');
                                element.PmtYear = element.PmtYear.toString();
                            });

                            $scope.kendo.adviceGridInit(data.Data);
                        }
                    }
                    else
                        $scope.modalifier.showSetupError();
                });
        };

        $scope.kendo.adviceGridInit = function (ds)
        {
            $scope.kendo.adviceGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.adviceGridDS.read();
            $scope.kendo.adviceGrid.dataSource.data($scope.kendo.adviceGridDS.data());
        };

        if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
        {
            $scope.goTo("/Home");
        }

        $scope.initialize = function ()
        {
            //set the side menu location info
            $scope.locationInfo.menuLocation = 6;
            $scope.locationInfo.subMenuLocation = 60;
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenu = templates.getTemplate("AccountSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "3";
        };
    })
;