/**
 * Created by rhampton on 4/25/2014.
 */
angular.module('pnHeader', ['core.enums'])
    .controller("headerMenuCtrl", function ($scope, $rootScope, WebUserSvc, $filter, $timeout)
    {
        'use strict';
        $scope.showAccounts = false;
        $scope.showUserOpt = false;
        $scope.locationInfo.menuLocation = $scope.locationInfo.menuLocation || 0;
        $scope.locationInfo.subMenuLocation = $scope.locationInfo.subMenuLocation || -1;

        $scope.setMenuLocation = function (menuLocation)
        {
            $scope.locationInfo.menuLocation = menuLocation;
            $scope.locationInfo.subMenuLocation = -1;
        };
        $scope.setSubMenuLocation = function (subMenuLocation)
        {
            $scope.locationInfo.subMenuLocation = subMenuLocation;
        };
        $scope.checkUserMenuSecurity = function (securityGroup)
        {
            $scope.checkForAppliedProfile();
            if (!$scope.appInfo.webUser) return false;
            if (!$scope.appInfo.webUser.CurrentAuthorization) return false;
            if ($scope.appInfo.webUser.CurrentAuthorization)
            {
                return $scope.checkCurrentUserPermissions(securityGroup);
            }
            return false;
        };
        $scope.setNewCurrentAuthorization = function (index)
        {
            $scope.appInfo.authorizationIndex = index;
            amplify.store('pnAuthIndex', $scope.appInfo.authorizationIndex);
            $scope.showAccounts = false;

            WebUserSvc.retrieveSelectedProfile($scope.appInfo.webUser.Id, $scope.appInfo.profileKey).then(function (data)
            {
                amplify.store('pnWebProfile', $scope.appInfo.profileKey);
                $scope.applySelectedProfile();
                $scope.appInfo.webUser.CurrentAuthorization = $scope.appInfo.webUser.WebAuthorizations[$scope.appInfo.authorizationIndex];
                $rootScope.$broadcast('event:AccountChange', {});
                $scope.goTo('/Home');
            });
        };

        $scope.showAccountList = function ()
        {
            $scope.showUserOpt = false;
            $scope.showAccounts = !$scope.showAccounts;
        };

        $scope.editUser = function ()
        {
            $scope.showUserOpt = false;
            $scope.goTo('/Account/Profile/YourProfile');
        };

        $scope.changePassword = function ()
        {
            $scope.showUserOpt = false;
            $scope.goTo('/Account/Profile/ResetPassword');
        };

        $scope.showUserOptions = function ()
        {
            $scope.showAccounts = false;
            $scope.showUserOpt = !$scope.showUserOpt;
        };

        $scope.headerTimer = {};
        $scope.subMenuWaiter = function (menuKey)
        {
            var barWidth = document.getElementById("hdr-submenu").clientWidth;
            var acctWidth = document.getElementById("hdr-acctInfo").clientWidth;
            document.getElementById("subMenu" + menuKey).style.width = (barWidth - acctWidth - 30) + 'px';
            document.getElementById("subMenu" + menuKey).style.overflowX = "hidden";

            $scope.startIndex = 0;
            $scope.endIndex = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SubMenu, {ParentKey: menuKey}).length - 1;
            $scope.menuLength = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SubMenu, {ParentKey: menuKey}).length - 1;

            $scope.headerTimer = $timeout(function ()
            {
                $scope.setMenuVisibility(menuKey);
            }, 100);
        };

        $scope.setMenuVisibility = function (menuKey)
        {
            var subMenu = $filter("filter")($scope.appInfo.webUser.CurrentAuthorization.SiteLayout.SubMenu, {ParentKey: menuKey});
            var barWidth = document.getElementById("hdr-submenu").clientWidth;
            var acctWidth = document.getElementById("hdr-acctInfo").clientWidth;
            var maxMenuWidth = barWidth - acctWidth - 80;
            var currentMenuWidth = 0;
            for (var x = 0; x < subMenu.length; x++)
            {
                var menuItem = subMenu[x];
                menuItem.visible = true;
                $scope.$apply();
                if (x < $scope.startIndex)
                {
                    menuItem.visible = false;
                    continue;
                }
                currentMenuWidth += document.getElementById(menuItem.Key).clientWidth + 50;
                menuItem.visible = currentMenuWidth < maxMenuWidth;
                if (menuItem.visible)
                    $scope.endIndex = x;
            }
        };
        $scope.moveMenuLeft = function (menuKey)
        {
            if ($scope.startIndex === 0)
                return;
            $scope.startIndex -= 1;
            $scope.setMenuVisibility(menuKey);
        };
        $scope.moveMenuRight = function (menuKey)
        {
            if ($scope.startIndex === $scope.menuLength)
                return;
            $scope.startIndex += 1;
            $scope.setMenuVisibility(menuKey);
        };
    });