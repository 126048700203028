/**
 * Created by mmagic on 06/02/2022.
 */
/* ==============================================================================

 SERVICES.PaperworkReports MODULE

 Defines the module namespace for the paperwork reports services

 ============================================================================= */
angular.module('services.paperworkReports', ['core.ajax'])

/* ==============================================================================

 Paperwork Reports SERVICE

 Contains services that retrieving paperwork reports.

 ============================================================================= */
.service('PaperworkReportsService', function($q, apiService) {
    'use strict';

    return {
        getPaperworkReports: function (customerContactKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkReports/',
                    data: {
                        CustomerContactKey: customerContactKey
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },

        getPaperworkReportSites: function (reportName, customerContactKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkReportSites/',
                    data: {
                        ReportName: reportName,
                        CustomerContactKey: customerContactKey
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});