/**
 * Created by rhampton on 2/17/2015.
 */
angular.module('core.constants', []).factory('constantLists', function(localizationSvc){
    var secretQuestions = [];
    var phoneTypes = [];

    var getSecretQuestions = function (localStrings) {
        if (angular.isUndefinedOrNullOrEmpty(localStrings)) {
            localStrings = localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
        }

        if (secretQuestions.length === 0) {
            secretQuestions[0] = localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("SecretQuestion1"));
            secretQuestions[1] = localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("SecretQuestion2"));
            secretQuestions[2] = localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("SecretQuestion3"));
            secretQuestions[3] = localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("SecretQuestion4"));
            secretQuestions[4] = localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("SecretQuestion5"));
            secretQuestions[5] = localStrings.stringFromCharacterEntity(localStrings.getLocalizedString("SecretQuestion6"));
        }

        return secretQuestions;
    };

    var getPhoneTypes = function (localStrings) {
        if (angular.isUndefinedOrNullOrEmpty(localStrings)) {
            localStrings = localizationSvc.getCachedLocalizationsContainer("AccountProfileLocalization");
        }

        if (phoneTypes.length === 0) {
            phoneTypes[0] = { key: 4, value: localStrings.getLocalizedString("Unknown") };
            phoneTypes[1] = { key: 1, value: localStrings.getLocalizedString("Home") };
            phoneTypes[2] = { key: 2, value: localStrings.getLocalizedString("Mobile") };
            phoneTypes[3] = { key: 3, value: localStrings.getLocalizedString("Other") };
            phoneTypes[4] = { key: 0, value: localStrings.getLocalizedString("Work") };
        }

        return phoneTypes;
    };
    return {
        secretQuestions: getSecretQuestions,
        phoneTypes: getPhoneTypes
    };
});
