/**
 * Created by rhampton on 7/25/2014.
 */
angular.module("pnOrders", ['core.objects', 'services.orders', 'core.directives'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/Orders/EditOrder/:ordKey?",
            {
                templateUrl: templateProvider.getTemplate("EditOrder"),
                controller: "editOrderCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
                    }
                }
            }
        )
            .when("/Orders/DisplayOrder/:ordKey?",
            {
                templateUrl: templateProvider.getTemplate("DisplayOrder"),
                controller: "displayOrderCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
                    }
                }
            }
        )
            .when("/Orders/OpenOrders",
            {
                templateUrl: templateProvider.getTemplate("OpenOrders"),
                controller: "openOrdersCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
                    }
                }
            }
        )
            .when("/Orders/OrderHistory",
            {
                templateUrl: templateProvider.getTemplate("OrderHistory"),
                controller: "orderHistoryCtrl"
            }
        );
    })
    .controller("ordersSideMenuCtrl", function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {
            $scope.checkForAppliedProfile();
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 20;
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.canOpenAddOrders = $scope.appInfo.webUser.CurrentAuthorization.CanAddEditWebOrders;
        };
    })
    .controller("displayOrderCtrl", function ($scope, $filter, $route, $routeParams, validatorSvc, WebProfileService, customerService,
                                                    userAccountService, ordersService, templates, localStrings, globalizationSvc)
    {
        'use strict';

        function hasErrors(errors)
        {
            var errorsDisplayed = false;
            angular.forEach(errors, function (item)
            {
                $scope.notifier.show(item, 'error');
                errorsDisplayed = true;
            });

            return errorsDisplayed;
        }

        function getOrderContext()
        {
            // Do not try to load an order if the user info isnt loaded
            if (!$scope.appInfo.userLoaded) {
                $scope.goTo('Orders/OpenOrders/');
                return;
            }

            ordersService.getOrdersContext($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.WOGSettings))
                    {
                        $scope.modalifier.showSetupError();
                        $scope.displayOrders.show.buttonBar = false;
                        return;
                    }

                    $scope.displayOrders.context.WOGSettings = data.WOGSettings[0] || [];
                    $scope.displayOrders.context.PONumber = data.PONumber;
                    $scope.displayOrders.context.DeliveryDT = data.DeliveryDT;

                    setButtonVisiblity();
                }
            );
        }

        function handleCancelOrder()
        {
            $scope.displayOrders.show.cancelCommentModal = false;
            ordersService.saveOrderDetails($scope.displayOrders.orderDetails).then(
                function (data)
                {
                    if (hasErrors(data.OrderDetails.Errors))
                    {
                        enableButton();
                        return;
                    }

                    $scope.notifier.show(localStrings.getLocalizedString("SavedChanges"), "success");
                    $scope.goTo('Orders/OpenOrders/');
                });
        }

        function handlePendingOrder()
        {
            ordersService.saveOrderDetails($scope.displayOrders.orderDetails).then(
                function (data)
                {
                    //notify and drop out on errors
                    if (angular.isUndefinedOrNullOrEmpty(data.OrderDetails.OrdKey) || data.OrderDetails.OrdKey === 0)
                    {
                        $scope.notifier.show(localStrings.getLocalizedString("ChangesNotSaved"), "error");
                        enableButton();
                        return;
                    }

                    if (hasErrors(data.OrderDetails.Errors))
                    {
                        enableButton();
                        return;
                    }

                    //notify and handle success
                    $scope.notifier.show(localStrings.getLocalizedString("SavedChanges"), "success");
                    $scope.goTo('Orders/EditOrder/' + data.OrderDetails.OrdKey);
                });
        }

        function handleReOpenOrder()
        {
            var yes = function ()
            {
                ordersService.saveOrderDetails($scope.displayOrders.orderDetails).then(
                    function (data)
                    {
                        if (hasErrors(data.OrderDetails.Errors))
                        {
                            enableButton();
                            return;
                        }

                        $scope.goTo('Orders/EditOrder/' + data.OrderDetails.OrdKey);
                    });
            };

            var no = function () {
                enableButton();
            };

            $scope.modalifier.confirm(localStrings.getLocalizedString("IfYouReopenOrder") + '<br/>' + localStrings.getLocalizedString("MayNoLonger") + '<br/><br/>' + localStrings.getLocalizedString("WishToContinue"), yes, no);
        }

        function handleSubmitOrder()
        {
            ordersService.saveOrderDetails($scope.displayOrders.orderDetails).then(
                function (data)
                {
                    if (hasErrors(data.OrderDetails.Errors))
                    {
                        enableButton();
                        return;
                    }

                    //notify and handle success
                    $scope.notifier.show(localStrings.getLocalizedString("ChangesBeenSubmitted"), "success");
                    $route.reload();
                });
        }

        function enableButton() {
            $('#displayOrderReOpen').removeAttr('disabled');
            $('#displayOrderSubmit').removeAttr('disabled');
            $('#displayOrderCancel').removeAttr('disabled');
            $('#displayOrderCancelComment').removeAttr('disabled');
        }

        function setButtonVisiblity()
        {
            // show buttons based ont he order status
            switch ($scope.displayOrders.orderDetails.OrdStatusKey)
            {
                case 16:
                    $scope.displayOrders.show.reopenBtn = false;
                    $scope.displayOrders.show.submitBtn = submitOnHold();
                    $scope.displayOrders.show.cancelBtn = true;
                    break;

                case 0:
                case 1:
                    $scope.displayOrders.show.reopenBtn = true;
                    $scope.displayOrders.show.submitBtn = false;
                    $scope.displayOrders.show.cancelBtn = true;
                    break;

                case 10:
                    $scope.displayOrders.show.reopenBtn = false;
                    $scope.displayOrders.show.submitBtn = false;
                    $scope.displayOrders.show.cancelBtn = true;
                    break;

                default:
                    $scope.displayOrders.show.reopenBtn = false;
                    $scope.displayOrders.show.submitBtn = false;
                    $scope.displayOrders.show.cancelBtn = false;
                    break;
            }
        }

        function submitOnHold()
        {
            if (angular.isUndefinedOrNullOrEmpty($scope.displayOrders.context.WOGSettings))
                return false;
            else
                return $scope.displayOrders.context.WOGSettings.SubmitOnHold || $scope.displayOrders.context.WOGSettings.CrStatus !== 1;
        }

        $scope.initialize = function ()
        {
            //clear any residual validations from the scope
            validatorSvc.clearValidationScopes();

            //set the side menu location info
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.addOrder = $scope.locationInfo.sideMenuData.addOrder || {};
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 20;
            $scope.locationInfo.sideMenu = templates.getTemplate("OrdersSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "2";

            //start with a clean data structure
            $scope.displayOrders = {
                show: {
                    reopenBtn: false,
                    submitBtn: false,
                    cancelBtn: false,
                    tankIDCol: false,
                    cancelCommentModal: false
                },
                context: {},
                orderDetails: ordersService.blankOrderHeaderObj()
            };

            $scope.globalizationSvc = globalizationSvc;

            //get the credit hold message
            WebProfileService.getWebProfileMessage($scope.appInfo.profileKey, 25).then(function (data)
            {
                $scope.displayOrders.context.creditHoldMessage = data;
            });

            getOrderContext();

            //get the order to be displayed
            if ($routeParams.ordKey)
            {
                ordersService.getOrderDetails($routeParams.ordKey).then(
                    function (data)
                    {
                        $scope.displayOrders.orderDetails = data.OrderDetails || {};

                        //get the tank/product context for this location
                        $scope.getLocationDetails();

                        setButtonVisiblity();
                    }
                );
            }
        };

        $scope.getLocationDetails = function getLocationDetails()
        {
            $scope.displayOrders.context.tanksAndEquipment = [];
            ordersService.getOrderLocationInfo($scope.displayOrders.orderDetails.CustLocKey, $scope.displayOrders.orderDetails.ReqDelivDateTime).then(
                function (data)
                {
                    if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.TanksAndEquipment))
                    {
                        $scope.notifier.show(localStrings.getLocalizedString("NoTankInformation"), 'error');
                        return;
                    }
                    else
                    {
                        $scope.displayOrders.context.tanksAndEquipment = data.TanksAndEquipment;
                    }

                    //use default directions if the order has none
                    if (angular.isUndefinedOrNullOrEmpty($scope.displayOrders.orderDetails.OrdDelivDirections))
                        $scope.displayOrders.orderDetails.OrdDelivDirections = data.DeliveryDirections;

                    //determine if TankID column is shown
                    $scope.displayOrders.show.tankIDCol = false;
                    angular.forEach($scope.displayOrders.context.tanksAndEquipment, function (item)
                    {
                        // check if ID is null or undefined first
                        if (item.ID) {
                            $scope.displayOrders.show.tankIDCol = !item.ID.isBlank();
                        }

                        //match and copy over the qty to be displayed
                        if (!angular.isUndefinedOrNullOrEmpty(item.TankNumber)) {
                            var tank = $filter('filter')($scope.displayOrders.orderDetails.OrderDetails, { TankNumber: item.TankNumber }, true)[0];
                            item.Quantity = angular.isUndefinedOrNullOrEmpty(tank) ? 0 : globalizationSvc.formatNumeric(tank.OrderQty);
                        }

                        if (!angular.isUndefinedOrNullOrEmpty(item.EquipmentKey)) {
                            var equipment = $filter('filter')($scope.displayOrders.orderDetails.OrderDetails, { EquipmentKey: item.EquipmentKey }, true)[0];
                            item.Quantity = angular.isUndefinedOrNullOrEmpty(equipment) ? 0 : globalizationSvc.formatNumeric(equipment.OrderQty);
                        }
                        
                    });
                }
            );
        };

        $scope.hasAnyTanks = function () {
            return hasAnyItems('TankNumber');
        };

        $scope.hasAnyEquipment = function () {
            return hasAnyItems('EquipmentKey');
        };

        function hasAnyItems(keyProperty) {
            if ($scope.displayOrders.context && $scope.displayOrders.context.tanksAndEquipment) {
                for (var i = 0; i < $scope.displayOrders.context.tanksAndEquipment.length; i++) {
                    if ($scope.displayOrders.context.tanksAndEquipment[i][keyProperty]) {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.saveOrder = function saveOrder(command)
        {
            $scope.displayOrders.orderDetails.Command = command;
            $scope.displayOrders.orderDetails.OrdCreatedByContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;

            switch (command)
            {
                case 'Pending':
                    handlePendingOrder();
                    break;

                case 'Submit':
                    handleSubmitOrder();
                    break;

                case 'ReOpen':
                    handleReOpenOrder();
                    break;

                case 'Cancel':
                    handleCancelOrder();
                    break;
            }
        };

        $scope.cancelCommentModalDialogClose = function () {
            enableButton();
        };

    })
    .controller("editOrderCtrl", function ($scope, $filter, $route, $routeParams, validatorSvc, WebProfileService, customerService, userAccountService, ordersService, templates, localStrings, globalizationSvc, $timeout)
    {
        'use strict';

        $scope.editOrders = {
            show: {
                buttonBar: true,
                shipTo: false,
                tankIDCol: false,
                submitBtn: false,
                cancelCommentModal: false
            },
            locations: undefined,
            contacts: undefined,
            context: {},
            orderDetails: {}
        };

        $scope.prevCustLocKey = undefined;
        $scope.hideTankAndEquipment = false;

        $scope.kendo = {};
        $scope.kendo.ordersGrid = {};
        $scope.kendo.ordersGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "TankNumber",
                    title: localStrings.getLocalizedString("Tank"),
                    attributes: {class: "alignCenter"},
                    width: 50,
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "TankID",
                    title: localStrings.getLocalizedString("ID"),
                    width: 100,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Product",
                    title: localStrings.getLocalizedString("Product"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "OrderQty",
                    title: localStrings.getLocalizedString("Quantity"),
                    width: 100,
                    format: "{0:n0}",
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    },
                    template: '<label>#= kendo.toString(OrderQty, "n0", "' + globalizationSvc.getLocale() + '")#</label>',
                    editor: function (element, options)
                    {
                        $("<input/>")
                            .attr("name", options.field)
                            .attr("data-bind", "value:" + options.field)
                            .attr("class", "alignRight")
                            .appendTo(element)
                            .kendoNumericTextBox({
                                decimals: 0,
                                format: "#",
                                min: 0,
                                spinners: false
                            });

                        $('<span></span>')
                            .attr("data-for", options.field)
                            .attr("class", "k-invalid-msg")
                            .appendTo(element);
                    }
                }
            ],
            dataSource: [],
            editable: true,
            filterable: false,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };
        $scope.kendo.ordersEquipmentGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "EquipmentID",
                    title: localStrings.getLocalizedString("Equipment"),
                    width: 200,
                    attributes: { class: "alignLeft" },
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    },
                    template: "# if (EquipmentID) { # #:EquipmentID# - #:EquipmentDescription# # } # "
                },
                {
                    field: "Product",
                    title: localStrings.getLocalizedString("Product"),
                    attributes: { class: "alignLeft" },
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "OrderQty",
                    title: localStrings.getLocalizedString("Quantity"),
                    width: 100,
                    format: "{0:n0}",
                    attributes: { class: "alignRight" },
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    },
                    editor: function (element, options) {
                        $("<input/>")
                            .attr("name", options.field)
                            .attr("data-bind", "value:" + options.field)
                            .attr("class", "alignRight")
                            .appendTo(element)
                            .kendoNumericTextBox({
                                decimals: 0,
                                format: "#",
                                min: 0,
                                spinners: false
                            });

                        $('<span></span>')
                            .attr("data-for", options.field)
                            .attr("class", "k-invalid-msg")
                            .appendTo(element);
                    }
                }
            ],
            dataSource: [],
            editable: true,
            filterable: false,
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.ordersGridInit = function (ds, showIdCol)
        {
            $scope.kendo.ordersGridDS = new kendo.data.DataSource({
                data: ds,
                schema: {
                    model: {
                        id: "TankNumber",
                        fields: {
                            TankNumber: {
                                type: "number",
                                editable: false,
                                nullable: false,
                                validation: false
                            },
                            TankID: {
                                type: "string",
                                editable: true,
                                nullable: true,
                                validation: false
                            },
                            Product: {
                                type: "string",
                                editable: false,
                                nullable: false,
                                validation: false
                            },
                            OrderQty: {
                                type: "number",
                                editable: true,
                                nullable: false,
                                validation: {
                                    capacity: function (e)
                                    {
                                        if(e.is('[name=OrderQty]'))
                                        {
                                            var rowData = $scope.kendo.ordersGrid.dataItem(e.closest('tr'));
                                            var val = e.getKendoNumericTextBox().value();

                                            rowData.OrderQty = val; //stop kendo from zeroing out the value on error

                                            if (e.val().isBlank())
                                            {
                                                e.attr("data-capacity-msg", localStrings.getLocalizedString("Required"));
                                                return false;
                                            }

                                            if (val < 0)
                                            {
                                                e.attr("data-capacity-msg", localStrings.getLocalizedString("MinValueZero"));
                                                return false;
                                            }

                                            if (val > rowData.MaxVolume)
                                            {
                                                var rowDataMaxVolume = [rowData.MaxVolume];
                                                e.attr("data-capacity-msg", localStrings.getLocalizedString("MaxValue", rowDataMaxVolume));
                                                return false;
                                            }
                                        }
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            });

            $scope.kendo.ordersGridDS.read();
            $scope.kendo.ordersGrid.dataSource.data($scope.kendo.ordersGridDS.data());

            if (showIdCol)
                $scope.kendo.ordersGrid.showColumn(1);
            else
                $scope.kendo.ordersGrid.hideColumn(1);
        };
        $scope.kendo.ordersEquipmentGridInit = function (ds) {
            $scope.kendo.ordersEquipmentGridDS = new kendo.data.DataSource({
                data: ds,
                schema: {
                    model: {
                        id: "EquipmentKey",
                        fields: {
                            EquipmentKey: {
                                type: "number",
                                editable: false
                            },
                            EquipmentID: {
                                type: "string",
                                editable: false
                            },
                            EquipmentDescription: {
                                type: "string",
                                editable: false
                            },
                            Product: {
                                type: "string",
                                editable: false
                            },
                            OrderQty: {
                                type: "number",
                                editable: true,
                                nullable: false,
                                validation: {
                                    capacity: function (e) {
                                        if (e.is('[name=OrderQty]')) {
                                            var rowData = $scope.kendo.ordersEquipmentGrid.dataItem(e.closest('tr'));
                                            var val = e.getKendoNumericTextBox().value();

                                            rowData.OrderQty = val; //stop kendo from zeroing out the value on error

                                            if (e.val().isBlank()) {
                                                e.attr("data-capacity-msg", localStrings.getLocalizedString("Required"));
                                                return false;
                                            }

                                            if (val < 0) {
                                                e.attr("data-capacity-msg", localStrings.getLocalizedString("MinValueZero"));
                                                return false;
                                            }

                                            // If equipment does not have ActualSize or RatedSize (MaxVolume) -> we won't be validating MaxVolume
                                            if (!angular.isUndefinedOrNullOrEmpty(rowData.MaxVolume)) {
                                                if (val > rowData.MaxVolume) {
                                                    var rowDataMaxVolume = [rowData.MaxVolume];
                                                    e.attr("data-capacity-msg", localStrings.getLocalizedString("MaxValue", rowDataMaxVolume));
                                                    return false;
                                                }
                                            }
                                        }
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            });

            $scope.kendo.ordersEquipmentGridDS.read();
            $scope.kendo.ordersEquipmentGrid.dataSource.data($scope.kendo.ordersEquipmentGridDS.data());
        };

        var validateOrderDate =
        {
            name: 'validateOrderDate',
            validationMessage: localStrings.getLocalizedString("RequestedDeliveryDateTimeIs") + '<br/>' + localStrings.getLocalizedString("NotAvailable"),
            validatorError: function ()
            {
                if ($scope.editOrders.orderDetails.Command === 'Cancel')
                {
                    return true;
                }

                if ($scope.editOrders.orderDetails.ReqDelivDateTime.isBlank()) {
                    return false;
                }

                var valueDate = new Date($scope.editOrders.orderDetails.ReqDelivDateTime);
                var nowDate = new Date();

                return valueDate < nowDate ? false : true;
            }
        };
        var savedTankData = [], dataBetweenDateChanges = [];
        $scope.validateOrderDate = new pdi.ng.validate.PdiValidation(validateOrderDate);

        function hasErrors(errors)
        {
            var errorsDisplayed = false;
            angular.forEach(errors, function (item)
            {
                $scope.notifier.show(item, 'error');
                errorsDisplayed = true;
            });

            return errorsDisplayed;
        }

        function getContactsContext()
        {
            userAccountService.listContacts($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.CustomerContacts))
                    {
                        $scope.modalifier.showSetupError();
                        $scope.editOrders.show.buttonBar = false;
                        return;
                    }

                    angular.forEach(data.CustomerContacts, function (item)
                    {
                        item.fullName = item.FirstName + ' ' + item.LastName;
                    });

                    $scope.editOrders.contacts = data.CustomerContacts.findAll({Active: -1}).sortBy('fullName');

                });
        }

        function getCreditHoldMsg()
        {
            WebProfileService.getWebProfileMessage($scope.appInfo.profileKey, 25).then(function (data)
            {
                $scope.editOrders.context.creditHoldMessage = data;
            });
        }

        function getLocationsContext()
        {
            ordersService.getOrderCustomerLocations($scope.appInfo.webUser.CurrentAuthorization.CustKey, $scope.editOrders.orderDetails.ReqDelivDateTime || $scope.editOrders.context.MinDateTime).then(
                function (data) {
                    if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.OrderLocations)) {
                        $scope.modalifier.showSetupError();
                        $scope.editOrders.show.buttonBar = false;
                        return;
                    }

                    $scope.editOrders.locations = data.OrderLocations.remove({ Active: 0 }).sortBy('Description');

                    if ($scope.editOrders.locations.length > 1)
                        $scope.editOrders.show.shipTo = true;
                    else {
                        $scope.editOrders.show.shipTo = false;
                    }

                    if (angular.isUndefinedOrNullOrEmpty($routeParams.ordKey)) {
                        // in case of a new order, remove all locations without valid tanks / equipment
                        $scope.editOrders.locations = $scope.editOrders.locations.remove({ HasValidTankOrEquipment: 0 });
                        $scope.editOrders.orderDetails.CustLocKey = $scope.editOrders.locations[0].Key;
                        $scope.updateCustomerLocationData();
                    } else {
                        // in case of an existing order, remove all locations without valid tanks / equipment except location on order
                        $scope.editOrders.locations = $scope.editOrders.locations.filter(function (l) {
                            return l.HasValidTankOrEquipment !== 0 || l.Key === $scope.editOrders.orderDetails.CustLocKey;
                        });
                        $scope.editOrders.locations.sort(function (l) {
                            return l.CustLocKey - (l.CustLocKey === $scope.editOrders.orderDetails.CustLocKey ? l.Key + 1 : l.CustLocKey - 2);
                        });
                    }
                });
        }

        function getOrderContext()
        {
            ordersService.getOrdersContext($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.WOGSettings))
                    {
                        $scope.modalifier.showSetupError();
                        $scope.editOrders.show.buttonBar = false;
                        return;
                    }

                    $scope.editOrders.context.WOGSettings = data.WOGSettings[0] || [];
                    $scope.editOrders.context.PONumber = data.PONumber;
                    $scope.editOrders.context.DeliveryDT = data.DeliveryDT;

                    $scope.editOrders.show.submitBtn = data.WOGSettings.SubmitOnHold || data.WOGSettings.CrStatus !== 1;
                    getLocationsContext();
                }
            );
        }

        function loadExistingOrder()
        {
            ordersService.getOrderDetails($routeParams.ordKey).then(
                function (data)
                {
                    savedTankData = data.OrderDetails.OrderDetails.clone();
                    data.OrderDetails.OrderDetails = [];
                    $scope.editOrders.orderDetails = data.OrderDetails;
                    $scope.editOrders.context.DeliveryDT = data.OrderDetails.ReqDelivDateTime;
                    $scope.editOrders.show.cancelBtn = true;

                    $scope.editOrders.orderDetails.CustContKey = $scope.editOrders.orderDetails.CustContKey.toNumber();
                    $scope.prevCustLocKey = $scope.initialOrderCustLocKey = $scope.editOrders.orderDetails.CustLocKey;
                    $scope.updateCustomerLocationData();
                }
            );
        }

        function loadNewOrder()
        {
            $scope.editOrders.orderDetails = new ordersService.blankOrderHeaderObj();

            $scope.editOrders.orderDetails.CustContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;

        }
        Number.isInteger = Number.isInteger || function (value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };
        function qtyValidityStates(items) {
            return items.map(
                function (order) {
                    if (!Number.isInteger(order.OrderQty)) {
                        return false;
                    }

                    if (order.OrderQty < 0) {
                        return false;
                    }

                    if (!angular.isUndefinedOrNullOrEmpty(order.MaxVolume) && order.OrderQty > order.MaxVolume) {
                        return false;
                    }
                    return true;
                }
            );
        }

        // This returns an array of boolean values
        // use .every to see if all the values are true or false
        function validOrderQtys(items) {
            return (qtyValidityStates(items).every(Boolean));
        }

        function validOrder()
        {
            $scope.orderQtyWarning = {
                show: false,
                text: ''
            };

            var orderTotal = ($scope.kendo.ordersGrid.dataSource.data().toJSON().sum('OrderQty') || 0) + ($scope.kendo.ordersEquipmentGrid.dataSource.data().toJSON().sum('OrderQty') || 0);
            var OrdQtyParams = [$scope.editOrders.context.WOGSettings.OrdMinQty, $scope.editOrders.context.WOGSettings.OrdMaxQty];

            if (!validOrderQtys($scope.kendo.ordersGrid.dataSource.data()) || !validOrderQtys($scope.kendo.ordersEquipmentGrid.dataSource.data())) {
                $scope.orderQtyWarning = {
                    show: true,
                    text: localStrings.getLocalizedString("InvalidTankQuantity")
                };
            }

            if (orderTotal < $scope.editOrders.context.WOGSettings.OrdMinQty)
            {
                $scope.orderQtyWarning = {
                    show: true,
                    text: localStrings.getLocalizedString("DoesNotMeetMinimum", OrdQtyParams)
                };
            }

            if (orderTotal > $scope.editOrders.context.WOGSettings.OrdMaxQty)
            {
                $scope.orderQtyWarning = {
                    show: true,
                    text: localStrings.getLocalizedString("OrderExceedsMaximum", OrdQtyParams)
                };
            }

            return validatorSvc.executeValidations() && !$scope.orderQtyWarning.show;
        }

        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            $scope.checkForAppliedProfile();

            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.addOrder = $scope.locationInfo.sideMenuData.addOrder || {};
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 20;
            $scope.locationInfo.sideMenu = templates.getTemplate("OrdersSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "2";

            getContactsContext();
            getOrderContext();
            getCreditHoldMsg();

            if ($routeParams.ordKey) {
                loadExistingOrder();
            }
            else {
                loadNewOrder();
            }
        };

        $scope.saveOrder = function saveOrder(command) {
            // If command is submit we need to save the details before we submit them.
            if (command === 'Submit') {
                $scope.editOrders.orderDetails.Command = 'Pending';
            } else {
                $scope.editOrders.orderDetails.Command = command;
            }

            if (!validOrder()) {
                enableButton();
                return;
            }

            $scope.resetDirtyFlags($scope.editOrders.editForm);

            $scope.editOrders.orderDetails.OrdCreatedByContKey = $scope.appInfo.webUser.CurrentAuthorization.CustContKey;
            $scope.editOrders.orderDetails.OrderDetails = $scope.kendo.ordersGrid.dataSource.data().toJSON().concat($scope.kendo.ordersEquipmentGrid.dataSource.data().toJSON());
            ordersService.saveOrderDetails($scope.editOrders.orderDetails).then(
                function (data) {
                    //notify and drop out on errors
                    if (angular.isUndefinedOrNullOrEmpty(data.OrderDetails.OrdKey) || data.OrderDetails.OrdKey === 0) {
                        $scope.notifier.show(localStrings.getLocalizedString("ChangesNotSaved"), "error");
                        hasErrors(data.OrderDetails.Errors);
                        enableButton();
                        return;
                    }

                    if (hasErrors(data.OrderDetails.Errors)) {
                        enableButton();
                        return;
                    }

                    if ($scope.editOrders.orderDetails.Command !== command) {
                        data.OrderDetails.Command = command;

                        ordersService.saveOrderDetails(data.OrderDetails).then(
                            function (submit) {
                                //notify and drop out on errors
                                if (angular.isUndefinedOrNullOrEmpty(submit.OrderDetails.OrdKey) || submit.OrderDetails.OrdKey === 0) {
                                    $scope.notifier.show(localStrings.getLocalizedString("ChangesNotSaved"), "error");
                                    hasErrors(submit.OrderDetails.Errors);
                                    enableButton();
                                    return;
                                }

                                if (hasErrors(submit.OrderDetails.Errors)) {
                                    enableButton();
                                    return;
                                }
                            });
                    }

                    //notify and handle success
                    switch (command) {
                        case 'Pending':
                            $scope.notifier.show(localStrings.getLocalizedString("SavedChanges"), "success");
                            $scope.goTo('Orders/EditOrder/' + data.OrderDetails.OrdKey);
                            loadExistingOrder();
                            enableButton();
                            break;

                        case 'Submit':
                            $scope.notifier.show(localStrings.getLocalizedString("ChangesBeenSubmitted"), "success");
                            $scope.goTo('Orders/DisplayOrder/' + data.OrderDetails.OrdKey);
                            break;

                        case 'Cancel':
                            $scope.notifier.show(localStrings.getLocalizedString("SavedChanges"), "success");
                            $scope.goTo('Orders/OpenOrders/');
                            break;
                    }
                }
            );
        };

        $scope.cancelCommentModalDialogClose = function () {
            enableButton();
        };

        function enableButton() {
            $('#editOrderPending').removeAttr('disabled');
            $('#editOrderSubmit').removeAttr('disabled');
            $('#editOrderCancel').removeAttr('disabled');
            $('#editOrderCancelComment').removeAttr('disabled');
        }

        function mergeTankData(target, source)
        {
            //filter out records with non-existent tank / equipment
            target = target.filter(function (t) {
                for (var i = 0; i < source.length; i++) {
                    if ((t.TankNumber && source[i].TankNumber === t.TankNumber) || (t.EquipmentKey && source[i].EquipmentKey === t.EquipmentKey)) {
                        return true;
                    }
                }
                if (t.TankNumber && $scope.initialOrderCustLocKey === $scope.editOrders.orderDetails.CustLocKey) {
                    $scope.notifier.show("Tank #" + t.TankNumber + " is no longer valid for this customer's location and will be removed from the order. Please click save or resubmit the order to proceed", 'warning');
                }
                return false;
            });
            //iterate through each item(s) in the source array
            source.each(function (s)
            {
                var t = null;
                if (s.EquipmentKey) {
                    t = target.find({ EquipmentKey: s.EquipmentKey });
                    if (!t) {
                        t = {};
                        target.add(t);
                    }
                } else {
                    //look for a matching item in the target
                    t = target.find({TankNumber: s.TankNumber});

                    //not matching item so lets make one
                    if (!t)
                        t = target
                            .add(new ordersService.blankOrderDetailsObj())
                            .last();
                }

                //if the source property value is empty, copy over the target value
                for (var p in s)
                {
                    if (angular.isUndefinedOrNullOrEmpty(t[p]))
                        t[p] = s[p];
                }

                //set null quantity to zero
                if (angular.isUndefinedOrNullOrEmpty(t['OrderQty'])) t['OrderQty'] = 0;
            });

            $scope.editOrders.orderDetails.OrderDetails = target.filter(function (t) { return t.TankNumber; }).sortBy('TankNumber');
            $scope.editOrders.orderDetails.OrderEquipmentDetails = target.filter(function (t) { return t.EquipmentKey; }).sortBy('EquipmentID');
            savedTankData = [];
        }

        function copyTankData(source)
        {
            var finalItems = [];
            //iterate through each item (s) in the source array
            source.each(function (s)
            {
                var existing = dataBetweenDateChanges.find({ CustFuelTankKey: s.CustFuelTankKey });

                if (existing) {
                    finalItems.push(existing);
                } else {
                    //set null quantity to zero
                    if (angular.isUndefinedOrNullOrEmpty(s['OrderQty'])) s['OrderQty'] = 0;
                    finalItems.push(s);
                }
            });

            $scope.editOrders.orderDetails.OrderDetails = finalItems.sortBy('TankNumber');
        }
        function copyEquipmentData(source) {
            var finalItems = [];
            source.each(function (s) {
                var existing = dataBetweenDateChanges.find({ EquipmentKey: s.EquipmentKey });
                if (existing) {
                    finalItems.push(existing);
                } else {
                    //set null quantity to zero
                    if (angular.isUndefinedOrNullOrEmpty(s['OrderQty'])) s['OrderQty'] = 0;
                    finalItems.push(s);
                }
            });

            $scope.editOrders.orderDetails.OrderEquipmentDetails = finalItems.sortBy('EquipmentID');
        }

        var updateCustomerLocationDataLock = 0;
        $scope.updateCustomerLocationData = function() {

            if (angular.isUndefinedOrNullOrEmpty($scope.editOrders.orderDetails.CustLocKey) || angular.isUndefinedOrNullOrEmpty($scope.editOrders.orderDetails.ReqDelivDateTime)) {
                return;
            }

            if (updateCustomerLocationDataLock === 0) {
                updateCustomerLocationDataLock++;
                // because of equipment we have to send also date parameter
                ordersService.getOrderLocationInfo($scope.editOrders.orderDetails.CustLocKey, $scope.editOrders.orderDetails.ReqDelivDateTime).then( // OMG this is string instead of date!
                    function (data) {
                        if (!data.Successful || angular.isUndefinedOrNullOrEmpty(data.TanksAndEquipment)) {
                            $scope.editOrders.show.buttonBar = false;
                            $scope.notifier.show('Could not find any tank information for the selected Ship To location', 'error');
                            $scope.hideTankAndEquipment = true;
                            updateCustomerLocationDataLock--;
                            return;
                        }
                        else {
                            var validTankOrEquipment = true;
                            if (!savedTankData.isEmpty()) {
                                mergeTankData(savedTankData, data.TanksAndEquipment);
                                // check if order customer location has valid tank and / or equipment
                                validTankOrEquipment = $scope.editOrders.orderDetails.OrderDetails.length > 0 || $scope.editOrders.orderDetails.OrderEquipmentDetails.length > 0;
                            }
                            else {
                                copyTankData(data.TanksAndEquipment.filter(function (te) { return te.TankNumber; }));
                                copyEquipmentData(data.TanksAndEquipment.filter(function (te) { return te.EquipmentKey; }));
                                if ($scope.editOrders.orderDetails.OrderDetails.length <= 0) {
                                    $scope.notifier.show('Could not find any tank information for the selected Ship To location', 'warning');
                                }
                            }
                            dataBetweenDateChanges = [];

                            $scope.hideTankAndEquipment = !validTankOrEquipment;
                            $scope.editOrders.show.buttonBar = validTankOrEquipment;
                        }

                        $scope.editOrders.context.DeliveryDT = data.DeliveryDT;

                        if (angular.isUndefinedOrNullOrEmpty($scope.editOrders.orderDetails.OrdDelivDirections) ||
                            angular.isUndefinedOrNullOrEmpty($scope.editOrders.orderDetails.OrdKey)) {
                            $scope.editOrders.orderDetails.OrdDelivDirections = data.DeliveryDirections;
                        }

                        if (!angular.isUndefinedOrNullOrEmpty($scope.prevCustLocKey) &&
                            ($scope.prevCustLocKey) !== ($scope.editOrders.orderDetails.CustLocKey)) {
                            $scope.editOrders.orderDetails.OrdDelivDirections = data.DeliveryDirections;
                            $scope.prevCustLocKey = $scope.editOrders.orderDetails.CustLocKey;
                        }

                        $scope.kendo.ordersGridInit($scope.editOrders.orderDetails.OrderDetails, !$scope.editOrders.orderDetails.OrderDetails.all({ ID: "" }));
                        $scope.kendo.ordersEquipmentGridInit($scope.editOrders.orderDetails.OrderEquipmentDetails);
                        updateCustomerLocationDataLock--;
                    }, function (error) {
                        updateCustomerLocationDataLock--;
                        throw error;
                    }
                );
            }
        };

        $scope.$watch('editOrders.context.DeliveryDT', function updateDateTime()
        {
            let requestedDT, suggestedDT, theWinner;

            if (!angular.isUndefinedOrNullOrEmpty($scope.editOrders.orderDetails.ReqDelivDateTime) && 
                    !isNaN(Date.parse($scope.editOrders.orderDetails.ReqDelivDateTime))) {
                requestedDT = new Date($scope.editOrders.orderDetails.ReqDelivDateTime.replace(/-/g, "/").replace(/T/g, " "));
            }

            if (!angular.isUndefinedOrNullOrEmpty($scope.editOrders.context.DeliveryDT) && 
                    !isNaN(Date.parse($scope.editOrders.context.DeliveryDT)))
            {
                let temp = $scope.editOrders.context.DeliveryDT;
                suggestedDT = new Date(temp.replace(/-/g, "/").replace(/T/g, " "));
            }

            if (requestedDT >= suggestedDT) {
                theWinner = requestedDT;
            }

            //save for validation
            $scope.editOrders.context.MinDateTime = theWinner;
            $scope.editOrders.orderDetails.ReqDelivDateTime = $filter('date')(new Date(theWinner), 'MM/dd/yyyy h:mm a');
        });

        $scope.$watch('editOrders.orderDetails.ReqDelivDateTime', function (reqDelivDateTimeString, oldReqDelivDateTimeString) {
            // OMG using strings instead of Date objects :(...
            if (updateCustomerLocationDataLock === 0) {
                if (reqDelivDateTimeString !== oldReqDelivDateTimeString &&
                !angular.isUndefinedOrNullOrEmpty(oldReqDelivDateTimeString) && !angular.isUndefinedOrNullOrEmpty(reqDelivDateTimeString) &&
                angular.isString(oldReqDelivDateTimeString) && angular.isString(reqDelivDateTimeString)) {
                    var oldReqDelivDateTime = new Date(oldReqDelivDateTimeString.replace(/-/g, "/").replace(/T/g, " "));
                    var reqDelivDateTime = new Date(reqDelivDateTimeString.replace(/-/g, "/").replace(/T/g, " "));

                    if (oldReqDelivDateTime.toDateString() !== reqDelivDateTime.toDateString()) {
                        // user changed date => get equipment list for that date
                        // we save quantities - so we will be able to merge them with new values, because a lot of items might be the same when you just change a date.
                        dataBetweenDateChanges = $scope.kendo.ordersGrid.dataSource.data().toJSON().concat($scope.kendo.ordersEquipmentGrid.dataSource.data().toJSON());
                        $scope.updateCustomerLocationData();
                    }
                }
            }
        });

        $scope.hasAnyTanks = function () {
            return $scope.editOrders.orderDetails.OrderDetails && $scope.editOrders.orderDetails.OrderDetails.length > 0 && !
                $scope.hideTankAndEquipment;
        };

        $scope.hasAnyEquipment = function () {
            return $scope.editOrders.orderDetails.OrderEquipmentDetails && $scope.editOrders.orderDetails.OrderEquipmentDetails.length > 0 && !
                $scope.hideTankAndEquipment;
        };
    })
    .controller("openOrdersCtrl", function ($scope, ordersService, templates, localStrings)
    {
        'use strict';      
        $scope.checkForAppliedProfile();

        if (!$scope.appInfo.webUser.CurrentAuthorization.CanAddEditWebOrders) {
            $scope.goTo('Orders/OrderHistory');
        }

        function getOpenOrders()
        {
            ordersService.getOpenOrders($scope.appInfo.webUser.CurrentAuthorization.CustKey).then(
                function (data)
                {
                    if (data.Successful)
                    {
                        if (!angular.isUndefinedOrNullOrEmpty(data.OpenOrders))
                        {
                            $scope.openOrders = data.OpenOrders;
                            $scope.kendo.ordersGridInit();
                        }
                        else
                            $scope.notifier.show(localStrings.getLocalizedString("SelectionCriteriaNoResults"), 'warning');
                    }
                    else
                        $scope.modalifier.showSetupError();
                }
            );
        }

        $scope.kendo = {};
        $scope.kendo.ordersGrid = {};
        $scope.kendo.ordersGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "Number",
                    title: localStrings.getLocalizedString("Order"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "PONumber",
                    title: localStrings.getLocalizedString("PONumber"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "LocDescription",
                    title: localStrings.getLocalizedString("ShipTo"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Contact",
                    title: localStrings.getLocalizedString("Contact"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "DeliveryDate",
                    title: localStrings.getLocalizedString("DateTime"),
                    width: 130,
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "Status",
                    title: localStrings.getLocalizedString("Status"),
                    width: 130,
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "EnteredBy",
                    title: localStrings.getLocalizedString("EnteredBy"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<i class='fa fa-pencil' ng-if='dataItem.CanEdit' ng-click='openOrder(dataItem)'></i>",
                    width: 50
                },
                {
                    template: "<i class='fa fa-trash-o' ng-if='dataItem.CanCancel' ng-click='goTo(\"Orders/DisplayOrder/\"+ dataItem.Key)'></i>",
                    width: 50
                }
            ],
            dataSource: new kendo.data.DataSource(),
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true
        };

        $scope.kendo.ordersGridInit = function ()
        {
            $scope.kendo.ordersGridDS = new kendo.data.DataSource({
                data: $scope.openOrders,
                schema: {
                    parse: function (response)
                    {
                        $.each(response, function (idx, elem)
                        {
                            if (elem.DeliveryDate && typeof elem.DeliveryDate === "string")
                            {
                                elem.DeliveryDate = Date.create(elem.DeliveryDate).format('{MM}/{dd}/{yyyy} {12hr}:{mm} {tt}');
                            }
                        });
                        return response;
                    }
                }
            });

            $scope.kendo.ordersGridDS.read();
            $scope.kendo.ordersGrid.dataSource.data($scope.kendo.ordersGridDS.data());
        };

        $scope.initialize = function ()
        {

            if (!$scope.appInfo.webUser.CurrentAuthorization.CanAddEditWebOrders)
                $scope.goTo('Orders/OrderHistory');

            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.openOrders = $scope.locationInfo.sideMenuData.openOrders || {};
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 20;
            $scope.locationInfo.sideMenu = templates.getTemplate("OrdersSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "1";

            $scope.openOrders = [];
            getOpenOrders();
        };

        $scope.openOrder = function openOrder(order)
        {
            switch (order.Status)
            {
                case 'Pending':
                    $scope.goTo('Orders/EditOrder/' + order.Key);
                    break;

                default:
                    $scope.goTo('Orders/DisplayOrder/' + order.Key);
                    break;
            }
        };
    })
    .controller("orderHistoryCtrl", function ($scope, customerService, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.orders = $scope.locationInfo.sideMenuData.orders || {};
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 20;
            $scope.locationInfo.sideMenu = templates.getTemplate("OrdersSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "3";
        };
    })
    .controller("orderHistorySideMenuCtrl", function ($scope, validatorSvc, customerService, WebFunctions, $filter, reportingService, Authentication, localizationSvc)
    {
        'use strict';
        //        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        var localStrings = localizationSvc.getCachedLocalizationsContainer("OrdersLocalization");
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.ViewOrderHistoryandPriceNotices))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.menuLocation = 2;
            $scope.locationInfo.subMenuLocation = 20;
            $scope.locationInfo.sideMenuData.orders = {
                quotes: "-1",
                ordered: "-1",
                dispatched: "-1",
                delivered: "-1",
                billed: "-1",
                fuel: "-1",
                warehouse: "-1",
                noninventory: "-1",
                location: 0,
                productSearchText: '',
                packageSearchText: '',
                orderInvoiceBOLSearchTerm: '',
                reportType: 'Orders',
                showReportDetail: false
            };
            $scope.$root.reportSettings.reportKey = 13;

            customerService.getCustomerLocations($scope.appInfo.webUser.CurrentAuthorization.CustKey, 1).then(function (data)
            {
                $scope.locations = $filter('filter')(data.Locations, {Active: '!0'});
                $scope.locations.unshift({
                    Active: -1,
                    Description: localStrings.getLocalizedString("All"),
                    Key: 0
                });
                setTimeout(function() {
                    $scope.locationInfo.sideMenuData.orders.location = 0;
                }, 0);
            });
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.orders = $scope.reportSettings.saveOptions.orders;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.orders = $scope.locationInfo.sideMenuData.orders;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;

            var reportName = $scope.locationInfo.sideMenuData.orders.showReportDetail ?
                "OrdersDetailed" : $scope.locationInfo.sideMenuData.orders.reportType;

            var qs = "reportName=" + reportName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-IncludeStatusOpen=" + $scope.locationInfo.sideMenuData.orders.quotes +
                "&RP-IncludeStatusOrdered=" + $scope.locationInfo.sideMenuData.orders.ordered +
                "&RP-IncludeStatusDispatched=" + $scope.locationInfo.sideMenuData.orders.dispatched +
                "&RP-IncludeStatusDelivered=" + $scope.locationInfo.sideMenuData.orders.delivered +
                "&RP-IncludeStatusBilled=" + $scope.locationInfo.sideMenuData.orders.billed +
                "&RP-IncludeTypeFuel=" + $scope.locationInfo.sideMenuData.orders.fuel +
                "&RP-IncludeTypeWarehouse=" + $scope.locationInfo.sideMenuData.orders.warehouse +
                "&RP-StartDate=" + $scope.dateSelector.startDate +
                "&RP-EndDate=" + $scope.dateSelector.endDate +
                "&RP-ShipToKey=" + $scope.locationInfo.sideMenuData.orders.location +
                "&RP-ProductSearchTerm=" + $scope.locationInfo.sideMenuData.orders.productSearchText +
                "&RP-PackageSearchTerm=" + $scope.locationInfo.sideMenuData.orders.packageSearchText +
                "&RP-IncludeTypeNonInventory=" + $scope.locationInfo.sideMenuData.orders.noninventory +
                "&RP-OrderInvoiceBOLSearchTerm=" + $scope.locationInfo.sideMenuData.orders.orderInvoiceBOLSearchTerm;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);

        };
    })
;