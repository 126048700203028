/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.paperworkTanks', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.paperworkDeliveries',[]).service('PaperworkDeliveriesService', function($q, apiService, $filter) {
    'use strict';

    return {
        getDeliveriesWithContext: function (paperworkBatchKey ,forViewOnly) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetPaperworkDeliveriesWithContext/',
                    params: { paperworkBatchKey: paperworkBatchKey, forViewOnly: forViewOnly }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        getDelivery: function (deliveryKey, paperworkBatchKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetPaperworkDelivery/',
                    params: { deliveryKey: deliveryKey, paperworkBatchKey: paperworkBatchKey }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        getSiteFuelAuthorizedOrigins: function (paperworkBatchKey, vendorKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetSiteFuelAuthorizedOrigins/',
                    params: { paperworkBatchKey: paperworkBatchKey, vendorKey: vendorKey }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        savePaperworkDelivery: function(deliveryHeader, delivery) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SavePaperworkDelivery/',
                    data: {
                        DeliveryHeader: deliveryHeader,
                        FuelDelivery: delivery
                    }
                }
            );
            apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        deletePaperworkDelivery: function(delivery, paperworkBatchKey) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/DeletePaperworkDelivery/',
                    params: { paperworkBatchKey: paperworkBatchKey },
                    data: delivery
                }
            );
            apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});