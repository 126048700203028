/**
 * Created by rhampton on 7/11/2014.
 */
angular.module('services.userAccounts', ['core.ajax']);

angular.module('services.userAccounts', []).service('userAccountService', function ($q, apiService)
{
    'use strict';
    function execute(apiSvcSettings, deferred)
    {
        apiService.execute(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );
    }

    function executeAndNotify(apiSvcSettings, deferred)
    {
        apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
            function (data)
            {
                deferred.resolve(data.Data);
            }
        );
    }

    return {
        loadContactContext: function (customerContactKey)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetContactContext/',
                    data: {
                        CustomerContactKey: customerContactKey
                    },
                    skipValidations: true
                }
            );
            execute(apiSvcSettings, deferred);
            return deferred.promise;
        },
        listContacts: function (customer)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/ListCustomerContacts/',
                    data: {
                        CustomerKey: customer
                    },
                    skipValidations: true
                }
            );
            execute(apiSvcSettings, deferred);
            return deferred.promise;
        },
        getBlankContact: function ()
        {
            var contact = {
                Active: -1,
                AltEmailAddress: "",
                AltExt: "",
                AltExt2: "",
                AltPhone: "",
                AltPhone2: "",
                AltPhone2Formatted: "",
                AltPhone2Type: -1,
                AltPhoneFormatted: "",
                AltPhoneType: -1,
                AltSpdDial: "",
                AltSpdDial2: "",
                CnSalKey: null,
                CPPrcNtcAllProducts: 0,
                CPPrcNtcChgByDateTime: null,
                CPPrcNtcChgByUser: null,
                CPPrcNtcSignupDateTime: null,
                CPPrcNtcSignupUser: null,
                CreatedBy: "",
                CustKey: 0,
                EmailAddress: "",
                EmailAddressOrig: "",
                Ext: "",
                FAX: "",
                FAXExt: "",
                FAXFormatted: "",
                FAXSpdDial: "",
                FaxPrefix: "",
                FirstName: "",
                Greeting: "",
                Key: -1,
                LastChgBy: "",
                LastName: "",
                Notes: "",
                Phone: "",
                PhoneFormatted: "",
                PhoneType: -1,
                ReceiveMarketingLetters: false,
                SpdDial: "",
                TextAddressPhone: -1,
                TextAddressTextMsgDomKey: -1,
                Title: "",
                WebActive: false,
                AllWebFunctions: false,
                WebMustChgPwd: -1,
                WebPWAccessSingleSite: 0,
                WebPWAccessSiteKey: null,
                WebPwd: "",
                WebQuestionAnswer: "",
                WebQuestionType: -1,
                Timestamp: "",
                EditorName: null,
                EditorEmailAddress: null,
                ApplyToAll: false,
                CustomerContactWebFunctions: [],
                CustomerContactWebAlerts: [],
                ConfirmEmail: ""
            };
            return contact;
        },
        saveContact: function (customerContact)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SaveContact/',
                    data: {
                        CustomerContact: customerContact
                    }
                }
            );
            executeAndNotify(apiSvcSettings, deferred);
            return deferred.promise;
        },
        unsubscribeContact: function (contKey)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/UnsubscribeContact/',
                    data: {
                        ContactKey: contKey
                    }
                }
            );
            execute(apiSvcSettings, deferred);
            return deferred.promise;
        }
    };
});