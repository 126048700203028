angular.module('services.pages', ['core.ajax']);
angular.module('services.pages', [])
    .factory('PagesService', function ($q, apiService) {
        'use strict';
        var localCache = {};
        var Cache = {
            add: function (name, object, expiration) {
                var exp = expiration || 0;
                var now = new Date().getTime();
                localCache[name] = {
                    data: object,
                    inserted: now,
                    expiration: (exp === 0) ? 0 : (now + exp * 1000)
                };
            },
            get: function (name) {
                var now = new Date().getTime();
                if (
                    (Object.keys(localCache).indexOf(name) !== -1 && localCache[name].expiration === 0) || (Object.keys(localCache).indexOf(name) !== -1 && localCache[name].expiration > now)
                ) {
                    return localCache[name].data;
                }
                else {
                    //remove expired object from cache
                    if (Object.keys(localCache).indexOf(name) !== -1) {
                        delete localCache[name];
                    }
                    return null;
                }
            },
            exists: function (name) {
                var now = new Date().getTime();
                // if object exists and expiration is 0 (never expires) return true
                // if object exists and expiration is above NOW, return true
                // if object exists and expiration is bellow NOW return false and force services to retrieve new data
                return (
                    (Object.keys(localCache).indexOf(name) !== -1 && localCache[name].expiration === 0) || (Object.keys(localCache).indexOf(name) !== -1 && localCache[name].expiration > now)
                ) ? true : false;
            }
        };
        return {
            getWebProfileSiteLayout: function (profileKey) {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'GET',
                        url: '../api/GetWebProfileSiteLayout/' + profileKey + '/',
                        skipValidations: true
                    }
                );
                if (Cache.exists("getWebProfileSiteLayout")) {
                    deferred.resolve(Cache.get("getWebProfileSiteLayout"));
                }
                else {
                    apiService.execute(apiSvcSettings).then(
                        function (data) {
                            Cache.add("getWebProfileSiteLayout", data.Data);
                            deferred.resolve(data.Data);
                        },
                        function (data) {
                            deferred.reject(data.ErrorMessages);
                        }
                    );
                }
                return deferred.promise;
            },
            getAccessToken: function (profileKey, nodeKey) {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'GET',
                        url: '../api/GetAccessToken/' + profileKey + '/' + nodeKey + '/',
                        skipValidations: true
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data) {
                        deferred.resolve(data.Data);
                    },
                    function (data) {
                        deferred.reject(data.ErrorMessages);
                    }
                );
                return deferred.promise;
            }
        };
    });
