/**
 * Created by rhampton on 3/24/14.
 */
angular.module("pnAccountReports", ['core.objects'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/Account/Reports/RecentActivity",
            {
                templateUrl: templateProvider.getTemplate("RecentActivity"),
                controller: "recentActivityCtrl"
            }
        )
            .when("/Account/Reports/Invoices",
            {
                templateUrl: templateProvider.getTemplate("Invoices"),
                controller: "invoicesCtrl"
            }
        )
            .when("/Account/Reports/PaymentHistory",
            {
                templateUrl: templateProvider.getTemplate("PaymentHistory"),
                controller: "paymentHistoryCtrl"
            }
        )
            .when("/Account/Reports/TransactionHistory",
            {
                templateUrl: templateProvider.getTemplate("TransactionHistory"),
                controller: "transactionHistoryCtrl"
            }
        )
            .when("/Account/Reports/ScheduledPayments",
            {
                templateUrl: templateProvider.getTemplate("ScheduledPayments"),
                controller: "scheduledPaymentsCtrl"
        }
        )
        .when("/Account/Reports/UnappliedPayments",
            {
                templateUrl: templateProvider.getTemplate("UnappliedPayments"),
                controller: "unappliedPaymentsCtrl"
            }
        )
        .when("/Account/Reports/CreditCardActivity",
            {
                templateUrl: templateProvider.getTemplate("CreditCardActivity"),
                controller: "creditCardActivityCtrl"
            }
        )
        .when("/Account/Reports/TenNinetyNine",
            {
                templateUrl: templateProvider.getTemplate("TenNinetyNine"),
                controller: "tenNinetyNineCtrl"
            }
        )

;
})
.controller('reportSideMenuCtrl', function ($scope, WebFunctions)
{
    'use strict';
    $scope.initialize = function ()
    {
        $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
        };
    })
    .controller('reportSideMenuInvoicesCtrl', function ($scope, validatorSvc, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.invoices) $scope.locationInfo.sideMenuData.invoices = {};
            $scope.locationInfo.sideMenuData.invoices.reportType = 'InvoiceByDateRange';
            $scope.locationInfo.sideMenuData.invoices.reportTypeDetail = 'InvoiceByDateRangeDetail';
            $scope.locationInfo.sideMenuData.invoices.outstandingOnly = 'True';
            $scope.locationInfo.sideMenuData.invoices.showReportDetail = 'False';
            $scope.$root.reportSettings.reportKey = 2;

            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.maxNumOfDays = 90;
            $scope.dateSelector.selection = 13;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.invoices.outstandingOnly = $scope.reportSettings.loadOptions.outstandingOnly;
            $scope.locationInfo.sideMenuData.invoices.showReportDetail = $scope.reportSettings.loadOptions.showReportDetail;
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.outstandingOnly = $scope.locationInfo.sideMenuData.invoices.outstandingOnly;
            $scope.reportSettings.saveOptions.showReportDetail = $scope.locationInfo.sideMenuData.invoices.showReportDetail;
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var reportName = $scope.locationInfo.sideMenuData.invoices.showReportDetail === 'True' ?
                $scope.locationInfo.sideMenuData.invoices.reportTypeDetail :
                $scope.locationInfo.sideMenuData.invoices.reportType;

            var qs = "reportName=" + reportName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-HideZeroBalance=" + $scope.locationInfo.sideMenuData.invoices.outstandingOnly +
                "&RP-startDate=" + $scope.dateSelector.startDate +
                "&RP-endDate=" + $scope.dateSelector.endDate +
                "&RP-showReportDetail=" + $scope.locationInfo.sideMenuData.invoices.showReportDetail;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller('reportSideMenuTransactionHistoryCtrl', function ($scope, validatorSvc, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();

            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.transactionHistory) $scope.locationInfo.sideMenuData.transactionHistory = {};
            $scope.locationInfo.sideMenuData.transactionHistory.reportType = 'TransactionHistory';
            $scope.locationInfo.sideMenuData.transactionHistory.showReportDetail = 'False';
            $scope.$root.reportSettings.reportKey = 6;

        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var qs = "reportName=" + $scope.locationInfo.sideMenuData.transactionHistory.reportType +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-startDate=" + $scope.dateSelector.startDate +
                "&RP-endDate=" + $scope.dateSelector.endDate;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller('reportSideMenuCreditCardActivityCtrl', function ($scope, validatorSvc, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.creditCardActivity) $scope.locationInfo.sideMenuData.creditCardActivity = {};
            $scope.locationInfo.sideMenuData.creditCardActivity.groupByDate = 0;
            $scope.locationInfo.sideMenuData.creditCardActivity.showReportDetail = 'False';
            $scope.locationInfo.sideMenuData.creditCardActivity.pending = '0';
            $scope.$root.reportSettings.reportKey = 7;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.creditCardActivity.groupByDate = $scope.reportSettings.saveOptions.groupByDate;
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
            $scope.setReport();
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.groupByDate = $scope.locationInfo.sideMenuData.creditCardActivity.groupByDate;
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var reportType = $scope.locationInfo.sideMenuData.creditCardActivity.groupByDate === 1 ? 'CreditCardActivityByDate' : 'CreditCardActivityByDescription';
            var qs = "reportName=" + reportType +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-startDate=" + $scope.dateSelector.startDate +
                "&RP-endDate=" + $scope.dateSelector.endDate;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller('reportSideMenuScheduledPaymentsCtrl', function ($scope, validatorSvc, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.scheduledPayments) $scope.locationInfo.sideMenuData.scheduledPayments = {};
            $scope.locationInfo.sideMenuData.scheduledPayments.reportType = 'ScheduledPaymentsAndEfts';
            $scope.locationInfo.sideMenuData.scheduledPayments.reportTypeDetail = 'ScheduledPaymentsAndEftsDetail';

            $scope.locationInfo.sideMenuData.scheduledPayments.showReportDetail = 'False';
            $scope.locationInfo.sideMenuData.scheduledPayments.pending = '0';
            $scope.$root.reportSettings.reportKey = 5;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.scheduledPayments.pending = $scope.reportSettings.loadOptions.pending;
            $scope.locationInfo.sideMenuData.scheduledPayments.showReportDetail = $scope.reportSettings.loadOptions.showReportDetail;
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.pending = $scope.locationInfo.sideMenuData.scheduledPayments.pending;
            $scope.reportSettings.saveOptions.showReportDetail = $scope.locationInfo.sideMenuData.scheduledPayments.showReportDetail;
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var reportName = $scope.locationInfo.sideMenuData.scheduledPayments.showReportDetail === 'True' ?
                $scope.locationInfo.sideMenuData.scheduledPayments.reportTypeDetail :
                $scope.locationInfo.sideMenuData.scheduledPayments.reportType;

            var qs = "reportName=" + reportName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-startDate=" + $scope.dateSelector.startDate +
                "&RP-endDate=" + $scope.dateSelector.endDate +
                "&RP-pending=" + $scope.locationInfo.sideMenuData.scheduledPayments.pending +
                "&RP-showReportDetail=" + $scope.locationInfo.sideMenuData.scheduledPayments.showReportDetail;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller('reportSideMenuPaymentHistoryCtrl', function ($scope, validatorSvc, $rootScope, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.paymentHistory) $scope.locationInfo.sideMenuData.paymentHistory = {};
            $scope.locationInfo.sideMenuData.paymentHistory.showReportDetail = false;
            $scope.$root.reportSettings.reportKey = 3;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.paymentHistory.showReportDetail = $scope.reportSettings.loadOptions.showReportDetail;
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.showReportDetail = $scope.locationInfo.sideMenuData.paymentHistory.showReportDetail;
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var rptName = $scope.locationInfo.sideMenuData.paymentHistory.showReportDetail ? 'PaymentHistoryIAD' : 'PaymentHistory';
            var qs = "reportName=" + rptName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-startDate=" + $scope.dateSelector.startDate +
                "&RP-endDate=" + $scope.dateSelector.endDate;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller('reportSideMenuTenNinetyNineCtrl', function ($scope, validatorSvc, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.tenNinetyNine) $scope.locationInfo.sideMenuData.tenNinetyNine = {};
            $scope.locationInfo.sideMenuData.tenNinetyNine.reportType = '1099-KByDate';
            $scope.locationInfo.sideMenuData.tenNinetyNine.useDateRange = '1';
            $scope.$root.reportSettings.reportKey = 8;
            $scope.exportReport = false;
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.dateSelector.selection = parseInt($scope.reportSettings.loadOptions.selection, 10);
            $scope.dateSelector.numOfDays = parseInt($scope.reportSettings.loadOptions.numOfDays, 10);
            $scope.dateSelector.startDate = $scope.reportSettings.loadOptions.startDate;
            $scope.dateSelector.endDate = $scope.reportSettings.loadOptions.endDate;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.selection = $scope.dateSelector.selection;
            $scope.reportSettings.saveOptions.numOfDays = $scope.dateSelector.numOfDays;
            $scope.reportSettings.saveOptions.startDate = $scope.dateSelector.startDate;
            $scope.reportSettings.saveOptions.endDate = $scope.dateSelector.endDate;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var qs = "reportName=" + $scope.locationInfo.sideMenuData.tenNinetyNine.reportType +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-StartDate=" + $scope.dateSelector.startDate +
                "&RP-EndDate=" + $scope.dateSelector.endDate;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller("recentActivityCtrl", function ($scope, WebFunctions, WebFunctionGroups, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermissions(WebFunctionGroups.AccountReports) || !$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                if ($scope.checkCurrentUserPermissions(WebFunctionGroups.AccountPayments))
                    $scope.goTo("/Account/Payments/ViewScheduledPayments");
                else
                    $scope.goTo("/Account/Profile/YourProfile");
                return;
            }

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.recentActivity) $scope.locationInfo.sideMenuData.recentActivity = {};
            $scope.locationInfo.sideMenuData.recentActivity.reportType = '/RecentActivity';
            $scope.locationInfo.sideMenuData.subMenu = "1";
            $scope.custKey = $scope.appInfo.webUser.CurrentAuthorization.CustKey;
            $scope.exportReport = "false";
            $scope.applyAction();
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            var qs = "reportName=" + $scope.locationInfo.sideMenuData.recentActivity.reportType +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller("invoicesCtrl", function ($scope, WebFunctions, templates)
    {

        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.invoices) $scope.locationInfo.sideMenuData.invoices = {};
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "2";
        };
    })
    .controller("paymentHistoryCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
        {
            $scope.goTo("/Home");
        }
        $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
        $scope.locationInfo.sideMenuData.subMenu = "3";
    })
    .controller("transactionHistoryCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
        {
            $scope.goTo("/Home");
        }
        $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
        $scope.locationInfo.sideMenuData.subMenu = "8";
    })
    .controller("scheduledPaymentsCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
        {
            $scope.goTo("/Home");
        }
        $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
        $scope.locationInfo.sideMenuData.subMenu = "5";
    })
    .controller("creditCardActivityCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
        {
            $scope.goTo("/Home");
        }
        $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
        $scope.locationInfo.sideMenuData.subMenu = "6";
    })
    .controller("unappliedPaymentsCtrl", function ($scope, WebFunctions, reportingService, Authentication, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
            {
                $scope.goTo("/Home");
            }

            $scope.locationInfo.menuLocation = 1;
            $scope.locationInfo.subMenuLocation = 10;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.subMenu = "4";
            $scope.locationInfo.sideMenuData.unappliedPayments = {};
            $scope.locationInfo.sideMenuData.unappliedPayments.reportType = '/UnappliedPayments';
            $scope.applyAction();
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            var qs = "reportName=" + $scope.locationInfo.sideMenuData.unappliedPayments.reportType +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })
    .controller("tenNinetyNineCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        if (!$scope.checkCurrentUserPermission(WebFunctions.AccountActivity))
        {
            $scope.goTo("/Home");
        }
        $scope.locationInfo.menuLocation = 1;
        $scope.locationInfo.subMenuLocation = 10;
        $scope.locationInfo.sideMenu = templates.getTemplate("ReportSideMenu");
        if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
        $scope.locationInfo.sideMenuData.subMenu = "7";
    })
;