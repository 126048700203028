
angular.module('pdi.extensions', [])
    .factory('dateTimeFormatPatterns', function() {
        'use strict';

        function formatForCulture(type, formatType) {

            var formatStr = '';

            if (formatType === 1) //kendo
            {
                if (type === 0) { //date

                    formatStr = "mm/dd/yyyy";

                } else if (type === 1) { //time

                    formatStr = "hh:mm t";

                } else { //date and time

                    formatStr = "mm/dd/yyyy hh:mm t";
                }
            } else if (formatType === 2) {  //input mask
                if (type === 0) { //date

                    formatStr = "m/d/yyyy";

                } else if (type === 1) { //time

                    formatStr = "hh:mm t";

                } else { //date and time

                    formatStr = "datetime12us";
                }
            } else {
                if (type === 0) { //date

                    formatStr = "MM/dd/yyyy";

                } else if (type === 1) { //time

                    formatStr = "HH:mm a";

                } else { //date and time

                    formatStr = "MM/dd/yyyy hh:mm a";
                }

            }

            return formatStr;
        }

        return {
            kendoDateFormat: formatForCulture(0, 1),
            kendoTimeFormat: formatForCulture(1, 1),
            kendoDateTimeFormat: formatForCulture(2, 1),
            inputMaskDateFormat: formatForCulture(0, 2),
            inputMaskTimeFormat: formatForCulture(1, 2),
            inputMaskDateTimeFormat: formatForCulture(2, 2),
            dateFormat: formatForCulture(0, 0),
            timeFormat: formatForCulture(1, 0),
            dateTimeFormat: formatForCulture(2,0)
        };
    })

    .directive('kendoDatePicker', [
        '$timeout', 'dateTimeFormatPatterns', function($timeout, dateTimeFormatPatterns) {
            'use strict';

            return {
                restrict: 'A',
                require: '?ngModel',
                link: function(scope, element, attrs, ctrl) {

                    var ntbCtrl;

                    $timeout(function() {
                        ntbCtrl = kendo.widgetInstance($(element[0]), kendo.ui);

                        if (ntbCtrl !== undefined) {
                            ntbCtrl.format = dateTimeFormatPatterns.kendoDateFormat;
                            $(element).inputmask(dateTimeFormatPatterns.inputMaskDateFormat, { clearMaskOnLostFocus: false });
                        }

                    },300);

                }
            };

        }
    ])

    .directive('kendoDateTimePicker', [
        '$timeout', 'dateTimeFormatPatterns', function($timeout, dateTimeFormatPatterns) {
            'use strict';

            return {
                restrict: 'A',
                require: '?ngModel',
                link: function(scope, element, attrs, ctrl) {

                    var ntbCtrl;

                    $timeout(function() {
                        ntbCtrl = kendo.widgetInstance($(element[0]), kendo.ui);

                        if (ntbCtrl !== undefined) {
                            ntbCtrl.format = dateTimeFormatPatterns.kendoDateTimeFormat;
                            $(element).inputmask(dateTimeFormatPatterns.inputMaskDateTimeFormat, {  clearMaskOnLostFocus: false });
                        }
                    },300);

                }
            };

        }
    ])

    .directive('kendoTimePicker', [
        '$timeout', 'dateTimeFormatPatterns', function($timeout, dateTimeFormatPatterns) {
            'use strict';

            return {
                restrict: 'A',
                require: '?ngModel',
                link: function(scope, element, attrs, ctrl) {

                    var ntbCtrl;

                    $timeout(function() {
                        ntbCtrl = kendo.widgetInstance($(element[0]), kendo.ui);

                        if (ntbCtrl !== undefined) {
                            ntbCtrl.format = dateTimeFormatPatterns.kendoTimeFormat;
                            $(element).inputmask(dateTimeFormatPatterns.inputMaskTimeFormat, { clearMaskOnLostFocus: false });
                        }
                    },300);
                }
            };

        }
    ]);


    