/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================
 services.documents MODULE
 ============================================================================= */
angular.module('services.documents', ['core.ajax']);

/* ==============================================================================
 DocumentService
 ============================================================================= */
angular.module('services.documents', []).factory('documentService', function ($q, ajax, apiService)
{

    'use strict';
    return {
        getNotifications: function (custkey, custContKey, type, beginDate, endDate)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetNotificationsList/',
                    data: {
                        CustKey: custkey,
                        CustContKey: custContKey === "null" ? null : custContKey,
                        Type: type === "null" ? null : type,
                        BeginDate: beginDate,
                        EndDate: endDate
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    deferred.resolve(data);
                }
            );
            return deferred.promise;
        },
        getStatements: function (custkey, beginDate, endDate)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetStatementsList/',
                    data: {
                        CustKey: custkey,
                        BeginDate: beginDate,
                        EndDate: endDate
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    deferred.resolve(data);
                }
            );
            return deferred.promise;
        },
        listDocuments: function (referenceNumber, description, startDate, endDate, customerKey, documentType, contactKey)
        {
            var deferred = $q.defer();
            var listAttachmentsCmdParms = {
                ReferenceNumber: referenceNumber,
                Description: description,
                StartDate: startDate,
                EndDate: endDate,
                CustomerKey: customerKey,
                DocumentType: documentType,
                ContactKey: contactKey
            };

            var apiSvcSettings = new ApiServiceSettings({
                method: 'POST',
                url: '../api/ListAttachments',
                data: listAttachmentsCmdParms
            });
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    var documetnList = data.Data;
                    deferred.resolve(documetnList);
                }
            );
            return deferred.promise;
        },
        Get1099AdviceList: function (cust, yb)
        {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/Get1099AdviceList/',
                    data: {
                        CustKey: cust,
                        YearsBack: yb
                    }
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data)
                {
                    deferred.resolve(data);
                }
            );
            return deferred.promise;
        }
    };
});