/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.contactUs', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.contactUs',[]).service('ContactUs', function($q, apiService, $filter) {
    'use strict';
    return {
        loadContactUsContext: function() {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'GET',
                    url: '../api/GetContactUsContext/',
                    data: {}
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        saveMessage: function(message) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/PostContactUsMessage/',
                    data: message
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});