/**
 * Created by rhampton on 2/27/2015.
 */
angular.module("pnContactUs", ['ngSanitize', 'core.objects', 'services.payments', 'core.directives'])
    .controller("ContactUs", function ($scope, ContactUs, localizationSvc)
    {
        var localStrings = localizationSvc.getCachedLocalizationsContainer("AppLocalization");
        function Message(ct, st, m)
        {
            this.ContactUsSubject = ct || 0;
            this.SubjectText = st || '';
            this.ContactUsMessage = m || '';
            this.CustomerContactKey = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.Key || 0;
        }

        $scope.appInfo.showContactUsModal = false;

        $scope.$watch('appInfo.showContactUsModal', function ()
        {
            if ($scope.appInfo.showContactUsModal)
            {
                $scope.message = new Message($scope.CommentContexts[0] ? $scope.CommentContexts[0].Key : '');
                $scope.getWebProfileMessage(2);
            }
        });

        $scope.initialize = function ()
        {
            $scope.CommentContexts = {};
            $scope.showErrorBox = false;

            ContactUs.loadContactUsContext().then(function (data)
            {
                $scope.CommentContexts = data.CommentContexts;
            });
        };

        $scope.$parent.sendMessage = function ()
        {
            if (!validateMessage())
                return;

            ContactUs.saveMessage($scope.message).then(
                function (data)
                {
                    if (data.MessageSent)
                    {
                        $scope.appInfo.showContactUsModal = false;
                        $scope.notifier.show(localStrings.getLocalizedString("MessageSent"), 'success');
                    }
                    else
                        $scope.notifier.show(localStrings.getLocalizedString("MessageNotSent"), 'error');
                });
        };

        function validateMessage()
        {
            var valid = true;

            if($scope.CommentContexts.length > 0 && $scope.message.ContactUsSubject.toNumber() < 1)
            {
                valid = false;
            }

            if($scope.CommentContexts.length <= 0 && $scope.message.SubjectText.isBlank())
            {
                valid = false;
            }

            if($scope.message.ContactUsMessage.isBlank())
            {
                valid = false;
            }

            $scope.showErrorBox = !valid;
            return valid;
        }
    });
