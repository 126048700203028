/* ==============================================================================

 CORE.AJAX MODULE

 Defines the core.ajax module and it all it's module dependencies

 ============================================================================= */
function ApiServiceSettings(settings)
{
    this.url = settings.url;
    this.data = settings.data;
    this.method = settings.method;
    this.params = settings.params;
    this.responseType = settings.responseType;
    this.scope = settings.scope; // if set then this is the scope that validations will execute for
    this.skipValidations = settings.skipValidations || false; // if true then POST or PUT validations eval is skipped
}
function ApiServiceOperationResult(data)
{
    this.Success = data.Success;
    this.SuccessMsg = data.SucessMessage;
    this.ErrorTitle = data.ErrorTitle;
    this.Errors = data.ErrorMessages || [];
    this.Warnings = data.WarningMessages || [];
    this.HasErrors = (this.Errors.length > 0);
    this.HasWarnings = (this.Warnings.length > 0);
    this.IsRedirect = data.IsRedirect;
    this.RedirectUrl = data.RedirectUrl;
    this.Data = data.Data;
}

angular.module('core.ajax', [])

    /* ==============================================================================

     CORE.AJAX

     Contains helpful methods that are common to most ajax/xhr commands.

     ============================================================================= */
    .factory('ajax', ['$http', function ($http)
    {
        'use strict';

        return {

            /*
             GET

             Simplified abstraction on top of Angular's $http provider.
             Performs a "GET" Request for a given url and data
             */
            get: function (url, data)
            {
                if (data === undefined)
                {
                    return $http({
                        method: 'GET',
                        url: url
                    });
                } else
                {
                    return $http({
                        method: 'GET',
                        url: url + '/' + data
                    });
                }
            },

            /*
             POST

             Simplified abstraction on top of Angular's $http provider.
             Performs a "POST" Request for a given url and data
             */
            post: function (url, data)
            {
                return $http({
                    method: 'POST',
                    url: url,
                    data: data,
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
            }
        };

    }])
    .service('apiService',
    ['$http', '$q', '$window', 'NotificationService',
        function ($http, $q, $window, NotificationService)
        {
            function webAPICall(settings, notifyOnSuccess, suppressErrors)
            {
                var opResults = {};
                var deferred = $q.defer();

                //// for POST and PUT execute validation on data
                //if ((settings.method === 'POST' || settings.method === 'PUT') && !settings.skipValidations) {
                //    if (!validatorSvc.executeValidations()) {
                //        return deferred.promise;
                //    }
                //}

                $http({
                    method: settings.method || 'GET',
                    url: settings.url,
                    data: settings.data,
                    params: settings.params
                })
                    .then(function (data, status, headers, config)
                    {
                        opResults = new ApiServiceOperationResult(data.data);

                        if (opResults.IsRedirect)
                        {
                            $window.location.assign(opResults.RedirectUrl);

                        }
                        else {
                            if (opResults.Success)
                            {
                                if (notifyOnSuccess)
                                    NotificationService.handleSuccess(settings, opResults, status, headers, config);
                                deferred.resolve(opResults, status, headers, config);
                            } else
                            {
                                if (!suppressErrors) {
                                    NotificationService.handleFailure(settings, opResults, status, headers, config);
                                }
                            }
                        }

                    })
                    .catch(function (data, status, headers, config)
                    {
                        if (!suppressErrors) {
                            NotificationService.handleError(settings, data.data, status, headers, config);
                        }
                    });

                return deferred.promise;
            }

            return {
                executeAndNotifyOnSuccess: function (settings)
                {
                    return webAPICall(settings, true, false);
                },
                executeAndNotifyOnSuccessAndSuppressErrors: function (settings)
                {
                    return webAPICall(settings, true, true);
                },
                execute: function (settings)
                {
                    return webAPICall(settings, false, false);
                },
                executeAndSuppressErrors: function (settings)
                {
                    return webAPICall(settings, false, true);
                }
            };
        }
    ]);
