/**
 * Created by rhampton on 7/11/2014.
 */
/* ==============================================================================

 SERVICES.Payments MODULE

 Defines the module namespace for the movie services

 ============================================================================= */
angular.module('services.paperworkTanks', ['core.ajax']);

/* ==============================================================================

 Payments SERVICE

 Contains the factories and services that pertain to retrieving
 and saving movies.

 ============================================================================= */
angular.module('services.paperworkTanks',[]).service('PaperworkTanksService', function($q, apiService, $filter) {
    'use strict';
    var netReadings = 0;
    var beginningAndEndingReadings = 1;
    var calculatedBasedOnUnitsAndPerUnitAmount = 2;

    function unFormatNumber(number)
    {
        if(number.toString().indexOf(',') > -1) {
            number = number.replace( /,/g , "");
        }
        if(number.toString().indexOf('$') > -1) {
            number = number.replace( /\$/g , "");
        }
        if(number.length === 0)// || isNaN(number))
            return "0";
        return number;
    }
    function convertTemp(temp, siteUOM, tankUOM){
        if(siteUOM === tankUOM) return temp;//no conversion necessary.
        if(tankUOM === 0) return (temp * 1.8) + 32; //Convert c to fl
        return (temp - 32) * 0.5555555556;//Convert f to c
    }
    function convertLinearUnits(units, siteUOM, tankUOM){
        if(siteUOM === tankUOM) return units;//no conversion necessary.
        if(tankUOM === 0) return units * 0.3937; //Convert cm to in
        return units * 2.54;//Convert in to cm
    }
    function convertVolumeToSiteUnits(units, siteUOM, tankUOM){
        if(siteUOM === tankUOM) return units;//no conversion necessary.
        if(siteUOM === 0) return units * 0.264172; //Convert liters to gal
        return units * 3.785412;//Convert gal to liters
    }
    function getVolume(tankCharts, tank, depth){
        var tankUOM = tank.UnitOfMeasure;
        var siteUOM = tank.MeasurementType;
        var tankChartKey = tank.TankTypeKey;
        var tankChart = tankCharts[tankChartKey + ""];

        var tankTypeUnits = convertLinearUnits(depth, siteUOM, tankUOM);
        var nStr = tankTypeUnits + '';
        var x = nStr.split('.');
        var wholeNumber = parseInt(x[0], 10) - 1;//subtract 1 from actual number to get index.
        var decimalNumber = parseFloat(x.length > 1 ? '0.' + x[1] : '0.0');//Partial units.
        var baseAmount = 0;
        var partialAmount = 0.00;
        if(wholeNumber > 0 && wholeNumber < tankChart.length) {
            baseAmount = tankChart[wholeNumber].Volume;
        }
        if(decimalNumber > 0 && (wholeNumber + 1) < tankChart.length){
            var nextAmount = tankChart[wholeNumber + 1].Volume;
            var avg = nextAmount - baseAmount;
            partialAmount = avg * decimalNumber;
        }
        if(wholeNumber >= tankChart.length)
            baseAmount = tankChart[tankChart.length-1].Volume;
        var units = baseAmount + partialAmount;
        return convertVolumeToSiteUnits(units, siteUOM, tankUOM);
    }
    return {
        loadTanks: function (paperworkBatchKey, siteKey, paperworkDate) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/GetPaperworkTanks/',
                    data: {
                        PaperworkBatchKey:paperworkBatchKey,
                        SiteKey:siteKey,
                        PaperworkDate:paperworkDate
                    },
                    skipValidations: true
                }
            );
            apiService.execute(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        },
        calculateTankDepth: function(tank, isMetric){
            var base = isMetric?100:12;
            var units = (tank.LargeUnits * base) +
                tank.SmallUnits +
                tank.PartialUnits;
            return units;
        },
        calculateWaterDepth: function(tank){
            var units = tank.WaterSmallUnits +
                tank.WaterPartialUnits;
            return units;
        },
        getVolumeFromTankChart: function(tank, tankCharts){
            return getVolume(tankCharts, tank, tank.Depth);
        },
        getMaxVolumeFromTankChart: function (tank, tankCharts) {
            var tankUOM = tank.UnitOfMeasure;
            var siteUOM = tank.MeasurementType;
            var tankChartKey = tank.TankTypeKey;
            var tankChart = tankCharts[tankChartKey + ""];
            var maxVolume = tankChart[tankChart.length - 1].Volume;
            return convertVolumeToSiteUnits(maxVolume, siteUOM, tankUOM);
        },
        getWaterVolumeFromTankChart: function(tank, tankCharts){
            return getVolume(tankCharts, tank, tank.WaterDepth);
        },
        adjustVolumeForTemperature: function(useTempCorrections, tank, tempCharts){
            if(!useTempCorrections || tank.TmpChartKey <= 0)
                return tank.Quantity;
            //var tankUOM = tank.UnitOfMeasure;
            //var siteUOM = tank.MeasurementType;
            var temperature = tank.Temperature;//$scope.convertTemp(tank.Temperature,siteUOM,tankUOM);
            var tmpChartKey = tank.TmpChartKey;
            var tempChart = tempCharts[tmpChartKey + ""];
            var conversionFactor = 1;
            for(var x=0; x<tempChart.length; x++){
                if(tempChart[x].Temperature >= temperature){//look for the temperature or the next greatest temperature.
                    conversionFactor = tempChart[x].ConvFactor;
                    break;
                }
                conversionFactor = tempChart[x].ConvFactor;//This will set it to the max conversion factor if none is found.
            }
            var qty = tank.Quantity;
            var wQty = tank.WaterQuantity;
            if(tank.TmpChartUseNetQty)
                return ((qty - wQty) / conversionFactor) + wQty;
            return qty / conversionFactor;

        },
        savePaperworkTanks: function(tankHeaders, tanks) {
            var deferred = $q.defer();
            var apiSvcSettings = new ApiServiceSettings(
                {
                    method: 'POST',
                    url: '../api/SavePaperworkTanks/',
                    data: {
                        PaperworkTankHeader: tankHeaders,
                        PaperworkTanks: tanks
                    }
                }
            );
            apiService.executeAndNotifyOnSuccess(apiSvcSettings).then(
                function (data) {
                    deferred.resolve(data.Data);
                }
            );
            return deferred.promise;
        }
    };
});